// import { translate } from "../../components/Language/Translate";

class AlbumFormSchema{

    constructor(){
        this.fields = {
            titleDE: {
                initialValue:"",
                validation:{
                    maxLength:60
                }
            },                
            titleEN: {
                initialValue:"",
                validation:{
                    maxLength:60
                }
            },                
            geolocation: {
                initialValue:"",
            },                
            description: {
                initialValue:"",
            },
    };
};

};
export default AlbumFormSchema