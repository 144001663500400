import React, { useEffect, useState, useCallback} from "react";
import {Button} from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompaniesFromAPI } from "../../actions/companies";
import Spinner from '../../components/Spinner';
import UserSchema from "./UserSchema";
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
// import { simpleRequest } from "../../newForms2/simpleRequest";
import FormValidation from "../Validation/FormValidation";
import { updateCurrentUserOnAPI } from "../../actions/currentUser";
import { useHistory } from "react-router";




  const UserEditByUser = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [constants, setConstants] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [success, setSuccess] = useState(false);

    const currentUser = useSelector(st => st.currentUser)
    const companies=useSelector(st => st.companies);
    const dispatch = useDispatch();
    const history = useHistory();
    // console.log({serverError, fieldErrors});
    
    const fetch = useCallback(async () => {
        dispatch(fetchCompaniesFromAPI())
        const consts= await new UserSchema(companies);
        const options = [{key:"user", value:"user"}];
        const userStatus = currentUser.userStatus
        userStatus !== "user" && options.unshift({key:userStatus, value:userStatus});
        consts.fields.userStatus.options=[...options];
        consts.fields.userStatus.value = userStatus
        consts.fields.userStatus.toolTip = translate('If you change to "user", you cannot undo this.');
        consts.fields.email.toolTip += " " + translate("If you change this value, you will be logged out and must confirm your email adress again.");
        const vars = {};
        ["nickname", "firstName", "lastName", "language", "userStatus", "email", "confirmEmail"]
        .forEach(i => vars[i] = currentUser[i] || "");
        vars.confirmEmail=currentUser.email;
        consts.oldValues = vars;
        (() => setVariables(vars))();
        (() => setConstants(consts))();
        (() => setIsLoading(false))();
    }, [dispatch, setVariables, setConstants, setIsLoading, companies, currentUser]);
    useEffect(() => {if(isLoading)fetch()},[isLoading, fetch]);

    if (isLoading || !constants || !variables) return <Spinner/>
    // console.log({constants, variables});
    
    const handleSubmit = async(e) => {
      e.preventDefault();
      // console.log('inside handleSubmit', e.target, {variables});
      const formValidation = new FormValidation({fieldSettings:constants.fields, variables, setFieldErrors});
      // console.log(formValidation, formValidation.valid);
      if(!formValidation.valid)return;
      const data = {...variables};
      delete data.confirmEmail;
      const changed = {}
      // console.log({data});
      Object.keys(data).forEach(k => {
        if(data[k] !== constants.oldValues[k])changed[k]=data[k];
      });
      // console.log({changed});
      // if(!changed.password){
      //   (()=>setServerError("Missing password."))();
      //   return;
      // }
      if(Object.keys(changed).length < 1){
        (()=>setServerError("No changed data."))();
        return;
      };
      dispatch(updateCurrentUserOnAPI(currentUser.username, changed));
      // console.log(response.data)
      if (changed.email){
        (()=> setSuccess(true))();
        setInterval(() => {
          localStorage.removeItem('user');
          window.location.href = '/';
        }, 5000);
      }else{
        history.goBack();
      };

      return;

    };

    const props = {fieldSettings:constants.fields, variables, setVariables, fieldErrors, setFieldErrors, serverError};
    const SuccessArea = () => (
      <div>
        <h4>{translate("The profil data were changed.")}</h4>
        <p>{translate("The email adress was changed.")} </p>
        <p>{translate("You will be logged out now. You get a new confirmation code to your new email adress.")} </p>
        <p>{translate("Please try to log in and follow the instructions.")}</p>
      </div>
    );
  
    return(
    
        <div>
            
             {success && <SuccessArea/>}
            <h4>{translate("Edit Profile")}</h4>
            {/* <h5>{translate("And please confirm the changes with your password.")}</h5> */}
            <FormFields props={props} />
            {serverError && <p className="text-danger">{serverError}</p>}
            <Button block size="lg" type="submit" onClick={handleSubmit}>Update</Button>
        </div>
        
    )

  };
  export default UserEditByUser;