import React from "react";
import SelectField from "../Fields/SelectField";
import TextField from "../Fields/TextField";
import TextArea from "../Fields/TextArea";
import CheckBox from '../Fields/CheckBox';
import FieldValidation from '../Validation/FieldValidation'
import UploadAreaSingle from "../upload/UploadAreaSingle";
import RadioField from "../Fields/RadioField";
import MultiCheck from "../destinations/MultiCheck";
import StarSet from "../Fields/StarSet";
import GeolocationField from "../Fields/GeolocationField";
import DateTime from "../Fields/DateTime";
import CurrencyField from "../Fields/CurrencyField";


const FormField = ({props}) => {
  // console.log(props);
  const {
    fieldSettings, 
    variables, 
    setVariables,
    fieldErrors, 
    setFieldErrors, 
    serverError,
    setServerError,
    field
  } = props;

  const handleChange = e => {
    setFieldErrors({...fieldErrors, [e.target.name]:""});
    setVariables({...variables, [e.target.name]:e.target.value});
    setServerError && serverError && (() => setServerError(""))();
  };
  
  const validateField = (e) => {
    const {name, value} = e.target;
    const validation = new FieldValidation(name, value, fieldSettings, variables);
    const objValid = validation.isValid()
    if(objValid.valid){
      const newFieldErrors = {...fieldErrors};
      delete newFieldErrors[name];
      setFieldErrors({...newFieldErrors});
    }else{
      setFieldErrors({...fieldErrors, [name]: objValid.errMsg});
    };
};
  
const newProps = {
    ...props,
    handleChange,
    validateField,
  };
  const keys = Object.keys(variables);
  // console.log({keys});
  return(
    <>
    	{[field].map(f => {
            // console.log(f.fieldName);

            switch (f.fieldType) {
                case "text":
                case "number":
                  return <TextField key={f.fieldName} name={f.fieldName} props={newProps} />
                case "select":
                  return <SelectField key={f} name={f.fieldName} props={newProps} />
                case "textarea":
                  return <TextArea key={f} name={f.fieldName} props={newProps}/>
                case "checkBox":
                  return <CheckBox name={f.fieldName} key={f.fieldName} props={newProps} />
                case "radio":
                  return <RadioField name={f} key={f.fieldName} props={newProps} />
                case "upload":
                  return <UploadAreaSingle key={f} name= {f.fieldName} props={newProps}/>
                case "multicheck":
                  return <MultiCheck key ={f} name={f.fieldName} props={newProps}/>
                case "starSet":
                  return <StarSet key = {f} name={f.fieldName} props={newProps}/>
                case "geolocation":
                  return <GeolocationField key = {f} name={f.fieldName} props={newProps} />
                case "dateTime":
                  return <DateTime key = {f} name={f.fieldName} props={newProps} />
                case "currency":
                  return <CurrencyField key = {f} name={f.fieldName} props={newProps}/>
                default:
                  return null;
            }
        })
      }
    </>
    )

};











// return(
//     <>  

//            {if(!fieldSettings[f])return null;
//             switch (field.fieldType) {
//                 case "text":
//                 case "number":
//                   return <TextField key={f} name={f} props={newProps} />
//                 case "select":
//                   return <SelectField key={f} name={f} props={newProps} />
//                 case "textarea":
//                   return <TextArea key={f} name={f} props={newProps}/>
//                 case "checkBox":
//                   return <CheckBox name={f} key={f} props={newProps} />
//                 case "radio":
//                   return <RadioField name={f} key={f} props={newProps} />
//                 case "upload":
//                   return <UploadAreaSingle key={f} name= {f} props={newProps}/>
//                 case "multicheck":
//                   return <MultiCheck key ={f} name={f} props={newProps}/>
//                 case "starSet":
//                   return <StarSet key = {f} name={f} props={newProps}/>
//                 case "geolocation":
//                   return <GeolocationField key = {f} name={f} props={newProps} />
//                 case "dateTime":
//                   return <DateTime key = {f} name={f} props={newProps} />
//                 default:
//                   return null;
//             }
//         }

//       </>
//   )

export default FormField;