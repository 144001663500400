import { translate } from "../../components/Language/Translate";

class DiaryFormSchema{

    constructor() {
        this.fields = {
            dateText:{
                label: translate("Date and Time (free text)"),
                value: "",
                type: "text",
                fieldType: "text"
            },
            description:{
                label: translate("Description"),
                value: "",
                type: "text",
                fieldType: "textarea"
            },
        };
    }
};
export default DiaryFormSchema;