import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { translate } from "../../components/Language/Translate";
// import DaysRouting from "./Routing/DaysRouting";
import DaysTours from "./Tours/DaysTours";
import { Col, Row } from "react-bootstrap";
import CruiseBackToCruiseButton from "../../components/CruiseBackToCruiseButton";
import Auth from "../../helpers/authNew";
import TourReportMain from "../../components/TourReports/TourReportMain";
import DaysRouting2 from "./Routing/DaysRouting2";
import DaysRouting from "./Routing/DaysRouting";


const DaysMain = () => {
    const [days, setDays] = useState([]);
    const [mainView, setMainView] = useState('main'); //tours
    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);

    useEffect(() => {
        setDays(currentCruise.days.sort((a,b) => `${a.date}-${a.arrival || '00:00'}` > `${b.date}-${b.arrival || "00:00"}` ? 1 : -1));
    }, [currentCruise]);

    const auth = new Auth(currentUser.userStatus, currentCruise.companyHandle);
    // console.log({auth}, auth.isCoSubAdmin)
    const darkClass = (bol) => bol ? "btn-dark" : "btn-outline-dark";
    const props = {days, setDays};

    if(!auth.isCoSubAdmin) return <p>Not authorized</p>

    return(
        <div>
            <CruiseBackToCruiseButton />
            <h3 className = "my-3">Admin Area - {currentCruise.companyName} - {translate("Routing and Tours")}</h3>

            <Row>
                <Col>
                    <button className={`btn ${darkClass(mainView === "routing")} col-12`} onClick={()=> setMainView("routing")}>
                        {translate("Routing")}
                    </button>
                </Col>
                <Col>
                    <button className={`btn ${darkClass(mainView === "tours")} col-12`} onClick={()=> setMainView("tours")}>
                        {translate("Tours")}
                    </button>
                </Col>
                <Col>
                    <button className={`btn ${darkClass(mainView === "tourReports")} col-12`} onClick={()=> setMainView("tourReports")}>
                        {translate("Tour Reports")}
                    </button>
                </Col>
                <Col>
                    <button className={`btn ${darkClass(mainView === "routing2")} col-12`} onClick={()=> setMainView("routing2")}>
                        {translate("Routing and Tours from Fidelio")}
                    </button>
                </Col>
            </Row>
            
            {mainView === "routing" && <DaysRouting props={props}/>}
            {mainView === "routing2" && <DaysRouting2 props={props}/>}
            {mainView === "tours" && <DaysTours props={props}/>}
            {mainView === "tourReports" && <TourReportMain/>}
        </div>
    )
};
export default DaysMain;