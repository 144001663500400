import React from 'react';
import {Translate, translate} from "./Language/Translate"
import { getDateObject} from '../helpers/Date';
import { useSelector } from 'react-redux';

const OtherUserMessageItem = ({message, otherUsername}) => {
    // console.log(message,otherUsername);
    const {sentFrom, body, createdAt, readAt, subject} = message;
    const className = sentFrom === otherUsername ? "text-left m-1 p-3" : "text-right m-1 p-3"
    const specialStyle = sentFrom === otherUsername ? {backgroundColor:"rgba(220, 255, 255,.5)"} : {backgroundColor:"rgba(255, 255, 220,.5)"};
    const currentUser = useSelector(st => st.currentUser);
    const language = currentUser.language || Translate.language;
    const created = getDateObject(createdAt, language).dateTime;
    const read = readAt && getDateObject(readAt, language).dateTime;
    const readText = `${translate("created at")}: ${created}`
            + `${readAt ? `, ${translate("read at")}: ${read}` : ""}`;
    return(
        <div className={className} style={{border:"1px solid grey", borderRadius:"5px", ...specialStyle}}>
            <p>{readText}</p>
            <p><b>{subject}</b></p>
            <p>{body}</p>
            
        </div>
    );
};
export default OtherUserMessageItem;