import React from "react";
import GroupListItem from './GroupListItem';
import { useSelector, useDispatch } from "react-redux";
import { setGlobalVarToState } from "../../actions/globals";
import { translate } from "../Language/Translate";
import { BlackWhite } from "../styledComponents/colors";

const GroupList = ({groups, title}) => {

    const dispatch = useDispatch();
    const globals = useSelector(st => st.globals);
    
    // console.log({groups});
    if(!groups || groups.length === 0)return null;
    const groupOrder = globals.groupOrder || {order:"lastChat", reverse:true};
    
    const sorted = () => {
        const arr = groups.sort((a,b) => a[groupOrder.order] > b[groupOrder.order] ? 1 : -1);
        return (groupOrder.reverse ? arr.reverse() : arr);
    };
    // console.log({groupOrder, sorted}, sorted())


    const handleClick = (sort) => {
        dispatch(setGlobalVarToState({
            groupOrder: {order:sort, reverse: sort === groupOrder.order ? !groupOrder.reverse : false}}));
    };
    

    return (
        <div className = "my-3">
            <h5>{title}</h5>
            
            <div>
                    {translate("Order")}
                    <span onClick={() => handleClick("lastChat")}>
                        {`: `}<BlackWhite>{`${translate("actual")}`}</BlackWhite>{` - `}
                    </span>
                     <span onClick={() => handleClick("name")}><BlackWhite>{`a-z`}</BlackWhite></span>
            </div>
            <div className="row row-eq-height">
                {sorted().map(g => <GroupListItem key={g.id} 
                        group={g} groups={groups} />)}
            </div>
        </div>
    );
}
export default GroupList;