import React from "react";
// import {translate} from '../Language/Translate';
import { bildnachweisText } from "./bildnachweisText";

const Bildnachweis = () => {
   


    return(
        <div style={{backgroundColor:"rgba(255, 255, 255, 0.5)"}} className="p-3">
            <h4>Bildnachweis</h4>
            Hintergrundbild: Bild von <a href="https://pixabay.com/de/users/tantien3-1866050/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2438542">Tantien3</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2438542">Pixabay</a>
            {bildnachweisText.map((t, idx) => {
                return (t === ""  ? <br key={idx}/> : <p key={idx}>{t}</p>)
            })}
        </div>
    )
    
    
};
export default Bildnachweis;