import React, {useState} from "react";
import { useSelector } from "react-redux";
import Randomfn from "../../../helpers/Randomfn";
import { translate } from "../../Language/Translate";
import ProposalsNewForm from "../../../newForms2/proposals/ProposalsNewForm";
import ProposalItems from "./ProposalItems";
import ProposalList from "./ProposalList";
import ProposalEdit from '../../../newForms2/proposals/ProposalsEdit'

const ProposalsMain = () => {
    const currentDestination = useSelector(st => st.currentDestination);
    const currentUser = useSelector(st => st.currentUser);
    const loggedIn = currentUser?.username;
    const proposals = currentDestination?.proposals || [];
    // const [proposalIdx, setProposalIdx] = useState(-1);
    const [actualProposalId, setActualProposalId] = useState(0);
    const [view, setView] = useState('items') // items, formNew, formEdit
    const newArray = Array.from(new Set(proposals.map(p => p.type))).sort();
    const newArray2=newArray.map(p => {return{key:Randomfn.createRandomString(), type:p}});
    
    // const ProposalList = () => {
    //     return(
    //         <div className="text-center">
    //             {proposals.length>0 && <h4 className="mt-4" key = 'title'>{translate("Proposals")}:</h4>}
    //             {loggedIn && <button onClick={() => setView('formNew')} className="btn btn-outline-dark m-1">{translate("Add Proposal")}</button>}
    //             {newArray2.map(t=> 
    //                 <div key={t.key}>
    //                     <h5 key={t.key} className="mt-4">{translate(t.type)}</h5>
    //                     <ProposalItems type = {t.type} proposals={proposals} proposalIdx={proposalIdx} setProposalIdx={setProposalIdx}/>
    //                 </div>
    //             )}

                
    //         </div>
    //     )
    // };
    
    return(
        <>
            {view === "items" && <ProposalList setView={setView}/>}
            {view === "formNew" && <ProposalsNewForm setView = {setView}/>}
            {view === "formEdit" && <ProposalEdit setView={setView} />}
        </>

    )
};



export default ProposalsMain;