import {
  FETCH_CURRENT_USER, 
  UPDATE_CURRENT_USER,
  ADD_BLOCKED,
  REMOVE_BLOCKED,
  MARK_MESSAGE_AS_READ,
  MARK_MESSAGE_AS_DELETED_BY_FROM,
  MARK_MESSAGE_AS_DELETED_BY_TO,
  APPROVE_FRIEND_REQUEST,
  REJECT_FRIEND_REQUEST,
  CANCEL_FRIEND_REQUEST,
  FETCH_USER_DAYS,
  ADD_FAVOURITE,
  DELETE_FAVOURITE
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchCurrentUserFromApi(username) {
 
  // console.log('inside fetchCurrentUserFromApi', {username})
  return async function (dispatch) {
    const response = await simpleRequest({url:`users/${username}`, method:"get"});
    const user=response.data || {};
    // console.log('inside fetchCurrentUserFromApi', {user}, response.data)
    return dispatch(getCurrentUser(user));
  };
}

function getCurrentUser(currentUser) {
  // console.log('inside action', {currentUser})
  return {
    type: FETCH_CURRENT_USER,
    currentUser,
  };
}

export function updateCurrentUserOnAPI(username, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`users/${username}`, method:"patch", data});
    // console.log(response);
    // console.log(response.data);
    // console.log(response.data && response.data.updated);
    const updated = response.data && response.data.updated
    // console.log({updated})
    if(!updated)return;
    return dispatch(updateCurrentUser(updated));
  };
}

function updateCurrentUser(updated){
  return {
    type: UPDATE_CURRENT_USER,
    updated
  };
};




export function toggleBlockedOnApi(blocked){
  // console.log("inside toggleBlockedOnApi", {blocked})
  return async function (dispatch){
    const response = await simpleRequest({url:`block/${blocked}`, method:"post"})
    // console.log("inside toggleBlocked", response.data);
    if (response.data.blocked){
      return dispatch(addBlocked(response.data.blocked.blocked))
    }else if(response.data.deblocked){
      return dispatch(removeBlocked(response.data.deblocked.blocked))
    }
  }
}
function removeBlocked(blocked){
  return {
    type: REMOVE_BLOCKED,
    blocked
  }
}
function addBlocked(blocked){
  return {
    type: ADD_BLOCKED,
    blocked
  }
}

export function markMessageAsReadOnApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`messages/${id}`, method:"patch"});
    // console.log('inside markMessageAsReadOnApi', response.data);
    if (response.data){
      return dispatch(markMessageAsRead(response.data.read))
    }
  }
}
function markMessageAsRead(read){
  // console.log('inside markMessageAsRead', {read})
  return{
    type: MARK_MESSAGE_AS_READ,
    read
  }
};

export function markMessageAsDeletedOnApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`messages/${id}`, method:'delete'});
    // console.log('inside markMessageAsDeleted', response.data);
    if(response.data.deletedByFrom){
      return dispatch(markMessageAsDeletedByFrom(response.data.deletedByFrom))
    }else if (response.data.deletedByTo){
      return dispatch(markMessageAsDeletedByTo(response.data.deletedByTo))
    }else return null
  }
};
function markMessageAsDeletedByFrom(deletedByFrom){
  // console.log("inside markMessageAsDeletedBFrom", {deletedByFrom})
  return{
    type: MARK_MESSAGE_AS_DELETED_BY_FROM,
    deletedByFrom
  }
};
function markMessageAsDeletedByTo(deletedByTo){
  return{
    type: MARK_MESSAGE_AS_DELETED_BY_TO,
    deletedByTo
  }
};

export function approveFriendRequestOnApi(id){
  // console.log("inside approveFriendRequestOnApi", {id})
  return async function(dispatch){
    // console.log("inside dispatch")
    const response = await simpleRequest({url:`friends/${id}`, method:"patch"});
    // console.log(response.data);
    if(response.data.approved){
      return dispatch(approveFriendRequest(response.data.approved))
    }
  }
}
function approveFriendRequest(approved){
  // console.log({approved})
  return{
    type: APPROVE_FRIEND_REQUEST,
    approved
  }
}
export function deleteFriendRequestOnApi(id){
  // console.log("inside deleteFriendRequestOnApi", id)
  return async function(dispatch){
    const response = await simpleRequest({url:`friends/${id}`, method:"delete"});
    // console.log(response.data);
    if(response.data && response.data.rejected){
      return dispatch(rejectFriendRequest(response.data.rejected))
    }else if(response.data.cancelled){
      return dispatch(cancelFriendRequest(response.data.cancelled))
    };
  };
};
function rejectFriendRequest(rejected){
  // console.log("inside rejectFriendRequest", {rejected})
  return {
    type: REJECT_FRIEND_REQUEST,
    rejected
  }
}
function cancelFriendRequest(cancelled){
  // console.log("inside cancelFriendRequest", {cancelled})
  return{
    type: CANCEL_FRIEND_REQUEST,
    cancelled
  }
};

export function fetchUserDaysFromApi(){
  return async function(dispatch){
    const response = await simpleRequest({url:`days/user`, method:"get"});
    const days = response.data?.userDays;
    // console.log({days});
    return dispatch(fetchUserDays(days));
  }
}
function fetchUserDays(days){
  return{
    type: FETCH_USER_DAYS,
    days
  }
};

export function addFavouriteOnApi(cruiseId){
  return async function(dispatch){
    const response = await simpleRequest({url:`favourites`, method:"post", data:{cruiseId}});
    const favourite = response.data?.favourite;
    // console.log({favourite});
    return dispatch(addFavourite(favourite));
  }
}
function addFavourite(favourite){
  return{
    type: ADD_FAVOURITE,
    favourite
  }
};
export function deleteFavouriteFromApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`favourites/${id}`, method:"delete"});
    // console.log({response});
    const deleted = response.data?.deleted;
    return dispatch(deleteFavourite(deleted));
  };
}
function deleteFavourite(deleted){
  return{
    type: DELETE_FAVOURITE,
    deleted
  }
}