import React from "react";

    const Symbols = ({typeArray, margin="ml-1"}) => {
    // console.log({typeArray});
    // const examples = ["MedicalInstitutes", "Hospital", "Dentist","Oculist", "Pharmacy", "Beach", "Person",  
    //         "Restaurant", "Bar", "Sight", "StarFas", "StarFar", "Copy"]
    
    const arr = typeArray || [];

    const classes = {
        "Hospital": {type:"i", clName:"fas fa-hospital text-danger"},
        "Pharmacy": {type:"i", clName:"fas fa-prescription-bottle text-info"},
        "Gynecology": {type:"i", clName:"fas fa-venus text-danger"},
        "Pediatric": {type:"i", clName:"fas fa-child text-danger"},
        "Doctor": {type:"i", clName:"fas fa-stethoscope text-danger"},
        "Dentist": {type:"i", clName:"fas fa-tooth text-danger"},
        "Oculist": {type:"i", clName:"fas fa-eye text-danger"},
        "Restaurant": {type:"i", clName:"fas fa-utensils"},
        "Bar": {type:"i", clName:"fas fa-wine-glass"},
        "Beach": {type:"i", clName:"fas fa-umbrella-beach"},
        "Sight": {type:"i", clName:"fas fa-camera"},
        "Person": {type:"i", clName:"far fa-user", color:"text-dark"},
        "Copy": {type:"i", clName:"fas fa-copy", color:"text-secondary"},
        "StarFar": {type:"i", clName:"far fa-star", color:"text-secondary"},
        "StarFas": {type:"i", clName:"fas fa-star", color:"text-info"},
        "GolfArea": {type:"i", clName:"fas fa-star", color:"text-warning"},
        "Edit": {type:"i", clName:"fa-solid fa-pen-to-square", color:"text-dark"},
        // <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
    }

    const values = arr.map((t,idx) => {
        const val = classes[t] || {};
        const className=`${val.clName || ""} ${val.color || "text-success"}`;
        const key = `symbol-${idx}`
        return(
            <span key={key} className={`${margin}`}>
                {val.type === "i" && <i key = {key} data-number={idx+1} className={className} >
                    </i>}
                {val.type === "span" && <span key={key} data-number={idx+1} className={className}  style={{fontSize:val.fontSize}} >
                    <b>{val.innerText}</b></span>}
            </span>
        )
    });

    return <>{values}</>;
};
export default Symbols;

