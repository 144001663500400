import React from "react";
// import {useSelector} from 'react-redux';
import {Card, Accordion, Button} from 'react-bootstrap';
// import GroupMain from './Groups/GroupMain';
// import GroupMain from './Groups/CruiseGroups/GroupMain'
import { BlackWhite } from "./styledComponents/colors";
import GroupProvider from './Groups/GroupProvider';


const CruiseAreaCardGroups = ({eventKey, title, defaultKey}) => {


    return(


      
        <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} onClick={() => defaultKey.current=eventKey}>
                <BlackWhite>{`${title}`}</BlackWhite>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
              <Card.Body>
                  <div >
                  {/* <button className="btn btn-success" onClick={() => setCounter(counter +1)}>Counter</button> */}
                      <h4>{title}</h4>
                      <GroupProvider location="cruise" key="cruiseGroupProvider"/>
                  </div>
              </Card.Body>
            </Accordion.Collapse>
        </Card>
        
    )


};
export default CruiseAreaCardGroups;