import React from "react";
import { NavLink } from "react-router-dom";
import './App.css';
import {translate} from './Language/Translate'


const NavBarFooter = () =>{
    

    return(
    <div className="footer">
        <nav>
            <NavLink className="ml-3" exact to="/impressum">{translate("Legal Notice")}</NavLink>
            {`  |   `}
            <NavLink exact to="/datenschutz" className="ml-3">{translate("Privacy Policy")}</NavLink>
            {`  |   `}
            <NavLink exact to="/bildnachweis" className="ml-3">Bildnachweis</NavLink>
            {`  |   `}
            <NavLink exact to="/agbs" className="ml-3">AGB</NavLink>
        </nav>

    </div>

    )
}

export default NavBarFooter;