import React from "react";
import { useSelector } from "react-redux";

import { Link } from 'react-router-dom';
import { getDateObject } from "../../helpers/Date";
// import {translate} from "../Language/Translate"
// import Spinner from '../Spinner';
import { BlackWhite } from "../styledComponents/colors";
import { Translate } from "../Language/Translate";


const ArticleListItem = ({article, showAuthor=true}) => {
    const language = useSelector(st => st.currentUser)?.language || Translate.language;
    const author = article.source ? `- ${article.source} -` : `${article.firstName} ${article.lastName}`;

    return (
        <tr key={article.id}>
            <td>{getDateObject(article.createdAt,language).short}</td>
            <td><Link to={`/articles/${article.id}`}><BlackWhite>{article.title}</BlackWhite></Link></td>
            <td>{article.subtitle}</td>
            <td>
                {showAuthor && !article.source && <Link to={`/authors/${article.username}`}><BlackWhite>{author}</BlackWhite></Link>}
                {showAuthor && article.source && <span>{article.source}</span>}
            </td>
           
        </tr>

    );
    
    

};
export default ArticleListItem;