import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
import {translate} from '../../Language/Translate';
import { BlackWhite } from "../../styledComponents/colors";
import ListOfMain from "../ListOfAlbums/ListOfMain";
import ListOfObservationsMain from "./ListOfObservationsMain";

const ListOfObservationsCard = ({eventKey}) => {

    return(  
        <Card key={eventKey}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
            <BlackWhite>{translate('Nature Observations')}</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>
                <ListOfObservationsMain/>
            </Card.Body>
        </Accordion.Collapse>
        </Card>
    )
};
export default ListOfObservationsCard;