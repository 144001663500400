// import { simpleRequest } from "../simpleRequest";

import { simpleRequest } from "../simpleRequest";
import {translate} from '../../components/Language/Translate'

class CruiseCrewFormData {

    static async cruiCrewNew(data) {
        // console.log('inside cruiCrewFormData.cruiCrewNew', data, data.params)
        const cruiseId = data.params.id;
        const response = await simpleRequest({url:`cruises/${cruiseId}`, method:"get"});
        const cruise=response.data;
        // console.log({cruise}, cruise.companyHandle)
        const consts = new CruiseCrewFormSchema();
        consts.fields.cruiseId.value=cruiseId;
        const selectList = await CruiseCrewFunctions._getCompanyCrewSelectList(cruise.companyHandle);
        consts.fields.companyCrewId.settings.options=selectList;
        let fields;
        Object.keys(consts.fields).forEach(f=> {
            // console.log(f,consts.fields[f].value )
            fields = {...fields, [f]:consts.fields[f].value}
        })
        consts.formType = 'cruiCrewNew';
        consts.request = {
            url:`cruisecrew/${cruiseId}`,
            method:"post"
        };
        consts.nextPage = (`/cruises/${cruiseId}`);
        consts.sendData = ["cruiseId", "companyCrewId", "position"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
    
    static async cruiCrewEdit(data){
        
        // console.log('inside CruiseFormData. cruiseEdit', {data})
        // const compCrewId=+data.params.id
        // const compCrew = await CompanyCrewFunctions._getById(compCrewId)

        // console.log({compCrew})
        // const consts = new CompanyCrewFormSchema()
        // delete consts.fields.companyHandle;
        
        // consts.sendData = ["firstName", "lastName", "photoUrl", "bio"]
        // let fields;
        // consts.sendData.forEach(k => fields = {...fields, k: compCrew[k]})
        // consts.formType = 'companyCrewEdit';
        // consts.formLabels = {
        //     title:"Edit Crew",
        //     btnLabel:"Update Crew"
        // }
        // consts.request = {
        //     url:`companycrew/${compCrewId}`,
        //     method:"patch"
        // };        
        // consts.nextPage = (`/companies/${compCrew.companyHandle}`);
        // consts.oldValues=compCrew;
        // const vars={fields:compCrew}
        
        // return {consts, vars};
    };
        
};
       




class CruiseCrewFormSchema{
    constructor(){
        this.formType="cruiCrewNew";
        this.nextPage=""
        this.request = {
            url:"",
            method:"post",
        };
        this.formLabels = {title: translate("Add Crew"), btnLabel:translate("Add")};

        this.fields = {

                cruiseId : {
                label:"Cruise ID",
                value : "",
                fieldType : 'info',
                type:"number"
            },


                position : {
                label:"Position",
                value : "",
                required:true,
                fieldType : 'text',
                type:"text"
            },
            companyCrewId: {
                label:"Name",
                value : "",
                fieldType : 'select',
                type:"number",
                required:true,
                settings:{},
                toNumber:true,
                validation:{mustNotBeEqualTo:'0'}
            }
        }
    }
};

class CruiseCrewFunctions{
    static async _getCruiseById(id){
        const response=await simpleRequest({url:`cruises/${id}`, method:"get"});
        const crc = response && response.data
        return crc;
    }
    static async _getCompanyCrewSelectList(handle){
        const response=await simpleRequest({url:`companies/${handle}`, method:"get"});
        
        // console.log({response})
        const companyCrewList = response.data && response.data.companyCrew;
        // console.log({companyCrewList});
        // const selectList = [];
        const selectList = companyCrewList.map(cc => {return {key:cc.id, value:`${cc.firstName} ${cc.lastName}`}})
        // console.log({selectList})
        selectList.unshift({key:0, value:` - ${translate("Please select")} - `})
        return selectList;
    }
}


export default CruiseCrewFormData;