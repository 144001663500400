import React, { useState } from "react";
import ExistingGroups from "./ExistingGroups";
import SpeciesGroupNew from "./SpeciesGroupsNew";
import SpeciesGroupUpdate from "./SpeciesGroupsUpdate";
import { translate } from "../../../components/Language/Translate";
import SpeciesGroupDetails from '../../../components/species/speciesGroups/SpeciesGroupDetails'

const SpeciesGroupsMain = ({mainProps}) => {

    const [speciesGroupId, setSpeciesGroupId] = useState(0);
    const [speciesId, setSpeciesId] = useState(0);

    const {openForm, setOpenForm} = mainProps

    const props = {...mainProps, speciesGroupId, setSpeciesGroupId, speciesId, setSpeciesId}

    return(
        <div className="mt-3">
            {/* <h5>openForm: {openForm}</h5> */}
            {openForm === "speciesGroupList" && <button className="btn btn-outline-dark" onClick={() => setOpenForm("addSpeciesGroup")}>{translate("Add New Group")}</button>}
            {openForm === "speciesGroupList" && <button className="btn btn-outline-secondary ml-1" onClick={() => setOpenForm("list")}>{translate("Back")}</button>}
            {openForm === "addSpeciesGroup" && <SpeciesGroupNew props = {props}/>}
            {openForm === "showSpeciesGroup" && <SpeciesGroupDetails props = {props}/>}
            {openForm === "updateSpeciesGroup" && <SpeciesGroupUpdate props = {props}/>}
            {openForm !== "showSpeciesGroup" && <ExistingGroups props={props}/>}
        </div>
    )
};
export default SpeciesGroupsMain;