import React, {useEffect, useState} from "react";
import ClaimsData from "./ClaimsData";
import {translate} from '../../components/Language/Translate'
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields";
import {Form} from "react-bootstrap";
import FormValidation from '../Validation/FormValidation'
import { simpleRequest } from "../simpleRequest";
// import FormValidation from '../Validation/FormValidation';

const ClaimsNewForm = ({tablename, ident, setClaimsArea, sourceData}) => {

    
    const {urls, textData} = sourceData
    // console.log({tablename, ident, setClaimsArea, sourceData, urls, textData})

    const [isLoading, setIsLoading] = useState(true);
    const [fieldSettings, setFieldSettings] = useState(ClaimsData[tablename]);
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [successText, setSuccessText] = useState(null);

    useEffect(() => {
        if(["url", "photoUrl"].includes(variables.selection)){
            // console.log("JJJJJJJJJJJJJJJJJJJJJAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
            fieldSettings.selection.validation = {};
            // console.log({fieldSettings, variables});
        }
    }, [variables, fieldSettings])

    useEffect(()=> {
        const fetch = async() => {
            const sets = ClaimsData[tablename];
            // console.log({sets})
            const vars = {};
            Object.entries(sets).forEach(s => vars[s[0]] = s[1].value);
            (() => setFieldSettings({...sets}))();
            (() => setVariables({...vars}))();
            (() => setIsLoading(false))();
        };
        if(isLoading)fetch()
    },[isLoading, tablename]);

    if(isLoading)return <Spinner/>
    const props = {
        fieldSettings, 
        variables, setVariables, 
        fieldErrors, setFieldErrors, 
        serverError}


    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log({fieldSettings, variables})
        const validation = new FormValidation({fieldSettings, variables});
        if(!validation.valid)return;        
        
        const {selection, reason} = variables;
        const passage = Object.keys(urls).includes(selection)? selection : variables.passage;
        // const newVars = {...variables, passage};

        if(!Object.keys(urls).includes(selection)){
                     
            if(!(textData[selection] && textData[selection].includes(passage))){
                (() => setFieldErrors({...fieldErrors, passage:"The field does not contain this text."}))();
                return;
            };
        };
        const data = {tablename, ident, passage, reason, fieldName:selection};
        // console.log(data);
        const response = await simpleRequest({url:`claims`, method:"post", data});
        // console.log(response);
        // console.log(response.status);
        
        if(response.status === 201){
            (() => setTimeout(() => {(() => setClaimsArea(false))();},3000))();
            (() => setSuccessText(translate("The claim was successfully sent to the server")))();
        } else { (() => setServerError(translate("Sending failed.")))() }
        

        
    };
       
    return(
        <div>
            <hr/>
            <h4>
                {translate("Claim about this entry")}:
            </h4>
            <div>
                <Form onSubmit={e => handleSubmit(e)}>
                    <FormFields props={props}/>
                    <p className = "text-danger">{serverError}</p>
                    <p >{successText}</p>
                <div className = "row">
                    <div className="col-md-6 p-2"><button className="btn btn-outline-primary col-12" type="submit">{translate("Submit")}</button></div>
                    <div className="col-md-6 p-2"><button className="btn btn-outline-secondary col-12" onClick={() => setClaimsArea(false)}>{translate("Cancel")}</button></div>
                </div>
                
                
                </Form>
            </div>
        </div>
    );
    
};
export default ClaimsNewForm;