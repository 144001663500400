import { simpleRequest } from "../simpleRequest";


const getGeolocationFromAddress = async(e, variables, setVariables) => {
    e.preventDefault();
    const previous = variables.geolocation;
    const address = variables.deDE || variables.enUK;
    if(!address)return "";
    const data = (await simpleRequest({url:"/destinations/geolocation", method:"post", data:{address}})).data;
    setVariables({...variables, geolocation: data.geolocation || ''})
    // console.log({data, previous}, data.geolocation);
};

export {getGeolocationFromAddress}