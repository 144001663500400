import React from "react";

export const FAQEnglish = () => {
    return(
        <div className="text-left p-2" style={{backgroundColor: 'rgba(200, 200, 200,.5)', color:"black"}}>
            <h2>FAQ - Tipps für den Start.... demnächst auch auf Englisch</h2>

            <h5>Als Gast möchte ich mir die Website anschauen, ohne mich anmelden zu müssen, um dann zu entscheiden.</h5>
            <p>Rufen Sie dazu die Seite cruisefriends.net auf, scrollen Sie nach unten, und wählen Sie „cruisefriends.net als Gast entdecken“ auf. Sie können dann ein Schiff und danach Ihre Reise auswählen. Aus Gründen des Datenschutzes können Sie jedoch keine Personen auf der Reise sehen. Als Gast können Sie keine Eingaben machen.</p>
            <p>Cruisefriends.net ausprobieren {`=>`} Cruisefriends.net als Gast entdecken {`=>`} Schiff auswählen {`=>`} Reise auswählen</p>
            <h5>Ich habe cruisefriends.net als Gast getestet, und möchte nun als User teilnehmen.</h5>
            <p>Rufen Sie die Seite cruisefriends.net auf und wählen Sie aus „Zur Registrierung“.</p>
            <p>Der Benutzername und die Emailadresse müssen einzigartig sein.</p>
            <h5>Was kostet die Benutzung von cruisefriends.net?</h5>
            <p>Die Benutzung ist für User komplett kostenlos. Es gibt keine kostenpflichtigen Varianten für User. </p>
            <h5>Warum muss ich meine Emailadresse bestätigen?</h5>
            <p>Die Emailadresse ist die einzige verlässliche Methode um sich auszuweisen. Cruisefriends.net erhält Daten seiner Mitglieder, die zum Teil für andere User sichtbar sind (siehe nächste Frage).</p>
            <h5>Welche meiner Daten sind für andere User sichtbar?</h5>
            <p>Sie sind für andere User nur sichtbar, wenn Sie beide auf einer Kreuzfahrt angemeldet sind.</p>
            <p>Sichtbar für andere sind</p>
            <p>Benutzername (dieser ist frei wählbar, darf aber noch nicht vergeben sein. Er ist wichtig für die Funktionen der Seite, und ist manchmal sichtbar.) 
                        Eventuell wird der Benutzername bald durch eine automatisch erstellte Chiffre ersetzt. </p>
            <p>Angezeigter Name (Sie brauchen nicht Ihren Vor- und Nachnamen zu verwenden. Genauso gut kann hier ein Künstlername oder ein Spitzname stehen.) </p>
            <p>Profilbild, falls vorhanden.</p>
            <h5>Alle anderen Daten (auch Vor- und Nachname) können von anderen Benutzer nicht gesehen werden.</h5>
            <h5>Als angemeldeter User möchte ich mich nur umschauen, möchte aber noch nicht gesehen werden.</h5>
            <p>Solange Sie sich nicht auf einer Kreuzfahrt als Gast oder als Crew anmelden, sind Sie für andere User nicht sichtbar. </p>
            <h5>Als User möchte ich ein Schiff auswählen, kann es jedoch nicht finden.</h5>
            <p>Folgen Sie den Schritten:</p>
            <p>Start {`=>`} Erkunden Sie cruisefriends.net {`=>`} Das Schiff ist nicht in der Liste. Ich möchte ein neues Schiff anlegen</p>
        
        </div>
   
    )
};

    