import React from "react";
import {Accordion} from 'react-bootstrap';
import UserAreaCardMessagesCard from './UserAreaCardMessagesCard';
// import {translate} from './Language/Translate'


const UserAreaCardMessages = () =>{
   
    return(
        <div>
            <Accordion>
                <UserAreaCardMessagesCard eventKey='2' type="from" />
                <UserAreaCardMessagesCard eventKey='3' type = "to" />
            </Accordion>
        </div>
    )
};

export default UserAreaCardMessages;