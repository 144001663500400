// 4.12.23
import React from "react";


const AlbumListItem = ({album, handleClick}) => {
    // console.log({album, handleClick});
        
    return(
        <button className = "btn btn-outline-dark m-1" onClick={e => handleClick(e)} id = {album.id}>
                {album.newTitle || `${album.titleDE} / ${album.titleEN}`}
        </button>
    )
}
export default AlbumListItem;