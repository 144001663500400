import {translate} from "../../components/Language/Translate"

class FieldValidation {
    constructor(name, value, fieldSettings, variables) {
        // console.log({name, value, fieldSettings, variables})

        this.fieldSettings = fieldSettings;
        this.variables = variables;
        this.name = name;
        this.value = value || "";
        this.label = this.fieldSettings && this.fieldSettings[name]?this.fieldSettings[name].label || "Kein Label":"Kein Label";
        this.validation = (this.fieldSettings && this.fieldSettings[name]) ? this.fieldSettings[name].validation : {};
        this.errMsg = [];
        this.valid = true;
    }

    isValid() {
        // console.log(this, this.validation)
        if (!this.validation) return {
            valid: true
        };
        // console.log(this.errMsg, this.value, this.name, this.validation,"llllllllllllllllllllllllllllllllll")
        this.minNumber()
        this.minLength();
        this.maxLength();
        this.noNumbers();
        this.isEmail();
        this.mustBeEqualTo();
        this.mustNotBeEqualTo();
        this.mustNotBeEqualToValues();
        this.mustBeGreaterThan();
        this.noSpace();
        this.mustNotContain();
        this.lowerCased();
        // this.ifStartsWithCharFieldMustNotBeEqualTo();
        // console.log("11111111111111111111111111")
        this.validDateString();
        // console.log("222222222222222222222222222222222222")
        if (this.errMsg.length > 0) this.valid = false;
        const result = {
            valid: this.valid,
            errMsg: this.errMsg.join(' ') + ''
        }
        // console.log(this.name, this.validation, result.valid, result.errMsg);
        return result;

    }

    minLength() {
        // console.log('inside minLength', this.name, this.value, this.validation, this.value.length)
        if (this.validation.minLength && this.validation.minLength > this.value.length) {
            // console.log("Fehler")
            this.errMsg.push(translate('Minimum number of characters') + ": " + this.validation.minLength + ".");
        }
    }
    maxLength() {
        if (this.validation.maxLength && this.value && this.validation.maxLength < this.value.length) {
            this.errMsg.push(translate('Maximum number of characters') + ": " + this.validation.maxLength + ".");
        }
    }
    noNumbers() {
        if (this.validation.noNumbers && (/\d/.test(this.value))) {
            this.errMsg.push(`${translate('This field must not contain numbers')}.`);
        }
    }
    isEmail() {
        if (this.validation.isEmail && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.value))) {
            this.errMsg.push(`${translate("Invalid email adress")}.`)
        }
    }
    mustBeEqualTo() {
        if(!this.validation.mustBeEqualTo)return;
        const compare = this.variables[this.validation.mustBeEqualTo];
        // console.log('inside FieldValidation.mustBeEqualTo', this.validation, {value:this.value, compare, mistake: compare!==this.value});
        if (compare !== this.value)this.errMsg.push(`${translate("The fields do not match")}.`);
            
    };
    mustBeGreaterThan() {
        if(!this.validation.mustBeGreaterThan)return;
        const compare = this.variables[this.validation.mustBeGreaterThan];
        // console.log('inside FieldValidation.mustBeGreaterThan', this.validation, {value:this.value, compare, mistake: compare > this.value});
        if (compare > this.value)this.errMsg.push(`${translate("The value must be greater")}.`);
            
    };
    mustNotBeEqualToValues = () => {
        // console.log(this.value);
        // console.log(this.validation.mustNotBeEqualToValue);
        if(this.validation.mustNotBeEqualToValues && this.validation.mustNotBeEqualToValues.includes(this.value)){this.errMsg.push(translate("Invalid value"))}
    }
    
    mustNotBeEqualTo = () => {
        if(!this.validation.mustNotBeEqualTo)return;
        const compare = this.variables[this.validation.mustNotBeEqualTo];
        // console.log(this.variables);
        // console.log(this.name);
        // console.log({compare})
        // console.log(this.value)
        // console.log('inside FieldValidation.mustNotBeEqualTo', this.validation, {value:this.value, compare, mistake: compare===this.value});
        if (compare === this.value)this.errMsg.push(`${translate("The fields must be different")}.`);
    };
    


    noSpace() {
        if (this.validation.noSpace && (this.value.includes(' '))) {
            this.errMsg.push(`${translate("This field must not have any empty spaces")}.`)
        }
    }
    mustNotContain(){
        if(this.validation.mustNotContain && this.value.includes(this.validation.mustNotContain)){
            this.errMsg.push(`${translate("Must not contain")}: ${this.validation.mustNotContain}.`)
            // console.log(this)
        }
    }
    lowerCased() {
        if (this.validation.lowerCased && this.value !== this.value.toLowerCase()) {
            this.errMsg.push(translate('For this field use only lowercased letters.'))
        }

    }
    
    validDateString(){
        // console.log('inside validDateString', "8888888888888888888888888888888888888888888")
        if (this.validation.validDateString) {
            // console.log('HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH')
            const dateString = this.value;
            const first = dateString.slice(4, 5)
            const second = dateString.slice(7, 8)
            const y = parseInt((dateString.slice(0, 4)));
            const m = parseInt((dateString.slice(5, 7)));
            const d = parseInt((dateString.slice(8, 10)));
            const newDate = new Date(dateString)
            // console.log({y,m,d,newDate,first,second})
            // console.log({newDate})
            if (dateString.length !== 10 || y < 1900 || y > 3000 || m < 1 || m > 12 || d < 1 || d > 31 ||
                !newDate || first !== "-" || second !== "-") {
                this.errMsg.push(`${translate("Invalid date string")}. ${translate("Please use Format: yyyy-mm-dd")}, (${translate("e. g.")}: 2021-06-12)`)
            }
        }

    }
    minNumber = () => {
        if(this.validation.minNumber){
            
            if (typeof parseInt(this.value) !=="number" || this.value < this.validation.minNumber){
                // console.log('inside min', typeof parseInt(this.value), typeof this.value !=="number", this.value < this.validation.minNumber, this.value, this.validation.minNumber)
                this.errMsg.push(`${translate("Not valid. The number must be at least")} ${this.validation.minNumber}.`)
            }
        }
    }






}
    




    







// mustBeGreaterThan() {
//     if (!(this.validation && this.validation.mustBeGreaterThan)) return;
//     if (!this.fieldSettings.fields[this.name].validation)return
//     const otherFieldName = this.fieldSettings.fields[this.name].validation.mustBeGreaterThan;
//     const otherFieldLabel = this.fieldSettings.fields[otherFieldName] && this.fieldSettings.fields[otherFieldName].label;
//     const otherValue = this.fields[this.fieldSettings.fields[this.name].validation.mustBeGreaterThan];
//     // console.log(this.label, this.value, 'must be greater than', otherFieldName, otherFieldLabel, otherValue)
//     if (this.validation.mustBeGreaterThan && (this.value <= otherValue)) {
//         this.errMsg.push(`This value must be greater than "${otherFieldLabel}"`
//     }
//     }



export default FieldValidation;