import React, {useEffect, useState} from "react";
import {Card, Accordion, Button} from 'react-bootstrap'
// import {useDispatch} from 'react-redux'
import {translate} from '../Language/Translate';
import { BlackWhite } from "../styledComponents/colors";
import { simpleRequest } from "../../newForms2/simpleRequest";
import Spinner from "../Spinner";
// import NewDestinationList from "./NewDestinations/NewDestinationList";
import NewDestinationInUseList from "./NewDestinations/NewDestinationInUseList";
import NewsBadge from "../NewsBadge";


const CardNewDestinationInUse = ({eventKey}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [newDestinations, setNewDestinations] = useState([]);
    
    useEffect(() => {
        const fetch = async() =>  {
            const destinations = (await simpleRequest({url:"admin/destinationsinuse", method:"get"})).data?.destinations || [];
            const withoutDuplicates = destinations
                .filter((d1, idx, array) => idx === array.findIndex(d2 => d1.id === d2.id))
                .filter(d => !d.geolocation);
            // console.log({destinations, withoutDuplicates});
            (() => setNewDestinations(withoutDuplicates))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    });
    if (isLoading)return <Spinner/>

        
    return (
        <Card style={{backgroundColor:'#e5e9df', color:"#1a1620"}}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("New Destinations in Use")} <NewsBadge count = {newDestinations.length}/></BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
                <Card.Body>
                    <NewDestinationInUseList newDestinations={newDestinations} setNewDestinations={setNewDestinations}/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};
export default CardNewDestinationInUse;