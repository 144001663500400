export const categories = {
    Fauna: {enUK:"Fauna", deDE:"Fauna"},
    Flora: {enUK:"Flora", deDE:"Flora"},
    Geology: {enUK:"Geology", deDE:"Geologie"},
    Meteorology: {enUK:"Meteorology", deDE:"Meteorologie"},
};

export const getCategoryOptions = (language="enUK") => {
    return Object.entries(categories).map(e => {
        return{
            key: e[0],
            value:e[1][language]
        }
    })};