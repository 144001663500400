import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Translate, translate } from "../../../components/Language/Translate";
import { BlackWhite } from "../../../components/styledComponents/colors";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";
import TourArea from "../../../components/Tours/TourArea";

const DaysToursActions = ({props}) => {
    const {action} = props;
    const [view, setView] = useState(null); // editDay, tours
    // const [variables, setVariables] = useState({});
    // const [isLoading, setIsLoading] = useState(true);
    const time = !!action.arrival || !!action.departure;

    // useEffect(() => {
    //     const sendFields = ["arrival", "departure", "destinationId", "destination", "description"];
    //     const vars = {};
    //     sendFields.forEach(sf => vars[sf] = action[sf]);
    //     console.log({vars});
    //     setVariables({...vars});
    //     setIsLoading(false);
    // }, [setVariables, action, setIsLoading]);

    // const newProps = {...props, variables, setVariables, isLoading}

    // console.log({newProps});
    return(
        <StyledDivWithBorder className="my-1">
            <Row>
                <Col sm={2} onClick={() => setView(view => view ==="tours" ? null : "tours")}>
                    <h6>{action.arrival}{time && ` - ` }{action.departure}</h6>
                </Col>
                <Col sm={6} onClick={() => setView(view => view ==="tours" ? null : "tours")}>
                    <h6>
                        {action.destinationId?
                            <BlackWhite>
                                {action[Translate.language] || action.enUK}
                            </BlackWhite> 
                            : action.destination ? <span className = "text-secondary">
                                                        {action.destination}
                                                        {` (${translate("No destination selected from list")})`}
                                                    </span>
                            : <p>{translate("No destination")}</p>}
                            <span><small>{action.description ? `- ${action.description}`:""}</small></span>
                    </h6>
                </Col>

            </Row>
        {view === "tours" && <TourArea action={action}/>}
    </StyledDivWithBorder>
    )
}
export default DaysToursActions;