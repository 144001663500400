import React, { useState, useEffect } from "react";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import Spinner from "../../Spinner";
import ListOfAlbumsItem from "./ListOfAlbumsItem";

const ListOfAlbums = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [albums, setAlbums] = useState([]);
    const [albumPhotos, setAlbumPhotos] = useState([]);
    const [albumDestinations, setAlbumDestinations] = useState([]);
    const [destinations, setDestinations] = useState([]);

    // console.log({albums});
    useEffect(() => {
        const fetch = async() => {
            const al = (await simpleRequest({url:"albums"})).data.albums;
            const ab = (await simpleRequest({url:"albumphotos"})).data.albumPhotos;
            const ad = (await simpleRequest({url:"albumdestination"})).data.albumDestinations;
            const dest = (await simpleRequest({url:"destinations"})).data.destinations;
            setAlbums(al);
            setAlbumPhotos(ab);
            setAlbumDestinations(ad);
            setDestinations(dest);
            setIsLoading(false);
        };
        if(isLoading)fetch();
    }, [isLoading]);
    if(isLoading) return <Spinner/>
    // console.log({albums, albumPhotos, albumDestinations, destinations})
    return(
        <div className="text-left p-3">
                 {[{id:0}, ...albums].map(a => 
                    <ListOfAlbumsItem key={a.id} album={a} 
                                albumDestinations={albumDestinations} 
                                albumPhotos={albumPhotos}
                                setAlbumPhotos={setAlbumPhotos}
                                destinations = {destinations}
                                setIsLoading = {setIsLoading} 
                                setAlbumDestinations={setAlbumDestinations}
                                albums = {albums}
                                setAlbums={setAlbums}
                                />
                )}
        </div>
    )
};
export default ListOfAlbums;