import { translate } from "../../components/Language/Translate";
// import FormValidation from '../Validation/FormValidation';


class CompanyCrewFormSchema{
    constructor(){
        this.formType="compCrewNew";
        this.nextPage=""
        this.request = {
            url:"cruises",
            method:"post",
        };
        this.formLabels = {title: translate("Add Crew"), btnLabel:translate("Add")};


        this.fields = {

            companyHandle : {
                label : "Company Handle",
                fieldType: "info",
                disabled:true,
                value :""

            },
            
            firstName : {
                label:translate("First Name"),
                value:"",
                validation : {minLength: 2, maxLength:40},
                fieldType : "text",
                type : "text",
                required:true
            },
            
            lastName : {
                label:translate("Last Name"),
                value:"",
                validation : {minLength: 2, maxLength:40},
                fieldType : "text",
                type : "text",
                required:true
            },
            
            photoUrl : {
                label:"Photo URL",
                value : "",
                validation : {minLength: 10},
                fieldType : "text",
                type:'url'
            },
        
            bio : {
                label:translate("Biography"),
                value : "",
                validation : {},
                fieldType : 'textarea',
                type:"text"
            },
        }
    }
};
export default CompanyCrewFormSchema;