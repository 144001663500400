import React from "react";
import {translate} from '../Language/Translate'
import {Accordion, Card, Button} from 'react-bootstrap';
import Main from './Memory/Main';
import { BlackWhite } from "../styledComponents/colors";



const CardMemory = ({eventKey}) => {

    return (
        <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6 ><BlackWhite>{translate("Memory")}</BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
                <Card.Body>
                    <Main />
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
};
export default CardMemory;