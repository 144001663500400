import React, { useEffect, useState } from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import Spinner from "../Spinner";
import { translate } from "../Language/Translate";
import { BlackWhite } from "../styledComponents/colors";
import ChatPostDetails from "./ChatPostDetails";

const ChatPostList = ({theme}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [activePostId, setActivePostId] = useState(0);
 
    useEffect(() => {
        const fetch = async() => {
            const request = {url: `travelchatposts/${theme.id}`}
            const _posts = (await simpleRequest(request)).data?.travelchatPosts || [];
            console.log({request, _posts});
            setPosts([..._posts])
            setIsLoading(false)
        }
        fetch()
        
    }, [setIsLoading, theme]);
    if(isLoading)return <Spinner/>

    return(
        <div className="my-3">
            <h6>{translate("Posts")}:</h6>
            <ul>
                {posts.map(p => {
                    return(
                        <li >
                            <BlackWhite onClick={() => setActivePostId(activePostId === p.id ? 0 : p.id)}>
                                <b>{p.title}: </b>
                                <span className="ml-3">
                                    {p.details.slice(0, 60)}...
                                </span>
                            </BlackWhite>
                            {activePostId === p.id && <ChatPostDetails post={p} backFunc={() => setActivePostId(0)}/>}
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};
export default ChatPostList;