const {words} = require('./words');


class Translate{
    static language = "enUK";
    static wordsDE = () => {
        const entries = Object.entries(words);
        const newObj = {};
        entries.forEach(e => {
            newObj[e[1].deDE] = {...e[1], "enUK": e[0]}
        });
        return newObj;
    };

    static translate(word){
        return words[word]? words[word][this.language] || word : word;
    }
    static translateDE(word){
        const obj1 = this.wordsDE()
        const obj2 = obj1 && obj1[word]
        const obj3 = (obj2 && obj2[this.language]) || word
       return obj3;
    };
};
const translate = (word) => {
    if(!word)return '';
    
    // console.log(typeof word);

    // console.log({word});
    // console.log(word);
    if(typeof word !== "string") return word;
    return Translate.translate(word && word.trim()) || word;
};
const translateDE = (word) => {
    if(!word)return '';
    return Translate.translateDE(word.trim()) || word;

}



export {Translate, translate, translateDE};
