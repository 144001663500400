import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import ListOfAlbums from "./ListOfAlbums";
import ListOfDestinations from "./ListOfDestinations";
import { simpleRequest } from "../../../newForms2/simpleRequest";


const ListOfMain = () => {
    const [view, setView] = useState('none');
    const findDoublettes = async() => {
        const result = (await simpleRequest({url:"albumphotos/doublettes"})).data;
        // console.log({result});
    }


    return(
        <div>
            <Row className="Row">
                <Col>
                    <button className = "btn btn-outline-dark" onClick={() => setView("none")}>{`None`}</button>
                </Col>
                <Col>
                    <button className = "btn btn-outline-dark" onClick={findDoublettes}>{`Doublettes`}</button>
                </Col>
                <Col>
                    <button className = "btn btn-outline-dark" onClick={() => setView("albumsToDestinations")}>{`Albums => Destinations`}</button>
                </Col>
                <Col>
                    <button className = "btn btn-outline-dark" onClick={() => setView("destinationsToAlbums")}>{`Destinations => Albums`}</button>
                </Col>

            </Row>
            {view === "albumsToDestinations" && <ListOfAlbums/>}
            {view === "destinationsToAlbums" && <ListOfDestinations/>}
        </div>
    )
};
export default ListOfMain;