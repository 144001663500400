import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import CompanyCrewFormSchema from './CompanyCrewFormSchema';
import { updateCompanyCrewOnAPI } from "../../actions/currentCompany";
import FormValidation from '../Validation/FormValidation';

  const CompanyCrewEdit = ({id, setShowEditForm}) => {
    // console.log({id, setShowEditForm});
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [fieldSettings, setFieldSettings] = useState();
    const [fieldErrors, setFieldErrors] = useState({});
    // const [serverError, setServerError] = useState('');
    const currentCompany = useSelector( st => st.currentCompany);
    const dispatch = useDispatch();
    const companyCrewItem = currentCompany.companyCrew.find(cc => cc.id === id);
    // console.log({companyCrewItem});
  
    useEffect(() => {
      const sets = new CompanyCrewFormSchema();
      // console.log(sets);
      const sendData = ["firstName", "lastName", "bio"];
      const fieldSets = [];
      const vars = [];
      sendData.forEach(d => {
        vars[d] = companyCrewItem[d];
        fieldSets[d] = sets.fields[d];
      });
      (() => setVariables({...vars}))();
      (() => setFieldSettings({...fieldSets}))();
      (() => setIsLoading(false))();

    },[isLoading, setIsLoading, companyCrewItem])

    if(isLoading) return <Spinner/>

    // console.log({variables, fieldSettings});

    const handleSubmit = async() => {
      const formValidation = new FormValidation({fieldSettings, variables});
      if(!formValidation.valid)return;
      // console.log({variables});
      await dispatch(updateCompanyCrewOnAPI({id, data:variables}));
      (() => setShowEditForm(false))();
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors};
    // const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div className = "my-5 p-3" style = {{border:"1px solid grey", borderRadius:"5px"}}>
            <h4 className="mb-3">{translate("Edit Crew")}</h4>
            {/* <DestinationField props={props}/> */}
            <FormFields props={props}/>
            <div className="row col-12" >
              <div className="col-6 p-1">
                <button className="btn btn-outline-info col-12" onClick={handleSubmit}>{translate("Submit")}</button>
              </div>
              <div className="col-6 p-1">
                <button className="btn btn-outline-secondary col-12" onClick={()=>setShowEditForm(false)}>{translate("Cancel")}</button>
           
              </div>
            </div>
        </div>
    )
  };
  export default CompanyCrewEdit;