import React, { useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import { fetchCompanyFromAPI } from "../../actions/currentCompany";
import { addCruiseCrewOnApi } from '../../actions/currentCruise';
import CruiseCrewFormSchema from "./CruiseCrewFormSchema";
import FormValidation from '../Validation/FormValidation';

  const CruiseCrewNew = ({setShowNewCrewForm}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [fieldSettings, setFieldSettings] = useState();
    const [fieldErrors, setFieldErrors] = useState({});
    // const [serverError, setServerError] = useState('');
    const currentCompany = useSelector( st => st.currentCompany);
    const currentCruise = useSelector(st => st.currentCruise)
    const dispatch = useDispatch();

    useEffect(() => {
      // console.log('inside useEffect 1')
      const fetch = async() => {
        await dispatch(fetchCompanyFromAPI(currentCruise.companyHandle));
        (() => setIsLoading(false))();
      }
      if(isLoading)fetch();
    },[isLoading, setIsLoading, dispatch, currentCruise]);
    
    
    useEffect(() => {
      // console.log('inside useEffect 2');
      const fetch = async() => {
        
        const sets = new CruiseCrewFormSchema(currentCompany);
        // console.log(sets);
        const sendData = ["position", "companyCrewId"];
        const fieldSets = [];
        const vars = [];
        sendData.forEach(d => {
          vars[d] = sets.fields[d].value;
          fieldSets[d] = sets.fields[d];
        });
        (() => setVariables({...vars}))();
        (() => setFieldSettings({...fieldSets}))();
      };
      if(isLoading)fetch();
    },[currentCompany, isLoading])

    if(isLoading) return <Spinner/>

    // console.log({variables, fieldSettings});
    // console.log({currentCompany})
    // console.log({currentCruise})

    const handleSubmit = async() => {
      if (variables.companyCrewId === -1)return;
      const formValidation = new FormValidation({fieldSettings, variables});
      if(!formValidation.valid)return;
      variables.companyCrewId = + variables.companyCrewId;
      // console.log({variables});
      await dispatch(addCruiseCrewOnApi( currentCruise.id, variables));
      (() => setShowNewCrewForm(false))();
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors};
    // const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div className = "my-5 p-3" style = {{border:"1px solid grey", borderRadius:"5px"}}>
            <h4>{translate("Add Crew")}</h4>
            <p>{translate("Crew members must first be entered in the company before they can be selected here. From the navigation bar, go to Cruises - Companies, select the company, then Crew - Add Crew")}</p>
            <FormFields props={props}/>
            <div className="row col-12" >
              <div className="col-6 p-1">
                <button className="btn btn-outline-info col-12" onClick={handleSubmit}>{translate("Submit")}</button>
              </div>
              <div className="col-6 p-1">
                <button className="btn btn-outline-secondary col-12" onClick={()=>setShowNewCrewForm(false)}>{translate("Cancel")}</button>
              </div>
            </div>
        </div>
    )
  };
  export default CruiseCrewNew;