import React, { useEffect, useState } from "react";
import Spinner from '../../components/Spinner';
import {simpleRequest} from '../simpleRequest';
import { translate } from "../../components/Language/Translate";
import UserLogin from "./UserLogin";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EmailConfirmation = (props) => {
    const params = useParams();
    const username=params.username || props.username;
    const code = params.code || props.code;
    // console.log({params, props, username, code});
    const [isLoading, setIsLoading] = useState(true)
    const [result, setResult] = useState({});
    const [login, setLogin] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const response = await simpleRequest({method:"post", url:"confirmation",data:{username, code}});
            (() => setResult(response.data))();
        };
        fetch();
        (() => setIsLoading(false))();
    },[setIsLoading, username, code]);
    if(isLoading)return <Spinner/>
    const valid = result?.valid;

    const InValid = () => (
        <>
            <h4>{translate('Invalid code/username')}.</h4>
            <p>{translate("Please try to login and check your emails")}.</p>
            <p>{translate("Klick the link inside the new email")}.</p>
        </>
    );

    const Valid = () => (
        <>
            <h4>{translate('Email Confirmation successful')}.</h4>
            <h6>{translate("You can login now")}.</h6>
        </>
    );
   const Confirm = () => (
        <>
            <h4>Email Confirmation</h4>
            {valid && <Valid/>}
            {!valid && <InValid/>}
            <button className="btn btn-outline-dark" onClick={() => setLogin(true)}>{translate("To Login")}</button>
        </>
    );
   return(
        <>
            {/* <h1 className="my-5"><i>Cruisefriends</i>.net</h1> */}
            {!login && <Confirm/>}
            {login && <UserLogin/>}
        </>
    );
};
export default EmailConfirmation;