import React, { useState } from "react";
// import {translate} from "../Language/Translate"
import {Button, Form} from 'react-bootstrap';
import { getAllPropertiesFromCsvData } from "./helpers";


const CsvToJsonField = () => {
    const [values, setValues] = useState("");

    const handleChange = e => {
        (() => setValues(e.target.value))();
    };

    
    const handleSubmit = async () => {
        console.log(getAllPropertiesFromCsvData(values));
    };
        return(
        <>
        <div>
            <Form>
                <Form.Group size="lg"  >
                    <Form.Label  className ="pt-1 px-1" >CSV-Data from fields table to JSON</Form.Label>
                    <Form.Control as="textarea" rows={5}
                        name="csvJson" 
                        type="textarea" 
                        fieldtype="textarea" 
                        key="csvJson" 
                        id="csvJson" 
                        value={values}
                        onChange={e => handleChange(e)} 
                    />
                </Form.Group>
                <Button onClick = {() => setValues("")}>Clear</Button>
                <Button onClick = {handleSubmit}>Submit</Button>
            </Form>
        </div>
        </>
    );
};
export default CsvToJsonField;