import React, {useState, useEffect} from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import {Card} from 'react-bootstrap'
import { getDateObject } from "../../helpers/Date";
import { Link, useParams, useHistory } from 'react-router-dom';
import ConfirmationArea from '../ConfirmationArea'
import Spinner from '../Spinner';
import {Translate, translate} from '../Language/Translate';
import UploadAreaSingle from "../../newForms2/upload/UploadAreaSingle";
import {useSelector} from 'react-redux';
import { BlackWhite } from "../styledComponents/colors";
import ArticleLinkList from "./ArticleLinkList";
import FormatText from "../../helpers/FormatText";

const ArticleDetails = () => {
    const {id} = useParams();
    const [article, setArticle] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [confirmation, setConfirmation] = useState(false);
    const [uploadAreaPhoto, setUploadAreaPhoto] = useState(false);
    const [uploadAreaDocument, setUploadAreaDocument] = useState(false);
    const [uploadData, setUploadData] = useState(false);
    const currentUser = useSelector(st => st.currentUser);
    const history = useHistory();
    const language = currentUser?.language || Translate.language;

    useEffect(()=> {
        const fetch = async() => {
            const response = await simpleRequest({url:`articles/${id}`, method:"get"});
            setArticle(response.data && response.data.article);
            setIsLoading(false);
        }
        if(isLoading)fetch();
    },[isLoading, id]);
    
    const developer = currentUser.userStatus === "developer";
    // console.log({article});

    useEffect(()=>{
        const updateUrl = async () => {
            const axiosObj = {url:`articles/${article.id}`, method:"patch", data:{...uploadData.data}};
            const response = await simpleRequest(axiosObj);
            const updatedArticle = response.data && response.data.article;
            // console.log({updatedArticle})
            if(!updatedArticle)return;
            const newObj = {...article, ...updatedArticle}
            (() => setArticle({...newObj}))();
            (() => setUploadData(null))();
        };
        if(uploadData && uploadData.method)updateUrl();
        (() => setUploadAreaPhoto(false))();
        (() => setUploadAreaDocument(false))();
    }, [uploadData, setArticle, article])

    const activePhoto = () => {
        (() => setUploadAreaPhoto(!uploadAreaPhoto))();
        uploadAreaDocument && (() => setUploadAreaDocument(false))();
    }
    const activeDocument = () => {
        (() => setUploadAreaDocument(!uploadAreaDocument))();
        uploadAreaPhoto && (() => setUploadAreaPhoto(false))();
    }

    if(isLoading)return <Spinner/>
    // console.log({article, isLoading});
    
    const handleDelete = () => {setConfirmation(true)};
    const handleCancel = () => {setConfirmation(false)};
    const deleteArticle = async() => {
        // console.log("DELETE!!!!!!!!",{article})
        // const res = await simpleRequest({url: `articles/${id}`, method:"delete"})
        // console.log({res});
        history.goBack();
    };
    
    const created = getDateObject(article.createdAt, language).short;
    const updated = (article.updatedAt && getDateObject(article.updatedAt, language).short) || null;
    const updatedTxt = (updated && (created !== updated))?<small>{` (updated at ${updated})`}</small>:null;
    const txtBtnPhoto = article.photoUrl ? translate("Replace/Remove Photo") : translate("Add Photo");
    const txtBtnDocument = article.photoUrl ? translate("Replace/Remove Document") : translate("Add Document");
    const userIsAuthor = article.username === currentUser.username;
    const source = article.source === "Weblink" ? `${translate("Source")}: ${article.source}` : `${translate("Author")}: ${article.firstName} ${article.lastName}`
    const photoSource = 
        article.copyright ? ` ${article.copyright}`
                            : article.source === "Weblink" 
                            ? `  Weblink (${translate("Open link for further informations")}.), ${translate("Photo address")}: ${article.photoUrl}` 
                            : ` ${article.firstName} ${article.lastName}`
    

    
    const Main = () => (
        <Card className="text-center" style={{backgroundColor:"rgba(255,255,255,.5"}}>
            <Card.Header>
                <h4>{article.title}</h4>
                <h6>{article.subtitle}</h6>
                <h6>{source}</h6>
                {developer && <p>Photo URL: {article.photoUrl}</p>}
                {developer && <p>Document URL: {article.documentUrl}</p>}
            </Card.Header>
            <Card.Body>
                {article.photoUrl && <img src={article.photoUrl}  className="mb-4" alt="img" style={{maxWidth:"70%"}}/>}
                {article.photoUrl && <p>&copy;{` ${photoSource}`}</p>}
                {/* <p>{formatText(article.description) }</p> */}
                <FormatText str = {article.description}/>
                {userIsAuthor && <p><b>{`${translate("Subjects")}: `}</b><br/>{article.subjects}</p>}
                {article.documentUrl && <a href = {article.documentUrl} target="_blank" rel="noreferrer" 
                         className="btn btn-outline-dark">{translate("To the article")}</a>}
                <button onClick = {() => history.goBack()} className="btn btn-outline-dark m-1">{translate("Back")}</button>
                {/* {article.documentUrl && <a href={article.documentUrl} target="_blank" rel="noopener noreferrer" className = "btn btn-outline-dark ml-3">{translate("To the article")}</a>} */}
                {userIsAuthor && 
                
                <>
                
                <button onClick={activePhoto} className="btn btn-outline-dark m-1">{txtBtnPhoto}</button>
                <button onClick={activeDocument} className="btn btn-outline-dark m-1">{txtBtnDocument}</button>
                <Link to={`/articles/edit/${article.id}`} className="btn btn-outline-dark m-1">{translate("Edit Article")}</Link>
                <button onClick={handleDelete} className="btn btn-outline-danger m-1">{translate("Delete Article")}</button>
                </>
                }
                
                {uploadAreaPhoto && <UploadAreaSingle title="photoUrl" setUploadArea={setUploadAreaPhoto} 
                    setUploadData={setUploadData} subFolder={`articles/${article.id}/photos`}/>}
                {uploadAreaDocument && <UploadAreaSingle title="documentUrl" setUploadArea={setUploadAreaDocument} methods={["link"]}
                    setUploadData={setUploadData} subFolder={`articles/${article.id}/documents`}/>}
                
            </Card.Body>
            <Card.Footer>
                <h6><i>posted on {created}{updatedTxt} by 
                    <Link to={`/authors/${article.username}`}>
                        <BlackWhite>{article.firstName} {article.lastName}</BlackWhite> 
                    </Link></i>
                </h6>
                <ArticleLinkList article={article} setArticle={setArticle}/>
            </Card.Footer>
        </Card>
    )
    return(
       <>
         {!confirmation && <Main />}
         {confirmation && <ConfirmationArea question="Are you sure to delete this article?" handleDelete={deleteArticle} handleCancel={handleCancel} />}
       </>
    )
}
export default ArticleDetails;