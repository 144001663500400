import React, {useState, useEffect} from "react";

import {useSelector, useDispatch} from 'react-redux';
import DiaryFormEdit from "../newForms2/diaries/DiaryFormEdit";
import { Translate, translate } from "./Language/Translate";
import { deleteDiaryOnApi } from "../actions/currentCruise";
import { getDateObject } from "../helpers/Date";
import Spinner from "./Spinner";
import { initDiaryEntry } from "../helpers/diary";

const CruiseAreaCardDestinationDiary = ({day}) => {
    // console.log({day})
    const [isLoading, setIsLoading] = useState(true);
    const [diary, setDiary] = useState({})
    const [showEditForm, setShowEditForm] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const dispatch = useDispatch();
    const currentCruise = useSelector(st => st.currentCruise);
    
    useEffect(() => {
        const initEntry = async() => {
            // console.log('No Diary for this date', day.date);
            const init = initDiaryEntry(day);
            // console.log({init});
        }
        
        
        // const fetch = async() => {
            const {diaries} = currentCruise;
            const d = currentCruise.diaries?.find(d => d.dateTime === day.date);
            if(!d && isLoading)initEntry();

                
                

                
            // };
            setIsLoading(false);
        }


        // if(isLoading)fetch()

    , [currentCruise, isLoading]);








    if(isLoading)return <Spinner/>



    // console.log({day});
    const {date} = day;
    const emptyDiaryDay = {dateTime:date, dateText:getDateObject(new Date(date), Translate.language).longWeekday}
    
    // console.log({currentCruise});
    const {diaries} = currentCruise;
    // console.log(diaries);
    // const diary = diaries.find(d => d.dateTime === date) || {...emptyDiaryDay};
    // console.log({diary});

    const {dateText, description} = diary;
    const texts = description?.split("\n") || [];
    // console.log({texts});

    const handleDelete = async() => {
        // console.log('inside handleDelete', diary, diary.id);
        await dispatch(deleteDiaryOnApi(diary.id));
        (() => setConfirmation(false))();
    }

    const ConfirmationArea = () => (
        <div >
            <h4>{translate("Are you sure to delete this diary entry")}?</h4>
            <div className = "row col-12 my-5">
                <div className = "col-md-6">
                    <button className = "btn btn-outline-danger col-12" onClick={handleDelete}>{translate("Delete")}</button>
                </div>
                <div className = "col-md-6">
                    <button className = "btn btn-outline-secondary col-12" onClick={() => setConfirmation(false)}>{translate("Cancel")}</button>
                </div>
            </div>
        </div>

    )

    return(
        <div className = "mt-4 p-4">
            <h4>{dateText}</h4>
            {!confirmation && texts.map((t, idx) => {
                return (t === ""  ? <br key={idx}/> : <p key={idx}>{t}</p>)
                
            })}
            {confirmation && <ConfirmationArea/>}
            {/* {!confirmation && showEditForm && <DiaryFormEdit setShowEditForm={setShowEditForm} id={diary.id}/>} */}
            <div className="row col-12">
                <div className = "col-md-6 p-1">
                    {!confirmation && !showEditForm && <button className="btn btn-outline-dark col-12" 
                        onClick={() => setShowEditForm(true)}>{translate("Edit Diary Entry")}</button>}
                </div>
                <div className = "col-md-6 p-1">
                    {!confirmation && !showEditForm && <button className="btn btn-outline-danger col-12" 
                        onClick={() => setConfirmation(true)}>{translate("Delete Diary Entry")}</button>}
                </div>
            </div>

        </div>
    )
};

export default CruiseAreaCardDestinationDiary;