// 1.5.24
import React, { useEffect, useState } from "react";
import "./VesselList.css";
import { useSelector, useDispatch } from 'react-redux';
import { fetchVesselsFromAPI } from '../actions/vessels';
import VesselListItem from "./VesselListItem";
import {translate} from "./Language/Translate"
import Spinner from './Spinner';
import CruiseBackToCruiseButton from "./CruiseBackToCruiseButton";
import { BlackWhite } from "./styledComponents/colors";


function VesselList() {
  const vessels = useSelector(st => st.vessels);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(function() {
    async function fetchVessel() {
      dispatch(fetchVesselsFromAPI());
      setIsLoading(false);
    }
    if(isLoading)fetchVessel();
  }, [dispatch, isLoading]);
  

  if (isLoading) return <Spinner/>
  
  const initials = Array.from(new Set(vessels.map(v => v.name.slice(0,1).toUpperCase()))).sort();
  const newObj = {};
  initials.forEach(a => newObj[a] = []);
  vessels.forEach(v => newObj[v.name.slice(0,1).toUpperCase()].push(v));
  
  if (!isLoading && vessels.length === 0) return <b>{translate('No Vessels')}</b>;

  return (
    <div className="text-center">
      <CruiseBackToCruiseButton/>
      <h4 className="mt-5">{translate("Vessels")} ({vessels.length})</h4>
      <div className = "text-center">
                {initials.map((i, idx) => 
                    <span key={idx}>{idx !== 0 && i !== "-"? " - ":""}
                        <a href={`#${i}`}><BlackWhite>{i}</BlackWhite></a>
                    </span>
                )}
            </div>
      
        {initials.map(a => {
          return(
            <div key={a} id={a}>
            <h5 className="text-left">{a}</h5>
            <div className = "row row-eq-height">
              
              {newObj[a].map(v => <VesselListItem key={v.id} className="VesselListItem col-md-6" vessel={v} />)}
            </div>
            </div>
          )
        })}
      {/* <div className = "row row-eq-height">
            {vessels.map(vessel => <VesselListItem key={vessel.id} className="VesselListItem col-md-6" vessel={vessel} />)}
      </div> */}
    </div>
  )
}

export default VesselList;