import React, {useState, useEffect} from "react";
import Spinner from "../../components/Spinner";
import GroupsFormSchema from "./GroupsFormSchema";
import FormFields from '../FormFields'
import {Button} from 'react-bootstrap'
import { translate } from "../../components/Language/Translate";
import FormValidation from "../Validation/FormValidation";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupOnApi } from "../../actions/groups";
// import { setGlobalVarToState } from "../../actions/globals";
// import { fetchCruiseFromApi } from "../../actions/currentCruise";

const GroupsEditForm = ({group, setShowForm}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [serverError, setServerError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
    const globals = useSelector(st => st.globals);
    // const {groupShowNewForm} = globals;

    useEffect(()=> {
        const fetch = () => {
            const sets = new GroupsFormSchema(group);
            const vars = {};
            Object.entries(sets.fields).forEach((e) => vars[e[0]]=e[1].value);
            // console.log({sets, vars});
            (() => setFieldSettings({...sets.fields}))();
            (() => setVariables({...vars}))();
            (() => setIsLoading(false))();
        };
        if(isLoading)fetch()
    })
    if (isLoading) return <Spinner/>
    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError, globals};

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formValidation = new FormValidation({fieldSettings, variables});
        if(!formValidation.valid)return;
        // console.log(group.id, {...variables});
        dispatch(updateGroupOnApi(group.id, {...variables}));
        // dispatch(fetchCruiseFromApi(group.cruiseId));
        (() => setShowForm("none"))();
        (() => setServerError(""))();
    };

    return(
        <div className = "my-3 p-3" style={{border:"1px solid grey", borderRadius:"5px"}}>
            <h4>{translate("Edit Group")}</h4>
            <FormFields props={props}/>
            <Button block size="lg" type="submit" onClick={handleSubmit}>{translate("Submit")}</Button>
        </div>
    );
};
export default GroupsEditForm;