// import { Duration } from "../../../helpers/Date";
import {simpleRequest} from '../../../newForms2/simpleRequest'

class CurrencyFunctions{
    static interval = 6 * 60*60*1000;

    static fields = {
        fromCountry:{
            defaultValue:"",
            type:"text",
            fieldType:"select",
            settings:{},
        },
        toCountry:{
            defaultValue:"",
            type:"text",
            fieldType:"select",
            settings:{},
        },
        amount:{
            defaultValue:"100"
        },
        fromCurrency:{
            defaultValue:"EUR",
            type:"text",
            fieldType:"select",
            settings:{}
        },
        toCurrency:{
            defaultValue:"USD",
            type:"text",
            fieldType:"select",
            settings:{}
        }
        };
        static _subtractIntervalFromNow(interval){
            let dt = new Date ();
            Date.prototype.subtractInterval = function (interval) {
                this.setTime (this.getTime () - interval);
                return this;
            }
            // console.log('xxxxxx', dt.subtractInterval (interval));
            return dt.subtractInterval(interval);
        }
        static mustBeUpdated = (lastModified, interval) => {
            // console.log({lastModified, interval}, new Date(lastModified), new Date(), interval)
            const bol = (new Date(lastModified) < this._subtractIntervalFromNow(interval))
            // console.log(bol)
            return bol;
        }




    static async getForexDataFromLocalStorageOrAPI(){
        // console.log('inside getForexDataFromLocalStorageOrAPI')
        const forexDataOnLS = localStorage.hasOwnProperty("forexData") ? JSON.parse(localStorage.getItem("forexData")) : null;
        // console.log('2');
        let forexData = forexDataOnLS;
        // console.log('inside getFromAPI', forexData)
        // console.log(this.mustBeUpdated(forexData.lastModified, this.interval))
        const values = forexData && forexData.values;
        // console.log({forexData, values, interval:this.interval}, new Date(), Date(forexData.lastModified), new Date() - Date(forexData.lastModified) )
        // console.log( 'mustBeUpdated', this.mustBeUpdated(forexData.lastModified, this.interval));
        if (!values || this.mustBeUpdated(forexData.lastModified, this.interval)){
            // if (!values || (new Date() - forexData.lastModified > this.interval)){
                // console.log("FOREX müssen neu geladen werden")
                const response = await simpleRequest({'url':"tools/forex", method:"get"});
                // console.log({response}, response.data)
                forexData = response && response.data ? response.data : forexDataOnLS;
            // }
        }
        if(forexData)localStorage.setItem("forexData", JSON.stringify(forexData));
        return forexData;
        
    }
    static getUserDataFromLocalStorageOrDefault(){
        let userSettings = {
            fromCountry:"invalid", toCountry:"invalid", 
            fromCurrency:"EUR", toCurrency:"USD", 
            amount:"100", tax:5
        };
            
            if(localStorage.hasOwnProperty("forexUserSettings")){
                const set = localStorage.getItem("forexUserSettings");
                // console.log({set})
                if(set)userSettings = JSON.parse(set);
            };
        return userSettings;
    }
}

export default CurrencyFunctions;