import React from "react";
import {Form } from "react-bootstrap"
import {translate} from '../../components/Language/Translate'


const HintArea = ({rightsConfirmed, setRightsConfirmed }) => {
    // const  = props;
    // console.log({props});
    // console.log({rightsConfirmed,setRightsConfirmed});
            
    const HintConfirm = () => {
   
        return(
            
            <Form.Group size="lg" controlId={"confirm"}>

                <Form.Check type="checkbox" 
                            label={translate("I confirm to respect these regulations.")}
                            key="confirm"
                            checked={rightsConfirmed}
                            name={"confirm"}
                            onChange={() => setRightsConfirmed(!rightsConfirmed)}

                />
            </Form.Group>
        );
    };
    
    return(
        <div className="mt-5">
            <h6>{translate("Hint")}</h6>
            <p>{translate("If you want to upload media, please be aware of Copyrights and Personal Rights")}.<br/>
            {translate("To be sure, choice the option 'Insert a link'")}</p>
            <HintConfirm/>
        </div>
    )
};
export default HintArea;