import React, {useRef} from "react";
import {useSelector} from 'react-redux';
import {translate} from './Language/Translate'
import { Accordion, Card, Button } from "react-bootstrap";
import { BlackWhite } from "./styledComponents/colors";
import CruiseAreaCardDestinationAccCard from "./CruiseAreaCardDestinationAccCard";

const CruiseAreaCardDestinationsAcc = ({dates}) => {
    // console.log({dates});
    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);
    const defaultKey = useRef("none");
    const isJoined = currentUser.cruises?.some(cc => cc.cruiseId === currentCruise.id);
    
    
    const HelpArea = () => (
        <p>
            <small>
                {!isJoined && translate('In order to use the diary function or to enter destinations, you must be connected to this trip.')}<br/>
                {translate('Use the "Add Destination" function if several destinations are to be visited in one day.')}<br/>
                {translate('To ensure that the destinations are displayed in the correct order, the fields "Arrival" and "Departure" should be completed.')}<br/><br/>
                {translate('To select a target in the form (after clicking "Edit"), enter part of the name and then click on an element in the list.')}<br/>
                {translate('Click on a travel destination to see information and suggestions from other people or to make suggestions yourself.')}<br/><br/>
                {translate('Use the diary function to write your own diary. The entries are only visible for you.')}
            </small>
        </p>
    );

    const HelpCard = () => (
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => defaultKey.current=0}>
                    <BlackWhite>{translate("Help")}</BlackWhite>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0" id="0" >
            <Card.Body>
                <HelpArea/>
            </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
    
    return(
        <Accordion>
            <HelpCard/>
            {dates.map(d => <CruiseAreaCardDestinationAccCard day={d} dates={dates} key={d.key} defaultKey={defaultKey}/>)}
        </Accordion>
    )
};
export default CruiseAreaCardDestinationsAcc;