import React, {useEffect,useState} from 'react';
import {useParams} from "react-router-dom";
import ReactFormFields from '../ReactFormFields';
import ProposalsSchema from './ProposalsSchema';
import { ServerErrorArea } from '../FormMessageArea';
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux'
import { addProposalOnApi } from '../../actions/currentDestination';
import Spinner from '../../components/Spinner';
import {translate} from '../../components/Language/Translate'

const ProposalsNewForm = () => {
    const [variables, setVariables] = useState();
    const [constants, setConstants] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState('');
    const [serverError, setServerError] = useState('');
    const [success, setSuccess] = useState(false);
    const destinationId = +useParams().destinationId;
    const history = useHistory();
    const dispatch = useDispatch();

    const params = useParams();
    // console.log({params})

    useEffect(()=>{
        const fetch = async () =>{
            const consts = {fields: new ProposalsSchema(destinationId)};
            consts.events = {setVariables, setErrors, setServerError, setSuccess}
            const vars = {fields:{}};
            Object.entries(consts.fields).forEach(f => vars.fields[f[0]] = f[1].value);
            (() => setConstants(consts))();
            (() => setVariables({fields: {...vars.fields}, errors, serverError}))();
            (() => setIsLoading(false))();
        }
        // console.log({isLoading})
        if(isLoading)fetch()
    }, [isLoading, destinationId, errors, serverError])
    
    if(!constants || !variables || !variables.fields)return <Spinner/>
    // console.log({constants, variables, isLoading});
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        await dispatch(addProposalOnApi(destinationId, variables.fields));
        history.push(`/destination/details/${destinationId}`)
    };

    return (
        <div>
          <h4>{translate('Add Proposal')}</h4>
          <Form onSubmit = {e => handleSubmit(e,constants, variables)}>
            <ServerErrorArea constants={constants} variables = {variables}/>
            {!success && <ReactFormFields constants={constants} variables = {variables}/>}
            {!success && <Button block size="lg" type="submit" >{translate("Add")}</Button>}
        </Form>
        </div>
    );
};

export default ProposalsNewForm;