import React from "react";
import {Form} from 'react-bootstrap';
import { translate } from "../../../components/Language/Translate";
import { getCategoryOptions } from "../../../components/species/categories";

const Fields = ({variables, setVariables}) => {
    // console.log({variables});
    const handleChange = e => setVariables({...variables, [e.target.name]: e.target.value});
    
    return(
        <>
            <Form.Group>
                <Form.Label>{translate("Category")}</Form.Label>
                <Form.Control as = "select"
                    name = "category"
                    type="select"
                    value={variables.category}
                    onChange = {e => handleChange(e)}
                >
                    {getCategoryOptions("deDE").map(opt =>(
                        <option value= {opt.key} key={`${opt.key}_${opt.value}`}>{opt.value}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>{translate("German")}</Form.Label>
                <Form.Control
                    name = "deDE"
                    type = "text"
                    value={variables.deDE}
                    onChange = {e => handleChange(e)}
                ></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>{translate("English")}</Form.Label>
                <Form.Control
                    name = "enUK"
                    type = "text"
                    value={variables.enUK}
                    onChange = {e => handleChange(e)}
                ></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>{translate("Latin")}</Form.Label>
                <Form.Control
                    name = "latin"
                    type = "text"
                    value={variables.latin}
                    onChange = {e => handleChange(e)}
                ></Form.Control>
            </Form.Group>
        </>
    )
};
export default Fields;