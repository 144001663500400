import React, {useEffect, useState} from "react";
import { useDispatch} from "react-redux";
import Spinner from '../../components/Spinner';
import SelectVessel from './SelectVessel'
import NewVesselByUser from "./NewVesselByUser";
import SelectCruise from './SelectCruise';
import NewCruiseByUser from "./NewCruiseByUser";
import { fetchVesselsFromAPI } from "../../actions/vessels";
import { fetchCompaniesFromAPI } from "../../actions/companies"; 
import NewUserCruise from "./NewUserCruise";



const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [stage, setStage] = useState("selectVessel");
    const [mainVariables, setMainVariables] = useState({});
    // console.log('rerender Main', {stage});
    const props = {stage, setStage, mainVariables, setMainVariables};
    const dispatch = useDispatch();


    // console.log({mainVariables})
    useEffect(() => {
        const fetch = async () => {
            await dispatch(fetchCompaniesFromAPI());
            await dispatch(fetchVesselsFromAPI());
            // await dispatch(fetchCruisesFromApi());
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, dispatch]);


    if(isLoading)return <Spinner/>

    return (
        <div style = {{backgroundColor: "rgba(255,255,255,.8)"}} className = 'p-3'>
            {/* {stage === "start" && <Start setStage = {setStage}/>} */}
            {stage === "selectVessel" && <SelectVessel props={props}/>}
            {stage === "addNewVessel" && <NewVesselByUser props={props}/>}
            {stage === "selectCruise" && <SelectCruise props={props}/>}
            {stage === "addNewCruise" && <NewCruiseByUser props={props}/>}
            {stage === "addUserCruise" && <NewUserCruise props={props}/>}
        </div>
    )

}
export default Main;