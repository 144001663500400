
const writeTimeString  = (y,m,d)=>{
    return `${y}-${`0${m}`.slice(-2)}-${`0${d}`.slice(-2)}`
}

const addDays = (dateString, num) => {
    const dateTimeVar = new Date(dateString);
    dateTimeVar.setDate(dateTimeVar.getDate() + num)
    const y = dateTimeVar.getUTCFullYear();
    const m = dateTimeVar.getUTCMonth()+1;
    const d = dateTimeVar.getUTCDate();
    return writeTimeString(y, m, d);
};

const createDaysArray = (start, end) => {
    // console.log({start, end});
    const days = [];
    let currentDate = start;
    let counter = 0;
    // if (!validDateString(start) || !validDateString(end)) return [];
    if (start > end) return [];
    while (currentDate <= end && counter < 1000){
        // console.log({currentDate});
        counter += 1;
        days.push(currentDate);
        currentDate = addDays(currentDate, 1);
    };
    // console.log({days, counter})
    return Array.from(new Set(days));
};

export const getDatesOfCruise = (cruise) => {
    if(!cruise)return [];
    const {startAt, endAt} = cruise;
    const daysArray = createDaysArray(startAt, endAt);
    // console.log({startAt, endAt, daysArray});
    return daysArray;
};
export const getDatesOfCruises = (cruises) => {
    let all = [];
    cruises.forEach(cr => all = [...all, ...getDatesOfCruise(cr)]);
    const result = Array.from(new Set(all)).sort();
    return result;
};
export const getDatesOfYear = (cruises, year) => {
    const a = getDatesOfCruises(cruises);

    const result = getDatesOfCruises(cruises).filter(d => d.includes(year));
    // console.log({result, a, year});
    return result
};

export const getDaysOnSea = (cruises) => {
    const daysOnSeaAll = cruises.reduce((accum,cruise) => {
        return [...accum, ...getDatesOfCruise(cruise)]
    }, []);
    const daysOnSea = Array.from(new Set(daysOnSeaAll));
    const len = daysOnSea.length
    // console.log("getDaysOnSea", {cruises, cr: cruises.map(cr => cr.id), idArr, _selectedCruises, daysOnSeaAll, daysOnSea, len});
    return len;
}

// const writeTimeString  = (y,m,d)=>{
//     return `${y}-${`0${m}`.slice(-2)}-${`0${d}`.slice(-2)}`
// }

// const addDays = (dateString, num) => {
//     const dateTimeVar = new Date(dateString);
//     dateTimeVar.setDate(dateTimeVar.getDate() + num)
//     const y = dateTimeVar.getUTCFullYear();
//     const m = dateTimeVar.getUTCMonth()+1;
//     const d = dateTimeVar.getUTCDate();
//     return writeTimeString(y, m, d);
// };

// const createDaysArray = (start, end) => {
//     // console.log({start, end});
//     const days = [];
//     let currentDate = start;
//     let counter = 0;
//     if (!validDateString(start) || !validDateString(end)) return [];
//     if (start > end) return [];
//     while (currentDate <= end && counter < 1000){
//         // console.log({currentDate});
//         counter += 1;
//         days.push(currentDate);
//         currentDate = addDays(currentDate, 1);
//     };
//     // console.log({days, counter})
//     return Array.from(new Set(days));
// }