import React from "react";
import "./VesselList.css";
import { Link } from 'react-router-dom';
import {translate} from './Language/Translate';

const VesselListItem = ({vessel}) => {
    const note = vessel.note && `${vessel.note}, `
    return(
      

        <div className="col-md-4 col-sm-6 p-1 my-1" >
        {/* <div className="col-md-4 p-1 my-1" style={{backgroundColor:"rgba(255,255, 255, .5)", borderRadius:"5px"}}> */}
          <Link to={`/vessels/${vessel.id}`} className="btn btn-outline-dark col-12" style={{width:"100%", height:"100%", backgroundColor:"rgba(255,255, 255, .5)", borderRadius:"5px"}}>
              {vessel.prefix} {vessel.name} <small>({translate(vessel.type)}, {note}{vessel.companyName})</small>
          </Link> 
        </div>
         
    );

};

export default VesselListItem;