class CruiseFunctions{
    static setIsJoined(currentUser, allCruises){
        // console.log('inside setIsJoined', {currentUser, allCruises})
        const cUC = currentUser && currentUser.cruises && currentUser.cruises.map(c => c.cruiseId)
        const cruises = allCruises.map(c =>{ 
          // console.log({c, cUC})
          c.isJoined=((cUC && cUC.includes(c.id))?true:false)
          c.showVessel=true
          // console.log(c.isJoined, {cUC, c})
          return c;
        });
        return cruises;
    }
};
export default CruiseFunctions;