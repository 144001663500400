import React from "react";
import { StyledDivWithBorder } from "../../../styledComponents/divs";

const ObservationListList = ({props}) => {
    const {events, observations, getText} = props
    return(
        <div>
                {events.map((ev, idx) => {
                    const obs = observations.filter(o => getText(o) === ev)
                        return(
                            <StyledDivWithBorder className="mt-3" key={idx} >
                                <h5 key={idx}>{ev}</h5>
                                {obs.map(o => <div key = {o.id} id={o.id}>
                                                    <b>{`${o.deDE} / ${o.enUK}`}</b>
                                                    {o.description && <p>{o.description}</p>}
                                                </div>)}
                            </StyledDivWithBorder>
                        )
                })}
        </div>
    );
};

export default ObservationListList;