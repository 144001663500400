import {
    FETCH_OTHER_USER,
    UPDATE_OTHER_USER
  } from '../actions/types';

  
export default function rootReducer(state = {}, action) {
    // console.log({action})
    switch (action.type) {
        case FETCH_OTHER_USER:
          return ({...action.otherUser});
        case UPDATE_OTHER_USER:
          return ({...action.otherUser})
        default:
            return state;
        };
};