import {translate} from '../../components/Language/Translate'


class ArticleLinksSchema{

        constructor(){
            this.fields = {
                subject: {
                    label: translate("Subject"),
                    // initialValue:"",
                    validation:{
                        minLength:3,
                        maxLength:60
                    },
                    required:true,
                    type:"text",
                    fieldType:"text",
                    autoFocus:true
                },  
                description: {
                    label:translate("Description"),
                    initialValue:"",
                    type:"text",
                    fieldType:"text"
                },  
                url: {
                    label:translate("URL"),
                    initialValue:"",
                    type:"text",
                    fieldType:"text",
                    required:true,
                    validation:{minLength: 5}
                },  
                photoUrl: {
                    label:translate("Photo URL"),
                    initialValue:"",
                    type:"text",
                    fieldType:"text"
                },  
                copyright: {
                    label:translate("Copyright"),
                    initialValue:"",
                    type:"text",
                    fieldType:"text"
                },  
        }
    }
};

export default ArticleLinksSchema;

