import React, {useState} from "react";
import CompanyVesselList from "./CompanyVesselList";
import {useSelector} from 'react-redux';
import {ensureAuthorized} from '../helpers/auth';
import {translate} from './Language/Translate';
import CompanyVesselNew from "../newForms2/companyVessel/CompanyVesselNew";

const CompanyVesselLists = () => {
    const [showFormNew, setShowFormNew] = useState(false);
    const currentCompany = useSelector(st => st.currentCompany);
    const currentUser = useSelector(st => st.currentUser);
    const actualVessels = currentCompany.vessels.filter(v => v.actual === true)
    const recentVessels = currentCompany.vessels.filter(v => v.actual === false)
    const mayAddVessel = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adVe', currentCompany.handle):false;


    return(
        <>
              {mayAddVessel && !showFormNew && <button onClick={() => setShowFormNew(true)} 
                className="btn btn-outline-success my-3 col-md-12">{translate("Add Vessel")}</button>}
              {showFormNew && <CompanyVesselNew setShowNewForm={setShowFormNew}/>}
              <CompanyVesselList title={translate("Actual in fleet")} vessels={actualVessels}/>
              <CompanyVesselList title={translate("Recent Vessels")} vessels={recentVessels}/>
        </>

    )
};
export default CompanyVesselLists;