import { translate } from "../../components/Language/Translate";

class ClaimsData{

    static announcements = {
            "selection":{
                label:`${translate("Please select the field with the claimed entry")}:`,
                options:[
                    {key:"invalid", value:`- ${translate("Please select")} - `},
                    {key:"subject", value:translate("Subject")},
                    {key:"description", value:translate("Description")},
                    {key:"url", value:translate("Document")},
                    {key:"photoUrl", value:translate("Photo")}
                ],
                value: "invalid",
                autofocus:true,
                type:"string",
                fieldType:"select",
                validation:{minLength:3, mustNotBeEqualToValues:["invalid"]},
            },
            "personel": {
                label:translate("Are you involved personally in this staff?"),
                toolTip:"If you are involved, the item will be hidden, until an administrator has checked it."
                        +" If not, the item is still be shown, until an administrator has checked it."
                        +" If you enable this box, and you are not involved, the claim will be rejected.",
                type:"boolean",
                fieldType:"radio",
                value:"no",
                options: [
                    {key:"yes", value:translate("Yes, it touches my personal rights or copyrights.")},
                    {key: "no", value:translate("No, it touches the rights of other persons.")}
                ]
            },
            "passage":{
                label:"Please insert (copy & paste) the passage with the claimed text",
                type:"string",
                fieldType:"textarea",
                validation:{minLength:3, maxLength:150},
                value:""
            },
            "reason":{
                label:"Please tell us the reason for the claim.",
                type:"string",
                fieldType:"textarea",
                validation:{minLength:3, maxLength:250},
                value:""
            }
    };
    static pinboard = this.announcements;
};
export default ClaimsData;