import React, {useEffect, useState, useRef} from "react";
import { useSelector} from "react-redux";
import { simpleRequest } from "../newForms2/simpleRequest";
import Spinner from './Spinner'
import CruiseAreaCardEditorialsCard from "./CruiseAreaCardEditorialsCard";
import {Accordion} from "react-bootstrap";
import {translate} from './Language/Translate'

const CruiseAreaCardEditorials = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [editorials, setEditorials] = useState({});
    const currentCruise = useSelector(st => st.currentCruise);
    const isMounted = useRef(false);
    
    useEffect(() => {
        (() => isMounted.current = true)();
        const fetch = async() => {
                const response = await simpleRequest({url:`keywords/${currentCruise.id}`, method:"get"});
                
                const edit = response.data || {};
                // console.log({edit});
                if(isMounted.current){
                    (() => setEditorials({...edit}))();
                    (() => setIsLoading(false))();
                }
        };
        if(isLoading)fetch();
        return () => isMounted.current=false;
    },[isLoading, currentCruise.id]);

    if (isLoading)return <Spinner/>;
    // console.log({editorials});
    return(
        <div>
            <Accordion>
                <p>{translate("If there are articles in our travel magazine related to your ship or the destinations, you will find them here.")}</p>
                {editorials && Object.keys(editorials).length === 0 && <p className="ml-3">{translate("No items")}</p>}
                {editorials && Object.entries(editorials).map((e,idx) => 
                    <CruiseAreaCardEditorialsCard 
                            editorials={e} idx = {idx} key={`event_${idx}`} eventKey={`event_${idx}`}
                    />)}
            </Accordion>
        </div>
    );
};

export default CruiseAreaCardEditorials;