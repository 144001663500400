import React, { useEffect, useState } from "react";
import {simpleRequest} from  '../../newForms2/simpleRequest';
import Spinner from '../Spinner';
import ProfilePhotoList from '../ProfilePhotoList';
import {Card, Accordion, Button} from 'react-bootstrap'
import { translate } from "../Language/Translate";
import { BlackWhite } from "../styledComponents/colors";
import NewsBadge from "../NewsBadge";


const CardProfilePhotoList = ({eventKey}) => {
    // console.log('CardProfilePhotoList');

    const [isLoading, setIsLoading] = useState(true);
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        // console.log('CardProfilePhotoList.useEffect')
        const fetch = async() => {
            const ph = await simpleRequest({url:`admin/profile`, method:"get"});
            // console.log({ph}, ph.data);
            (() => setPhotos(ph.data?.photos || []))();
            (() => setIsLoading(false))();
        };
        if(isLoading)fetch();
    }, [setPhotos, setIsLoading, isLoading]);

    if(isLoading) return <Spinner/>
    // console.log({photos});
    return(
        
            <Card style={{backgroundColor:'#e5e9df', color:"#1a1620"}}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("New Profile Photos")} <NewsBadge count = {photos.length} /></BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
                <Card.Body>
                    <ProfilePhotoList photos={photos} setPhotos={setPhotos}/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
        
    )

};
export default CardProfilePhotoList;