import React from "react";

const FieldError = ({name, props}) => {
    const {fieldErrors} = props;
    if(!fieldErrors){
        // console.log("FIELDERRORS MISSING");
        return null;
    }
    const fieldError = fieldErrors[name]
    if(!fieldError)return null;
    return(
        <span className = "text-danger"><small>{` - ${fieldError}`}</small></span>
    );
};
export default FieldError;