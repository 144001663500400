import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../components/Language/Translate";
import { simpleRequest } from "../../simpleRequest";
import DaysDates from "./DaysToursDates";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";
import TourEscortArea from "../../../components/Tours/TourEscortArea";

const DaysTours = ({props}) => {
    const {days, setDays} = props;
    // const 
    const [tourEscorts, setTourEscorts] = useState([]);
    const [account, setAccount] = useState(null);
    const currentCruise = useSelector(st => st.currentCruise);
    const dates = Array.from(new Set(days.map(d => d.date))).sort();

    // console.log({currentCruise})

    // useEffect(() => {
    //     const fetch = async() => {

    //     }
    // })
    
    const handleTR = async() => {
        const response = (await simpleRequest({url:`worddocuments/tourreport/cruise/${currentCruise.id}/all`})).data;
        console.log({response})
    };
    const handleDD = async() => {
        // /dailyduty/cruise/:cruiseId/all
        const response = (await simpleRequest({url:`worddocuments/dailyduty/cruise/${currentCruise.id}/all`})).data;
        console.log({response})
    }
    const handleClickWishList = async () => {
        // console.log('Wish List');
        const wishlist = (await simpleRequest({ url: `worddocuments/wishlist/${currentCruise.id}` })).data?.wishlist;
        console.log({ wishlist });
    }
    const buttons = () => (
        <>
            <button className="btn btn-outline-dark" onClick={handleTR}>
                {`${translate("Send all Tour Reports")} - ${currentCruise.shorthand}`}
            </button>
            <button className="btn btn-outline-dark ml-1" onClick={handleDD}>
                {`${translate("Send all Daily Duties")} - ${currentCruise.shorthand}`}
            </button>
            <button className="btn btn-outline-dark ml-1" onClick={handleClickWishList}>
                {`${translate("Send Wishlist")} - ${currentCruise.shorthand}`}
            </button>        
        </>
    )

    return(
        <div className="my-3">
            <h4 className="mb-3">{translate("Tours")}</h4>
            <TourEscortArea tourEscorts={tourEscorts} setTourEscorts={setTourEscorts} account={account} setAccount={setAccount}/>
            {!!account && buttons()}
            {!!account && <StyledDivWithBorder >
                {dates.map ((date,idx)=> <DaysDates key={idx} props={{days, setDays, date, idx, tourEscorts}}/>)}
            </StyledDivWithBorder>}
        </div>
    )
};
export default DaysTours;