import React from "react";


const Rekursion = ({name, props}) => {

    const type = typeof(props)
    return (
        <div className="text-left ml-5">
            {type ==="object" && <h6>{name} ({type})</h6>}
            {props instanceof(Object) && !(props instanceof(Array)) && Object.entries(props).map((v,idx) => (
                <div className="pl-1" key={`object_${name}${v.toString()}-${idx}`}>
                    <Rekursion props={v[1]} name={v[0]}/>
                </div>))}
            {props instanceof(Array) && props.map((v,idx) => (
                <div className="pl-1" key={`array_${name}${v.toString()}-${idx}`}>
                    <Rekursion props={v} name={idx}/>
                </div>))}
            {type !== "array" && type !== "object" && type!=="boolean" && <p className="pl-1" key={`other_${name}}`}>{name} ({type}): {props}</p>}
            {type === "boolean" && <p className="pl-1" key={`bol_${name}`}>{name} ({type}): {props?"true":"false"}</p>}
        </div>
    )
};
export default Rekursion;