import axios from "axios";
// const key = process.env.REACT_APP_IMGBB_KEY;
const key="0ec914662e54523b3a1d3276fbdc533d";
const API_URL = `https://api.imgbb.com/1/upload`;



const uploadToImgBB = async (file) => {
    return new Promise((resolve, reject) => {
        let payload = new FormData()
        payload.append('image', file);
        payload.append('key', key);
        axios.post(API_URL, payload)
        .then(({data}) => {
            // console.log({data});
            if(!data?.data)reject("No data returned from ImgBB.")
            resolve(data.data)
        })
        .catch(err => {
            // console.log({err});
            reject(err)
        })
    })
};




export {uploadToImgBB}