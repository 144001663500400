import React, { useState } from 'react';
import DestinationEditByAdmin from '../../../newForms2/destinations/DestinationEdit';


const NewDestinationInUseListItem = ({destination, newDestinations, setNewDestinations}) => {
    // console.log({destination, newDestinations, setNewDestinations});
    const [editForm, setEditForm] = useState(false);

    

    const handleClick = () => {
        
        // (() => setNewDestinations(newDestinations.filter(d => d.id !== destination.id)))();
        (() => setEditForm(!editForm))();
    }

    return(
        <div>
            <div key={destination.id}  className = "">
                <button className="btn btn-outline-dark col-12 my-1" onClick={handleClick} id={destination.id}>
                    {destination.deDE}, {destination.enUK}, ({destination.updatedBy || destination.createdBy})
                </button>
            </div>
            {editForm && <DestinationEditByAdmin destination={destination} newdestinations={newDestinations} setNewdestinations={setNewDestinations} setShowFormEdit={setEditForm}/>}
        </div>
    )
};

export default NewDestinationInUseListItem;