
import {
  
  FETCH_USERS,
} from "../actions/types";


function sortByUsername(users) {

  return users.sort(username => username);
}


export default function rootReducer(state = [], action) {

  switch (action.type) {

    case FETCH_USERS:
      // console.log({action})
      const users = action.users?.users || [];
      return sortByUsername([...users]);



    default:
      return state;
  }
}
