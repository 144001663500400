import React, { useState } from "react";
import { Translate, translate } from "../Language/Translate";
import { BlackWhite } from "../styledComponents/colors";
import ChatThemeNew from "../../newForms3/travelchats/ChatThemeNew";
import ChatThemeList from "./ChatThemeList";
// import { categories } from "../species/categories";


const ChatCategory = ({props}) => {
    const [formNew, setFormNew] = useState(false)
    const [sorting, setSorting] = useState('abc')  //actual
    const {chatCategory} = props;

    const handleClick = () => setFormNew(!formNew);



    const newProps = {setFormNew, formNew, sorting, props, chatCategory};
    // console.log(newProps);
    return(
        <div>
            <h3>{chatCategory.label[Translate.language || "enUK"]}</h3>
            <p>
                <span onClick={() => setSorting("abc")}><BlackWhite>A-B-C</BlackWhite></span>
                <span onClick={() => setSorting("actual")}><BlackWhite>{translate("actual")}</BlackWhite></span>
            </p>
            {!formNew && <button className="btn btn-outline-dark" onClick={handleClick}>{translate("Add Theme")}</button>}
            {formNew && <ChatThemeNew chatCategory={chatCategory} backFunc={() => setFormNew(false)}/>}
            {!formNew && <ChatThemeList category={chatCategory.category} />}
        </div>
    )
};
export default ChatCategory;