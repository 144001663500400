

import {
    ADD_GROUP,
    UPDATE_GROUP,
    UPDATE_GROUP_MEMBER,
    FETCH_GROUPS,
    ADD_GROUP_CHAT
  } from '../actions/types';

  
  export default function rootReducer(state = [], action) {
    switch (action.type) {
    
     case ADD_GROUP:
      if(!action.group)return state;
      action.group.order = state.length;
      return [...state, action.group];
    case FETCH_GROUPS:
      if(!action.groups)return state;
      return ([...action.groups]);
    case UPDATE_GROUP:
      if(!action.group)return state;
      // console.log({action}, "inside UPDATE_GROUP");
      return [...state.filter(g => g.id !== action.group.id), action.group];
    case UPDATE_GROUP_MEMBER:
      // console.log({action});
      if(!action.groupMember)return state;
      const grp = state.find(g => g.id === action.groupMember.groupId);
      grp.members = [...grp.members.filter(gm => gm.id !== action.groupMember.id), action.groupMember];
      return [...state.filter(g => g.id !== grp.id), grp];
    case ADD_GROUP_CHAT:
      // console.log(action);
      if(!action.groupChat)return state;
      const group = state.find(g => g.id === action.groupChat.groupId);
      // console.log(group);
      group.chat = [...group.chat, action.groupChat];
      // console.log({group, state}, [...state.filter(g => g.id !== action.groupChat.groupId)], group);
      return [...state.filter(g => g.id !== action.groupChat.groupId), group];
      
      
      default:
            return state;
        };
    };