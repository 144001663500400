import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import { translate } from "../../components/Language/Translate";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useSelector} from "react-redux";
import Spinner from '../../components/Spinner';
// import FormCruiseNotFoundSchema from "./CruiseProposalSchema";
import FormFields from "../FormFields"
import CruiseProposalSchema from "./CruiseProposalSchema";
// import {addCruiseProposalOnAPI} from '../../actions/cruiseProposals'
import FormValidation from '../Validation/FormValidation';
import { simpleRequest } from "../../newForms2/simpleRequest";

const CruiseProposalNew = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [success, setSuccess] = useState(false);
    const [serverError, setServerError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const vessels = useSelector(st => st.vessels)
    const history = useHistory();
    // const dispatch= useDispatch();

    // console.log('inside CruiseProposalNew in newforms2')

    useEffect(() => {
        // console.log('inside useEffect');
        const fetch = () => {
            const sets=new CruiseProposalSchema(vessels);
            (() => setFieldSettings({...sets.fields}))();
            const vars = {}
            Object.entries(sets.fields).forEach(v => vars[v[0]] = v[1].initialValue);
            (()=> setVariables(vars))();
            (()=> setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, setFieldSettings, setVariables, setIsLoading, vessels]);
 
    if(isLoading) return <Spinner />

    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log('inside handleSubmit', e.target, variables, fieldSettings);
        const formValidation = new FormValidation({fieldSettings, variables});
        if(!formValidation.valid)return;
        const data = {...variables};
        if(data.vesselId)data.vesselId = + data.vesselId;
        const response = await simpleRequest({url:'cruiseproposals', method:"post", data});
        // console.log(response.data);
        if(response.data){
            (()=> setSuccess(true))();
        }else{
            const errorMessage = response.response && response.response.data.error && response.response.data.error.message;
            if (errorMessage){
                (() => setServerError(errorMessage))();
            };
        };
        
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div>
            <hr/>
            <h4>{translate("Add new Cruise")}</h4>
            {!success && 
                <Form onSubmit = {e => handleSubmit(e)}>
                    <FormFields props={props}/>
                    <Button block size="lg" type="submit" >{translate("Add Cruise")}</Button>
                </Form>}
            {success && 
                <div>
                    <h5>{translate("Cruise is sent.")}</h5>
                    <p>{translate('We will check the entries and then put the cruise into the database.')}</p>
                    <p>{translate('This may take some days.')}</p>
                    {/* <Link to="/" className="btn btn-outline-dark">Ok</Link> */}
                    <button className="btn btn-outline-dark" onClick={() => history.push('/')}>Ok</button>
                </div>
            }
            
        </div>
    )
};
export default CruiseProposalNew;