import React from "react";
import { translate } from "../../components/Language/Translate";
import {Form} from "react-bootstrap"


const InsertLink = ({data, setData}) => {


    const handleChange = e => setData({...data, value:e.target.value});

    return(

                    <Form.Group size="lg" controlId={"input"} className="my-5">
                        <Form.Label>{translate("Please insert an URL")} * </Form.Label>
                         
                        <Form.Control 
                             type="url"
                             key="input"
                             name="inputFile"
                             onChange={e => handleChange(e)}
                        />
                    </Form.Group>

    );
};
export default InsertLink;