import React, {useEffect, useState} from "react";
import { translate } from "../../components/Language/Translate";
import { useSelector } from "react-redux";
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields"
import { simpleRequest } from "../../newForms2/simpleRequest";
import VesselFormSchema from '../vessels/VesselFormSchema'

const NewVesselByUser = ({props}) => {
    const {stage, setStage, mainVariables, setMainVariables} = props;
    // console.log({stage, setStage, mainVariables, setMainVariables})
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [serverError, setServerError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const companies = useSelector(st => st.companies);
    
    // console.log(setServerError);

    useEffect(() => {
        const fetch = () => {
            const sets = new VesselFormSchema(companies);
            const sendData = ["companyHandle", "type", "prefix", "name", "note", "description"];
            let fields = {};
            let vars = {};
            sendData.forEach(d => {
                fields[d] = sets.fields[d];
                vars[d] = sets.fields[d].value
            });

            (() => setFieldSettings ({...fields}))();
            (() => setVariables ({...vars}))();
            (() => setIsLoading (false))();
        };
        if(isLoading)fetch();
    });

    if(isLoading)return <Spinner/>
    // console.log({fieldSettings, variables});

    const handleSubmit = async(e) => {
        e.preventDefault();
        const response = await simpleRequest({url:"vessels", method:"post", data:{...variables}});
        const newVessel = response.data && response.data.vessel;
        // console.log({newVessel});
        if(!newVessel)return;
        (() => setMainVariables({...mainVariables, vesselId:newVessel.id}))();
        (() => setStage("selectCruise"))();
    };


    const fieldProps = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div style={{border:"1px solid grey", borderRadius:"5px"}} className = "p-3 mt-5">
            <h4>{translate("Add New Vessel")}</h4>
            <FormFields props={fieldProps}/>
            <button className= "btn btn-outline-dark" onClick={handleSubmit}>{translate("Add Vessel")}</button>
        </div>
    )

};
export default NewVesselByUser;