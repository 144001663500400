import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
import ArticleList from "./Admin/ArticleList";
import { BlackWhite } from "./styledComponents/colors";

const CruiseAreaCardEditorialsCard = ({editorials, eventKey}) => {
  // console.log({editorials})
  

    return(
      <Card key={eventKey}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
              <BlackWhite>{editorials[0]}</BlackWhite> 
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={eventKey}>
              <Card.Body>
                <ArticleList list={editorials[1].articles}/>
              </Card.Body>
          </Accordion.Collapse>
      </Card>

    )
};
export default CruiseAreaCardEditorialsCard;