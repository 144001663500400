import React, { useEffect, useState} from "react";
import "./CruiseList.css";
// import {Link} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCruisesFromAPI } from '../actions/cruises';
import { fetchCompaniesFromAPI } from '../actions/companies';
import { fetchVesselsFromAPI } from '../actions/vessels';
import {actualDateString} from '../helpers/Date'
import CruiseListItems from "./CruiseListItems";
import {translate} from "./Language/Translate";
import Spinner from './Spinner';
import CruiseBackToCruiseButton from "./CruiseBackToCruiseButton";
import { sortArrayByObjectField } from "../helpers/sort";
// import reactRouterDom from "react-router-dom";

function CruiseList() {
  // console.log("CruiseList");
  const actualDate=actualDateString("local")
  const cruises = sortArrayByObjectField(useSelector(st => st.cruises), "startAt");
  const currentUser = useSelector(st => st.currentUser);
  // const currentCruise = useSelector(st => st.currentCruise);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(function() {
    // console.log("CruiseList.useEffect", isLoading);
    async function fetchData() {
      dispatch(fetchCruisesFromAPI());
      // console.log('fetchCruisesFromAPI')
      dispatch(fetchCompaniesFromAPI());
      // console.log('fetchVesselsFromAPI')
      dispatch(fetchVesselsFromAPI());
      
      setIsLoading(false);
    }
    if (isLoading) fetchData();
  }, [isLoading, dispatch]);


  // console.log({isLoading, cruises})
  if (isLoading) return <Spinner/>

  if (!isLoading && (!cruises || cruises.length === 0)) return <b>{translate("No items")}</b>;
  const ucr = currentUser && currentUser.cruises && currentUser.cruises.map(c => c.cruiseId)
  // console.log ({ucr})
  cruises.forEach(c => {
    c.showVessel=true;
    if (ucr && ucr.includes(c.id))c.isJoined=true; 
  });
  console.log({cruises});
   
  
  const currentCruises = cruises.filter(c =>{return (c.startAt <= actualDate && c.endAt >= actualDate)});
  const plannedCruises = cruises.filter(c =>{return (c.startAt > actualDate)});
  const passedCruises = cruises.filter(c =>{return (c.endAt < actualDate)});
  // console.log({cruises, currentCruises, plannedCruises, passedCruises, actualDate});
  return (
    <div className="text-center">
      <CruiseBackToCruiseButton/>
      <h4>{translate("Cruises")} ({cruises.length}):</h4>
      <CruiseListItems cruises = {currentCruises} headLine="Current" period="none"/>
      <CruiseListItems cruises = {plannedCruises} headLine="Future" period="month"/>
      <CruiseListItems cruises = {passedCruises} headLine="Past" period="month"/>
    </div>

  )
}

export default CruiseList;