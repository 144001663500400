import React from "react";
import UserAreaCardProfile from './UserAreaCardProfile'
import UserAreaCardCruises from './UserAreaCardCruises'
import UserAreaCardMessages from './UserAreaCardMessages'
import UserAreaCardFriends from './UserAreaCardFriends'
import {Accordion, Card, Button} from 'react-bootstrap';
import { BlackWhite } from "./styledComponents/colors";
// import {useSelector} from 'react-redux';
// import User from "../helpers/user";
// import GroupMain from './Groups/GroupMain';
import NewsBadge from "./NewsBadge";
import GroupProvider from './Groups/GroupProvider';
import UserMap from "./User/UserMaps/UserMap";
import UserFavourites from "./UserFavourites";

const UserAreaCard = ({eventKey, title, count, type}) =>{
  // const currentUser = useSelector(st => st.currentUser);
  // console.log({eventKey, title, count, type});

    return(
        <Card>
            <Card.Header style={{backgroundColor:"rgba(255,255,255,.5)"}}>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <BlackWhite>{title} <NewsBadge count={count}/></BlackWhite>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
              <Card.Body>
                    {type==="showProfile" && <UserAreaCardProfile />}
                    {type==="showMap" && <UserMap />}
                    {type==="showCruises" && <UserAreaCardCruises />}
                    {type==="showFavourites" && <UserFavourites />}
                    {type==="showMessages" && <UserAreaCardMessages />}
                    {type==="showFriends" && <UserAreaCardFriends />}
                    {type==="showGroups" && <GroupProvider location="user"/>}
              </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
};
export default UserAreaCard;