import React, { useState } from "react";
import {Form} from 'react-bootstrap';
import { translate } from "../../../components/Language/Translate";
import {BlackWhite} from '../../../components/styledComponents/colors';
import FieldError from "../../Fields/FieldError";

const TextFieldSelect = ({props, array, name}) => {
    const {setVariables} = props;
    const [content, setContent] = useState('');

    const ValueArea = () => {
        const handleClick = e => {
            const {albid, destid} = e.target.parentElement.dataset;
            setVariables(variables => {return {...variables, albumId:+albid, destinationId:+destid}});

        }
        const newArray = array.filter(i => i.name.toLowerCase().includes(content.toLowerCase()));
        const hint = newArray.length ? translate("Please select from list") : translate("No results");

        return(
            <div className="mt-3">
                <p className = "mb-2"><b>{hint}</b></p>
                <ul>
                    {newArray.map(i => {
                        const key = `${i.albumId}_${i.destinationId}`
                        return(
                            <li key = {key} data-albid = {i.albumId || 0} data-destid = {i.destinationId || 0}
                                onClick = {e => handleClick(e)}
                            >
                                <BlackWhite>{i.name}</BlackWhite>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
    return(
        <>
            <FieldError name={name} props={props}/>
            <Form.Control
                value={content}
                onChange = {e => setContent(e.target.value)}
                placeholder = {translate('Write some chars and then select from list')}
                
            >
            </Form.Control>
            {content.length > 0 && <ValueArea />}
        </>
    )
};
export default TextFieldSelect;