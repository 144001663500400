
import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {Card } from 'react-bootstrap';
import { deleteCruiseCrewOnApi } from '../actions/currentCruise';
import {translate} from "./Language/Translate"
import Spinner from './Spinner'


const CruiseAreaCruiseCrewDetails = ({index, setIndex, crew}) => {
    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);
    const dispatch = useDispatch();

    // if(!currentCruise || !currentUser)return <p><Spinner/></p>

    const ccItem = crew && crew[index]

    if(!ccItem)return <Spinner/>
    const handleClose = () => {
        setIndex(-1);
    }
    const removeFromCruise = async(e) => {
        const id = crew && crew[index].id;
        setIndex(-1);
        dispatch(deleteCruiseCrewOnApi(id));
    };
    const {userStatus} = currentUser;
    const mayEdit = userStatus && (["developer", "admin"].includes(userStatus) || userStatus.includes(currentCruise.companyHandle));
    return(
        <Card  style = {{height: "100%", width:"100%"}}>
        <Card.Header>
            <h5 className="text-center">{ccItem.firstName} {ccItem.lastName} ({ccItem.position})</h5>
        </Card.Header>
        <Card.Body className="">
            <div className="row">
                <div className="col-md-6 my-2">
                    {ccItem.photoUrl && <img src={ccItem.photoUrl} alt="" style={{maxWidth:"100%"}}></img>}
                </div>
                <div className="col-md-6 my-2">
                    <h5>Biography</h5>
                    <p>{ccItem.bio}</p>
                </div>
            </div>
        </Card.Body>
        <Card.Footer className="text-center">
            {mayEdit && <button className="btn btn-outline-danger mx-3" data-txt="Cruise" data-idx={ccItem.id} onClick={removeFromCruise}>{translate("Remove from this Cruise")}</button>}
            <button className="btn btn-outline-secondary mx-3" onClick={handleClose} data-txt="Cruise">{translate('Close')}</button>
        </Card.Footer>
    </Card>
    )
}
export default CruiseAreaCruiseCrewDetails

