import { useEffect, useState } from "react"
import ChatThemeSchema from "./ChatThemeSchema"
import { translate } from "../../components/Language/Translate";
import TextField from "../Fields/TextField";
import SelectField from "../Fields/SelectField";
import Spinner from '../../components/Spinner';
import { Col, Form } from "react-bootstrap";
import { simpleRequest } from "../../newForms2/simpleRequest";
import {StyledDivWithBorder} from '../../components/styledComponents/divs'

const ChatThemeNew = ({chatCategory, backFunc}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});

    useEffect(() => {
        const fetch = async() => {
            const _fields = new ChatThemeSchema(chatCategory).fields;
            const _vars = {};
            Object.keys(_fields).forEach(f => _vars[f] = _fields[f].initialValue);
            setVariables({..._vars, category: chatCategory.category});
            setFieldSettings({..._fields});
            setIsLoading(false);
        }

        if(isLoading)fetch()
    }, [setVariables, isLoading, chatCategory]);

    if(isLoading)return <Spinner/>

    const handleSubmit = async(e) => {
        e.preventDefault();
        const travelchatTheme = (await simpleRequest({url:"travelchatthemes", method:'post', data:{...variables}})).data?.travelchatTheme;
        console.log({travelchatTheme});
        backFunc();
    }

    console.log({variables, fieldSettings})
    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors}
    return(
        <StyledDivWithBorder className= "p-3">
            <h3>{translate("Add Theme")}</h3>
            <Form onSubmit={handleSubmit}>
                <SelectField props = {{...props, name:"category"}}/>
                <TextField props = {{...props, name:"title"}}/>
                <TextField props = {{...props, name:"subTitle"}}/>
                <Form.Row>
                    <Col>
                        <button type="submit" className="btn btn-info col-12">{translate("Submit")}</button>
                    </Col>
                    <Col>
                        <button className="btn btn-outline-secondary col-12" onClick={backFunc}>{translate("Cancel")}</button>
                    </Col>
                </Form.Row>
            </Form>

        </StyledDivWithBorder>
    )
};
export default ChatThemeNew;
