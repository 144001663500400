class FormSpecialValidation{
       
    static async userEditByAdmin(constants, variables, data){
        // console.log('inside FormSpecialValidation')
        let userStatus = data.userStatus;
        if (userStatus[0] === "|"){
            if(!data.companyHandle || data.companyHandle==="invalid"){
                constants.events.setServerError("Select company.")
                return null;
            }
            userStatus = `${data.companyHandle.trim()}${userStatus.trim()}`
            return {...data, userStatus}
        }else return {...data};
    }
};
export default FormSpecialValidation;