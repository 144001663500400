import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getDateObject } from "../../../helpers/Date";
import { Translate, translate } from '../../Language/Translate';
import FollowItem from "./FollowItem";

const ProposalDetails = ({ proposals, proposalIdx, setProposalIdx, setView }) => {
    // console.log({proposals});
    // const [view, setView] = useState("details"); // edit
    const currentUser = useSelector(st => st.currentUser);
    const proposal = proposals.find(p => proposalIdx === p.id);
    const created = getDateObject(proposal.createdAt, Translate.language).short;
    const follows = proposal.follows
        .filter(f => (f.comment || f.createdBy === currentUser.username))
        .reverse();
    // follows.sort((a,b) => b.updatedAt.localeCompare(a.updatedAt)).reverse()
    return (
        <Card className="my-1" style={{ borderRadius: "5px", backgroundColor: "rgba(255,255,255,.04)" }}>
            <Card.Header >
                <h3 onClick={() => setProposalIdx(-1)}>{proposal.name}</h3>
                <p>{proposal.note}</p>
                <p>{translate("posted by")} {proposal.nickname}, {created}</p>
            </Card.Header>
            <Card.Body>
                <h5>{translate("Location")}</h5>
                <p>{proposal.place}</p>
                <h5>{translate("Description")}</h5>
                <p>{proposal.description}</p>
                <Row>
                    <Col>
                        <button className="btn btn-outline-dark" onClick={() => setView("edit")}>{translate("Edit")}</button>
                    </Col>
                    <Col>
                        <button className="btn btn-outline-danger" onClick={() => console.log("delete")}>{translate("Delete")}</button>
                    </Col>
                    <Col>
                        <button className="btn btn-outline-secondary" onClick={() => setProposalIdx(-1)}>{translate("Close")}</button>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <h5>{translate("Comments")}:</h5>
                {!proposal.isFollower && <p>{translate("To add a comment, you must give it a like")}.</p>}
                {follows.map(f => <FollowItem key={f.id} f={f} currentUser={currentUser} />)}

            </Card.Footer>
        </Card>
    )
};
export default ProposalDetails;