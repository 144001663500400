import React, {useEffect, useState} from "react";
import { StyledDivWithBorder } from "../styledComponents/divs";
import { translate } from "../Language/Translate";
import TourEdit from "../../newForms2/tours/TourEdit";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { useSelector } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";


const TourReportDetailsSubmit = ({props}) => {
    console.log({props});
    const currentUser = useSelector(st => st.currentUser);
    const currentCruise = useSelector(st => st.currentCruise);
    const {tour, tourReport, tourEscorts} = props;
    const [options, setOptions] = useState({toEscort: true, toOffice: false})
    // const [timing, setTiming] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);
    

    // useEffect(() => {
    //     const fetch = async () => {
    //         const _timing = (await simpleRequest({url:`/tourreport/${tourReport.id}`})).data?.tourTiming || [];
    //         setTiming([..._timing]);
    //         setIsLoading(false)

    //     }
    //     if(isLoading)fetch()
    // })
    const handleOption = e => {
        console.log(e.target, options)
        setOptions({...options, [e.target.name]: e.target.checked});
    }


    const handleClick = async (e) => {
        e.preventDefault();
        // console.log(e.target.id, options)
    //   console.log({options}, '###################################################')
        
        const request = {url:`worddocuments/tourreport/ready/${tourReport.id}`,method:"post"}
        const request1 = {url:`worddocuments/tourreport/ready/${tourReport.id}/toTourEscort`,method:"post"}
        const request2 = {url:`worddocuments/tourreport/ready/${tourReport.id}/toOffice`,method:"post"}
        
        console.log({tourReport, target:e.target, options, request, request1, request2});
        const response = (await simpleRequest(request)).data;
        let response2 = {}
        if(options.toEscort)response2.toEscort = (await simpleRequest(request1)).data;
        if(options.toOffice)response2.toOffice = (await simpleRequest(request2)).data;
        console.log({response, response2});
    };


    console.log({currentUser, currentCruise, tour, tourReport, tourEscorts})
    


    return(
        <div>
            <h3>{translate("Submit Tour Report")}</h3>
            <Form>
            <Row>
                <Col>
                <Form.Group size="lg" controlId={"toTourEscort"}>
        {/* <Form.Label>{field.label}{field.required? ' * ':' '}</Form.Label> */}
        {/* { errors[name]?<small className="text-danger" > - {errors[name]}</small>:null} */}
        <Form.Check type="checkbox" 
                    label="Send to Tour Escort"
                    key={"toTourEscort"}
                    checked={options.toEscort}
                    name={"toEscort"}
                    onChange={(e) => handleOption(e)}
        />
            
        </Form.Group>
                </Col>
                <Col>
                <Form.Group size="lg" controlId={"toTourEscort"}>
        <Form.Check type="checkbox" 
                    label="Send to Office"
                    key={"toOffice"}
                    checked={options.toOffice}
                    name={"toOffice"}
                    onChange={(e) => handleOption(e)}
        />
            
        </Form.Group>
                </Col>
                <Col>
                    <button id="checkbox" className="btn btn-outline-dark col-12" onClick = {e => handleClick(e)} >{translate("Submit")}</button>
                </Col>
                <Col>
                    <button id="own" className="btn btn-outline-dark" onClick = {e => handleClick(e)} >{translate("Send to your Response Email")}</button>
                </Col>
                <Col>
                    <button id="foreign" className="btn btn-outline-dark" onClick = {e => console.log("muss bearbeitet werden:", e.target)} >{translate("Send to Office Email")}</button>
                </Col>
            </Row>
            </Form>

        </div>
    )
}
export default TourReportDetailsSubmit;