import React, { useState } from "react";
import { Form} from 'react-bootstrap';
import { simpleRequest } from "../../../newForms2/simpleRequest";

const ListOfDestinationsItem = ({destination, destinations, albumDestinations, days, setAlbumDestinations, albums}) => {
    const [addNew, setAddNew] = useState(false);
    const destDays = days.filter(dy => dy.destinationId === destination.id);
    const destAlbums = albumDestinations.filter(ad => ad.destinationId === destination.id);
    const newAlbums = [{id:0, titleDE:"Please Select"}, ...albums];
    // console.log(destAlbums);
    
    const handleChange = async(e) => {
        if(!+e.target.value)return;
        await simpleRequest({url:"albumdestination", method:'post', data:{albumId: +e.target.value, destinationId:destination.id}});
        setAlbumDestinations((await simpleRequest({url:"albumdestination"})).data.albumDestinations);
        setAddNew(false);
    }

    const handleDelete = async(e) => {
        const id = +e.target.parentElement.parentElement.id;
        await simpleRequest({url:`albumdestination/${id}`, method:'delete'});
        setAlbumDestinations([...albumDestinations.filter(ad => ad.id !== id)]);
    }

    const DestAlbums = () => {

        return(
            <>
                {destAlbums.map(da => 
                <div className = "ml-3" key = {da.id} id={da.id}>
                    <p >
                        {da.titleDE}
                        <span 
                            className="text-danger ml-3 px-2" 
                            style={{border:"1px solid black"}}
                            onClick = {handleDelete} >
                            X
                        </span>
                    </p>
                </div>
                )}
            </>
        )
    }

    const AddAlbum = () => {
        const options = newAlbums.map( a => {return{key: a.id, value:a.titleDE}})
  
        return(
                <Form>
                <Form.Group size="lg">
                    <Form.Control as="select"
                        name={destination.deDE} 
                        type="select" 
                        key={destination.id} 
                        value={0}
                        onChange={e => handleChange(e)} 
                    >
                    {options.map(opt => (
                                <option value= {opt.key} key={`${opt.key}_${opt.value}`}>{opt.value}</option>
                            ))}
                    </Form.Control>
                </Form.Group>
            </Form>
            )
    }



    return(
        <div className="mt-3" id = {destination.id}>
            <h4>
                {destination.deDE}, <small>({destDays.length} days)</small>
                <button className = "btn btn-outline-dark ml-2" onClick={() => setAddNew(!addNew)}>Add Album</button>
                
            </h4>
            <DestAlbums/>
            {addNew && <AddAlbum/>}
        </div>
    )
};
export default ListOfDestinationsItem;