import React, {useState, useEffect} from "react";
import { Translate, translate } from "../../../components/Language/Translate";
import { Col, Form } from "react-bootstrap";
import Spinner from "../../../components/Spinner";
import TextField from "./Fields/TextField";
import TextAreaField from "./Fields/TextAreaField";
import { useSelector } from "react-redux";
import DestinationList from "./Fields/DestinationList";
import { simpleRequest } from "../../simpleRequest";
import ProposalList from "./ProposalList";

const FormEdit = ({props}) => {

    const {variables, isLoading, setVariables, action, setDays, backFunc} = props;
    // const [variables, setVariables] = useState({});
    // const [isLoading, setIsLoading] = useState(true);
    const [destination, setDestination] = useState(null);
    const destinations = useSelector(st => st.destinations);

    // useEffect(() => {
    //     const sendFields = ["arrival", "departure", "destinationId", "destination", "description"];
    //     const vars = {};
    //     sendFields.forEach(sf => vars[sf] = action[sf]);
    //     setVariables({...vars});
    //     setIsLoading(false);
    // }, [setVariables, action, setIsLoading]);

    useEffect(() => setDestination(destinations.find(d => d.id === variables.destinationId)|| null)
                , [variables.destinationId, destinations]);

    if(isLoading)return <Spinner/>

    // const handleChange = e => console.log(e.target);
    const handleChange = e => {
        console.log(e.target)
        setVariables(v => {return{...v, [e.target.name]: e.target.value}});
        
    };
    const handleSubmit = async(e) => {
        e.preventDefault();
        const data = {...variables};
        if(data.destinationId)data.destination = "";
        const newAction = (await simpleRequest({url:`days/${action.id}`, method:"patch", data})).data?.day;

        console.log('submit', {variables, newAction, data});
        setDays(days => [...days.filter(d => d.id !== action.id), newAction]
                            .sort((a,b) => `${a.arrival || "00:00"}` > `${b.arrival || "00:00"}` ? 1: -1));
        backFunc();
    };
    const selectDestination = e => setVariables({...variables, destinationId: +e.target.id});
    
    console.log({props});

    return(
        <div className="text-center">
            <h3>{translate("Edit Entry")}</h3>
            <ProposalList props = {props}/>
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col>
                        <TextField name="arrival" variables={variables} label="Arrival" handleChange={handleChange}/>
                    </Col>
                    <Col>
                        <TextField name="departure" variables={variables} label="Departure" handleChange={handleChange}/>
                    </Col>
                   
                    <Col>
                        {variables.destinationId ? 
                        <h6>
                            <button className="btn btn-outline-danger mr-3" 
                                onClick={() => setVariables({...variables, destinationId:0})}>X</button>
                            <span>{destination && (destination[Translate.language] || destination.enUK)}</span>
                        </h6> :
                        !variables.destinationId && <>
                            <TextField name="destination" variables={variables} label="Destination" handleChange={handleChange}/>
                            <DestinationList destination={variables.destination} selectDestination={selectDestination}/>
                        </>}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <TextAreaField name="description" variables={variables} label="Description" handleChange={handleChange}/>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <button className="btn btn-outline-dark" type = "submit">{translate("Submit")}</button>
                    </Col>
                    <Col>
                        <button className="btn btn-outline-secondary" onClick={backFunc}>{translate("Cancel")}</button>
                    </Col>
                </Form.Row>

                
                
            </Form>
        </div>
    )
};
export default FormEdit;