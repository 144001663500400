import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { formatDate } from '../../helpers/Date';
import { useHistory } from 'react-router';

const SelectFieldCruise = ({props}) => {
    const {mainVariables, setMainVariables} = props;
    const currentVessel = useSelector(st => st.currentVessel);
    const currentUser = useSelector(st => st.currentUser);
    const history = useHistory();
  
    const handleSelect = e => {
        if (e.target.className.includes("noValue"))return;
        const value = parseInt(e.target.id);
        
        const isJoined = currentUser.cruises && currentUser.cruises.some(uc => uc.cruiseId === value);
        // console.log(currentUser.cruises, value, isJoined);
        (() => setMainVariables({...mainVariables, cruiseId:value}))();
        history.push(`/cruises/${value}`)
    };

    useEffect(() => {
        // console.log('Add EVENTLISTENER')
        const cruiseList = document.getElementById('cruiseList');
        cruiseList && cruiseList.addEventListener('click', handleSelect);
        return () => {
            // console.log('CLEANUP');
            cruiseList && cruiseList.removeEventListener('click', handleSelect);
        };
    });

    const yearSet = new Set();
    currentVessel.cruises.forEach(c => yearSet.add(c.startAt.slice(0,4)));
    // console.log({yearSet});

    const list = Array.from(yearSet).sort().map(y => {
        return (
            <div key={y} className="m-1 mt-4 noValue" >
                <h6 className="noValue">{y}</h6>
                {currentVessel.cruises
                    .filter(c => c.startAt.includes(y))
                    .map(c => {
                        return( 
                            <div key={c.id} id={c.id} className="btn btn-outline-dark col-12 my-1">
                                {c.title}, {formatDate(c.startAt)} ({c.startPort}) - {formatDate(c.endAt)} ({c.endPort})
                            </div>
                        )
                    })
                }
            </div>
        );
    });
    
    // console.log({currentVessel});
    return(
        <div id="cruiseList">
            {list}
        </div>
    );
};
export default SelectFieldCruise;