import React, {useState} from "react";
import {Form} from "react-bootstrap";
import ValueArea from "./ValueArea";
import { translate } from "../../components/Language/Translate";
import FieldError from "../Fields/FieldError";

const TextFieldWithValues = ({name, props, array}) => {
    // const [input, setInput] = useState('');
    const {variables,setVariables} = props

    const handleChange = e => {
        setVariables({...variables, species: e.target.value})
    }

    return(
        <Form.Group size="lg" controlId={name} >
            <Form.Label  className ="pt-1 px-1" >
                {translate("Species")}{` * `}<FieldError name="speciesId" props={props}></FieldError>
            </Form.Label>
            <Form.Control 
                name={name} 
                type="text" 
                key={name} 
                value={variables.species || ""}
                placeholder={translate('Write some chars and then select from list, or press enter, if the list is empty.')}
                onChange={e => handleChange(e)} 
            />
            {variables.species?.length > 1 && <ValueArea str={variables.species} arr={array} setVariables={setVariables} variables={variables}/>}
        </Form.Group>
    )
}
export default TextFieldWithValues;
