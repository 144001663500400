import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";
import { useDispatch, useSelector } from "react-redux";
import { simpleRequest } from "../../simpleRequest";
import { fetchCruiseFromApi } from "../../../actions/currentCruise";
import { getDateObject } from "../../../helpers/Date";
// import { parse } from "path";

const RoutingFormNew3 = ({setProposals}) => {
    const [value, setValue] = useState('');
    const [dayArray, setDayArray] = useState([]);
    const [dayObj, setDayObj] = useState({});
    const [statusText, setStatusText] = useState(null);

    // const year = 2024;
    const currentCruise = useSelector(st => st.currentCruise);
    const destinations = useSelector(st => st.destinations);
    const dispatch = useDispatch()
    const companyHandle = currentCruise.companyHandle;

    useEffect(() => {
        if(!value)return;
        try{
            
            let currentDay = {tours:[]};
            let destination = "";
            let _dayObj = {};
            let _dayArray = [];
            let mmdd = "";
            
            const v2 = value.split('\n')
            const i1 = v2.findIndex(i => i.includes('Reise'));
            const shorthand = v2[i1]?.split('\t')[1];
            const v3 = v2.splice(1);
            // const dayArrayAfterRequest = [];
            v3.forEach((v, idx) => {
                // let dayTours = []
                // jede Zeile
                const cells = v.split('\t');
                // console.log({cells}, cells.length);

                if(cells[0]){   // DIES IST EINE ZEILE MIT HAFEN

                    const txt = cells[0].split(',')[1]?.trim();
                    if(!!txt && txt.length){
                        // console.log({txt})
                        const [day, month] = txt.split('.');
                        mmdd = `${`00${month}`.slice(-2)}-${`00${day}`.slice(-2)}`
                        const id = currentCruise.days.find(d => d.date?.includes(mmdd))?.id || 1;
                        const cruiseId = currentCruise.id;
                        const [arrival, departure] = cells[1]?.split('-');
                        destination = cells[2];
                        const destinationId = destinations.find(d => `${d.deDE} - ${d.enUK}`.includes(destination))?.id;
                        const newDay = {id, arrival, departure, cruiseId, destination, destinationId, setByAdmin: true, tours:[], idx}
                        currentDay = {...newDay, tours:[]}
                        _dayArray.push(newDay)
                        _dayObj = ({..._dayObj, [mmdd]: newDay});
                        };
                }
                if(cells.length === 11){
                    // console.log('57')
                    const short = cells[3];
                    const title = cells[4];
                    // console.log('57')
                    const description = `${cells[5]} - ${cells[6]}`
                    const [currency, prizeStr] = cells[7]?.split(' ');
                    const prize = prizeStr && (parseFloat(prizeStr)*100 || 0);
                    // console.log({short, title, description, currency, prizeStr, prize});
                    const dayTours = [...currentDay?.tours, {short, title, description, currency, prize}];
                    currentDay = {...currentDay, tours:dayTours}
                    // console.log({currentDay})
                    if(mmdd)_dayObj = {..._dayObj, [mmdd]: currentDay}
                    // console.log("hier")
                }

            });
           
            const result = ({
                v2, 
                i1, v3,
                shorthand,
                _dayArray, _dayObj
            });
            setDayObj(_dayObj);
            setDayArray([...Object.values(_dayObj)]);
            console.log({result});
        }catch(err){
            console.error('..........................................', {"ERROR": err})
        }
        }, [value, setProposals, currentCruise, destinations]);

    const handleChange = e => {setValue(e.target.value)};

    const sendTours = async(dayId, tours) => {
        for (let i = 0; i < tours.length; i++){
            const tour = {
                starttime:"",
                endtime:"",
                difficulty:"",
                numbers:"", 
                dayId,
                ...tours[i]
            }
            const requestTour = {url: "tours", method:"post", data:{...tour}};
            console.log({requestTour});
            const newTour = (await simpleRequest(requestTour)).data?.tour;

        }
        return "done";
    }


    const handleSubmitDays = async() => {
        setStatusText('Submitting Data...');
        const cruiseDays = currentCruise.days;
        for(let i = 0; i < cruiseDays.length; i++){
            // console.log(cruiseDays[i], {dayArray})            
            const match = dayArray.find(item => item.id === cruiseDays[i].id) || {destinationId: 1, setByAdmin:true};
            const tours = (match.tours && [...match.tours]) || [];
            ["id", "idx", "tours"].forEach(i => {if(match)delete match[i]})
            const request = {url: `days/${cruiseDays[i].id}`, method:"patch", data: {...match}};
            const newDay = (await simpleRequest(request)).data?.day;
            const {id} = newDay;
            await sendTours(id, tours)
            };
        dispatch(fetchCruiseFromApi(currentCruise.id));
        setStatusText('Submitting done.');
        setInterval(() => setStatusText(null), 3000);
    }


    console.log({dayArray, dayObj}, currentCruise.days[0], destinations[0]);
    return (
        <>
        <Form>
        <Form.Group size="lg" controlId={"routing"} >
            <Form.Label  className ="pt-1 px-1" >Text</Form.Label>
            <Form.Control as="textarea" rows={5}
                name={"routing"} 
                type="textarea" 
                fieldtype="textarea" 
                key={"description"} 
                autoFocus={true}
                value={value}
                onChange={e => handleChange(e)}
                placeholder={translate("Please insert Fidelio Routing")}
            />
        </Form.Group>
        <Button onClick={handleSubmitDays}>{translate("Submit Days")}</Button>
        {statusText && <h4>{statusText}</h4>}
        {/* <Button className = "ml-2" onClick={handleSubmitDaysTest}>{translate("Submit Days Test")}</Button> */}
    </Form>
    <ul>
        {Object.entries(dayObj).map (([key, data]) => 
            <li>
                {`${key.slice(3,5)}.${key.slice(0,2)} - `}
                {data.destination}
                <ul>
                    {data.tours?.map(t => 
                        <li>{`${t.short} - ${t.title}`}</li>
                    )}
                </ul>

            </li>)}
    </ul>
    </>
    )
};
export default RoutingFormNew3;