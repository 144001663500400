import React from 'react'
// import Form from "react-bootstrap/Form";
// import {handleChange, handleSubmit, validateField, validateForm} from '../ReactFormFunctions';
// import {Link} from "react-router-dom";


const PreviewField = ({value}) => {

    const newArr = value.split(',').map(v => v.trim().replace('#','').replace('_', " "))
    // console.log(newArr)
    return(
        <>
            <div className="white">
                {newArr.map((v,idx)=>{
                    return(<p className="ml-3" key={idx}>{v}</p>)
                })}
            </div>
            
                
            
        </>
    )
}
export default PreviewField;

