import React, {useState} from "react";
import { Row, Col } from "react-bootstrap";

const Album = ({album, albumPhotos, actualPhoto, setActualPhoto}) => {
    const [albumView, setAlbumView] = useState('list') // single
    const [index, setIndex] = useState(0)
    const images = albumPhotos?.filter(ap => album.id === ap.albumId) || [];
    // console.log({actualPhoto, album, albumPhotos, setActualPhoto,albumView, index});

    const handleClickList = e => {
            (() => setIndex(+e.target.dataset.idx))();
            (() => setActualPhoto(images[+e.target.dataset.idx]))();
            (() => setAlbumView('single'))();
    };

    const List = () => {


        return(
            <div>
                <h6>{album.titleDE}</h6>
                {images.map((i, idx) => {
                              return(<img className="m-1"
                                    onClick={e => handleClickList(e)}
                                    key={i.id} src={i.imageUrl} alt = {i.id} data-idx={idx}
                                    style={{ maxWidth: '20%', minHeight:"10px", maxHeight:"100px" }} />)
                                    })}
            </div>
        )
    };
    const Single = () => {
        // console.log('ALbum/Single');


        const handleNav = (e, int) => {
            // console.log('handleNav,', int, index)
            const i = index + int;
            const newIndex = (i >= 0 && i < images.length) ? i : index;
            setIndex(newIndex)
            setActualPhoto(images[newIndex])
        }

        return(
            <Row className="row">
                <Col sm={1} onClick = {e => handleNav(e, -1)}>{`<`}</Col>
                <Col sm={10}>
                    <img 
                    onClick={() => setAlbumView('list')}
                    key={actualPhoto.id} src={actualPhoto.imageUrl} alt =''
                    // style={{ maxWidth: '100%', minHeight:"200px", maxHeight:"900px" }} />
                    style={{ maxWidth: '100%' }} />
                </Col>
                <Col sm={1} onClick = {e => handleNav(e, 1)}>{`>`}</Col>
            </Row>

        )
    }

    

    return(
        <div>
            {albumView === "list" && <List/>}
            {albumView === "single" && <Single/>}
        </div>
    )
};
export default Album;