import Form from "react-bootstrap/Form";
import {handleChange, validateField} from '../ReactFormFunctions';

const TextAreaField = (props) => {
    
         // console.log('inside textfield, ', {props});
         const {name, variables, constants} = props;
         const {errors} = variables;
         const {fields} = constants;
         const field = fields[name]
         const value = variables.fields[name] || "";
         // console.log({name, value})
     
         return (
         
              <Form.Group size="lg" controlId={name}>
                <Form.Label>{field.label}{field.required? ' * ':' '}</Form.Label>
                   { errors[name]?<small className="text-danger" > - {errors[name]}</small>:null}
                   <Form.Control as="textarea" rows={5}
                     //  componentClass="textarea" style={{ height: 200 }}
                      type={field.type || "textarea"}
                      key={name}
                      required={field.required}
                      autoFocus={field.autofocus}
                      disabled={field.disabled}
                      value={value || ""}
                      placeholder={field.placeholder?`${field.placeholder}`:''}
                      name={name}
                      onChange={(e) => handleChange(e, constants, variables)}
                      onBlur={(e) => validateField(e, constants, variables)}
                         //              onTouchEnd={(e) => // console.log('onTouchEnd')}
                />
                
                {field.toolTip?<p><small className="text-black" >{field.toolTip}</small></p>:null}
             </Form.Group>
         )
}

export default TextAreaField;

