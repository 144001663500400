import React, { useState } from "react";
import ConfirmationArea from "../../ConfirmationArea";
import { useSelector, useDispatch } from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import {Form} from 'react-bootstrap'
import { addCommentOnApi, deleteFollowOnApi } from "../../../actions/currentDestination";
import { getDateObject } from "../../../helpers/Date";
import {translate, Translate} from '../../Language/Translate';
import FollowItem from "./FollowItem";

const AddComment = ({follow, setCommentArea}) => {
    // console.log({follow})
    const [value, setValue] = useState(follow.comment);
    const dispatch = useDispatch();
    const handleChange = (e) => (() => setValue(e.target.value))();
    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(addCommentOnApi(follow.id, {comment:value}))
    };
    return (
        <div>
            <h4 className = "my-1">Add Comment</h4>
            <Form onSubmit={(e)=>handleSubmit(e)}>
                <Form.Group size="lg" controlId="comment">
                       <Form.Control as="textarea" rows={5}
                          type="textarea"
                          key="comment"
                          autoFocus={true}
                          value={value || ""}
                          name="comment"
                          onChange={(e) => handleChange(e)}
                        />
                </Form.Group>
                <button className="btn btn-outline-dark" type="submit">{translate("Add/Update Comment")}</button>
                <button className="btn btn-outline-secondary ml-3" onClick={()=>setCommentArea(false)}>{translate("Cancel")}</button>
            </Form>
        </div>
    );
}
export default AddComment;