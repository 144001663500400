import React, { useEffect, useState } from "react";
import { StyledDivWithBorder } from "../../styledComponents/divs";
import { translate } from "../../Language/Translate";
import TourTimingNew from '../../../newForms3/tourTiming/TourTimingNew'
import { simpleRequest } from "../../../newForms2/simpleRequest";
import Spinner from "../../Spinner";
import TimingList from "./TimingList";


const TimingMain = ({tourReport}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showFormNew, setShowFormNew] = useState(false);
    const [timing, setTiming] = useState([]);
    // const [startTime, setStartTime] = useState();

    useEffect(() => {
        const fetch = async() => {
            const request = {url: `tourtiming/tourreport/${tourReport.id}`};
            const _timing = (await simpleRequest(request)).data?.tourTiming || [];
            console.log({_timing, request})
            setTiming([..._timing]);
            setIsLoading(false);
        }
        if(isLoading)fetch();
    }, [isLoading, tourReport]);
    if(isLoading)return <Spinner />

    return(
        <StyledDivWithBorder>
            <h5>Timing</h5>
            {!showFormNew && <TimingList setTiming = {setTiming} timing = {timing}/>}
            {!showFormNew && <button className="btn btn-outline-dark" onClick = {() => setShowFormNew(true)}>{translate("Add item")}</button>}
            {showFormNew && <TourTimingNew timing={timing} setTiming= {setTiming} tourReport = {tourReport} backFunc={() => setShowFormNew(false)}/>}
        </StyledDivWithBorder>

    )
};
export default TimingMain;