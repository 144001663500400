import React from "react";
// import React,{useState, useEffect} from "react";
import {useSelector} from 'react-redux';
// import {actualDateString} from '../helpers/Date'
// import CruiseListItems from './CruiseListItems';
import { sortArrayByObjectField } from "../helpers/sort";
import CruiseListItem from "./CruiseListItem";
import { translate } from "./Language/Translate";

const UserFavourites = () =>{
    const currentUser = useSelector(st => st.currentUser);
    // console.log({currentUser});
    const favourites = sortArrayByObjectField(currentUser.favourites, "startAt");
    favourites.forEach(f => {
        f.isJoined = currentUser.cruises.findIndex(c => c.id === f.cruiseId) > -1;
    });

    return(
        <div>
            <h5>{translate("Your Favourites")}</h5>
            {favourites.map(f => <CruiseListItem cruise={f} key={f.id}/>)}
        </div>
    )
};

export default UserFavourites;