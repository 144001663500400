import React from "react";
// import {translate} from '../Language/Translate';
import { agbText } from "./agbText";

const AGBs = () => {
   


    return(
        <div style={{backgroundColor:"#adc8d1", color:"#52372e"}} className="p-3">
            <h4>AGBs</h4>
            {agbText.map((t, idx) => {
                return (t === ""  ? <br key={idx}/> : <p key={idx}>{t}</p>)
            })}
        </div>
    )
    
    
};
export default AGBs;
    
    
    
    
    
