import React, { useEffect, useState } from "react";
import { Translate, translate } from "../Language/Translate";
import chatCategories from "./chatCategories"
import { BlackWhite } from "../styledComponents/colors";
import ChatGroupTheme from "./ChatCategory";
import ChatCategory from "./ChatCategory";

const ChatMain = () => {
    const [selected, setSelected] = useState("garden");

    const categories = Object.values(chatCategories).sort((a, b) => 
        a.label[Translate.language || "enUK"] > b.label[Translate.language || "enUK"] ? 1 : -1);
    // console.log(categories)

    const handleClick = category => setSelected(category);

    const props = {chatCategory: chatCategories[selected]}
    return(
        <div>
            <h2>{translate("Travel Chats")}</h2>
            <p>{
                    categories.map((cG,idx) => {
                        const cat = cG.label[Translate.language || "enUK"]
                        return (
                            <span key = {cG.category}  id = {cG.category} onClick={() => handleClick(cG.category)}>
                                <BlackWhite >
                                    {selected === cG.category ? <b>{cat}</b> : <span>{cat}</span>}
                                </BlackWhite>
                                {idx < categories.length-1 ? ` - `: ""}
                            </span>
                        )
                    })
                }
            </p>
            <ChatCategory props = {props}/>
        </div>
    )
};
export default ChatMain;