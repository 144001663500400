import React, {useEffect, useState} from "react";
import { translate } from "../../components/Language/Translate";
import SelectFieldCruise from "./SelectFieldCruise";
import {useSelector, useDispatch} from "react-redux";
import { fetchVesselFromAPI } from "../../actions/currentVessel";
import Spinner from '../../components/Spinner';

const SelectCruise = ({props}) => {

    const [isLoading, setIsLoading] = useState(true);
    const {mainVariables, setStage} = props;
    const currentVessel = useSelector(st => st.currentVessel);
    const currentUser = useSelector(st => st.currentUser);
    const loggedIn = currentUser && currentUser.username ? true :false;
    
    

    // const currentUser = useSelector(st => st.currentUser);
    const dispatch = useDispatch();
    
    useEffect(() => {
        const fetch = async() => {
            dispatch(fetchVesselFromAPI(mainVariables.vesselId));
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, dispatch, mainVariables]);
    
    if(isLoading || !(currentVessel && currentVessel.id) )return <Spinner />
    // console.log({mainVariables, currentVessel});
    const hasList = currentVessel.cruises && currentVessel.cruises.length > 0

    const text = hasList ? translate("Please select a cruise or click the button at the bottom to enter a new cruise") 
            : translate("No voyages have yet been entered for this ship");
    let btnText = hasList ? translate("The cruise is not in the list. I want to create a new one.") : translate("I would like to register a cruise for this ship.")
    btnText = loggedIn ? btnText : `${btnText} (${translate('Only for logged in users.')})`
    // console.log({hasList, text, btnText});

    return(
        <div>
            <h4>{currentVessel.prefix} {currentVessel.name}: {translate("Cruises")}</h4>
            <h5 className="mt-5">{text}.</h5>
            <SelectFieldCruise props={props}/>
            <hr/>
            <div className="row">
                <div className = "col-md-6 p-3">
                    <button className="btn btn-outline-dark col-12" disabled={!loggedIn} onClick={() => setStage("addNewCruise")}>{btnText}</button>
                </div>
                <div className = "col-md-6 p-3">
                    <button className="btn btn-outline-dark col-12" onClick={() => setStage("selectVessel")}>{translate("Back")}</button>
                </div>
            </div>
        </div>
    )
}
export default SelectCruise;