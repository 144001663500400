import {
    SET_GLOBAL_VAR
  } from "../actions/types";

 
  export default function rootReducer(state = {}, action) {
    // console.count('reducer_globals', action.varObj, 'ä#####################################################################');
    // console.log('Hier ist der State: ', state, action, action.varObj);
  
    switch (action.type) {
      case SET_GLOBAL_VAR:
        return {...state, ...action.varObj}
      default:
        return state;
    };
  };