import React from "react";
import { translate } from "../../components/Language/Translate";
import SelectFieldVessel from "./SelectFieldVessel";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";



const SelectVessel = ({props}) => {
    const {setStage} = props;
    
    const currentUser = useSelector(st => st.currentUser);
    const loggedIn = currentUser && currentUser.username ? true :false;
    let buttonText = translate("The ship is not on the list. I want to create a new one.");
    buttonText = loggedIn ? buttonText : `${buttonText} (${translate('Only for logged in users.')})`

    return(
        <div style={{borderRadius:"5px"}}>
            <h4 className="my-3">{translate("Please select the ship for your cruise.")}</h4>
            {loggedIn && <h5 className = "my-3">{translate("Please select a ship or click the button below to create a new vessel.")}</h5>}
            <SelectFieldVessel props={props}/>
            <hr/>
            <div className="row">
                 <div className = "col-md-6 p-3">
                    <button className = "btn btn-outline-dark col-12" disabled={!loggedIn} onClick={() => setStage("addNewVessel")}>
                        {buttonText}
                    </button>

                </div>
                
                <div className = "col-md-6 p-3">
                    <Link to={"/home"} className="btn btn-outline-secondary col-12">{translate("Back")}</Link>
                </div>
            </div>
            

            

        </div>
    )
};
export default SelectVessel;

