import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
import {translate} from '../../Language/Translate';
import { BlackWhite } from "../../styledComponents/colors";
import ListOfMain from "./ListOfMain";

const ListOfAlbumsCard = ({eventKey}) => {

    return(  
        <Card key={eventKey}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
            <BlackWhite>{translate('Albums and Destinations')}</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>
                <ListOfMain/>
            </Card.Body>
        </Accordion.Collapse>
        </Card>
    )
};
export default ListOfAlbumsCard;