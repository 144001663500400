import React from "react";
// import {Accordion, Card, Button} from 'react-bootstrap';
// import { BlackWhite } from "../styledComponents/colors";
// import { translate } from "../Language/Translate";
import { useSelector } from "react-redux";
import MapMain from "../Leaflet/MapMain";




const DestinationMap = () => {
    const currentDestination = useSelector(st => st.currentDestination);
    const {geolocation, deDE, enUK, id} = currentDestination;
    const day = {
        geolocation,
        deDE,
        enUK, 
        destinationId : id
    }
    // console.log({day});

    return  <MapMain daysInMap={[day]} zoom={11}/>
};
export default DestinationMap;