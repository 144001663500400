import { simpleRequest } from "../../newForms2/simpleRequest";
import {translate} from "../../components/Language/Translate"

class CruiseFormData {

    static async cruiseNew(data) {
        // console.log('inside CruiseFormData.cruiseNew', data, data.params, data.params.vessel_id)
        const vesselId = +data.params.vessel_id;
        const consts = new CruiseFormSchema();
        delete consts.fields.id;
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        const options = await CruiseFunctions._getSelectListVessels(vesselId);
        consts.formType = 'cruisesNew';
        consts.formLabels = {
            title:translate("Add Cruise"),
            btnLabel:translate("Add Cruise")
        }
        consts.request = {
            url:"cruises",
            method:"post"
        };
        consts.fields.vesselId.settings.options=options;
        fields.vesselId = +vesselId;
        consts.nextPage = (`/vessels/${vesselId}`);
        consts.sendData = ["vesselId", "shorthand", "title", "suffix", "description", "startAt", 
                            "endAt", "startPort", "endPort", "announcement", "cancelled"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
    
    static async cruiseEdit(data){

        
        const cruiseId=+data.params.cruise_id
        // console.log('inside CruiseFormData. cruiseEdit', {data, cruiseId})
        const response = await CruiseFunctions._getCruise(cruiseId);
        // console.log({response});
        const cruise = response && response.cruise;
        
        // console.log({cruise}, cruise.vesselId)
        const consts = new CruiseFormSchema();
        // let fields;
        // Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        // fields.vesselId = cruise.vesselId;
        consts.fields.vesselId.settings.options=await CruiseFunctions._getSelectListVessels(cruise.vesselId);
        consts.fields.vesselId.fieldType="info";
        delete consts.fields.vesselId;
        consts.fields.id.disabled="info"
        consts.formType = 'cruiseEdit';
        consts.formLabels = {
            title:translate("Edit Cruise"),
            btnLabel:"Update Cruise"
        }
        consts.request = {
            url:`cruises/${cruise.id}`,
            method:"patch"
        };        
        consts.nextPage = (`/cruises/${cruiseId}`);
        consts.sendData = ["shorthand", "title", "suffix", "description", "startAt", 
                            "endAt", "startPort", "endPort", "announcement", "cancelled"]
        consts.oldValues=cruise;
        const vars={fields:{}};
        consts.sendData.forEach(sd => vars.fields[sd] = cruise[sd]);
        // vars.fields.id = cruiseId;
        // console.log({consts,vars});
        
        
        return {consts, vars};
        };
        
        };
       




class CruiseFormSchema{
    constructor(){
        this.formType="CruiseNew";
        this.nextPage=""
        this.request = {
            url:"cruises",
            method:"post",
        };
        this.formLabels = {title: "Add Cruise", btnLabel:"Add Cruise"};


        this.fields = {

            id: {
                label:'Cruise ID',
                fieldType:"info"
            },
            
            vesselId : {
                label : "Vessel ID",
                validation : {min: 0},
                fieldType: "select",
                type : "text",
                required : true,
                toNumber:true, 
                value:0,
                settings:{}
            },
            
            shorthand : {
                label:translate("Shorthand"),
                value:"",
                validation : {maxLength:15},
                fieldType : "text",
                type : "text"
            },
            
            title : {
                label:translate("Title"),
                value : "",
                validation : {minLength: 10},
                fieldType : "text",
                type:'text',
                required : true
            },
        
            suffix : {
                label:"Suffix",
                value : "",
                validation : {},
                fieldType : 'text',
                type:"text"
            },
            
            description : {
                label : translate("Description"),
                value : '',
                validation : {},
                fieldType : "textarea",
                type : "textarea"
        
            },
            
            startAt : {
                label : translate("Start at"),
                value : "",
                placeholder : "Format: 2021-03-21",
                validation : {isDate:true, validDateString:true},
                fieldType : "date",
                type : 'date',
                required : true
            },
        
            endAt : {
                label : translate("End at"),
                value : "",
                placeholder : "Format: 2021-03-21",
                validation : {isDate:true, mustBeGreaterThan:"startAt", validDateString:true},
                fieldType : "date",
                type : 'date',
                required : true
            },
        
            startPort : {
                label : translate("Start Port"),
                value : "",
                validation : {minLength: 3},
                required : true,
                fieldType : "text",
                type : "text"
            },
        
            endPort : {
                label : translate("End Port"),
                value : "",
                validation : {minLength: 3},
                required : true,
                fieldType : "text",
                type : "text",
            },
            
            announcement : {
                label:translate("Announcement"),
                value:"",
                validation:{maxLength:40},
                required:false,
                fieldType:"text",
                type:"text",
                toolTip:"For example: This cruise was cancelled"
            },
            cancelled : {
                label: translate("Cancelled"),
                value:false,
                fieldType:"checkBox",
                type:"boolean"
            }

        }

        }
};

class CruiseFunctions{
    static async _getVessels(){
        const reqObj= {url: `vessels`, method:"get"};
        const response= await simpleRequest(reqObj);
        // console.log(response);
        // console.log(response.data);
        // console.log(response.data.vessels);
        return response.data.vessels;
    };
    static async _getSelectListVessels(vesselId){
        // console.log({vesselId})
        const vessels= await this._getVessels();
        // console.log({vessels});
        let selectList = vessels.map(v => {return{"key":+v.id,"value":`${v.prefix} ${v.name}`}});
        // console.log({selectList});
        if(vesselId) return selectList.filter(id => id.key === +vesselId);
        return selectList;
    };
    static async _getCruise(cruiseId){
        const response = await simpleRequest({url:`cruises/${+cruiseId}`, method:'get'});
        // console.log(response.data)
        return response.data;
    };
}

export default CruiseFormData;