class Auth{
    constructor(userStatus, companyHandle){

        this.userStatus = !!userStatus ? userStatus : false
        this.companyHandle = !!companyHandle ? companyHandle : false;
        this.statusHasHandle = !!companyHandle && userStatus?.includes(companyHandle)
        this.isDeveloper = userStatus === "developer"
        this.isAdmin = this.isDeveloper || userStatus === "admin";
        this.isCoMainAdmin = this.isAdmin || (userStatus?.includes("coMainAdmin") && this.statusHasHandle);
        this.isCoAdmin = this.isCoMainAdmin || (userStatus?.includes("coAdmin") && this.statusHasHandle);
        this.isCoSubAdmin = this.isCoAdmin || (userStatus?.includes("coSubAdmin") && this.statusHasHandle);
        this.isCoStaffAdmin = this.isCoSubAdmin || (userStatus?.includes("coStaffAdmin") && this.statusHasHandle);
        this.isLoggedIn = !!userStatus;
    }
};
export default Auth;