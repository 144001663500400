import React from "react";
// import { Translate } from "../../../Language/Translate";
import { Form } from "react-bootstrap";

const SpeciesGroupListItemItem = ({speciesItem, groupItem, props}) => {
    const {variables, setVariables} = props

    const handleChange = () => {
        const newSpeciesItem = {...speciesItem, checked:!speciesItem.checked};
        const newGroupItem = {...groupItem, checked:false, species: {...groupItem.species, [speciesItem.name]: newSpeciesItem}};
        setVariables({...variables, all:false, speciesGroups:{...variables.speciesGroups, [groupItem.name]: newGroupItem}});
    };


    return(
        <Form.Group>
            <Form.Check type="checkbox"
                label={speciesItem.name}
                checked={speciesItem.checked}
                onChange={handleChange}
            />
        </Form.Group>
    )
};
export default SpeciesGroupListItemItem