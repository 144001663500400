import React, {useState} from "react";
import {Form, Col} from "react-bootstrap";
import TextField from "./TextField";
import { translate } from "../../components/Language/Translate";
import { actualLocation } from "../../helpers/location";

const GeolocationField = ({name, props}) => {
    const [actualPosition, setActualPosition] = useState('');
    const {
        variables,
        setVariables,
        fieldSettings, 
        } = props
        const {visible} = fieldSettings[name];
    if (visible === false) return null;

    const handleActualPosition  = e => {
        e.preventDefault();
        (() => actualLocation(setActualPosition))();
        const txt = actualPosition?.text;
        if(txt)(() => setVariables({...variables, geolocation:txt}))();
    };

    return(
        <>
            <TextField name = {name} props = {props}/>
            <div className="mb-3">
            <Form.Row >
                <Col>
                    <button className = "btn btn-outline-dark col-12" onClick={e => handleActualPosition(e)}>{translate("Get actual position")}</button>
                </Col>
            </Form.Row>
            </div>
        </>
    )
}
export default GeolocationField;
