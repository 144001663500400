import React, { useState, useEffect } from "react";
import "./MainBoard.css" 
import Memory from './Memory';
import {useSelector} from "react-redux";
import {actualDateTimeString} from '../../../helpers/Date';
import CruiseScores from './CruiseScores';
import CommonScores from './CommonScores';
import CompanyScores from "./CompanyScores";
import UserScores from './UserScores';
import { getUserScores } from "./functions";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import Spinner from '../../Spinner';
import { translate } from "../../Language/Translate";

const Main = () => {
    const duration = 14;
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState({seconds:null, counter:null, cruiseId:0});
    const [showApiButton, setShowApiButton] = useState(false);
    const [allScores, setAllScores] = useState([])
    const currentUser = useSelector(st => st.currentUser);
    const userCruises = currentUser && currentUser.cruises;
    const now = actualDateTimeString();
    const currentUserCruises = (userCruises && userCruises.filter(uc => `${uc.startAt} 11:59` <= now && now <= `${uc.endAt} 12:00`)) || [];
    const username = currentUser && currentUser.username;
   
    useEffect(() => {
        const fetch = async () => {
            const response = await simpleRequest({url:'memoryscores', method:"get"});
            const scores = response.data && response.data.memoryScores;
            // console.log({scores});
            if(!scores)return [];
            (() => setAllScores(scores))();
            (() => setIsLoading(false))();  
        };
        if(isLoading)fetch();
    }, [isLoading, setAllScores, setIsLoading]);
    
    const userScores = username && getUserScores({username, duration, allScores});
    // console.log({userScores});
    
    useEffect(() => {
        (() => setShowApiButton(false))();
        const check = () => {
            if(results.seconds && currentUser && userScores && (!userScores.fastest || !userScores.best) )(()=>setShowApiButton(true))();
            
            if(results.seconds && results.counter && userScores && userScores.fastest && userScores.best){
                const bol = results.seconds < parseFloat(userScores.fastest.seconds) || results.counter < userScores.best.counter;

                (() => setShowApiButton(bol))();
            }
        };
        if(results.seconds && results.counter)check();
    }, [results, setShowApiButton, currentUser, userScores]);

 
    if(isLoading) return <Spinner/>
    // console.log({userScores, results, showApiButton})

    const sendToApi = async() => {
        // console.log({results});
        const {counter, seconds} = results;
        
        const cUCruises = currentUserCruises.length === 0 ? [{cruiseId:0}] : [...currentUserCruises];
        // console.log({counter, seconds, cUCruises});
        const resultArr = [];
        for (let i = 0; i < cUCruises.length; i ++){
            const response = (await simpleRequest({url:'memoryscores', method:"post", data:{cruiseId:cUCruises[i].cruiseId, counter, seconds }})).data;
            resultArr.push(response)
        };
        // console.log({resultArr});
        (() => setShowApiButton(false))();
        (() => setResults({seconds:null, counter:null, cruiseId:0}))();
        (() => setIsLoading(true))();
    };


    // console.log({currentUserCruises});
    const firstCruise = currentUserCruises[0] || {companyHandle:null, companyName:null};
    // if(!currentUserCruises || currentUserCruises.length === 0)return null;
    return(
        <div>
            {results.counter && <h5 className="my-3">Result: {results.counter} {translate("clicks")}, {results.seconds} {translate("seconds")}</h5>}
            {showApiButton && <button className="btn btn-outline-dark my-1" onClick = {sendToApi}>{translate("Save Result")}</button>}
            {showApiButton && <p>({translate("New best or fastest game")}.)</p>}
            <Memory setResults = {setResults}/>
            <UserScores duration = {duration} allScores={allScores}/>
            {currentUserCruises.map(c => <CruiseScores cruise={c} key = {c.id}/>)}
            <CompanyScores duration = {duration} companyHandle={firstCruise.companyHandle} allScores={allScores}/>
            <CommonScores duration = {duration} title = {translate("Best Results on Cruise Ships")} filterCruises={true} allScores={allScores} />
            <CommonScores duration = {duration} title = {translate("Common Best Results")} allScores={allScores}/>
        </div>
    );
};
export default Main;