import React from 'react';
import AdminArea from './Admin/AdminArea';
import {useSelector} from 'react-redux'
import FirstSteps2 from './FirstSteps2/FirstSteps2';
import ActualCruises from './FirstSteps2/ActualCruises';
import CruiseBackToCruiseButton from './CruiseBackToCruiseButton';
import HomePhotos from './PhotoShows/Start/HomePhotos';
import { developerModus } from '../config';

function Home() {

  const currentUser = useSelector(st => st.currentUser);
  const developer = currentUser && ["developer"].includes(currentUser.userStatus);
  const isInDeveloperModus = developer && developerModus;
  console.log({developer, developerModus, isInDeveloperModus});

  return (
    <div className="text-center">
      {!isInDeveloperModus && <HomePhotos />}
      <CruiseBackToCruiseButton/>
      <ActualCruises/>
      {developer && <AdminArea />}
      <FirstSteps2/>
    </div>
  );
}

export default Home;