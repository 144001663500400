import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
// import CompanyVesselLists from "./CompanyVesselLists";
// import CompanyCrewList from "./CompanyCrewList";
import {translate} from '../Language/Translate';
import { BlackWhite } from "../styledComponents/colors";
import {useSelector} from "react-redux";
import CompanyCrewList from "./CompanyCrewList";


const CompanyCrewListCard = ({eventKey}) => {

  const currentCompany = useSelector(st => st.currentCompany);
  const length = currentCompany.companyCrew && currentCompany.companyCrew.length;
  // console.log({currentCompany}, currentCompany.companyCrew.length, {length});

    return(  
        <Card key={eventKey}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
            <BlackWhite>{translate('Crew')} ({length})</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>
                <CompanyCrewList/>
            </Card.Body>
        </Accordion.Collapse>
        </Card>
    )
};
export default CompanyCrewListCard;