import React from "react";
import { translate } from "../../../components/Language/Translate";
// {
//     "fulltext": "Sa 6.7. Oslo /Norwegen 7.00 17.00",
//     "day": "06",
//     "month": "07",
//     "wd": "Sa",
//     "dateOrigin": "6.7.",
//     "dateMonthDay": "-07-06",
//     "destination": "Oslo",
//     "rest": [
//         "/Norwegen",
//         "7.00",
//         "17.00"
//     ],
//     "place": "Pier"
// }

const ProposalList = ({props}) => {
    console.log({props});

    
    const {proposals, date, activate, variables, setVariables} = props;
    const localProposals = proposals.filter(p => date.includes(p.dateMonthDay));
        const handleClick = e => {
        const proposal = localProposals.find(p => p.idx === +e.target.id);
        console.log({proposals, localProposals, e:+e.target.id})
        const {arrival, destination, departure} = proposal;
        console.log({proposal});
        // activate();
        setVariables(vars => {return {...vars, arrival, destination, departure}})
        

    }

    if(localProposals.length === 0)return null;
    return(
        <div>
            <p>{translate("Please click to insert the data into the form")}</p>
            <ul>
                {localProposals.map((p) => 
                    <li key={p.idx} id={p.idx} onClick = {e => handleClick(e)}>
                        {p.fulltext}
                    </li>)}
            </ul>
        </div>
    )
};
export default ProposalList;