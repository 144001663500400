import React from "react";
import ProfilePhotoListItem from "./ProfilePhotoListItem";

const ProfilePhotoList = ({photos, setPhotos}) => {

    return(
        <div>
            <h4>Profilfotos</h4>
            <p>
                Diese Fotos wurden von Usern als Profilfotos hochgeladen. Bevor sie für andere User sichtbar werden, 
                müssen sie von Admins bestätigt werden.
            </p>
            <div className="row row row-eq-height">

                {photos.map(p => <ProfilePhotoListItem key={p.username} photo={p} setPhotos={setPhotos} photos={photos} />)}
            </div>
        </div>
    )
};
export default ProfilePhotoList;