import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from "react-redux";
import {translate} from '../Language/Translate';
import UploadAreaSingle from "../../newForms2/upload/UploadAreaSingle";
import {updateCurrentUserOnAPI} from '../../actions/currentUser';

const AddProfilePhoto = ({setStage}) => {
    const currentUser = useSelector(st => st.currentUser);
    // const hasPhoto = currentUser.photoUrl ? true: false;
    // const photoIsConfirmed = currentUser.photoConfirmed;
    // const [stage, setStage] = useState('loggedIn');
    const [uploadArea, setUploadArea] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const dispatch=useDispatch();


    useEffect(() => {
        const updateUrl = async () =>{
            let data = {...uploadData.data};
            // console.log({data}, 'lllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll');
            await dispatch(updateCurrentUserOnAPI(currentUser.username, data));
            (() => setUploadData(null))();
            (() => setUploadArea(false))();
            (() => setStage("startLoggedIn"))();
        };
        if(uploadData)updateUrl();

    }, [uploadData, currentUser, dispatch, setUploadData, setStage]);


    return(
        <div>
            <h4>{translate("Add Profile Photo")}</h4>
            <p>{translate("You can upload your profile photo here.")}</p>
            <p>{translate("Then the photo will be checked and activated by one of our administrators.")}</p>
            <p>{translate("This can take a few hours.")}</p>
            <div className="row col-12 my-3">
                <div className= "col-md-6">
                    <button onClick={() => setUploadArea(true)} className="btn btn-outline-dark col-12">{translate("Add photo")}</button>
                </div>
                <div className= "col-md-6">
                    <button onClick={() => setStage("startLoggedIn")} className="btn btn-outline-dark col-12">{translate("Back")}</button>
                </div>
            </div>


            {uploadArea && <UploadAreaSingle title="photoUrl" setUploadArea={setUploadArea} methods={["file", "url", "link"]} 
                setUploadData={setUploadData} subFolder={`users/${currentUser.username}`}/>}
        </div>
    )
};
export default AddProfilePhoto;