
import {
    FETCH_DESTINATION,
    ADD_PROPOSAL,
    EDIT_PROPOSAL,
    ADD_FOLLOW,
    ADD_COMMENT,
    DELETE_FOLLOW,
    // UPDATE_DESTINATION,
    ADD_DESTINATION_PHOTO,
    ADD_MEDICAL_INSTITUTE,
    UPDATE_MEDICAL_INSTITUTE,
    ADD_MEDICAL_COMMENT,
    UPDATE_MEDICAL_COMMENT,
    DELETE_MEDICAL_COMMENT,
    // ADD_ALBUM,
    // DELETE_ALBUM
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"
// import { updateDestinationOnAPI } from "./destinations";
// import DestinationPhotoFormSchema from "../newForms2/destinations/DestinationPhotoFormSchema";

function fetchDestinationFromAPI(destinationId) {
  return async function (dispatch) {
    const response = await simpleRequest({url:`destinations/${destinationId}`, method:"get"});
    const destination = response.data?.destination;
    return dispatch(getDestination(destination));
  };
};
function getDestination(destination) {
  return {
    type: FETCH_DESTINATION,
    destination,
  };
};

// function updateCurrentDestinationOnAPI(id, data){
//     return async function(dispatch){
//         const response = await simpleRequest({url:`destination/${id}`, method:"patch", data});
//         const destination = response.data?.destination;
//         return dispatch(updateCurrentDestination(destination))
//     };
// };
// function updateCurrentDestination(destination){
//     return{
//         type: UPDATE_DESTINATION,
//         destination
//     };
// };
export function addDestinationPhotoOnApi(data){
    return async function(dispatch){
        const response = await simpleRequest({url: `destinationphotos`, method:"post", data});
        const destinationPhoto = response.data?.destinationPhoto;
        return dispatch(addDestinationPhoto(destinationPhoto))
    };
};
function addDestinationPhoto(destinationPhoto){
    return{
        type: ADD_DESTINATION_PHOTO,
        destinationPhoto
    };
};
export function addMedicalInstituteOnApi(data){
    return async function(dispatch){
        const response = await simpleRequest({url:"medicalinstitutes", method:"post", data});
        // console.log(response.data);
        const medicalInstitute = response.data?.medicalInstitute;
        return dispatch(addMedicalInstitute(medicalInstitute))
    };
};
function addMedicalInstitute(medicalInstitute){
    return{
        type: ADD_MEDICAL_INSTITUTE,
        medicalInstitute
    };
};
export function updateMedicalInstituteOnApi(id, data){
    return async function(dispatch){
        const response = await simpleRequest({url: `medicalinstitutes/${id}`, method:"patch", data});
        // console.log(response.data);
        const medicalInstitute = response.data?.medicalInstitute;
        return dispatch(updateMedicalInstitute(medicalInstitute))
    }
}
function updateMedicalInstitute(medicalInstitute){
    return{
        type: UPDATE_MEDICAL_INSTITUTE,
        medicalInstitute
    };
};
export function addMedicalCommentOnApi(data){
    return async function(dispatch){
        const resData = (await simpleRequest({url: `medicalcomments`, method:"post", data})).data;
        const medicalComment = resData?.medicalComment;
        if(!(medicalComment))return;
        return dispatch(addMedicalComment(medicalComment));
    };
};
function addMedicalComment(medicalComment){
    return{
        type: ADD_MEDICAL_COMMENT,
        medicalComment
    };
};
export function updateMedicalCommentOnApi(id, data){
    return async function(dispatch){
        const resData = (await simpleRequest({url: `medicalcomments/${id}`, method:"patch", data})).data;
        const medicalComment = resData?.medicalComment;
        if(!medicalComment)return;
        return dispatch(updateMedicalComment(medicalComment));
    };    
};
function updateMedicalComment(medicalComment){
    return{
        type: UPDATE_MEDICAL_COMMENT,
        medicalComment
    };
};
export function deleteMedicalCommentOnApi(id){
    return async function(dispatch){
        const resData = (await simpleRequest({url:`medicalcomments/${id}`, method:"delete"})).data;
        const deleted = resData?.deleted;
        if(!deleted)return;
        return dispatch(deleteMedicalComment(deleted))
    };
};
function deleteMedicalComment(deleted){
    return{
        type: DELETE_MEDICAL_COMMENT,
        deleted
    };
};






// proposals

function addProposalOnApi(destinationId, data){
    // console.log({destinationId, data})
    return async function (dispatch){
        const response = await simpleRequest({method:"post", url:`proposals/${destinationId}`, data});
        const proposal = response.data?.proposal;
        return dispatch(addProposal(destinationId, proposal))
    }
};
function addProposal(destinationId, proposal){
    return{
        type:ADD_PROPOSAL,
        destinationId,
        proposal
    }
};
function editProposalOnApi(id, data){
    return async function (dispatch){
        const response = await simpleRequest({method:"patch", url:`proposals/${id}`, data});
        const proposal = response.data && response.data.proposal;
        return dispatch(editProposal(id, proposal));
    };

};
function editProposal(id, proposal){
    return{
        type:EDIT_PROPOSAL,
        id,
        proposal
    };
};
function addFollowOnApi(proposalId){
    return async function (dispatch){
            const response = await simpleRequest({method:"post", url:`follows/${proposalId}`});
            const follow = response.data && response.data.follow;
            return dispatch(addFollow(proposalId, follow));

    };
};
function addFollow(proposalId, follow){
    return {
        type:ADD_FOLLOW,
        proposalId,
        follow
    }
};
function addCommentOnApi(followId, data){
    return async function (dispatch){
        const response = await simpleRequest({method:"patch", url:`follows/${followId}`, data});
        const follow = response.data && response.data.follow;
        return dispatch(addComment(follow))
    };
};
function addComment(follow){
    return{
        type:ADD_COMMENT,
        follow
    };
};
function deleteFollowOnApi(followId){
    return async function (dispatch){
        const response = await simpleRequest({method:"delete", url:`follows/${followId}`});
        const deleted = response.data && response.data.deleted;
        return dispatch(deleteFollow(deleted))
    }
}
function deleteFollow(deleted){
    return{
        type: DELETE_FOLLOW,
        deleted
    }
}

// function addDestinationAlbumOnApi(destinationId, albumId){
//     return async function(dispatch){
//         const data = (await simpleRequest({method:"post", url:"albumdestination", data:{destinationId, albumId}})).data;
//         const albumDestination = data?.albumDestination;
//         return dispatch(addAlbumDestination(albumDestination))
//     }
// };
// function addAlbumDestination(albumDestination){
//     return{
//         type: ADD_ALBUM,
//         albumDestination
//     }
// };

export {
    fetchDestinationFromAPI,
    // updateCurrentDestinationOnAPI,
    addProposalOnApi,
    editProposalOnApi,
    addFollowOnApi,
    addCommentOnApi,
    deleteFollowOnApi
};
