import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
// import {validateField} from '../ReactFormFunctions';

const CheckBox = ({name,props}) => {
    // console.log('inside CheckBox' ,{name, props});
    const {
        fieldErrors,
        fieldSettings,
        validateField,
        setVariables,
        variables
    } = props;
    const {label} = fieldSettings[name];
    const checked = variables[name] || false;
    
    useEffect(() => {
        // console.log('inside useEffect')
        const value = variables[name] || false
        setVariables(variables => {return {...variables, [name]: value}})
    }, []);




    // const checked = variables[name];
    const field = fieldSettings[name];
    // console.log({checked, field})

    // console.log({checked, validateField});

    const handleCheck=(e)=>{
        // console.log(e.target.checked, e.target.name);
        const {name, checked, value} = e.target;
        // console.log({name, checked, value});
        (() => setVariables({...variables,[name]: checked}))();
        // console.log({variables})
    };

    // console.log({checked});
    
    return (

        <Form.Group size="lg" controlId={name}>
        <Form.Label></Form.Label>
        { fieldErrors[name]?<small className="text-danger" > - {fieldErrors[name]}</small>:null}
        <Form.Check type="checkbox" 
                    style = {fieldErrors[name] ? {border:"2px solid red", backgroundColor:"rgb(255,200,200)"}:{}}
                    label={label}
                    key={name}
                    checked={checked}
                    value={checked}
                    name={name}
                    onChange={(e) => handleCheck(e)}
                    onBlur={(e) => validateField(e)}
        />
            {field.toolTip?<p><small className="text-secondary" >{field.toolTip}</small></p>:null}
        </Form.Group>
    
 
    )
}

export default CheckBox;

