
// 11.11.23
import {
  FETCH_COMPANY,
  UPDATE_COMPANY,
  ADD_COMPANY_CREW, 
  UPDATE_COMPANY_CREW,
  DELETE_COMPANY_CREW,
  ADD_COMPANY_VESSEL
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchCompanyFromAPI(companyHandle) {
  // console.log({companyHandle});
  return async function (dispatch) {
    const response = await simpleRequest({url:`companies/${companyHandle}`, method:"get"});
    const company = response.data && response.data.company;
    // console.log({company});
    return dispatch(getCompany(company));
  };
};
function getCompany(company) {
  return {
    type: FETCH_COMPANY,
    company,
  };
};
export function updateCompanyOnAPI({handle, data}){
  return async function (dispatch){
    const response = await simpleRequest({url:`companies/${handle}`, method:"patch", data});
    const company = response.data && response.data.company;
    return dispatch(updateCompany(company));
  };
};
function updateCompany(company){
  return{
    type: UPDATE_COMPANY,
    company
  };
};



//======================================CompanyCrew================================================================

export function addCompanyCrewOnAPI({handle, data}){
  return async function (dispatch){
    const response = await simpleRequest({url:`companycrew/${handle}`, method:"post", data});
    const companyCrew = response.data && response.data.companyCrew
    return dispatch(addCompanyCrew(companyCrew));
  };
};
function addCompanyCrew(companyCrew){
  return{
    type:ADD_COMPANY_CREW,
    companyCrew
  };
};
export function updateCompanyCrewOnAPI({id, data}){

  return async function (dispatch){
    const response = await simpleRequest({url:`companycrew/${id}`, method:"patch", data});
    // console.log(response.data, response.error);
    const companyCrew = response.data && response.data.companyCrew;
    return dispatch(updateCompanyCrew(companyCrew));
  };
};
function updateCompanyCrew(companyCrew){
  return{
    type:UPDATE_COMPANY_CREW,
    companyCrew
  };
};
export function deleteCompanyCrewOnAPI(id){
  return async function (dispatch){
    const response = await simpleRequest({url:`companycrew/${id}`, method:"delete"});
    const deleted = response.data && response.data.deleted
    return dispatch(deleteCompanyCrew(deleted));;
  };
};
function deleteCompanyCrew(deleted){
  return{
    type: DELETE_COMPANY_CREW,
    deleted
  };
};

//========================================CompanyVessel=======================================================

export function addCompanyVesselOnAPI(data){
  return async function (dispatch){
    const response = await simpleRequest({url:`vessels`, method:"post", data});
    const vessel = response.data && response.data.vessel;
    return dispatch(addCompanyVessel(vessel));
  };
};
function addCompanyVessel(vessel){
  return{
    type:ADD_COMPANY_VESSEL,
    vessel
  };
};
