// import FormCruiseNotFoundForm from "./FormCruiseNotFound";
import {translate} from '../../components/Language/Translate'

class CruiseNotFoundData{

    static async notFoundNew({vessels}){
        

        const consts = new CruiseNotFoundSchema(vessels);
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        consts.formType = 'notFoundNew';
        consts.request.url=`cruiseproposals/`
        consts.nextPage = (`/`);
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }



}













class CruiseNotFoundSchema {
    
    constructor(vessels){
    
       this.fields = {
            vesselId : { type:"text", fieldType:"select" ,label:translate("Vessel"), autoFocus:true,
                value:'-1', settings:{options: this.writeVesselSelectList(vessels)}},
            vessel : {type:"text", fieldType:"text" ,label:translate("Vessel"), value:"", 
                   placeholder:translate("or write the ship's name in this field")},
            shorthand : {type:"text", fieldType:"text" ,label:translate("Shorthand"), value:""},
            "startAt": {type:"text", fieldType:"text" ,label:translate("Start at"), placeholder:"Format: 2021-09-09", value:""},
            "startPort": {type:"text", fieldType:"text" ,label:translate("Start Port"), value:""},
            "endAt": {type:"text", fieldType:"text" ,label:translate("End at"), placeholder:"Format: 2021-09-09", value:""},
            "endPort": {type:"text", fieldType:"text" ,label:translate("End Port"), value:""},
            "title": {type:"text", fieldType:"text" ,label:translate("Title"), value:""},
            "description": {fieldType:"textarea" ,label:translate("Description"), type:"textarea", value:""}
       };
    
        this.formLabels = {title: translate("Add Cruise"), btnLabel:translate("Add Cruise")};

        this.sendData = ["vesselId", "vessel", "shorthand", "startAt", 
            "startPort", "endAt", "endPort", "title", "description"];

        this.request = {url:"/cruiseproposals", method:"post"};
        this.evaluation=true;
        

    };


    
    
        
    
    
    
    writeVesselSelectList(vessels){
        // console.log('inside writeVesselSelectList')
        const vesselSelect = vessels && vessels.map(v => {
            const type = translate(v.type);
            const value = `${v.prefix} ${v.name} - ${v.companyName}${v.note?` (${v.note})`:""}, ${type}`;
            return {key: v.id.toString(), value}
        });
        // return vesselSelect;
        // return vesselSelect;
        // console.log('Please select a vessel', translate('Please select a vessel'));
        vesselSelect.unshift({key:"-1", value:` - ${translate('Please select')} - `});
        return vesselSelect;
    }
};

export default CruiseNotFoundData;
