// 8.4.24

import {translate} from '../../components/Language/Translate';
import {proposals} from '../../components/Destinations/Proposals/proposalsData'

class ProposalsSchema {

    constructor() {
      
            this.type = {
                label:translate("Type"),
                value:"Restaurant",
                validation:{minLength:2, maxLength: 50},
                required:true,
                options:proposals.map(p => {
                    // console.log({p}, p[0])
                    return{key:p[0].replace(' ','_'), value:translate(p[0])}}),
                type:"text",
                fieldType:"select",
                autofocus:true
            };
            this.name = {
                label:translate("Name"),
                value:"",
                validation:{minLength:2, maxLength:40},
                required:true,
                type:"text",
                fieldType:"text"
            }; 
            this.note = {
                label:translate("Note"),
                value:"",
                validation:{maxLength:50},
                type:"text",
                fieldType:"text"
            }; 
            this.place = {
                label:translate("Location"),
                value:"",
                validation:{maxLength:100},
                type:"text",
                fieldType:"textarea"

            };
            this.geolocation={
                label:translate("Geolocation"),
                value:"",
                validation:{maxLength:100},
                type:"text",
                fieldType:"geolocation"
            }
            this.description = {
                label:translate("Description"),
                value:"",
                validation:{maxLength:100},
                type:"text",
                fieldType:"textarea"
            };
            this.visibleCrew = {
                name:"visibleCrew",
                label : translate("Visible for Crew"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true,
            };
            this.visibleStaff = {
                name:"visibleStaff",
                label : translate("Visible for Staff"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true,
            };
            this.visibleGuests = {
                name:"visibleGuests",
                label : translate("Visible for Guests"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true
            };
    }

};
export default ProposalsSchema;