import React, { useState } from "react";
import {Form} from "react-bootstrap";

const DecimalField = ({name, props}) => {
    const {
        variables,
        setVariables,
        validateField,
        fieldSettings, 
        fieldErrors, 
        } = props

    const [displayed, setDisplayed] = useState((variables[name] / 100)?.toFixed(2) || "0,00");
    const {label, required, autoFocus, toolTip, placeholder, visible, disabled} = fieldSettings[name];

    const handleChangeLocal = e => {
        setDisplayed(e.target.value);
        const toCents = Math.round(parseFloat(e.target.value.replace(',', ".")) * 100) || 0;
        // console.log({toCents})
        setVariables({...variables, [name]: toCents})
    }
    const handleBlur = e => {
        validateField(e)
        setDisplayed(((variables[name] / 100)?.toFixed(2)) || "0,00")
    }

    if (visible === false) return null;
    return(
        <Form.Group size="lg" controlId={name} >
            <Form.Label  className ="pt-1 px-1" >{label}{required? ' * ':' '}
            { fieldErrors[name]?<small className="text-danger" > - {fieldErrors[name]}</small>:null}
            </Form.Label>
            <Form.Control 
                // id = {name}
                style = {fieldErrors[name] ? {border:"2px solid red", backgroundColor:"rgb(255,235,235)"}:{}}
                name={name} 
                disabled={disabled}
                type="text"
                key={name} 
                autoFocus={autoFocus}
                value={displayed}
                required = {required}
                placeholder={placeholder}
                onChange={e => handleChangeLocal(e)} 
                onBlur={e => handleBlur(e)}
            />
            {toolTip?<p><small className="text-black p-1" >{toolTip}</small></p>:null}
        </Form.Group>
    )
}
export default DecimalField;
