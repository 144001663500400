import React from "react";
import {useSelector} from 'react-redux';
import { translate, Translate } from "../../components/Language/Translate";
import { Form, Col} from "react-bootstrap";
import DestinationList from "./DestinationList";

const CruiseAreaEditRoutingForm = ({props}) => {

const{
        variables, 
        setVariables, 
        changed,
        setChanged,
        handleChange
    } = props;

    const language = useSelector(st => st.currentUser)?.language || Translate.language;
    const destinations = useSelector(st => st.destinations);
    const {destinationId} = variables;
    const destColor = destinationId ? "green" : "red";
    const savedColor = changed ? "red" : "green";
    const curDestination = destinations?.find(d => d.id === variables.destinationId) || {};

    const deleteDestination = e => {
        e.preventDefault();
        // console.log('delete');
        (() => setVariables({...variables, destinationId:null, destination: ""}))();

    };
    console.log({variables});

    return(

                  <Form className="my-2">
                  <Form.Row>
                    <Col sm={2}>
                      <Form.Control 
                          placeholder={translate("Arrival")}
                          value = {variables.arrival || ""}
                          name = "arrival"
                          onChange = {e => handleChange(e)}
                          style={{border:`1px solid ${savedColor}`}}
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Control 
                          placeholder={translate("Departure")}
                          value = {variables.departure || ""}
                          name = "departure"
                          onChange = {e => handleChange(e)}
                          style={{border:`1px solid ${savedColor}`}}
                      />
                    </Col>
           
                    <Col sm={8} className="text-left">
                        {variables.destinationId ?
                          <button className="btn btn-outline-danger mr-2" onClick={e => deleteDestination(e)} tooltip={translate("Delete Destination")}>X</button> :null}
                        {variables.destinationId ? <b>{`${curDestination[language] || curDestination.enUK}`}</b>:null}
                        {!variables.destinationId && 
                              <Form.Group>
                                  <Form.Control
                                      placeholder={translate('Destination')}
                                      style={{border:`1px solid ${destColor}`}}
                                      type = "text"
                                      value = {variables.destination} 
                                      name = "destination"
                                      onChange = {e => handleChange(e)}
                                  >
      
      
                                  </Form.Control>
                              </Form.Group>
      
      
                          }
                    </Col>
      
                  </Form.Row>
                  <Col sm={4}></Col>
                  <Col sm={8}>
                    <ol>
                      <DestinationList variables = {variables} setVariables = {setVariables} setChanged = {setChanged}/>
                    </ol>
                  </Col>

                  <Form.Control as = "textarea" className = "mt-2" rows = {5}
                        name = "description"
                        type="textarea"
                        value = {variables.description || ""}
                        placeholder = {translate("Description")}
                        onChange = {e => handleChange(e)}
                  />
            </Form>
    );



};
export default CruiseAreaEditRoutingForm;