// import Button from 'react-bootstrap/esm/Button';
import FormDataFieldClass from '../FormDataFieldClass'
// import {BASE_URL} from '../../config'
import {translate} from "../../components/Language/Translate"

class UserFieldsData{
        static username = new FormDataFieldClass(
            "", translate("Username"), "text", "text",
            {required:true, noUpdate: true},
            {minLength:8, maxLength:15, noSpace:true, mustNotContain:"@"}
        );
        static nickname = new FormDataFieldClass(
            "", translate("Displayed Name"), "text", "text",
            {required:true},
            {minLength:3, maxLength:40,}
        );        
        static firstName = new FormDataFieldClass(
            "", translate("First Name"), "text", "text",
            {required:true},
            {minLength:2, maxLength:25, noNumbers: true}
        );
        static lastName = new FormDataFieldClass(
            "", translate("Last Name"), "text", "text",
            {required:true},
            {minLength:2, maxLength:25, noNumbers:true}
        );
        static language = new FormDataFieldClass(
            "enUK", translate("Language"), "select", "text",
            {required:true, options:[{key:"deDE", value:"Deutsch"}, {key:"enUK", value:"English"}]},
            {minLength:3, maxLength:5, noSpace:true}
        );
        static email = new FormDataFieldClass(
            "", "Email", "text", "email",
            {required:true},
            { minLength:8, maxLength:40, isEmail:true}
        );
        static confirmEmail = new FormDataFieldClass(
            "", translate("Confirm Email"), "text", "email",
            {required:true},
            {mustBeEqualTo:"email"}
        );
        static photoUrl = new FormDataFieldClass(
            "", "Photo URL", "text", "url",
            {required:false},
            {isUrl:true}
        );
        static password = new FormDataFieldClass(
            "", translate("Password"), "text", "password",
            {required:true},
            {minLength:8, maxLength:15, noSpace:true}
        );
        static confirmPassword = new FormDataFieldClass(
            "", translate("Confirm Password"), "text", "password",
            {required:true},
            {mustBeEqualTo:"password", minLength:8, maxLength:15, noSpace:true}
        );
        static companyHandle = new FormDataFieldClass(
            "*", "Company", "select", "text",
            {options:[{key:'*', value:'*'}]}
        )
        static userStatus = new FormDataFieldClass(
            "user", "Status", "select", "text",
            {options:[{key:"user", value:"user"}, {key:"admin", value:"admin"}]}
            
        );
        static usernameOrEmail = new FormDataFieldClass(
            "", translate("Please insert username or email address"), "text", "text",
            {required:true}
            
        );
        static forgotPassword = new FormDataFieldClass(
            `/password/request`, translate("Forgot Password?"), "link", "link",{}
        );
        static codeField = new FormDataFieldClass(
            "", translate("Please insert the code from the email here."), "textArea", "string", {}
        );
        }

export default UserFieldsData;