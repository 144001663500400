import { translate } from "../../components/Language/Translate";
// import { simpleRequest } from "../simpleRequest";

class GroupsFormSchema{

    constructor(group={}) {
        this.group = group;
        this.options = this.getAdminSelectOptions();
        this.fields = {
            name:{
                label: translate("Name"),
                value: this.group.name || "",
                type: "text",
                fieldType: "text",
                required:true,
                validation: {
                    minLength: 3,
                    maxLength: 40
                },
                autoFocus:true
            },
            type:{
                label: translate("Type"),
                value: this.group.type || "public",
                type: "select",
                fieldType: "select",
                required:true,
                options: [
                    {key:"public", value:translate("Public group. Any fellow traveller can join this group.")}, 
                    {key:"private", value:translate("Private group. Fellow travellers can only become members if they are invited by the group administrator.")},
                    {key:"noAdmin", value:translate("Private group. No admin. Fellow travellers can be invited by all groupmembers.")}
                ]
            },
            level:{
                label: translate("Level"),
                value: "cruise",
                type: "select",
                fieldType: "select",
                required:true,
                options: [
                    {key:"cruise", value:translate("Group for this cruise")}, 
                    {key:"vessel", value:translate("Group for this vessel")},
                    {key:"company", value:translate("Group for this company")}
                ]
            },

            admin:{
                label: translate("Admin"),
                value: this.group.admin,
                type: "select",
                fieldType: "select",
                options: this.options
            }
        };

    }; 
    getAdminSelectOptions(){
        if(!this.group.members)return[];
        const members = this.group.members;
        const options = members.map(m => {return {key:m.username, value:m.nickname}});
        return options;
    }
};
export default GroupsFormSchema;