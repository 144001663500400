import React from "react";
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ensureEditAllowed} from '../helpers/auth';
// import "./UserListCard.css";
import {Image, Card} from "react-bootstrap";
import {translate} from "./Language/Translate";
import { BlackWhite } from "./styledComponents/colors";

const UserListCard = ({user}) => {

  // console.log('inside UserListCard' , user)
  const currentUser=useSelector(st => st.currentUser)
  const editable = ensureEditAllowed(currentUser, user);
  // const loggedIn = currentUser && currentUser.username?true:false;
  const loggedIn = true;




  let txt = user.department?user.department:"";
        txt=user.position?((txt?txt+=", ":"")+user.position):txt;
        txt=txt?`(${txt})`:null;

  // console.log({user});
    return(
        // <div >
          <Card key={user.username} className="text-center m-1" style={{height:"100%"}} >
            <Card.Body >
                <div style={{height:"200px"}} className = "my-3">
                  {}
                {user.photoUrl && !user.photoConfirmed && <p>{translate("The photo is currently being checked")}</p>}
                {user.photoUrl && user.photoConfirmed && <Image src={user.photoUrl} className="mb-4" alt={user.nickname} style={{maxHeight:"100%", maxWidth:"100%", minWidth:"75%"}}/>}
                </div>
                <h4>{user.nickname}</h4>
                {editable?<p>{user.firstName} {user.lastName}, status: {user.userStatus}</p>:null}
                {["developer", "admin"].includes(currentUser.userStatus) && <p>Username: {user.username}</p>}
                {["developer", "admin"].includes(currentUser.userStatus) && <p>Username: {user.email}</p>}
                {txt}
                <p><i>{user.description}</i></p>
           </Card.Body>
            <Card.Footer className="card-footer usercard-footer">
             {loggedIn && currentUser.username !== user.username?
              <Link to={"/users/show/" + user.username}>
                <BlackWhite>
                  {translate("Details about")} {user.nickname}
                </BlackWhite>
              </Link>:null}
            </Card.Footer>
          </Card>
        //  </div> 
    )

};

export default UserListCard;