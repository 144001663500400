import { simpleRequest } from "../simpleRequest";
import {translate} from "../../components/Language/Translate"

const _getVars = (consts) => {
    let vars = {}
    Object.entries(consts.fields).forEach(f => {vars = {...vars, [f[0]]:f[1].value}});
    return {fields:vars};
}

class CompanyFormData{

    static async _getForm(){

        const consts = {
            formType:"companyNew",
            request : {
                url:"companies",
                method:"post",
            },
            nextPage:"/companies",
            formLabels : {
                title: translate("Add Company"), 
                btnLabel:translate("Add Company")
            },
            fields:{
                handle: {
                    label:translate("Handle"),
                    value:"",
                    validation:{maxLength:25, minLength:3, lowerCased:true},
                    required:true,
                    type:"text",
                    fieldType:"text",
                    toolTip:translate("An unique Symbol for the Company.")

                },  

                name: {
                    label:"Name",
                    value:"",
                    validation:{
                        minLength:5,
                    },
                    required:true,
                    type:"text",
                    fieldType:"text"
                },  
                suffix: {
                    label:"Suffix",
                    value:"",
                    validation:{},
                    required:false,
                    type:"text",
                    fieldType:"text"
                },  
                note: {
                    label:"Beschreibung",
                    value:"",
                    validation:{},
                    required:false,
                    type:"text",
                    fieldType:"textarea"
                },  

                logoUrl: {
                    label:"Logo URL",
                    value:"",
                    validation:{},
                    type:"url",
                    fieldType:"text"

                }
             }
        }
        return consts;
    }
    static async _getCompany(handle){
        // console.log("inside _getCompany", handle)
        const reqObj = {url:`companies/${handle}`, method:"get"};
        const response = await simpleRequest(reqObj);
        // console.log(response.data)
        return response.data
    }

    static async companyNew(){
        // console.log('inside CompanyFormData.companyAdd')
        const consts = await this._getForm();
        const vars = _getVars(consts)
        consts.oldValues = {...vars.fields}
        consts.nextPage = `/companies`
        // console.log({consts,vars})
        return {consts, vars}
    }
    static async companyEdit({params}){
        const{handle}=params;
        const consts = await this._getForm();
        consts.formType="companyEdit";
        consts.formLabels={title:"Edit Company", btnLabel:"Update"};
        consts.request={url:`companies/${handle}`, method:"patch"};
        consts.oldValues=await this._getCompany(handle)
        consts.nextPage = `/companies/${handle}`
        consts.sendData = ["name", "suffix", "logoUrl"]
        delete(consts.oldValues.vessels);
        consts.fields.handle.fieldType="info"
        let varFields;
        Object.keys(consts.fields).forEach(k => varFields = {...varFields, [k]: consts.oldValues[k]})
        const vars ={fields:varFields}
        // console.log({consts, vars})
        return {consts, vars};
    }
};



export default CompanyFormData;





  