import {
  FETCH_VESSELS, 
  UPDATE_VESSEL, 
  DELETE_VESSEL
} from "../actions/types";

function sortByVesselName(vessels) {
  return vessels.sort(name => name);
};



export default function rootReducer(state = [], action) {

  switch (action.type) {
    case FETCH_VESSELS:
      if(!action.vessels)return state;
      return sortByVesselName([...action.vessels]);
    case UPDATE_VESSEL:
      // console.log(state, action)
      return sortByVesselName([...state.filter(v => v.id !== action.vessel.id), action.vessel]);
    case DELETE_VESSEL:
      // console.log(state, action)
      return sortByVesselName([...state.filter(v => v.id !== action.deleted.id)])
    default:
      return state;
  };
};
