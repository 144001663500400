import React from "react";
import {useSelector} from 'react-redux';
import {Translate} from '../Language/Translate'
import {getDateObject} from '../../helpers/Date'


const GroupChatItem = ({chatItem}) => {
    const {createdAt, createdBy, body} = chatItem;
    const currentUser = useSelector(st => st.currentUser);
    const usersItem = createdBy === currentUser.username;
    const creator = usersItem ? "you" : createdBy;
    const language = useSelector(st => st.currentUser)?.language || Translate.language;
    
    const className = `my-1 p-2 ${usersItem ? "text-right": "text-left"}`
    // console.log({usersItem, createdBy, currentUser, className});
    return(
        <div className = {className} 
            style = {{border:"1px solid grey", borderRadius:"5px", backgroundColor: usersItem ? "rgba(200,255,200)" : "lightblue"}}>
            <p><b>{getDateObject(createdAt,language).dateTime}, {creator}:</b></p>
            <p>{body}</p>
        </div>
    )

};
export default GroupChatItem;