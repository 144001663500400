import React, { useState } from "react";
import { translate } from "../../Language/Translate";
import TimingListItem from "./TimingListItem";

const TimingList = ({timing, setTiming}) => {
    const [activeId, setActiveId] = useState(0);

    return(
        <table className="table col-12">
            <thead>
                <tr>
                    <th>{translate("From")}</th>
                    <th>{translate("Until")}</th>
                    <th>{translate("Activity")}</th>
                </tr>
            </thead>
            <tbody>
                {timing.map(t => <TimingListItem key={t.id} setTiming= {setTiming}timingItem={t} activeId={activeId} setActiveId={setActiveId}/>)}
            </tbody>
        </table>
  
    )
};
export default TimingList;