import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
import Spinner from "../../components/Spinner";
    
const CheckBox = ({props}) => {
    console.log({props});
    const [isLoading, setIsLoading] = useState(true);

    const {name, fieldSettings, setVariables, variables, fieldErrors} = props;
    const {label, required, toolTip} = fieldSettings[name];
    const style = fieldErrors[name] ? {border:"2px solid red", borderRadius:"5px"} : {};
    const ErrorSpan = () => <span className = "text-danger">{` - ${fieldErrors[name]}`}</span>;
    
    const handleCheck = e => setVariables({...variables, [name]: e.target.checked});
    
    useEffect(() => {
        if(variables[name] === "false") setVariables({...variables, [name]:false});
        setIsLoading(false);
    }, [variables, name, setVariables, setIsLoading])

    if(isLoading)return <Spinner/>



    return(
        
        <Form.Group>
        <Form.Label>{translate(label) + `${required ? ` *` : ''}`}
            {fieldErrors[name] && <ErrorSpan/>}
        </Form.Label>
        <Form.Check
            type="checkbox" 
            style={style}
            name={name}
            checked={variables[name]}
            value={variables[name]}
            onChange={e => handleCheck(e)}
            required = {!!required}
        />
        {toolTip && <p><small>{toolTip}</small></p>}
        </Form.Group>
    );
};
export default CheckBox;