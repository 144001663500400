import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import TextField from "./TextField";
import { Translate, translate } from "../../components/Language/Translate";
import { getDateObject } from "../../helpers/Date";




const TextFieldWithNowButton = ({props}) => {

    const {name, setVariables, variables, fieldSettings, fieldErrors, setFieldErrors} = props;

    const handleClick = () => {setVariables(vars => {
        return {...vars, [name]: getDateObject(new Date(), Translate.language, 'local').time}})};
    console.log({variables})
    return(
        <Col className = "p-2" >
            <TextField props={props}/>
            <button className="btn btn-outline-dark col-12" onClick={handleClick}>{translate("Now")}</button>
        </Col >

    )
};
export default TextFieldWithNowButton;