import React from "react";
import HeaderNavLinks from "./HeaderNavLinks";
import { Navbar, Container, Nav } from "react-bootstrap";
// import CollapsibleExample from "./CollapsibleExample";

const HeaderNavBar = () => {
    // console.log('HeaderNavBar')

    return(
        <>
            <Navbar collapseOnSelect expand="lg">
              <Container>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav>
                          <HeaderNavLinks/>
                      </Nav>
                  </Navbar.Collapse>
              </Container>
            </Navbar>
        </>
    )
};

export default HeaderNavBar;