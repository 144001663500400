import React from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import { BlackWhite } from "../styledComponents/colors";
import { translate } from "../Language/Translate";
import DestinationAlbums from "../PhotoShows/Destination/DestinationAlbums";

const AlbumsCard = ({eventKey}) => {

    return(
        <Card>
            <Card.Header className="text-center">
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
                <BlackWhite>{translate("Photo Albums")} </BlackWhite>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey}>
              <Card.Body>
                <DestinationAlbums/>
              </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};
export default AlbumsCard;