import React, { useEffect, useState } from "react";
import "./UserList.css";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsersFromAPI } from '../actions/users';
import UserListCard from './UserListCard'
import {translate} from "./Language/Translate"
import Spinner from './Spinner';
import User from "../helpers/user";

function UserList() {
  const [sorting, setSorting] = useState('abc');
  const [filtered, setFiltered] = useState(false);

  // console.log('inside UserList')
  const users = useSelector(st => st.users);
  const currentUser = useSelector(st => st.currentUser);
  
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(function() {
    async function fetchUser() {
      dispatch(fetchUsersFromAPI());
      setIsLoading(false);
    }

    if (isLoading) fetchUser();
    
  }, [dispatch, isLoading]);


  if (isLoading) return <Spinner/>

  if (!isLoading && users.length === 0) {
    return <b>{translate('Users')}</b>;
  };
  switch (sorting){
    case "abc":
      // console.log('inside abc')
      users.sort((a,b) => a.username > b.username ? 1 : -1);
      break;
    case "cba":
      users.sort((a,b) => a.username < b.username ? 1 : -1);
      break;
    case "createdAtUp":
      users.sort((a,b) => a.createdAt > b.createdAt ? 1 : -1);
      break;
    case "createdAtDown":
      users.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1);
      break;
    case "updatedAtUp":
      users.sort((a,b) => a.updatedAt > b.updatedAt ? 1 : -1);
      break;
    case "updatedAtDown":
      users.sort((a,b) => a.updatedAt < b.updatedAt ? 1 : -1);
      break;
    default:
      break;
  };
  const userList = filtered ? users.filter(u => !u.isConfirmed): [...users];
  // console.log(setFiltered);
  // console.log({users, userList});
  const {newUsers, oldUsers} = User.newAndOldUsers(userList, currentUser);
  // console.log({newUsers, oldUsers, sorting, filtered}, currentUser.lastLogin);
  return (
    <div>
      <p>
        <span onClick={() => setSorting(sorting === "abc" ? "cba" : "abc")}>abc</span> - 
        <span onClick={() => setSorting(sorting === "createdAtUp" ? "createdAtDown" : "createdAtUp")}> created</span> - 
        <span onClick={() => setSorting(sorting === "updatedAtUp" ? "updatedAtDown" : "updatedAtUp")}> not confirmed</span>
        
      </p>
      {newUsers.length>0 && <h5>{translate("New Users")}</h5>}
      <div className="row row-eq-height">
        {newUsers.map(user => (
          <div className= 'col-xl-3 col-lg-4 col-sm-6 p-1' key={user.username} >
            <UserListCard user={user}/>
          </div>
        ))}
      </div>
      <h5>{translate("Users")}</h5>
      <div className="row row-eq-height">
        {oldUsers.map(user => (
          <div className= 'col-xl-3 col-lg-4 col-sm-6 p-1' key={user.username} >
            <UserListCard user={user}/>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserList;