import React, {useState} from "react";
import ConfirmationArea from "./ConfirmationArea";
import { useSelector, useDispatch } from "react-redux";
import { deleteAnnouncementPinboardOnApi } from "../actions/currentCruise";
import { ensureAuthorized } from "../helpers/auth";
import CruiseAreaCardAnnouncementsItem from "./CruiseAreaCardAnnouncementsItem";
import {translate} from './Language/Translate'
import AnnouncementNewForm from "../newForms2/announcements/AnnouncementNewForm";
import {sortArrayByObjectField} from '../helpers/sort';

const HelpArea = ({table}) => {
    const text = {
        "announcements": [
            translate("Announcements from the ship management to passengers, staff or crew can be made here."),
            translate("You need admin rights to post an announcement here."),
            translate("If you do not have admin rights, please use the pinboard instead."),

        ],
        "pinboard": [
            translate("You must be logged in and connected to the cruise to post here."),
            '',
            translate("Here you can publish texts or documents."),
            translate("Feel free to share interesting links with your fellow travelers."),
            translate("Please respect the copyright and personal rights.")

        ]
    };
    return(
        <div>
            <p>
                {text[table].map((t,idx) => {return <span key={idx}>{t}<br/></span>})}
            </p>
        </div>
    )
}

const CruiseAreaCardAnnouncements = ({table}) => {
    const currentCruise = useSelector(c => c.currentCruise);
    const currentUser = useSelector(c => c.currentUser);
    const [showFormNew, setShowFormNew] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState();
    const dispatch = useDispatch();

    const deleteAnnouncementPinboard = async() => {
        const {id} = toBeDeleted.dataset;
        setConfirmation(false);
        dispatch(deleteAnnouncementPinboardOnApi({table, id}));
      }

    const handleDelete=(e)=>{
        setToBeDeleted(e.target)
        setConfirmation(true)
    }
    const handleCancel = () => setConfirmation(false)
    const itemList = currentCruise && currentCruise[table];
    const userCruise = currentUser && currentUser.cruises && currentUser.cruises.find(v => v.cruiseId===currentCruise.id)
    const ucStatus = userCruise && userCruise.cruiseStatus
    const newList = itemList && sortArrayByObjectField(itemList, "updatedAt").reverse().filter(v => {
        return ((v.visibleCrew && ucStatus ===  "crew") 
        || (v.visibleStaff && ucStatus === "staff") 
        || (v.visibleGuests && ucStatus === "guest")
        || (v.createdBy === currentUser.username)
        || (v.updatedBy === currentUser.username))
    });
    const mayEditCruise = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adCr', currentCruise.companyHandle):false;
    const mayAddItem = mayEditCruise || (table ==="pinboard" && userCruise);
    const btnText = table === "pinboard" ? translate("Add Pinboard Item") : translate("Add Announcement")
    const tableName = translate(table[0].toUpperCase() + table.substring(1));
    // console.log({table, tableName, itemList});
    const MainArea = () =>{
            return(
            <div>
                    <h4>{tableName}</h4>
                    <HelpArea  className = "m-3" table = {table}/>
                    {newList && newList.length === 0 && <p>{translate("No items")}.</p>}
                    
                    {mayAddItem && <button onClick={() => setShowFormNew(!showFormNew)} className="btn btn-outline-dark">{btnText}</button>}
                    
                    {showFormNew && <AnnouncementNewForm table={table} setShowFormNew={setShowFormNew}/>}
                    <ul>
                        {newList  && 
                            newList.map((a, idx) => {
                                return(
                                    <div key={a.id}>
                                        <CruiseAreaCardAnnouncementsItem a={a} idx={idx} key={a.id} handleDelete={handleDelete} table={table}/>
                                    </div>
                                )
                            })
                        }
                    </ul>
            </div>
        )
    }
    return(
        <>
            {confirmation && <ConfirmationArea question={`Are you sure to delete this item? `} handleDelete= {deleteAnnouncementPinboard} handleCancel={handleCancel}/>}
            {!confirmation && <MainArea />}
        </>
    )

};

export default CruiseAreaCardAnnouncements;