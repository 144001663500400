import { translate } from "../../components/Language/Translate";
import { getNewDateObject } from "../../helpers/Date";

class ObservationSchema {
  constructor(species = []) {
    this.species = [{ key: 0, value: translate("Please select") }, ...species];
    this.speciesOptions = this.species.map((s) => {
      return { key: s.id, value: s.deDe || s.enUk || "?" };
    });
    this.fields = {
      dateTime: {
        label: translate("Date and Time"),
        initialValue: getNewDateObject(new Date()),
        type: "datetime-local",
        fieldType: "dateTime",
        required: true,
      },
      geolocation: {
        label: translate("Geolocation"),
        initialValue: "",
        type: "text",
        fieldType: "geolocation",
        required: true,
        placeholder: `${translate("Please use Format")}: 60.472024, 8.468946`,
      },
      destinationId: {
        initialValue: 0,
      },
      albumId: {
        initialValue: 0,
      },
      species: {
        label: translate("Input Species"),
        initialValue: "",
        type: "text",
        fieldType: "text",
      },
      speciesId: {
        label: translate("Species"),
        initialValue: 0,
        type: "select",
        fieldType: "checkBox",
        options: this.speciesOptions,
      },

      description: {
        label: translate("Description"),
        type: "text",
        initialValue: "",
      },
    };
  }
}

export default ObservationSchema;
