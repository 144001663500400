import React, {useState, useEffect} from "react";
import {Row, Col, Spinner} from 'react-bootstrap';
import { translate } from "../../components/Language/Translate";
import { StyledDivWithBorder } from "../../components/styledComponents/divs";
import { simpleRequest } from "../simpleRequest";
import TourSchema from "./TourSchema";
import FormRowFields from "../FormRows/FormRowFields";
import { useSelector } from "react-redux";
import { getStringTour, getTemplates, templateKeys } from "./helpers";
import TemplateList from "./TemplateList";

const TourNew = ({action, backFunc, setTours}) => {
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [showTemplates, setShowTemplates] = useState(false);
    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);

    useEffect(() => {

        const initialData = () => {
            const _fields = new TourSchema(currentUser).fields;
            const _vars = {};
            Object.keys(_fields).forEach(k => _vars[k] = _fields[k].initialValue);
            setVariables({..._vars});
            setFieldSettings({..._fields});
        }
        const fetch = async() => {
            // console.log('inside fetch', isLoading)
            initialData();
            setTemplates([...await getTemplates(currentCruise, action)]);
            setIsLoading(false);
        }
        if(isLoading)fetch()
    }, [isLoading, action, currentCruise, currentUser]);

    useEffect(() => {
        if(!variables.analyseField)return;
        console.log(variables.analyseField);
        const difficultyObj = {
            "": {txt: "aktiv", num: 5},
            "": {txt: "aktiv", num: 5},
            "": {txt: "anspruchsvoll", num: 4},
            "": {txt: "anspruchsvoll", num: 4},
            "": {txt: "moderat", num: 3},
            "": {txt: "moderat", num: 3},
            "": {txt: "leicht", num: 2},
            "": {txt: "leicht", num: 2},
            "": {txt: "sehr leicht", num: 1},
            "": {txt: "sehr leicht", num: 1},
        }

        const analyseField = variables.analyseField.replaceAll("Landausug", "Landausflug");
        const includes1 = analyseField.includes('');
        const includes2 = analyseField.includes();
        let arr1 = [];
        if(analyseField.includes('')){
            arr1 = analyseField.split('')
        }else if(analyseField.includes('')){
            arr1=analyseField.split('')

        }else{}

        console.log({includes1, includes2, arr1});

        const positionBeforeDifficulty = arr1[0]?.length || 0;;
        const difficultyStr = analyseField.slice(positionBeforeDifficulty, positionBeforeDifficulty+5);
        const short = arr1[0]?.trim().replace('Landausflug', "LA").replace("\n", "") || "";
        const difficulty = difficultyObj[difficultyStr]?.txt;
        // const arr2 = analyseField.split('\n');
        const arr3 = analyseField.split(')\n');
        console.log({includes1, includes2, arr1, arr3}, arr3[0]);
        const title = ((arr3[0].slice(positionBeforeDifficulty + 5, arr3[0].length)).replaceAll('\n', ' ') +")").trim();
        const arr4 = analyseField.split('€');
        const prize = parseFloat(arr4[1]?.trim() || "0")*100;
        const description = arr3[1] && arr3[1].trim().replaceAll("\n", " ")
                                .replaceAll('Hinweise', "\nHinweise")
                                .replaceAll('Dauer:', '\nDauer:')
                                .replaceAll("Erklärungen", "\nErklärungen");
        setVariables(variables => {return{...variables, short, title, difficulty, prize, description}});
        // console.log({arr1, positionBeforeDifficulty, difficultyStr, short, difficulty, arr2, arr3, arr4, prize, title});
        
    }, [variables.analyseField])



    if(isLoading)return <Spinner/>

    const handleClick = e => {
        // console.log(e.target.parentElement)
        const _template = templates.find(t => t.id === +e.target.parentElement.id);
        const newObj = {};
        templateKeys.forEach(k => newObj[k] = _template[k]);
        setVariables({...variables, ...newObj});
    };
    const handleSubmit = async () => {
        // console.log({variables});
        const {destinationId} = action;
        const {companyHandle} = currentCruise;
        const data = {...variables, dayId: action.id};
        delete data.analyseField;
        const templateExists = !!templates.find(t => (getStringTour(t) === getStringTour(data)));
        const tour = (await simpleRequest({url:"tours", method:"post", data})).data?.tour;
        if(!tour)return;
        setTours(tours => [...tours, tour]);
        if(!templateExists){
            const dataTemplate = {companyHandle, destinationId};
            templateKeys.forEach(k => dataTemplate[k] = variables[k]);
            const tourTemplate = (await simpleRequest({url:"tourtemplates", method:"post", data:dataTemplate})).data?.tourTemplate;
            if(!tourTemplate)return;
            setTemplates(templates => [...templates, tourTemplate]);
        };
        backFunc();
    };

    const rowSet = {
        lg: [[12],[2,10], [2,2,5,2], [4,4,4], [12]], 
        sm: null,
    }

    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError,
        rowSet
      };

      console.log({props});

    return(
        <StyledDivWithBorder>
            <h4>{translate("Add Tour")} </h4>
            <button className="btn btn-outline-dark" onClick={() => setShowTemplates(!showTemplates)}>{translate("Templates")}</button>
            {showTemplates && <TemplateList templates={templates} handleClick={handleClick} setTemplates={setTemplates} />}
            
            <FormRowFields props= {props}/>
            <Row>
                <Col>
                    <button className="btn btn-info col-12" onClick={handleSubmit}>{translate("Submit")}</button>
                </Col>
                <Col>
                    <button className="btn btn-secondary col-12" onClick={backFunc}>{translate("Cancel")}</button>
                </Col>
            </Row>
        </StyledDivWithBorder>
    )

};
export default TourNew;