import React, {useState, useEffect} from "react";
import {useParams, useHistory} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux";
import {Image, Accordion} from "react-bootstrap";
import { simpleRequest } from "../newForms2/simpleRequest";
import {ensureAuthorized} from '../helpers/auth';
import { fetchCompanyFromAPI, updateCompanyOnAPI } from "../actions/currentCompany";
import './CompanyDetails.css';
import ConfirmationArea from './ConfirmationArea';
import CompanyVesselListCard from "./CompanyVesselListCard";
import {translate} from "./Language/Translate";
import Spinner from './Spinner';
import UploadAreaSingle from "../newForms2/upload/UploadAreaSingle";
import CompanyCrewListCard from "./CompanyCrew/CompanyCrewListCard";
import CompanyEdit from '../newForms2/companies/CompanyEdit';


const CompanyDetails = () => { 
   
    const handle = useParams().handle || "hlc";
    // console.log({handle});
    const currentUser = useSelector(st => st.currentUser);
    const currentCompany = useSelector(st => st.currentCompany);
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true);
    const [confirmation, setConfirmation] = useState(false);
    const [uploadArea, setUploadArea] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const [showFormEdit, setShowFormEdit] = useState(false);

    const history = useHistory();
    
    useEffect (()=>{
        const fetchData = async ()=>{
            dispatch(fetchCompanyFromAPI(handle));
            (() => setIsLoading(false))();
        };
        if (isLoading)fetchData();
    },[isLoading, dispatch, handle]);

    useEffect(() => {
        
        // dispatches run when uploadData change and uploadData are not empty
        const updateUrl = () => {

            if(uploadData.data && (uploadData.data.logoUrl || uploadData.data.logoUrl === '')){
                // console.log("Jetzt auf API und im State ändern:", uploadData);
                dispatch(updateCompanyOnAPI({handle: currentCompany.handle, data:{logoUrl:uploadData.data.logoUrl}}));
                (() => setUploadData(null))();
            };
        };
        if(uploadData)updateUrl();
        (()=> setUploadArea(false))();
    },[uploadData, setUploadData, dispatch, currentCompany]);

    if (!(currentCompany && currentCompany.handle) || !currentUser) return <Spinner/>;

    
    // console.log({currentUser, currentCompany})

    // console.log({showFormEdit})

    const handleDelete = async () =>{
            const response = await simpleRequest({method:"delete", url:`companies/${handle}`, data:{}});
            // console.log(response.data);
            history.push('/companies');
        };


    const handleConfirm = () => setConfirmation(true);
    const handleCancel = () => setConfirmation(false);
    const mayDeleteCompany = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, 'adCo'):false;
    const mayEditCompany = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adVe', handle):false;
    // const mayAddVessel = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adVe', handle):false;
    const txtLogoUpdateButton = currentCompany.logoUrl?translate("Replace/Remove Logo"):translate("Add Logo");

    return (
        <div className="CompanyDetails p-4 text-center" style={{backgroundColor:"white"}}>
            {currentCompany.logoUrl && <div style={{height:"20vh", width:"auto"}} className="align-items-bottom mb-5"> 
                      <Image src={currentCompany.logoUrl} alt={currentCompany.name} className="img-fluid" style={{width:"auto", height:"100%"}} />
            </div>}
            <h4>{currentCompany.name}</h4>
            <h5>{currentCompany.suffix}</h5>
            <p>{currentCompany.note}</p>

            {confirmation?<ConfirmationArea 
                            question={`${translate("Are you sure")}?`}  
                            handleDelete={handleDelete} 
                            handleCancel={handleCancel} />:null}
            
            <div className="row my-3">
                {!confirmation && mayEditCompany?
                    <>
                    <div className="col-md-4 my-2">
                        <button className="btn btn-outline-dark col-12" 
                           onClick={() => setUploadArea(!uploadArea)}>{txtLogoUpdateButton}</button>
                    </div>
                    <div className="col-md-4 my-2">
                        {/* <Link to={`/companies/${currentCompany.handle}/edit`} 
                                className="btn btn-outline-dark col-12">{translate("Edit Company")}
                        </Link> */}
                        <button className = "btn btn-outline-success col-12" onClick={() => setShowFormEdit(!showFormEdit)}>{translate("Edit Company")}</button>
                    </div>
                    </>
                        :null}
                {!confirmation && mayDeleteCompany ? 
                    <div className="col-md-4 my-2">
                    <button className="btn btn-outline-danger col-12" 
                       onClick={handleConfirm}>{translate("Delete Company")}</button>
                    </div>
                       :null}
                    {uploadArea && <UploadAreaSingle title="logoUrl" setUploadArea={setUploadArea} 
                        methods={["file", "url", "link"]} setUploadData={setUploadData} subFolder={`companies/${currentCompany.handle}`}/>}
                    
            </div>    
                {showFormEdit && <CompanyEdit setShowFormEdit={setShowFormEdit} company={currentCompany}/>}
            <div>
                <Accordion>
                    <CompanyVesselListCard eventKey="0" />
                    <CompanyCrewListCard eventKey="1"/>
                </Accordion>
            </div>
        </div>
    )
};

export default CompanyDetails;