import {translate} from '../../components/Language/Translate'

class CruiseCrewFormSchema{
    constructor(company){
        this.company = company;
        this.formType="cruiCrewNew";
        this.nextPage=""
        this.request = {
            url:"",
            method:"post",
        };
        this.formLabels = {title: translate("Add Crew"), btnLabel:translate("Add")};

        this.fields = {

                cruiseId : {
                label:"Cruise ID",
                value : '',
                fieldType : 'info',
                type:"number"
            },


                position : {
                label:"Position",
                value : "",
                required:true,
                fieldType : 'text',
                type:"text"
            },
            companyCrewId: {
                label:"Name",
                value : -1,
                fieldType : 'select',
                type:"number",
                required:true,
                options: this._getOptions(),
                toNumber:true,
                validation:{mustNotBeEqualTo:'0'}
            }
        };
       

    };
        _getOptions(){
            // console.log(this.company);
            const cc = this.company && this.company.companyCrew;
            if(!cc)return [];
            const options = cc.map(cc => {return {key:cc.id, value:`${cc.firstName} ${cc.lastName}`}});
            options.unshift({key:-1, value:" - Please select - "});
            return options;
            
    };
};
export default CruiseCrewFormSchema;