import {translate} from "../../components/Language/Translate"

class UserSchema{
    constructor(companies=[], currentUser={userStatus:"user"}){
        this.currentUser=currentUser;
        this.companies=companies;
        this.fields = {
            username:{
                initialValue:"",
                label:translate("Username"),
                fieldType:"text",
                type:"text",
                autoFocus:true,
                required:true,
                noUpdate:true,
                validation:{minLength:8, maxLength:15, noSpace:true, mustNotContain:"@"},
                toolTip:translate("An unique name for login, etc.")
            },
            nickname:{
                initialValue:"",
                label:translate("Displayed Name"),
                required:true,
                validation:{minLength:3, maxLength:40},
                fieldType:"text",
                type:"text",
                toolTip:translate("This is the name, that will be shown in your profile. It can be your full name, or e.g. an artist name.")
            },
            firstName:{
                initialValue:"",
                label:translate("First Name"),
                required:true,
                validation:{minLength:2, maxLength:25, noNumbers: true},
                fieldType:"text",
                type:"text",
                toolTip:translate("Not visible for other users.")
            },
            lastName:{
                initialValue:"",
                label:translate("Last Name"),
                required:true,
                validation:{minLength:2, maxLength:25, noNumbers: true},
                fieldType:"text",
                type:"text",
                toolTip:translate("Not visible for other users.")
            },
            language:{
                initialValue:"enUK",
                label:translate("Language"),
                required:true,
                validation:{minLength:4, maxLength:5, noSpace:true},
                options: [
                    {key:"deDE", value:"Deutsch"}, 
                    {key:"enUK", value:"English"}
                ],
                fieldType:"select",
                type:"text"
            },
            email:{
                initialValue:"",
                label:translate("Email"),
                required:true,
                validation:{minLength:8, maxLength:40, isEmail:true},
                fieldType:"text",
                type:"email",
                toolTip:translate("Not visible for other users.")
            },
            confirmEmail:{
                initialValue:"",
                label:translate("Confirm Email"),
                required:true,
                validation:{mustBeEqualTo:"email"},
                fieldType:"text",
                type:"email",
                toolTip:translate("Not visible for other users.")
            },
            photoUrl:{
                initialValue:"",
                label:translate("Photo URL"),
                required:false,
                validation:{isUrl:true},
                fieldType:"text",
                type:"url"
            },
            password:{
                initialValue:"",
                label:translate("Password"),
                required:true,
                validation:{minLength:8, maxLength:15, noSpace:true},
                fieldType:"text",
                type:"password"
            },
            confirmPassword:{
                initialValue:"",
                label:translate("Confirm Password"),
                required:true,
                validation:{minLength:8, maxLength:15, noSpace:true, mustBeEqualTo:"password"},
                fieldType:"text",
                type:"password"
            },
            userStatus:{
                initialValue:"user",
                label:translate("User Status"),
                fieldType:"select",
                type:"text",
                options:this.getStatusOptions(),
                required:true
            },
            companyHandle:{
                initialValue:"*",
                label:translate("Company"),
                required:true,
                fieldType:"select",
                type:"text",
                options:this.getCompanyOptions()
            },

            usernameOrEmail:{
                initialValue:"",
                label:translate("Please insert username or email address"),
                fieldType:"select",
                type:"text",
                required:true
            },
            confirmationCode:{
                initialValue:"",
                label:translate("Confirmation Code"),
                fieldType:"textArea",
                type:"text"
            },


        };

    };
    getStatusOptions(){
        const status = this.currentUser.userStatus;
            const coSubAdmin=[{key:"coStaffAdmin", value:"Company Staff Admin"},{key:"user", value:"user"}];
            const coAdmin=[{key:"coSubAdmin", value:"Company Sub Admin"}, ...coSubAdmin];
            const coMainAdmin=[{key:"coMainAdmin", value:"Company Main Admin"}, {key:"coAdmin", value:"Company Admin"}, ...coAdmin];
            const admin=[...coMainAdmin];
            const developer=[{key:"developer", value:"Developer"},{key:"admin", value:"Admin"}, ...admin];
            if (status.includes("|coSubAdmin"))return coSubAdmin;
            if (status.includes("|coAdmin"))return coAdmin;
            if (status.includes("|coMainAdmin"))return coMainAdmin;
            if(status==="admin")return admin;
            if(status==="developer")return developer;
        return developer;

    }
    getCompanyOptions(){
        const opts = this.companies?.map(c => {return {key: c.handle, value:c.name}}) || [];
        const options = ["admin", "developer"].includes(this.currentUser?.userStatus)
            ? [{key:"*", value:translate("- Please select -")},...opts]
            : [...opts.filter(o => this.currentUser?.userStatus.includes(o.key))];
        return options;
    }
};
export default UserSchema;