import React from 'react';
import CruiseListYearCard from './CruiseListYearCard';
import {Accordion} from 'react-bootstrap';
import {getYearsFromCruises} from './CruiseListFunctions';


const CruiseListPagination = ({cruises, period, headLine}) => {

    const ys = getYearsFromCruises(cruises);
    // console.log(headLine)
    const years = headLine === "Past" ?  ys.reverse() : ys;
    return(

            <div style={{backgroundColor:"rgba(255,255,255,.5)"}}> 
                <Accordion >
                    {years.map((y,idx) => <CruiseListYearCard cruises = {cruises} year = {y} key={idx} eventKey={idx+1} idx={idx} period={period}/>)}
                </Accordion>
            </div>

            

    
    )
}


export default CruiseListPagination;