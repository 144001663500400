import React from "react";
import TourDetails from "./TourDetails";
import { getStringTour } from "../../newForms2/tours/helpers";

const TourListItem = ({tour, openTour, setOpenTour, setTours, action}) => {
    // console.log(tour)
    
    
    const ListItem = () => {
        const className = `text-left ${tour.cancelled ? "text-danger" : "text-success"}`
        return(
            <li onClick={() => setOpenTour(tour.id)} className={className}>
                {`${tour.cancelled ? `clx: `:""} ${getStringTour(tour)}`}
            </li>

        )
    };
    return(
        <>
            {openTour === tour.id ? <TourDetails tour={tour} backFunc={() => setOpenTour(0)} setTours={setTours} action={action}/> : <ListItem/>}
        </>
    )
};
export default TourListItem;