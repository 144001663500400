import React, { useState } from "react";
import ConfirmationArea from "../../ConfirmationArea";
import { useSelector, useDispatch } from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import {Form} from 'react-bootstrap'
import { addCommentOnApi, deleteFollowOnApi } from "../../../actions/currentDestination";
import { getDateObject } from "../../../helpers/Date";
import {translate, Translate} from '../../Language/Translate';
import AddComment from "./AddComment";

const FollowItem = ({f, currentUser}) => {
    const [commentArea, setCommentArea] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const dispatch = useDispatch();
    const handleAddComment = () => setCommentArea(true);
    const handleClickDelete = () => {setConfirmation(true)};
    const handleCancel = () => {setConfirmation(false)};
    const language = useSelector(st => st.currentUser)?.language || Translate.language;
    const updated = getDateObject((f.updatedAt || f.createdAt), language).short;
    
    const handleDelete = async () => {
        setConfirmation(false);
        dispatch(deleteFollowOnApi(f.id));
    };
    const MainArea = () => {
        return(
        <div className="p-3 mt-1" style={{border:"1px solid grey", borderRadius:"5px"}}>
            {(f.comment || currentUser.username === f.createdBy) && <p>{translate("posted by")} {f.nickname} {translate("on")} {updated}</p>}
            {f.comment && <p style={{fontFamily: 'Caveat', fontSize:"22px"}}><i>" {f.comment} "</i></p>}
            {!commentArea && currentUser.username === f.createdBy && <button onClick={handleAddComment} className="btn btn-outline-dark m-1">{translate("Add/Edit Comment")}</button>}
            {!commentArea && currentUser.username === f.createdBy && <button onClick={handleClickDelete} className="btn btn-outline-danger m-1">{translate("Delete Comment and Confirmation")}</button>}
            {commentArea && <AddComment follow={f} setCommentArea={setCommentArea}/>}
        </div>
        );
    }
    return(
        <>
            {!confirmation && <MainArea/>}
            {confirmation && <ConfirmationArea question="Are you sure to delete this comment?" 
                  handleCancel={handleCancel} handleDelete={handleDelete}/>}
        </>
    );
};
export default FollowItem;