import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import {Card} from 'react-bootstrap';
import ConfirmationArea from "../ConfirmationArea";
import ArticleList from "./ArticleList";
import Spinner from '../Spinner';
import UploadAreaSingle from "../../newForms2/upload/UploadAreaSingle";
import { translate } from "../Language/Translate";
import {useSelector, useDispatch} from "react-redux";
import { fetchAuthorFromAPI, updateAuthorOnAPI } from "../../actions/currentAuthor";
import FormatText from "../../helpers/FormatText";


const AuthorDetails = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [confirmation, setConfirmation] = useState(false)
    const {username} = useParams()
    const [uploadArea, setUploadArea] = useState(false);
    const [uploadData, setUploadData] = useState({});
    const currentAuthor = useSelector(st => st.currentAuthor);
    const currentUser = useSelector(st => st.currentUser);
    const dispatch = useDispatch();
    
    
    useEffect(()=>{
        const fetch=async()=>{
            if(isLoading){
                dispatch(fetchAuthorFromAPI(username));
                (() => setIsLoading(false))();
            };
        };
        fetch()
    }, [isLoading, username, setIsLoading, dispatch]);

    useEffect(() => {
        const updateUrl = async () => {
            dispatch(updateAuthorOnAPI({username: currentAuthor.username, data:{photoUrl:uploadData.data.photo}}));
            (() => setUploadData(null))();
        };
        if(uploadData && uploadData.method)updateUrl();
        (()=> setUploadArea(false))();
    },[uploadData, setUploadData, dispatch, currentAuthor]);

    if(isLoading)return <Spinner/>
    const admin = ["developer", "admin"].includes(currentUser.userStatus);

    // console.log({currentAuthor});
    const photo = currentAuthor.photoUrl || currentAuthor.userPhoto || null;
    // const 

    const userAuthor = currentAuthor.username === currentUser.username;
    // console.log({currentAuthor, currentUser, userAuthor})
    const handleDelete = () => setConfirmation(true);
    const handleCancel = () => setConfirmation(false);
    const deleteAuthor = () => {console.log("DELETE!!!!")}
    
    const Main = () => {
        const txtBtnAddPhoto = currentAuthor.photoUrl ? translate("Replace/Remove Photo") : translate("Add Photo");
        const arr = Array.from(new Set(currentAuthor.subjects?.split(', '))) || [];
        const subjects = arr.sort().map(s => (s !== "" && <li key={s}>{s}</li>));

        return(
        
            <Card style={{backgroundColor:"rgba(255,255,255,.5"}}>
                <Card.Header>
                    <h4>{translate("Author")}: {currentAuthor.firstName} {currentAuthor.lastName} {currentAuthor.suffix && <small><i>({currentAuthor.suffix})</i></small>}</h4>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                        <div className="col-md-6 text-center">
                        {photo && <img src={photo} alt="img" style={{width:"70%", maxHeight:"auto"}}/>}
                        </div>
                        <div className='col-md-6'>
                            <h5>{translate("Biography")}:</h5>
                            {/* <div>{currentAuthor.bio} </div> */}
                            <FormatText str={currentAuthor.bio} />
                            <h5>{translate("Subjects")}:</h5>
                            {/* <p>{currentAuthor.subjects}</p> */}
                            <ul>{subjects}</ul>
                        </div>

                    </div>  
                </Card.Body>
                <Card.Header>
                    {(admin || userAuthor) && <button className="btn btn-outline-dark m-1" onClick={()=> setUploadArea(!uploadArea)}>{txtBtnAddPhoto}</button>}
                    {userAuthor && <Link to={`/articles/new`} className="btn btn-outline-dark m-1">{translate("Add Article")}</Link>}
                    {(admin || userAuthor) && <Link to={`/authors/edit/${currentAuthor.username}`} className="btn btn-outline-dark m-1">{translate("Edit Author")}</Link>}
                    {(admin || userAuthor) && <button className="btn btn-outline-danger m-1" onClick={handleDelete}>{translate("Delete Author")}</button>}
                </Card.Header>
                <Card.Header>
                    <ArticleList list={currentAuthor.articles} showAuthor={false}/>
                </Card.Header>
                {uploadArea && 
                    <Card.Footer>
                        <UploadAreaSingle title="photo" setUploadArea={setUploadArea} subFolder={`authors/${currentAuthor.username}`}
                                uploadData={uploadData} setUploadData={setUploadData}/>
                    </Card.Footer>
                }
            </Card>
        )
    }
    return(
        <>
            {!confirmation && <Main/>}
            {confirmation && <ConfirmationArea question=
                {`${translate("Are you sure you want to delete this author")}: ${currentAuthor.firstName} ${currentAuthor.lastName} ?`} 
                handleDelete={deleteAuthor} handleCancel={handleCancel}/>}
        </>
    )

};
export default AuthorDetails;
