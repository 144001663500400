import { translate } from "../../components/Language/Translate";
// import MedicalDepartments from "../../components/Destinations/MedicalDepartments";

class MedicalCommentFormSchema{
    constructor(){
        this.fields={
            points:{
                name:"points",
                label : translate("Points"),
                fieldType: "starSet",
                type:"number",
                value : 5 ,
                validation :{min: 1, max: 5},
                required: true
            },
            subject:{
                name:"subject",
                label : translate("Subject"),
                fieldType: "text",
                type:"text",
                value : "",
                validation :{maxLength: 50},

            },
            comment:{
                name:"comment",
                label : translate("Comment"),
                fieldType: "textarea",
                type:"textarea",
                value : "",
            },
        };
    };
};
export default MedicalCommentFormSchema;