import React, {useState, useEffect } from "react";
import './CompanyDetails.css';
import {useHistory} from "react-router-dom"
import { Card } from "react-bootstrap";
import ConfirmationArea from "./ConfirmationArea";
import {translate} from "./Language/Translate"
import UploadAreaSingle from "../newForms2/upload/UploadAreaSingle";
import {updateCompanyCrewOnAPI, deleteCompanyCrewOnAPI} from '../actions/currentCompany';
import {useDispatch, useSelector} from 'react-redux';
import { ensureAuthorized } from "../helpers/auth";
import CompanyCrewEdit from "../newForms2/companyCrew/CompanyCrewEdit";
import FormatText from "../helpers/FormatText";

const CrewListDetails = ({cc, setSwitcher}) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(st => st.currentUser);
    const currentCompany = useSelector(st => st.currentCompany);
    const [showEditForm, setShowEditForm] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [uploadArea, setUploadArea]= useState(false);
    const [uploadData, setUploadData] = useState({});
    const handleClick=()=>{setSwitcher(false)};
    const history = useHistory();
    const handleClickDelete = () => setConfirmation(true);
    const handleCancel = () => setConfirmation(false);
    
    const handleDelete=()=>{
            dispatch(deleteCompanyCrewOnAPI(cc.id));
            history.push(`/companies/${cc.companyHandle}`)
    };

    useEffect(() => {
        const updateUrl = async () => {
            if(uploadData.data && (uploadData.data.photoUrl || uploadData.data.photoUrl === '')){
                // console.log("Jetzt auf API und im State ändern:", uploadData);
                dispatch(updateCompanyCrewOnAPI({id: cc.id, data:{photoUrl:uploadData.data.photoUrl}}));
                (() => setUploadData(null))();
            };
        };
        if(uploadData)updateUrl();
        (()=> setUploadArea(false))();
    },[uploadData, setUploadData, dispatch, currentCompany, cc]);

    const mayEditCompany = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adVe', currentCompany.handle):false;
    const txtUploadButton = cc.photoUrl?translate("Replace/Remove Photo"):translate("Add Photo");
    
    const Buttons = () => (
        <>      
                {showEditForm && <CompanyCrewEdit id={cc.id} setShowEditForm={setShowEditForm}/>}
                <button className="btn btn-outline-dark m-1" onClick={handleClick}>{translate("Close")}</button>
                {mayEditCompany && <button className="btn btn-outline-dark m-1" onClick={()=>setUploadArea(!uploadArea)}>{txtUploadButton}</button>}
                {mayEditCompany && <button className="btn btn-outline-dark m-1" onClick={() => setShowEditForm(true)}>{translate("Edit")}</button>}
                {mayEditCompany && <button className="btn btn-outline-danger m-1" onClick={handleClickDelete}>Delete</button>}
        </>
    );
       
    return(
        <Card className="my-3">
            <Card.Header>
                <h3 className="text-center">{cc.firstName} {cc.lastName}</h3>
            </Card.Header>
            <Card.Body>
                <div className="row">
                <div className="col-6 text-center">
                    {cc.photoUrl && <img src={cc.photoUrl} style = {{width : "90%", height:"auto"}}
                          alt='' className="thumbnail"/>}
                </div>
                <div className="col-6 text-left">
                    <h5>{translate("Biography")}:</h5>
                    <FormatText str = {cc.bio} />
                </div>
                </div>
               </Card.Body>
            <Card.Footer>
                {!confirmation && <Buttons/>}
                {uploadArea && <UploadAreaSingle title="photoUrl" setUploadArea={setUploadArea} methods={["file", "url", "link"]} uploadData={uploadData} setUploadData={setUploadData}/>}
                {confirmation && <ConfirmationArea question={`Are you sure to delete ${cc.firstName} ${cc.lastName}?`}
                    handleDelete={handleDelete} handleCancel={handleCancel}/>}
            </Card.Footer>
        </Card>
    )
};
export default CrewListDetails;