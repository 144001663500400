import React from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import { translate } from "./Language/Translate";
import {BlackWhite} from './styledComponents/colors';
import ObservationMain from "./species/species/cruise/ObservationMain";


const CruiseObservationCard = ({eventKey, defaultKey}) =>{
    return(
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} onClick={() => defaultKey.current=eventKey}>
            <BlackWhite>{translate("Nature Observations")}</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey} id={eventKey} >
          <Card.Body>
              <div className="p-3">
                <h3>{translate("Nature Observations on this Cruise")}</h3>
                <ObservationMain/>
              </div>
          </Card.Body>
        </Accordion.Collapse>
    </Card>
    )
}

export default CruiseObservationCard;