
const user={editStatus:"", setStatus:"", setProps:"/seUP"}  // see UserProfile
const coStaffAdmin={editStatus:"", setProps:user.setProps+"/#anGu"} // announcements Guests
const coSubAdmin={editStatus:"", setProps:coStaffAdmin.setProps + '/#anCr/#dePC/#dePG/#adCr'} // announcements Crew, delete Pinnwand (Crew/Guest), add Cruise
const coAdmin={editStatus:"user, #coStaffAdmin, #coSubAdmin", setProps : coSubAdmin.setProps} // set Sub and Staff admin or back to user
const coMainAdmin={editStatus:coAdmin.editStatus + ", #coAdmin, #coMainAdmin", setProps : coSubAdmin.setProps + '/#adVe/'}; //set coMainAdmin or co Admin, add Vessel
const admin={editStatus: coMainAdmin.editStatus, setProps : coSubAdmin.setProps + '/#adVe/adCo/'} //add Company
const developer={editStatus: admin.editStatus+ ', admin, developer', setProps: admin.setProps }

const statusRights = {
    user, coStaffAdmin, coSubAdmin, coAdmin, coMainAdmin, admin, developer
}

const labels = {
    'user':"User",
    '#coStaffAdmin': "Company Staff Admin #",
    '#coSubAdmin': "Company Sub Admin #",
    '#coAdmin':"Company Admin #",
    '#coMainAdmin':'Company Main Admin #',
    'admin':"Admin",
    'developer':'Developer'
}

const getSelectListStatus = (userStatus, companies) => {
    // console.log('inside geSelectListStatus', {userStatus})
    let handle="";
    let status=userStatus;
    if (userStatus.includes('|')){
        handle=userStatus.split('|')[0];
        status=userStatus.split('|')[1];
    }
    let selectList = statusRights[status].editStatus.split(', ')
    // console.log({userStatus, selectList})
    
    const companyObj = {}
    companies.forEach(c=>companyObj[c.handle]=c.name)
    // console.log({companyObj})
    // console.log({handle});
        
    selectList = selectList.reverse().map(s=> {return{"key":s.replace('#',`${handle}|`), "value": labels[s].replace('#', handle?"("+companyObj[handle]+")":"(selected Company)")}})
    // console.log ({selectList})
    selectList.unshift({'key': "invalid", "value": "- Please select a status -"})
    return selectList;
};
    




const getSelectListCompanies = (userStatus, companies) => {
    // console.log('inside getSelectListCompanies', userStatus, companies)
    let result
    const selectAll = companies.map(c => {return{"key": c.handle, "value": c.name}})
    if (!["admin", "developer", "user"].includes(userStatus)){
        const companyHandle=userStatus.split('|')[0]
        result = selectAll.filter(c => c.key===companyHandle)
    } else result = selectAll;
    result.unshift({'key': "invalid", 'value': "- Please select a Company -"})
    return result;
}

const userRights = (userStatus="user") => {
    // console.log('inside userRights', {userStatus})
    
    const rightsArr=[];
    const editArr=[];
    userStatus.split(', ').forEach(v =>{
        let handle;
        let status;
        if (v.includes('|')){
            handle=v.split('|')[0]
            status=v.split('|')[1]
        }else{
            handle='';
            status =v
        }
            statusRights[status].setProps.split('/').forEach(r =>{
                r.length>0 && rightsArr.push(r.replace('#',(handle+"|")))
            })
            const arr = Array.from(new Set (statusRights[status].editStatus.split(', ').filter(r=>r.length>0)))
            arr.forEach(r=>{
                rightsArr.push(r.replace('#',(handle+'|')));
                let handleTxt=handle || "all companies";
                editArr.push({key:r.replace('#',(handle+'|')), value:(labels[r].replace('#', handleTxt))});
            })
    })
    
    return {rights:Array.from(new Set(rightsArr)),editStatus:editArr};
}

function ensureEditAllowed(user,otherUser){
    if(!user || !otherUser) return false;
    const userRightsUser = userRights(user.userStatus).rights
    const result = userRightsUser.some(v => (otherUser.userStatus?.includes(v)))
    return result;
}



function ensureGivenRight(userRightsArray, right, companyHandle){
    const search1= right.replace('#',(companyHandle+"|"));
    const search2= right.replace('#','|') //admin, developer for all companies;
    // console.log({search1, search2, userRightsArray, right, companyHandle})
    return userRightsArray.some(v=>v===search1 || v===search2);
}

function ensureAuthorized(userStatus, right, handle=""){
    const userRightArr = userRights(userStatus)
    // console.log({userRightArr,right,handle, userStatus})
    return ensureGivenRight(userRightArr.rights,  right, handle)
};


export  {ensureAuthorized, ensureEditAllowed, userRights, getSelectListCompanies, getSelectListStatus};





