import React from "react";
import {useSelector} from 'react-redux';
import {Accordion} from 'react-bootstrap';
import UserAreaCardFriendsCard from './UserAreaCardFriendsCard';
import {translate} from "./Language/Translate";
// import NewsBadge from './NewsBadge';

const UserAreaCardFriends = () =>{

    const currentUser = useSelector(st => st.currentUser)
    if(!currentUser.friends)return null
    const friends = currentUser.friends?.friends || [];
    const receivedRequests= currentUser.friends?.friendRequestsFrom || [];
    const sentRequests = currentUser.friends?.friendRequestsTo || [];
    const newsCount = receivedRequests.length;

    return(
        <div>
            <Accordion >
                <UserAreaCardFriendsCard currentUser={currentUser} eventKey='4' friends={friends} 
                        type = "friends" title={`${translate('Friends')} (${friends.length})`}/>
                <UserAreaCardFriendsCard currentUser={currentUser} eventKey='2' friends={receivedRequests} 
                        type="requestFrom" title={`${translate('Friend Requests for you')}`} newsCount={newsCount}/>
                <UserAreaCardFriendsCard currentUser={currentUser} eventKey='3' friends={sentRequests} 
                        type = "requestTo" title={`${translate('Your Friend Requests')} (${sentRequests.length})`}/>
            </Accordion>
        </div>
    )
};

export default UserAreaCardFriends;