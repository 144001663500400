import React from 'react';
import CruiseListItem from './CruiseListItem';
import {Accordion, Card, Button} from 'react-bootstrap';


const CruiseListMonthCard = ({eventKey, month, idx, cruises}) => {
  // console.log({eventKey, month, idx, cruises})

    const monthCruises = cruises.filter(c => {
        // const yearCruises = cruises.filter(c => {
            // console.log(c.startAt.slice(0,7), c.endAt.slice(0,7), month.longMonth, c.startAt.slice(0,7) <= month.longMonth && month.longMonth <= c.endAt.slice(0,7), '0000000000000000000000000000000')
            return (c.startAt.slice(0,7) <= month.longMonth && month.longMonth <= c.endAt.slice(0,7) )
          });

    // console.log({eventKey, month, monthCruises});
    const handleClick = () => {console.log('Clicked')}
    return(
        <Card className="text-left">
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" className="btn btn-outline-dark ml-3"  eventKey={eventKey} onClick={handleClick}>
            {month.monthString}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey} id={eventKey} >
          <Card.Body>
            <h4>{month.monthString}</h4>
            {monthCruises.map((c, idx) => <CruiseListItem key={idx} cruise = {c}/>)}
          </Card.Body>
        </Accordion.Collapse>
    </Card>
    )

};
export default CruiseListMonthCard;