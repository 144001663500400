// ÜBERARBEITEN
// 5.4.24
import React, {useEffect,useState} from 'react';
import ProposalsSchema from './ProposalsSchema';
import {Form, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { addProposalOnApi } from '../../actions/currentDestination';
import Spinner from '../../components/Spinner';
import {translate} from '../../components/Language/Translate'
import FormFields from '../FormFields';
import { simpleRequest } from '../simpleRequest';

const ProposalsEdit = ({id, setView}) => {
    const currentDestination = useSelector(st => st.currentDestination);
    const [variables, setVariables] = useState();
    const [fieldSettings, setFieldSettings] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();

    useEffect(()=>{
        const fetch = async () =>{
            const _proposal = (await simpleRequest({url:`proposals/${id}`})).data;
            // console.log({_proposal});
            const fields = new ProposalsSchema();
            const vars = {};
            Object.entries(fields).forEach(f => vars[f[0]] = f[1].value);
            (() => setFieldSettings({...fields}))();
            (() => setVariables({...vars}))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, serverError])
    
    if(isLoading)return <Spinner/>
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        dispatch(addProposalOnApi(currentDestination.id, variables));
        (() => setView("items"))();
    };

    const props = {
        setView,     
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError, 
        addressVar:"place"
    }
    // console.log('inside ProposalsNewForm', props);
    return (
        <div className="text-center">
          <h4 >{translate('Edit Proposal')}</h4>
          <Form onSubmit = {e => handleSubmit(e)}>
            <FormFields props = {props}/>
            <Form.Row>
                <Col>
                    <button className = "btn btn-outline-dark col-12" type="submit" >{translate("Add")}</button>                
                </Col>
                <Col>
                    <button className = "btn btn-outline-secondary col-12" onClick={() => setView("items")} >{translate("Cancel")}</button>                
                </Col>
            </Form.Row>
        </Form>
        </div>
    );
};

export default ProposalsEdit;