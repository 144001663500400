import { names } from "./names";

class AlbumObj{

        static expanded = [];
        static renew(){
                return new Promise((resolve, reject) => {
                        names.forEach(n => {
                        n.keys.forEach(k => {
                        const longKey = k.replace('#', ', ')
                        AlbumObj.expanded.push(
                                {
                                        key: k.split('#')[0],
                                        longKey: k.replace('#', ', '),
                                        deDE: n.deDE?.replace('#', longKey) || n.enUK.replace('#', longKey),
                                        enUK: n.enUK.replace('#', longKey),
                                })
                        })
                           resolve( "done");     
                })

        })}
        static async buildExpanded(){
                return new Promise((resolve, reject) => {
                        if(AlbumObj.expanded.length > 0){
                        // console.log('Nicht neu einlesen', AlbumObj.expanded.length)
                        resolve('nicht neu eingelesen');
                        }else{
                                this.renew()
                                .then(resolve("neu eingelesen"));
                        }
        })};
        static analyze(album, language){
                let result=album.titleEN;
                this.expanded.forEach(e => {
                    if (album.titleDE === e.key || album.titleEN === e.key) result = e[language];
                });
                return result;
        }
        static async init(){
                // console.log('inside init')
                await this.buildExpanded()
        }
};

(() => AlbumObj.init())();


export default AlbumObj;