import React, {useState, useEffect} from "react";
import FirstStepsNotLoggedIn2 from "../FirstSteps2/FirstStepsNotLoggedIn2";
import {translate} from '../Language/Translate';
import {HelpText1, HelpText2} from './HelpTexts';
  
function StartPageEmpty2({setShowStartPage, showStartPage, next}) {
  const [showHelp, setShowHelp] = useState(0);
  
  useEffect(() => {if(next)setShowStartPage(false)})

    return (
    <div>
        <div style = {{height:"5%"}}></div>
        <div style = {{height:"25%"}} className="p-5">
            <h1 className="App-title display-5"><i>Cruisefriends</i>.net</h1>
            <h5><i>{translate("The Innovative Network For Friends of the Cruise")}</i></h5>
        </div>
        <div style = {{height:"25%"}} className="p-5">
            <div style={{fontFamily: 'Kalam', color:"#4c332d"}}>
               <h2 ><i>{translate("Meet friends again")}</i></h2>
               <h2 className = "ml-3"><i>{translate("Find travel partners")}</i></h2>
               <h2 className = "ml-5"><i>{translate("Stay in Contact")}</i></h2>
            </div>
        </div>
        <div style = {{height:"25%"}} className="p-5">
            <div className="row col-12 ">
             <div className="col-md-6 p-2">
                 <button style={{backgroundColor:"rgba(255,255,255,.3"}} className="btn btn-outline-dark col-12" onClick={() => setShowHelp(1)}>
                   {translate("What is Cruisefriends.net")}?
                 </button>
             </div>
             <div className="col-md-6 p-2">
               <button style={{backgroundColor:"rgba(255,255,255,.3"}} className="btn btn-outline-dark col-12" onClick={() => setShowHelp(2)}>
                 {translate("How it works")}
               </button>
            </div>
             <div className = "p-2 col-12">
                  <button style={{backgroundColor:"rgba(255,255,255,.3"}} className="btn btn-outline-dark col-12" onClick={() => setShowStartPage(false)}>
                      <b>{translate("Try out Cruisefriends.net")}</b>
                  </button>
              </div>
             <div style={{height:"auto"}}>
                {showHelp === 1 && <HelpText1 setShowHelp={setShowHelp}/>}
                {showHelp === 2 && <HelpText2 setShowHelp={setShowHelp}/>} 
             </div>
             {!showStartPage && <FirstStepsNotLoggedIn2/>}
           </div>
        </div>
    </div>
  );
}

export default StartPageEmpty2;