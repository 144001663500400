
export const buildVariables = (speciesObservations, language) => {
    const variables = {all: true, speciesGroups:{}}
    speciesObservations.forEach(so => {
        const groupName = so[`group${language}`];
        const speciesName = so[language];
        if(!Object.keys(variables.speciesGroups).includes(groupName)){
            variables.speciesGroups[groupName] = {id:so.speciesGroupId, name:groupName, checked:true, species:{}};
        };
        variables.speciesGroups[groupName]["species"][speciesName] = {name: so[language], id:so.speciesId, checked:true};
    });
    return variables;
};