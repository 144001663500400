import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { translate } from "../../components/Language/Translate";
import GroupMemberNewItem from './GroupMemberNewItem';
import {Form} from "react-bootstrap";
import { addDeleteMultipleGroupMembersOnApi } from "../../actions/groups";
// import Globals from "../../helpers/globals";
// import { setGlobalVarToState } from "../../actions/globals";

// const GroupMembersNew = ({group, setShowFormMemberNew}) =>  {
const GroupMembersNew = ({group, setShowForm}) =>  {
    // const {showNewForm, setShowNewForm, showDetails, setShowDetails, showFormMemberNew, setShowFormMemberNew} = stateVars;
    // const {showFormMemberNew} = Globals.groupSettings;
    const [deleteArray, setDeleteArray] = useState([]);
    const [addArray, setAddArray] = useState([]);
    const dispatch = useDispatch();
    const currentCruise = useSelector(st => st.currentCruise);
    const users = currentCruise.users || [];
    const members = group.members.sort(m => m.nickname);
    const others = users
        .filter(u => !(group.members.map(m => m.username)).includes(u.username))
        .map(u => {return{username:u.username, nickname:u.nickname}})
        .sort(u => u.nickname);
    // console.log({deleteArray, addArray, members, others});
  
    
    const handleSubmit = async e => {
        e.preventDefault();
        if (addArray.length === 0 && deleteArray.length===0) return
        const data = {addMembers:addArray.map(nm => nm.username), deleteMembers: deleteArray};
        // console.log({data});
        dispatch(addDeleteMultipleGroupMembersOnApi(group, data));
        
        
    };
    const MembersToggle = ({m}) => {
        // console.log({deleteArray});
        const toBeDeleted = deleteArray.map(m => m.id).includes(m.id);
        const color = toBeDeleted ? "danger" : m.username === group.admin ? "secondary" : "dark";
        const className = `text-${color}`;
        
        const handleClick = () => {
            if(m.username === group.admin){
                // console.log('is admin');   
                return
            }
            
            (() => setDeleteArray(toBeDeleted ? deleteArray.filter(d => {
                  return d.id !== m.id
            }) : [...deleteArray, {id:m.id, username:m.username}]))();
        }
        return(
            <>
                {!toBeDeleted && 
                    <li key={m.username} className = {className} onClick={handleClick}>
                        {m.nickname || m.username}{m.username === group.admin && ` (Admin)`}
                    </li>}
                {toBeDeleted && 
                    <li key={m.username} className = {className} onClick={handleClick}>
                        <del>{m.nickname || m.username}{m.username === group.admin && ` (Admin)`}</del>
                    </li>}
            </>
        )
    }

    return(
        <div className="mt-3 p-3" style={{border:"1px solid grey", borderRadius:"5px"}}>
            <h4>{translate("Add Group Members")}</h4>
            <h5>{translate("Members of")} {group.name} <small>({translate("Select to remove from group")})</small></h5>
            <ul className="text-left">
                {members.map(m => <MembersToggle m={m} key={m.id}/>)}
            </ul>
            <h5>{translate("Other Follow Travelers")} <small>({translate("Select to add to group")})</small></h5>
            <Form onSubmit={handleSubmit}>
                <div className="text-left">
                    {others.map(o => <GroupMemberNewItem key={o.username} other={o} addArray={addArray} setAddArray={setAddArray}/>)}
                </div>
                <div className="row">
                <div className="col-6">
                    <button className="btn btn-outline-dark col-12" onClick={handleSubmit}>{translate("Submit")}</button>
                </div>
                <div className="col-6">
                    <button className="btn btn-outline-secondary col-12" onClick={() => setShowForm('none')}>{translate("Back")}</button>
                    {/* <button className="btn btn-outline-secondary col-12" onClick={() => setShowFormMemberNew(false)}>{translate("Back")}</button> */}
                </div>
            </div>
            </Form>
        </div>

    )

}
export default GroupMembersNew;