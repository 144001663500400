import React, {useEffect, useState} from "react";
import { translate } from "../../components/Language/Translate";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch} from "react-redux";
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields"
import CompanySchema from "./CompanySchema";
import FormValidation from '../Validation/FormValidation';
import { updateCompanyOnAPI } from "../../actions/currentCompany";

const CompanyEdit = ({company, setShowFormEdit}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [serverError, setServerError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
    // console.log(setServerError);

    // console.log('inside CompanyEdit in newforms2')

    useEffect(() => {
        // console.log('inside useEffect');
        const fetch = () => {
            const sendData = ["name", "suffix", "note"];
            const sets=new CompanySchema();
            (() => setFieldSettings({...sets.fields}))();
            const vars = {}
            sendData.forEach(d => vars[d] = company[d] || "");
            (()=> setVariables(vars))();
            (()=> setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, setFieldSettings, setVariables, setIsLoading, company]);
 
    if(isLoading) return <Spinner />
    // console.log({variables})

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formValidation = new FormValidation({fieldSettings, variables});
        if(!formValidation.valid)return;
        await dispatch(updateCompanyOnAPI({handle:company.handle, data:{...variables}}));
        (() => setShowFormEdit(false))();
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div className="my-3 p-2" style={{border:"1px solid grey", borderRadius:"5px"}}>
            <hr/>
            <h4>{translate("Edit Company")}</h4>
            <p>Handle: {company.handle}</p>
                 <Form onSubmit = {e => handleSubmit(e)}>
                    <FormFields props={props}/>
                    <Button block size="lg" type="submit" >{translate("Update Company")}</Button>
                </Form>
        </div>
    )
};
export default CompanyEdit;


