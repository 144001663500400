import React from "react";
import {Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
import PreviewArea from "./PreviewArea";

const FileInput = ({data, setData, maxCount=1}) => {
    // console.log({data, maxCount})
    
    const handleChange = e => {
        e.preventDefault();
        const f = Array.from(e.target.files);
        // console.log({f});
        if(!f.length)return;
        const files = f.length > maxCount ? f.slice(0,maxCount) : f;
        (() => setData({...data, selectedFiles:[...files]}))();
        // (() => setData({...data, selectedFiles:[]}))();
        // (() => setData({...data, selectedFiles:Object.values(files)}))();
    }

    return(
    <>
        <Form.Group size="lg" controlId={"input"} className="my-2">
                        <Form.Label>{`${translate("Please select file/files")} (max ${maxCount})*`} </Form.Label>
                        <Form.Control 
                             type="file"
                             key="input"
                             name="inputFile"
                             multiple="multiple"
                             onChange={e => handleChange(e)}
                             autoFocus={true}
                        />
        </Form.Group>
        <PreviewArea data={data}/>
    </>)
}
export default FileInput;