import React from 'react';
// import { useHistory } from 'react-router-dom';
import {translate} from "./Language/Translate"

const NotFound = () =>{
    // console.log('inside Not Found');


    return(
        <h3>404 - {translate('Not found.')}</h3>
    )
};

export default NotFound;