
import {
  FETCH_VESSELS, 
  UPDATE_VESSEL, 
  DELETE_VESSEL
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchVesselsFromAPI() {
  // console.log('inside fetchVesselsFromAPI')
  return async function (dispatch) {
    const response = await simpleRequest({url:`vessels`, method:"get"});
    const vessels = response.data && response.data.vessels;
    return dispatch(getVessels(vessels));
  };
};

function getVessels(vessels) {
  // console.log('inside action', {vessels})
  return {
    type: FETCH_VESSELS,
    vessels,
  };
};


// update vessel in vessel-list
export function updateVesselOnAPI(vesselId, data){
  // console.log('inside updateVesselonAPI,', vesselId, data)
  return async function(dispatch){
    const response = await simpleRequest({url:`vessels/${vesselId}`, method:"patch", data});
    const vessel = response.data && response.data.vessel
    // console.log({vessel});
    return dispatch(updateVessel(vessel));
  };
};
function updateVessel(vessel){
  return{
    type: UPDATE_VESSEL,
    vessel
  };
};

export function deleteVesselFromAPI(vesselId){
  return async function (dispatch){
    const response = await simpleRequest({url: `vessels/${vesselId}`, method:"delete"});
    const deleted = response.data && response.data.deleted;
    // console.log({deleted});
    return dispatch(deleteVessel(deleted))
  };
};
function deleteVessel(deleted){
  return{
    type: DELETE_VESSEL,
    deleted
  };
};