import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import { translate } from "../../components/Language/Translate";
import { simpleRequest } from "../simpleRequest";
import {useHistory} from "react-router-dom";

const UserPasswordInsertCodeAndPw = () => {
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState("");
    const [success, setSucess] = useState("");
    const history = useHistory();



    const valid = () => {
        const confirmPassword = variables.password !== variables.confirmPassword ? "The passwords must be equal" : null;
        const password = (variables.password.lenght < 8) ? `${translate("Minimum number of characters")}: 8` : null;
        (() => setFieldErrors({...fieldErrors, confirmPassword, password}))();
        const result = (confirmPassword || password) ? false : true;
        // console.log({result, fieldErrors});
        return result;
    };
    // console.log({fieldErrors})
    // console.log(variables)
    const handleChange = (e) => {
        (() => setVariables({...variables, [e.target.name]: e.target.value}))();
        if (fieldErrors)(() => setFieldErrors({}))();
        if(serverError)(() => setServerError(""))();
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(!valid())return;
        if(variables.code && variables.code.split("#c#f#").length !== 3){
            (() => setFieldErrors({...fieldErrors, code:translate("Invalid Code.")}))();
            return;
        };
        const response = await simpleRequest({url:`users/password/set`, method:"patch", data:variables});
        // console.log(response);
        // console.log(response.status);
        if(response.status === 200){
            (() => setSucess(true))();
            (() => setTimeout(() => history.push("/login"), 3000))();
        }else{
            (() => setServerError("Something went wrong."))();
        };
    }
    return (
        <Form onSubmit={e => handleSubmit(e)}>
            <Form.Group>
                
                <Form.Label>
                    {translate("Please insert the code from the email here")}
                    {fieldErrors.code && <p className="text-danger">{fieldErrors.code}</p>}
                </Form.Label>
                <Form.Control as="textarea" rows={5}
                    name = "code"
                    key="code"
                    type="text"
                    onChange={e => handleChange(e)}
                    required={true}
                />
            </Form.Group>
            <Form.Group>
                {fieldErrors.password && <p className="text-danger">{fieldErrors.password}</p>}
                <Form.Label>
                    {translate("New Password")}
                    {fieldErrors.password && <span className="text-danger">{` - ${fieldErrors.password}`}</span>}
                </Form.Label>
                <Form.Control
                    name = "password"
                    key="password"
                    type="password"
                    onChange={e => handleChange(e)}
                    required={true}
                />
            </Form.Group>
            <Form.Group>
                
                <Form.Label>
                    {translate("Confirm new Password")}
                    {fieldErrors.confirmPassword && <span className="text-danger">{` - ${fieldErrors.confirmPassword}`}</span>}
                </Form.Label>
                <Form.Control
                    name = "confirmPassword"
                    key="confirmPassword"
                    type="password"
                    onChange={e => handleChange(e)}
                    required={true}
                />
            </Form.Group>
            {serverError && <h4>{serverError}</h4>}
            {success && <h4>Password was set successfully. You can login now with the new password.</h4>}
            <button type = "submit" className="btn btn-outline-dark">{translate("Submit")}</button>
            
        </Form>
    )

};
export default UserPasswordInsertCodeAndPw;