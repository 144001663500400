import { advancedRequest } from "../simpleRequest";
import { translate } from "../../components/Language/Translate";
import { resizeFile } from "./Resize";
// for single

const uploadImage = async (base64EncodedImage, selectedFile, setUrlsForTable, developer) => {
    // console.log('inside uploadImage', base64EncodedImage)
    // try {
        const formData = new FormData();
        formData.append('image', selectedFile);
        const requestObj={
            url:'freeimages/upload', 
            method: 'POST',
            // data: {...data, source: base64EncodedImage },
            // data: {...data, source: selectedFile },
            // data: {...data, source: formData },
            data: {source: base64EncodedImage.toString() },
            headers: { 'Content-Type': 'application/json' }
        };
        const response = await advancedRequest(requestObj);
        // console.log(response);
        if(response.data && response.data.cloudUrl){
            (() => setUrlsForTable(response.data.cloudUrl))();
        }else{
            const msg = (response.response && response.response.data && response.response.data.error && response.response.data.error.message);
            (developer && msg)? flashError(msg) : flashError(translate("Something went wrong by creating the url. Perhaps the connection to the internet does not work."));
        }
};

const uploadFileAndGetCloudUrl = async(data) => {
            // console.log('inside uploadImageAndGetCloudUrl', data.selectedFile);
            if(!data.selectedFile)return;
            const  {selectedFile} = data;
            // if it is an image, resize it , if not, return file
            // console.log({selectedFile})
            // console.log(selectedFile.type)
            // console.log(selectedFile.type.includes("image"));
            const resizedFile = selectedFile.type.includes("image") ? await(resizeFile(selectedFile)) : selectedFile;
            const reader = new FileReader();
            // console.log({resizedFile});
            reader.readAsDataURL(resizedFile);
            
            // reader.readAsDataURL(data.selectedFile);
            reader.onloadend = async() => await uploadImage(reader.result, selectedFile);
};

const uploadUrlAndGetCloudUrl = async (data) => {
    // console.log('inside uploadUrlAndGetCloudUrl', {data});
    if(!data)return;
    await uploadImage(data.value);
}; 

const isFormError = (rightsConfirmed, data, setFieldError) => {
    let error = ""
    if(["file", "files", "url"].includes(data?.method) && !rightsConfirmed) error = (translate("Please confirm"));
    if(!data?.value) error = (translate("No data."));
    if(error){
        flashError(error, setFieldError);
        return true;
    };
    return false;
};

const flashError = (errMsg, setFieldError) => {
    // console.log({errMsg, setFieldError});
    if(!setFieldError || !errMsg)return;
    (() => setFieldError(errMsg))();
    (() => setTimeout(() => setFieldError(null), 3000))();
};










const previewFile = async (idx, file, obj) => {
    const resized = await resizeFile(file);
    const reader = new FileReader();
        reader.readAsDataURL(resized);
        // reader.readAsDataURL(file);
        reader.onloadend = () => {
            // console.log('#######################################################', reader.result.length);
            obj[idx] = reader.result;
    };
};
const previewFileSimple = async (file, setPreview) => {
    const resized = await resizeFile(file);
    const reader = new FileReader();
        reader.readAsDataURL(resized);
        reader.onloadend = () => {
            (() => setPreview(reader.result))();
    };
};









// for multiple

const previewFiles = async({files}) => {
    const obj = {};
    for (let i = 0; i < files.length; i++){
        await previewFile(i, files[i], obj)
    };
    return obj;
};


export {
    uploadImage,
    uploadFileAndGetCloudUrl,
    uploadUrlAndGetCloudUrl,
    previewFiles,
    previewFileSimple,
    isFormError,
    flashError
}