import React, {useState} from "react"
import { translate } from "../../Language/Translate";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const DeleteHints = () => (
        <div >
                <h4>Hinweise zum Löschen des Accounts</h4>
                <p>Zum Löschen des Accounts müssen Sie die Emailadresse verwenden, mit der Sie sich angemeldet haben, damit das System die Daten zuordnen kann.</p>
                <p>Klicken Sie auf den Link unten und geben Sie dann Benutzernamen (oder die Emailadresse) und das Password ein.</p>
                <p>Wenn Sie Ihr Passwort vergessen haben, wählen Sie "Zum Login" und dann "Passwort vergessen", und folgen Sie dann der Anleitung. Beginnen Sie anschließend wieder mit dem ersten Schritt</p>
                <p>Sie erhalten dann eine Email mit einem Link, mit der Sie die Löschung Ihres Accounts bestätigen können.</p>
                <p>Dieser Link ist 60 Minuten lang gültig.</p>
                <p>Der Link kann nur einmal verwendet werden, danach ist er nicht mehr gültig.</p>
                <p><b>Wenn Sie Ihre veröffentlichten Fotos löschen wollen, loggen Sie sich ein, gehen Sie zu Ihrem Persönlichen Bereich, dort auf "Ihre Fotos" und löschen Sie jedes einzelne Foto.
                    Nicht gelöschte Fotos bleiben erhalten (unter: &copy; {translate("Unknown Photographer")}) und Sie haben nach dem Löschen Ihres Accounts keinen Zugriff mehr darauf.</b></p>
                <p className = "text-danger"><b>Hinweis: Wenn Sie Ihren Account löschen, werden alle personenbezogenen Daten gelöscht und können nicht wiederhergestellt werden.</b></p>
        </div>
);
const Confirmation=({confirmed, setConfirmed})=> {
    // console.log()
    return(
        <Form>
            <Form.Group>
                {/* <Form.Label>{translate("I understand the hints.")}</Form.Label> */}
                <Form.Check type = "checkbox"
                    label = {translate("I understand the hints.")}
                    checked={confirmed}
                    onChange={e => setConfirmed(!confirmed)}
                    />


            </Form.Group>
        </Form>
    )
}

const DeleteData = () => {
    const [confirmed, setConfirmed] = useState(false);
    const history = useHistory();

    const handleClick = () => {
        // console.log('Clicked', confirmed);
        history.push('/deleteform')
        
    }
    
    
        return(
            <div style={{backgroundColor:"rgba(255,255,255,.7)"}} className="p-4">
                <DeleteHints confirmed={confirmed} setConfirmed={setConfirmed}/>
                <Confirmation confirmed={confirmed} setConfirmed={setConfirmed}/>
                {/* <a href = {`${frontendURL}?next=deleteForm`}className="btn btn-outline-danger" onClick = {handleClick}>{translate("Delete Account")}</a> */}
                <button className="btn btn-outline-danger" onClick = {handleClick}>{translate("Delete Account")}</button>
            </div>
        );
    };
export {DeleteData, DeleteHints, Confirmation};







