import React from "react";
import { translate } from "../../components/Language/Translate";
import {Form} from "react-bootstrap"


const UploadFile = ({data, setData, previewSource, setPreviewSource, setSelectedFile, setFileInputState, fileInputState, selectedFile}) => {


    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
        };
    };

    const handleChange = e => {
        // console.log(e.target);
        // console.log(e.target.files);
        // console.log(e.target.files[0]);
        
        const file = e.target.files[0];
        previewFile(file);
        setSelectedFile(file);
        setFileInputState(e.target.value);

        (() => setData({...data, value:e.target.value, fileInputState:e.target.value, selectedFile:file}))();
    };
    // console.log({fileInputState, previewSource, selectedFile})


    return(
        <>
        <Form.Group size="lg" controlId={"input"} className="my-5">
                        <Form.Label>{translate("Please select a file")} * </Form.Label>
                         
                        <Form.Control 
                             type="file"
                             key="input"
                             name="inputFile"
                            //  multiple="multiple"
                             onChange={e => handleChange(e)}
                             autoFocus={true}
                             

                        />
        </Form.Group>
        {previewSource && (
                <img
                    src={previewSource}
                    alt="chosen"
                    style={{ maxWidth: '90%', minHeight:"100px", maxHeight:"200px" }}
                />
            )}
        </>
    );
};
export default UploadFile;