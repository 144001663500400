import React, {useState} from "react";
import { translate } from "../../Language/Translate";
import { StyledDivWithBorder } from "../../styledComponents/divs";
import { useSelector } from "react-redux";
import Confirmation from "../../../newForms2/confirmation";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import SpeciesEdit from "../../../newForms2/observations/species/SpeciesEdit";

const SpeciesDetails = ({props}) => {
    const [confirmation, setConfirmation] = useState(false);
    const [formEdit, setFormEdit] = useState(false);
    const {species, setSpecies, speciesId, setLocalForm} = props;
    const currentUser = useSelector(st => st.currentUser);
    const actualSpecies = species.find(s => s.id === speciesId);
    if(!actualSpecies)return null;
    const {deDE, enUK, createdBy} = actualSpecies;

    const mayEdit = createdBy === currentUser.username || ["developer, admin"].includes(currentUser.userStatus);

    const handleDelete = async () => {
        const deleted = (await simpleRequest({url:`species/${speciesId}`, method:"delete"})).data?.deleted;
        if(!deleted?.id)return;
        setSpecies(species.filter(s => s.id !== speciesId));
        setConfirmation(false);
        setLocalForm('main');
    };

    return(
        <StyledDivWithBorder>
            <h4>{`${deDE} / ${enUK}`}</h4>
            {mayEdit && <button className="btn btn-outline-dark" onClick={() => setFormEdit(true)}>{translate("Edit")}</button>}
            {mayEdit && <button className="btn btn-outline-danger ml-2" onClick={() => setConfirmation(true)}>{translate("Delete")}</button>}
            {mayEdit && <button className="btn btn-outline-secondary ml-2" onClick={() => setLocalForm("main")}>{translate("Back to the List")}</button>}
            {confirmation && <Confirmation setConfirmation={setConfirmation} func={handleDelete}/>}
            {formEdit && <SpeciesEdit props={{...props, actualSpecies, setFormEdit}}/>}
        </StyledDivWithBorder>
    )

};
export default SpeciesDetails;