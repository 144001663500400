import React from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import { BlackWhite } from "../styledComponents/colors";
import { translate } from "../Language/Translate";
import NewsBadge from '../NewsBadge';
import ArticleList from '../Admin/ArticleList';



const ArticlesCard = ({articles, eventKey}) => {
    // console.log({articles});
    return(
        <Card>
            <Card.Header className="text-center">
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
                <BlackWhite>{translate("Matching articles from the travel magazine")} <NewsBadge count={articles.length}/></BlackWhite>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey}>
              <Card.Body>
                <ArticleList list = {articles}/>
              </Card.Body>
            </Accordion.Collapse>
        </Card>
    );

};
export default ArticlesCard;