// import { translate } from "../../components/Language/Translate";

import { translate } from "../../components/Language/Translate";


class TourSchema{
    constructor(){
        this.fields = {
            "analyseField": {
                    "fieldName": "analyseField",
                    "type": "text",
                    "fieldType": "textarea",
                    "label": "Read tour automatically",
                    "initialValue": "",
                    "placeholder": translate("Please insert the complete text of the excursion (including price) from the brochure here"),
            },
            "short": {
                "fieldName": "short",
                "validation": {
                    "maxLength": 10
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 10,
                "required": true,
                "label": "Short",
                "initialValue": ""
            },
            "title": {
                "fieldName": "title",
                "validation": {
                    "maxLength": 150
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 150,
                "required": true,
                "label": "Title",
                "initialValue": ""
            },
                       "currency": {
                "fieldName": "currency",
                "validation": {
                    "maxLength": 3
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 3,
                "label": "Currency",
                "initialValue": "€"
            },
            "prize": {
                "fieldName": "prize",
                "validation": {},
                "type": "integer",
                "fieldType": "currency",
                "label": "Prize",
                "initialValue": 0
            },
            "difficulty": {
                "fieldName": "difficulty",
                "validation": {
                    "maxLength": 15
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 15,
                "label": "Difficulty",
                "initialValue": ""
            },
            "cancelled": {
                "fieldName": "cancelled",
                "validation": {},
                "type": "boolean",
                "fieldType": "checkBox",
                "label": "Cancelled",
                "initialValue": false
            },

            "starttime": {
                "fieldName": "starttime",
                "validation": {
                    "maxLength": 15
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 15,
                "label": "Start",
                "initialValue": ""
            },
            "endtime": {
                "fieldName": "endtime",
                "validation": {
                    "maxLength": 15
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 15,
                "label": "End",
                "initialValue": ""
            },
            "numbers": {
                "fieldName": "numbers",
                "validation": {
                    "maxLength": 10
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 10,
                "label": "Tour Numbers",
                "initialValue": ""
            },

            "description": {
                "fieldName": "description",
                "validation": {},
                "type": "text",
                "fieldType": "textarea",
                "label": "Description",
                "initialValue": ""
            }
        }



    }
    
};
export default TourSchema;