import React from 'react'
import {formatDate} from '../helpers/Date'
import {Link} from 'react-router-dom'
import './CruiseListItem.css'
import { getDaysOnSea } from '../helpers/usercruise';
import { translate } from './Language/Translate';
// import { useSelector } from 'react-redux';

const CruiseListItem = ({cruise}) =>{
    const cruiseId = cruise.cruiseId?cruise.cruiseId:cruise.id;
    const vesselTxt= `${cruise.isJoined?'* ':''}${cruise.prefix} ${cruise.name} `
    const cruiseStar = `${cruise.isJoined ? '* ':""}`
    // const cruiseStar = `${(cruise.isJoined && !cruise.showVessel)? '* ':""}`
    const Cancelled = () => (<b style={{color:"darkred"}}>- CANCELLED -</b>)
    return(
        <div key={cruiseId} style={{backgroundColor:"rgba(255, 255, 255, .5", borderRadius:"5px"}} className="m-1">
            {/* <Link to={`/cruises/${cruiseId}`} className="btn btn-outline-dark col-12 mb-1 white" style={{backgroundColor:"rgba(255,255,255,0.5"}}> */}
            <Link to={`/cruises/${cruiseId}`} className="btn btn-outline-dark col-12 white" >
            <i>
                {cruise.cancelled && <h3><Cancelled/></h3>}
                {cruise.showVessel && <h4>{vesselTxt}</h4>}
                {!cruise.showVessel && cruiseStar}{cruise.shorthand} - {formatDate(cruise.startAt)} - {formatDate(cruise.endAt)}, {cruise.startPort} - {cruise.endPort}
                {cruise.title && <small>,  - {cruise.title} {cruise.suffix && `, ${cruise.suffix}`}, {`${getDaysOnSea([cruise])} ${translate("days")}`}</small>}
            </i>
            </Link>
        </div>
    )
};
export default CruiseListItem;