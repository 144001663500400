import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { simpleRequest } from '../../../newForms2/simpleRequest';
import { Result } from '../../Admin/SqlField';


const TestFrontendUrl = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [values, setValues] = useState({});
    const params = useParams();
    // console.log({params});

    const {code} = useParams();

    useEffect(() => {
        // console.log({isLoading});
        const fetch = async () => {
            // console.log('inside fetch', isLoading);
            const response = await simpleRequest({url:`admin/testurl/${code}`, method:"get"});
            // console.log(response.data, '__________________________________________________________________');
            (() => setValues(response.data))();
            setIsLoading(false)
            
        }
        if(isLoading)fetch()
    })

    // const {sendCode, savedCode, result} = values;
    // console.log({values});
    return(
        <>
        <h1>This is a TestFrontendUrl</h1>
        <Result element={values}/>
        </>
        
    );
    

};
export default TestFrontendUrl;