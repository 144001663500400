import React from "react";
// import {Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { addFollowOnApi } from "../../../actions/currentDestination";
import Randomfn from "../../../helpers/Randomfn";
// import Randomfn from "../../helpers/Randomfn";
import ProposalDetails from "./ProposalDetails";
import { translate } from "../../Language/Translate";
// import ProposalsNewForm from "../../newForms2/proposals/ProposalsNewForm";
// import ProposalsNewForm from "../../newForms2/Request/proposals/ProposalsNewForm";

const ProposalItems = ({type, proposals, proposalIdx, setProposalIdx, setView}) => {
    // console.log({type, proposals, proposalIdx, setProposalIdx});

    const dispatch = useDispatch();
    const currentUser = useSelector(st => st.currentUser)
    const filt = proposals.filter(p => p.type === type);
    filt.forEach(p => p.key = Randomfn.createRandomString())
    filt.forEach(p => p.isCreator = p.createdBy === currentUser.username?true:false);
    filt.forEach(p => p.isFollower = (p.follows.map(f => f.createdBy)).includes(currentUser.username));
    filt.sort((p => (-p.follows.length)));
    const admin = currentUser && ["developer", "admin"].includes(currentUser.userStatus);
    const handleFollow = async (id) => await dispatch(addFollowOnApi(id));
    const handleDetails = id => id === proposalIdx ? setProposalIdx(-1):setProposalIdx(id);
    // console.log(filt);
    const loggedIn = currentUser && currentUser.username;
    // console.log({proposals});
    return(
        <>
            {filt.map((f) => {
                return(
                <div key={f.key} className="p-1 mb-1" style={{border:"1px solid black", borderRadius:"5px"}}>
                {/* <div key={f.key} style={{border:"1px solid grey", borderRadius:"5px", backgroundColor:"rgba(255,255,255,.4)"}} className="p-2 mb-2"> */}
                <div  className="px-1 col-12 mb-1 text-center">
                    <button className = "my-1 btn btn-outline-dark">
                        <span onClick={() => handleDetails(f.id)}>{`${f.name} `} {f.note && `(${f.note}) `}</span> 
                        <span className="badge badge-success">{` ${f.follows.length} `} </span> 
                        <span>
                        {loggedIn && !f.isFollower && <span><i className="fa fa-thumbs-up ml-2"  onClick={() => handleFollow(f.id)}
                            data-placement="top" title={translate("Like this proposal. Then you can add a comment.")}></i></span>}
                        {loggedIn && f.isFollower && <span><i className="fa fa-thumbs-up ml-2" style = {{color:"grey"}} 
                            data-placement="top" 
                            title="You like this already."></i></span>}
                        </span>
                    </button>
                    {/* <br/>
                    {(admin || f.isCreator) &&<Link to={`/proposals/${f.id}`} className="btn btn-outline-dark ml-2 mt-3">{translate("Edit")}</Link>} */}
                </div>
                {proposalIdx === f.id &&
                    <div className="text-center">
                      <ProposalDetails proposals={proposals} proposalIdx={proposalIdx} setProposalIdx={setProposalIdx} setView={setView}/>
                </div>}
                </div>
                )})}
        </>
    );

};
export default ProposalItems;