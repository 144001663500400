import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
import {translate} from '../Language/Translate';
import { BlackWhite } from "../styledComponents/colors";
// import ListOfMain from "./ListOfMain";
// import Privates from "./Privates";
import PrivateMain from "./Private/PrivateMain";

const PrivateCard = ({eventKey}) => {
    // console.log('PrivateCard', eventKey);
    return(  
        <Card key={eventKey}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
            <BlackWhite>{translate('Privat')}</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>
                <PrivateMain/>
            </Card.Body>
        </Accordion.Collapse>
        </Card>
    )
};
export default PrivateCard;