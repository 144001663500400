import React, { useCallback, useEffect, useState } from "react";
import {Col, Form, Row} from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
// import PreviewArea from "./PreviewArea";
import heic2any from "heic2any";
import { StyledDivWithBorder } from "../../components/styledComponents/divs";
// import axios from "axios";
import {simpleRequest} from '../../newForms2/simpleRequest'

// ORIGINAL
// const fileInput = document.getElementById('fileInput');

// fileInput.addEventListener('change', (event) => {
//   const file = event.target.files[0];

//   if (file.type === 'image/heic') {
//     heic2any(file).then((imageData) => {
//       const image = new Image();
//       image.src = URL.createObjectURL(imageData);
//       document.body.appendChild(image);
//     });
//   }
// });






const HeicInput = () => {
    // console.log({data, maxCount})
    const [files, setFiles] = useState([]);
    const [results, setResults] = useState([]);
    const [loadend, setLoadend] = useState(false);

    const heic2anyVariants = useCallback(() => {
    const results = {};
    const file = files[0];
    if(!file || file.type !== "image/heic")return {};

        heic2any({file})
        .then(imageData => {
            results.imageData = imageData
            console.log({imageData});
            return heic2any({file, toType:"image/gif"})
        }).then(gif => {
            results.gif = gif;
            console.log({gif})
        }).catch(err => {
            results.error = err;
            console.log(err);
        }).finally(() => {
            console.log("DONE")
        });
        return results;
  
   
    }, [files]);

    useEffect(() => {
        const fetch = async() => {
            const newArr = []
            for(let i =0; i<files.length;i++){
                const file = files[i];

                console.log({file})
                if(file.type === "image/heic"){
                    console.log('inside fetch', {file});
                    heic2any(file)
                    .then(imageData => {
                        console.log("nach heic2any", {imageData});
                        // const image = new Image();
                        // image.src = URL.createObjectURL(imageData);
                        const url = URL.createObjectURL(imageData);
                        newArr.push({type: file.type, url})
                        return "done"
                    }).then(res => {
                        console.log({res})
                    }).catch(err => {
                        console.log({err});
                        newArr.push({msg:"Error", type: file.type, err});
                    });
                }else{
                    // const image = new Image();
                    // image.src = URL.createObjectURL(file);
                    const url = URL.createObjectURL(file);
                    newArr.push({type: file.type, url})
                }
            };
            const variants = await heic2anyVariants()
            setResults({converted: [...newArr], variants});
            setLoadend(true);
        } 
        fetch()
    },[files, heic2anyVariants]);


    useEffect(() => {
        const send = async () => {
            console.count("send");
            const request = {url: "testroutes/heic", method:"post", data:{x:"Dietmar", results}};
            console.log({request});
            await simpleRequest(request);    
        }
        if(loadend)send();
    }, [results, files, loadend])

    const getFromInternet = async() => {
        // axios.get("https://github.com/alexcorvi/heic2any/blob/master/demo/1.heic")
        const res = await simpleRequest({url: "testroutes/heic"});

        console.log({res})
        return res
        // fetch("https://github.com/alexcorvi/heic2any/blob/master/demo/1.heic")
	.then((res) => {
        console.log({res})
        // res.blob()
        return res.blob()
    })
	.then((blob) => heic2any({ blob }))
	.then((conversionResult) => {
		// conversionResult is a BLOB
		// of the PNG formatted image
        console.log(conversionResult)
        setFiles([conversionResult])
	})
	.catch((e) => {
        console.log({e})
		// see error handling section
	});
    }

    const handleChange = e => {
        e.preventDefault();
        setLoadend(false)
        const _files = Array.from(e.target.files);
        if(!_files.length)return;
        setFiles([..._files]);
    };


    console.log({files, results, loadend})
    return(
    <StyledDivWithBorder>
        <h4>Heic Input</h4>
        <Form.Group size="lg" controlId={"input"} className="my-2">
                        <Form.Label>{`${translate("Please select file/files")}`} </Form.Label>
                        <Form.Control 
                             type="file"
                             key="input"
                             name="inputFile"
                             multiple="multiple"
                             onChange={e => handleChange(e)}
                             autoFocus={true}
                        />
        </Form.Group>
        {/* <button className="btn btn-outline-dark" onClick={getFromInternet}>Get from internet</button> */}
        <Row>
            {results.converted?.map((r, idx) => {
                console.log(r);
                if(r.err)return <Col key={idx}>Error</Col>
                return (
                    <Col key={idx}>
                        <p>{r.type}</p>
                <img key={idx} alt= "file" src={r.url} style={{ maxWidth: '100%', minHeight:"30px", maxHeight:"200px" }}/>
                </Col>
                )})}
        </Row>
    </StyledDivWithBorder>)
}
export default HeicInput;