import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserCruiseNew from "../newForms2/userCruise/UserCruiseNew";
import { translate } from "./Language/Translate";


const CruiseDetailJoinArea = () => {
    const history = useHistory();
    // const currentCruise = useSelector (st => st.currentCruise);
    const currentUser = useSelector (st => st.currentUser);
    const loggedIn = currentUser.username ? true : false;

    return(
        <div style = {{border:"1px solid grey", borderRadius:"5px"}} className="p-3">
            {loggedIn &&
                <>
                <h6>{translate("If you participiate on this cruise, please fill up this form")}.</h6>
                <h6>{translate("Otherwise ignore it")}.</h6>
                <UserCruiseNew/>
                </>
            }
            {!loggedIn && 
                <>
                <h6>{translate("In order to see more details, please log in or register")}.</h6>
                <div className="row">
                    <div className="col-md-6 p-2">
                        <button className="btn btn-outline-success col-12" onClick={() => history.push("/login")}>{translate("Login")}</button>
                    </div>
                    <div className="col-md-6 p-2">
                        <button className="btn btn-outline-dark col-12" onClick={() => history.push("/register")}>{translate("Register")}</button>
                    </div>
                </div>
                </>
            }
        </div>
    );
};
export default CruiseDetailJoinArea;