import React, { useState, useEffect, useRef, useCallback } from "react";
import "./MainBoard.css" 
import { buildMasterArray } from "./functions";
import MainBoardCell from "./MainBoardCell";
import {translate} from '../../Language/Translate';

function MainBoard({counter, setCounter, seconds, setPlay, initialize}) {

    const [start, setStart] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const boardRef = useRef(buildMasterArray(12));
    const firstCardRef = useRef(null);
    const secondCardRef = useRef(null);
    const openCardsRef = useRef(0);
    const counterRef = useRef(0);
    
    const won = boardRef.current.every(v => v.className.includes("resolved"));
    if (won){
        (() => setPlay(p => "won"))();
        // (() => setStart(false))();
        // console.log({play, start})
    };

    const match = () => {
        const firstImg = firstCardRef.current.dataset.img;
        const secondImg = secondCardRef.current.dataset.img;
        return firstImg === secondImg;
    };
    const restoreCards = () => {
        // console.log('inside restoreCards');
        (() => setBlocked(true))();
        setTimeout(() => {
            boardRef.current.forEach(v => {
                if(!v.className.includes("resolved"))v.className = v.className.replace(' uncovered', '');
            });
            openCardsRef.current = 0;
            firstCardRef.current=null;
            (() => setBlocked(false))();
            // setDummy(Math.random());
        }, 750);
        
        
    };
    const handleResolved = (id, firstId) => {
        // console.log('inside handleResolved', {id, firstId});
        boardRef.current[+id].className +=  " resolved";
        boardRef.current[+firstId].className +=  " resolved";
        openCardsRef.current = 0;
        firstCardRef.current = null;
    };

    const clickValid = (id, className) => {
        // console.log('inside clickValid', {id, className})
        let valid = true;
        const firstId = firstCardRef.current && firstCardRef.current.id;
        // console.log({id, firstId});
        if(id === firstId) valid = false;
        if(!className.includes("validCard")) valid = false;
        if(className.includes("resolved")) valid = false;
        // console.log({valid});
        return valid;
    };


    const handleClick = useCallback(e => {
        e.preventDefault();
        if(blocked)return;
        if(!start){
            initialize();
            setStart(true);
        };
        const {id, className} = e.target.parentElement.parentElement;
        const firstId = firstCardRef.current && firstCardRef.current.id;
        
        if(!clickValid(id, className))return;
        boardRef.current[+id].className += " uncovered";
        openCardsRef.current = openCardsRef.current + 1;
        
        if(openCardsRef.current === 1){
            firstCardRef.current = e.target.parentElement.parentElement;
        }else if(openCardsRef.current === 2){
            secondCardRef.current = e.target.parentElement.parentElement;
            if(match()){
                handleResolved(id, firstId);
            }else{
                restoreCards();
            }
        }else{
            // console.log('INVALID, open cards:', openCardsRef.current)
        }
        
        counterRef.current = counterRef.current + 1;
        (() => setCounter(c => c + 1))();
    });

 

    useEffect(() => {
        // console.log('useEffect')
        const board = document.getElementById('board');
        if (board)board.addEventListener('click', handleClick);
        return () => {
            // console.log('CLEANUP IN MAINBOARD');
            board.removeEventListener('click', handleClick);
        }
    },[handleClick]);

    return (
        
            <div id="main">
                {/* <p>{translate('If you use firefox this game may not work.')}</p> */}
                <p>{translate('Image rights')}: Dietmar Schlabertz</p>
                <div id="board">
                    <div style={{height:"20%", width:"100%"}} className="row">
                        {[0,1,2,3,4].map(i => <MainBoardCell key={i} i={i} boardArray={boardRef.current}/>)}
                    </div>
                    <div style={{height:"20%", width:"100%"}} className="row">
                        {[5,6,7,8,9].map(i => <MainBoardCell key={i} i={i} boardArray={boardRef.current}/>)}
                    </div>
                    <div style={{height:"20%", width:"100%"}} className="row">
                        {[10,11].map(i => <MainBoardCell key={i} i={i} boardArray={boardRef.current}/>)}
                        <div className="text-center" style={{height:"20%", width:"20%"}} id="counter">Counter: {counter} - {seconds}</div>
                        {[12,13].map(i => <MainBoardCell key={i} i={i} boardArray={boardRef.current}/>)}
                    </div>
                    <div style={{height:"20%", width:"100%"}} className="row">
                        {[14,15,16,17,18].map(i => <MainBoardCell key={i} i={i} boardArray={boardRef.current}/>)}
                    </div>
                    <div style={{height:"20%", width:"100%"}} className="row">
                        {[19,20,21,22,23].map(i => <MainBoardCell key={i} i={i} boardArray={boardRef.current}/>)}
                    </div>
                </div>
                <hr/>
            </div>
        
    );
}

export default MainBoard;
