import {translate} from "../../components/Language/Translate"


class MessageFormData{

    static async messageNew(data){
        
        // console.log('inside MessageFormData.messageNew', data, data.params)
        const {currentUser} = data;
        const {sentTo} = data.params;
        const consts = new MessageFormSchema();
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        // const options = await CruiseFunctions._getSelectListVessels(vesselId);
        consts.formType = 'messageNew';
        consts.formLabels = {
                title:translate("Write Message"),
                btnLabel:translate("Send")
            }
        consts.request = {
                url:`messages/${sentTo}`,
                method:"post"
            };
        
        fields.sentTo = sentTo;
        fields.sentFrom = currentUser.username;
        consts.nextPage = (`/users/${currentUser.username}`);
        consts.sendData = ["subject", "body"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
        
       
};

class MessageFormSchema{

    constructor(){
        
        this.formType="messageNew";
        this.request = {
            url:"messages",
            method:"post",

        };
        this.formLabels = {title: translate("New Message"), btnLabel:translate("Send Message")};
        this.actions= {
            onSuccess:{
                action:true,
                title:translate("The Message was sent."),
                text:""
            }
        }

        
        
        this.fields={
            sentFrom : {
                label:translate("From:"),
                value:"",
                validation:{minLength:8},
                required:true,
                disabled:true,
                type:"text",
                fieldType:"text"
        },

            sentTo : {
                label:translate("To:"),
                value:"",
                validation:{
                    minLength:8
                },
                required:true,
                disabled:true,
                type:"text",
                fieldType:"text"
        },
            subject : {
                label:translate("Subject"),
                value:"",
                validation:{
                    maxLength:50
                },
                type:"text",
                fieldType:"text"

        }, 
            body : {
                label:translate("Body"),
                value:"",
                validation:{},
                type:"text_area",
                fieldType:"textarea"

        }
        }
        
    }
}

export default MessageFormData;