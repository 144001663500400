
class FormDataClass{
    constructor(fields={}, actions={}, request={}, formLabels={}, defaultValues={}){
        this.fields = fields;
        this.formLabels = formLabels;
        this.actions = actions;
        this.request = request;
        this.defaultValues= defaultValues;
    }
}

export default FormDataClass;