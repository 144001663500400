import React, { useEffect, useState } from "react";
import {Button, Form} from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompaniesFromAPI } from "../../actions/companies";
import Spinner from '../../components/Spinner';
import UserSchema from "./UserSchema";
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import FormValidation from "../Validation/FormValidation";
import { updateOtherUserOnApi } from "../../actions/otherUser";

const UserEditByAdmin = ({setShowForm}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const otherUser = useSelector(st => st.otherUser);
    const companies = useSelector(st => st.companies);
    const currentUser = useSelector(st => st.currentUser);
    const dispatch = useDispatch();


    useEffect(() => {
        const fetch = () => {  
            if(companies.length === 0)dispatch(fetchCompaniesFromAPI());        
            const {userStatus, companyHandle} = new UserSchema(companies, currentUser).fields;
            (() => setFieldSettings({userStatus, companyHandle}))();
            const recentStatus = otherUser.userStatus;
            const vars = recentStatus.includes('|') 
                    ? {companyHandle:recentStatus.split('|')[0], userStatus:recentStatus.split('|')[1]}
                    : {companyHandle:"*", userStatus: recentStatus};
            // console.log({recentStatus, isLoading, vars});
            (() => setVariables({...vars}))();
        }
        fetch();
        (() => setIsLoading(false))();
    },[companies, isLoading, currentUser, dispatch, otherUser])

    if(isLoading)return <Spinner/>
    
    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};
    // console.log(props, companies);
    
    const handleSubmit = e => {
        e.preventDefault();
        const validation = new FormValidation({fieldSettings, variables, setFieldErrors});
        if(!validation.formValid())return;
        if(variables.companyHandle === "*" && !["developer", "admin", "user"].includes(variables.userStatus)){
            (() => setServerError("Please select a company."))();
            setTimeout(() => setServerError(''), 2000);
            return;
        };
        const newUserStatus = ["developer", "admin", "user"].includes(variables.userStatus) 
            ? variables.userStatus
            : `${variables.companyHandle}|${variables.userStatus}`;
        dispatch(updateOtherUserOnApi(otherUser.username, {userStatus: newUserStatus}));
        (() => setShowForm('none'))();

    }

    return(
        <div className="p-3">
            <h5>{translate("Edit User")}</h5>
            <h6>{otherUser.nickname} ({translate("Username")}: {otherUser.username})</h6>
            <p>{translate("Your User Status")}: {currentUser?.userStatus}</p>
            <p>{translate("Recent User Status")} {otherUser.nickname}: {otherUser.userStatus}</p>
            <Form onSubmit={handleSubmit}>
                <FormFields props={props}/>
                <Button type="submit" block size="lg">{translate("Update User")}</Button>
            </Form>
        </div>
    );
};
export default UserEditByAdmin;