import React from "react";
import { translate } from "../../components/Language/Translate";
import { useHistory } from "react-router";
import TextField from "../Fields/TextField";
import PasswordToggle from "../Fields/PasswordToggle";

const UserLoginFields = ({props}) => {
    const history = useHistory();

    return (
        <div>
            
            <TextField name = "usernameOrEmail" props={props}/>
            <TextField name = "password" props={props}/>
            <PasswordToggle props={props}/>
            <div className="my-3 text-left">
                <button 
                    className = "btn btn-outline-dark" 
                    onClick={() => history.push('/password/request')} >
                        {translate("Forgot Password?")}
                </button>
            </div>
        </div>
    );

};
export default UserLoginFields;