export const names = [
    {keys: ["Brown Bluff", "Jougla Point#Wiencke Island", "Orne Harbour", "Paradise Bay",
            "Paulet Island", "Snow Hill Island", "The Naze#James Ross Island", "Weddelmeer"], deDE: "#, Antarktis", enUK: "#, Antarctica"},
    {keys: ["Rimatara", "Tubuai", "Rurutu", "Raivavae"], deDE: "#, Australinseln", enUK: "#, Austral Islands"},
    
    {keys: ["Lajes de Pico", "Horta", "Ponta Delegada#São Miguel",
            "Praia da Vitoria"], deDE: "#, Azoren", enUK: "#, Azores"},
    
    {keys: ["Monterosso", "Manarola", "Vernazza"], deDE: "#, Cinque Terre", enUK: "#, Cinque Terre"},
    {keys: ["Aitutaki", "Rarotonga"], deDE: "#, Cook Inseln", enUK: "#, Cook Islands"},
    {keys: ["Capoliveri", "Porto Azzurro"], deDE: "#, Elba", enUK:"#, Elba"},
    {keys: ["Carcass Island", "New Island", "Port Stanley"], deDE:"#, Falkland-Inseln (Malvinen)", enUK: "#, Falkland Islands (Malvinas)"},
    {keys: ["Lautoka", "Leleuvia", "Somosomo#Taveuni" ], deDE:"#, Fiji", enUK: "#, Fiji"},
    {keys: ["Mangareva"], deDE:"#, Gambier-Inseln", enUK: "#, Gambier Islands"},
    {keys: ["Bora Bora", "Papeete#Tahiti", "Huahine", "Moorea", "Raiatea", "Motu Mahaea"], deDE:"#, Gesellschafts-Inseln", enUK: "#, Society Islands"},
    {keys: ["Nidry"], deDE:"#, Griechenland", enUK: "#, Greece"},
    {keys: ["Ittoqqortoormiit", 'Cape Tobin', 'Fleming Fjord', 
            'Alpefjord', 'Segelsallskapet Fjord', 'Ella Øye', 'Isfjord, Keyser Franz Joseph Fjord',
            'Blomsterbukta', 'Grejsdalen'], deDE:"#, Grönland", enUK: "#, Greenland"},
    
    
    
    
    
    {keys: ["Cowes"], enUK: "#, Isle of Wight"},
    {keys: ["Puerto de la Estaca#El Hierro", "Puerto del Rosario#Fuerteventura",
            "San Sebastian#La Gomera"], deDE:"#, Kanaren", enUK: "#, Canarian Islands"},

    {keys: ["Bonifacio", "Ile Rousse", "Propriano"], deDE: "#, Korsika", enUK:"#, Corse"},
    {keys: ["Hvar", "Rab"], deDE: "#, Kroatien", enUK:"#, Croatia"},
    {keys: ["Lipari", "Stromboli", "Salina", "Volcano"], deDE: "#, Liparische Inseln", enUK:"#, Aeolian Islands"},
    {keys: ["Koror"], deDE: "#, Palau", enUK:"#, Palau"},
    {keys: ["Bramble Haven#Siva Island", "Sebulugomwa", "Sepic River#Kopar", 
            "Dobu Island", "Kaileuna", "Kitawa", "Kopar", "Medang", "Vanimo",
            "Tuam", "Tufi"], deDE: "#, Papua-Neuguinea", enUK: "#, Papua New Guinea"},
    {keys: ["Porto Cervo"], deDE: "#, Sardinien", enUK: "#, Sardinia"},
    {keys: ["Santa Ana Island", "Tinakula", "Tulagi", "Honiara#Guadalcanal",
            "Nendoe",
            ], deDE: "#, Salomonen", enUK: "#, Solomon Islands"},
    {keys: ["Marbella"], deDE: "#, Spanien", enUK: "#, Spain"},
    {keys: ["Binnebukta", 'Wahlbergoya', "14. Juli Gletscher", "Möllerhamna", "Ny-Alesund",
            'St. Jonsfjord', 'Alkhornet' ], deDE: "#, Spitzbergen", enUK: "#, Spitzbergen"},



    {keys: ["Cooper Bay", "Fortuna Bay", "Hercules Bay", "Grytviken", "Salisbury Plain",
            "St. Andrews Bay"], deDE: "#, Südgeorgien", enUK: "#, South Georgia"},
    {keys: ["Shingle Cove"], deDE: "#, Shingle Cove, Süd-Orkney-Inseln", enUK: "#, South Orkney Islands"},
    {keys: ["Nukulalofa", "Foa Haapai", "Neiafu#Vavau"], deDE: "#, Tonga", enUK: "#, Tonga"},
    

];