import React, { useState } from "react";
import { translate } from "../../Language/Translate";
import YesNoButtons from "../../YesNoButtons";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import TourTimingEdit from "../../../newForms3/tourTiming/TourTimingEdit";

const TimingListItem = ({timingItem, setActiveId, activeId, setTiming}) => {
    const [view, setView] = useState("main");
   

    const {id} = timingItem;
    const isActiveItem = activeId === id
    
    const handleClick = () => {
        console.log('handleCLICK')
        if(isActiveItem){
            setActiveId(0)
        }else{
            setActiveId(id)
        }
    };
    const handleDelete = async() => {
        const request = {url: `tourtiming/${id}`, method:"delete"};
        console.log({request});
        const deleted = (await simpleRequest(request)).data?.deleted;
        console.log({deleted});
        setTiming(timing => timing.filter(t => t.id !== id));
    }
    

    const listItem =         
            <>
            <td onClick={handleClick}>{timingItem.startTime}</td>
            <td onClick={handleClick}>{timingItem.endTime}</td>
            <td onClick={handleClick}>{timingItem.activity}</td>
            {isActiveItem && 
                <td>
                    <button className="btn btn-outline-dark" onClick = {() => setView("edit")}>{translate("Edit")}</button>
                    <button className="btn btn-outline-danger ml-1" onClick={() => setView("delete")}>{translate("Delete")}</button>
                </td>}
            </>
        

    const confirmation = 
                    <td>
                        {translate("Do you want to delete this item ? ")}
                        <YesNoButtons yesFunc={handleDelete} noFunc={() =>setView("main")}/>
                    </td>
    const edit = <td colSpan={12}>
        <TourTimingEdit timingItem = {timingItem} setTiming={setTiming} backFunc={() => setView("main")}/>
        </td>

    return(
        <tr key={id} id={id} >
            {(view === "main" || !isActiveItem) && listItem}
            {view === "delete" && isActiveItem && confirmation}
            {view === "edit" && isActiveItem && edit}
                    
        </tr>
        
    )
};
export default TimingListItem;