// 4.12.23
import React from "react";
import { translate } from "../Language/Translate";

const AlbumDestinationList = ({album, handleClickDestination}) => {
    
    return(
        <div className = "p-2 mt-2" style={{border:"1px solid grey", borderRadius:"5px"}}>
            <p><b>{`${translate("Ports near")} ${album.titleDE} / ${album.titleEN}:`}</b></p>
            <ul>
                {album.destinations.map(d => 
                    <button key={d.id} className="btn btn-outline-dark m-1"
                         id={d.destinationId} onClick={e => handleClickDestination(e)}>
                            {`${d.deDE} / ${d.enUK}`}
                        </button>)}
            </ul>
        </div>
    )

}
export default AlbumDestinationList;