import React, { useEffect, useState } from "react";
import { translate } from "../../components/Language/Translate";
import Spinner from "../../components/Spinner";
import DestinationFormSchema from "./DestinationFormSchema";
import { Button, Form } from "react-bootstrap";
import FormFields from "../FormFields";
import { useDispatch } from "react-redux";
import { updateDestinationOnAPI } from "../../actions/destinations";
import { fetchDestinationFromAPI } from "../../actions/currentDestination";
import { getGeolocationFromAddress } from "./helpers";


const DestinationEdit = ({setShowFormEdit, destination}) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldsettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    // const [serverError, setServerError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const fetch = () => {
            const sets = {...new DestinationFormSchema().fields};
            // console.log({sets});
            (() => setFieldsettings(sets))();
            const v = {};
            Object.keys(sets).forEach(k => v[k] = destination[k]);
            (() => setVariables({...v}))();
            (() => setIsLoading(false))();
        };
        if(isLoading)fetch();
    }, [destination, isLoading, setFieldsettings, setVariables, setIsLoading])
    if(isLoading)return <Spinner/>

    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        // serverError
    }

    // console.log({props});

    // const handleChange = e => {
    //     // console.log(e.target)

    // };
    const handleSubmit = e => {
        e.preventDefault();
        // console.log("submit", {fieldErrors}, Object.keys(fieldErrors).length);
        if(Object.values(fieldErrors).join('').length !== 0)return;
        // console.log('keine Fehler', variables);
        dispatch(updateDestinationOnAPI(destination.id, {...variables}));
        dispatch(fetchDestinationFromAPI(destination.id));
        setShowFormEdit(false);
    };

    // console.log('DestinationEdit', {setShowFormEdit, destination, props});
    return(
        <div className = "p-3" style = {{border:"1px solid black", borderRadius:"5px"}}>
            <h4>{translate("Edit Destination")}</h4>
            <Form onSubmit={e => handleSubmit(e)}>
                <FormFields props = {props}/>
                {/* <button className="btn btn-outline-dark mb-2" onClick={getGeolocation}>{translate("Get Geolocation")}</button> */}
                <button className="btn btn-outline-dark mb-2" 
                        onClick={e => getGeolocationFromAddress(e, variables, setVariables)}>
                            {translate("Get geolocation from address")}
                </button>
                <Button block type="submit">{translate("Submit")}</Button>
            </Form>
        </div>
    );
};
export default DestinationEdit;