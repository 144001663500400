

import React, { useState } from "react";
import { translate } from "../../components/Language/Translate";
import { createAxiosRequest } from "../simpleRequest";
import EmailWasBlocked from "./EmailWasBlocked";
import Spinning from "../../components/Spinning";
import { Form } from "react-bootstrap";
import axios from "axios";

const UserForgotPassword = () => {
    const [variables, setVariables] = useState({usernameOrEmail:""});
    const [serverError, setServerError] = useState('');
    const [view, setView] = useState('input'); // input, waiting, serverError, success
    
    // console.log({variables, view});

    const handleChange = e => {
        (() => setVariables({...variables,[e.target.name]:e.target.value}))();
    };
    const handleSubmit = async(e) => {
        e.preventDefault();
        (() => setView('waiting'))();
        
        const request = createAxiosRequest({url:"users/password/request", method:"post", data:{...variables}});
        // console.log('HIER', {request})
        try{
            const response = await axios(request);
            // console.log({response});
            (() => setView('success'))();
        }catch(err){
            // console.log(err);
            setServerError(JSON.stringify(err));
            (() => setView('serverError'))();
        }
    };
    
    const Success = () => (
        <div>
            <h5>{translate("We sent you an email with a link inside")}.</h5>
            <h6>{translate("Please open this email and click the link to set a new password")}.</h6>
        </div>
    )
    const Input = () => (
        <div>
            <h6 className="mb-4">{translate("Please insert username or Email")}</h6>
            <Form>
                <Form.Control
                
                name={"usernameOrEmail"} 
                type="text" 
                fieldtype="text" 
                key={"usernameOrEmail"} 
                autoFocus={true}
                value={variables.usernameOrEmail}
                onChange={e => handleChange(e)} 
                placeholder={translate("Username or Email")}
                >

                </Form.Control>
                <button type="submit" className="btn btn-outline-dark" onClick={e => handleSubmit(e)}>{translate("Submit")} </button>
            </Form>
        </div>
    );
    const ServerError = () => (
        <div>
            <p>{serverError}</p>
        </div>
    )

    return(
        <div className = "text-center">
            <h5>{translate("Forgot Password")}</h5>
            
            {view === "input" && <Input/>}
            {view === "waiting" && <Spinning/>}
            {view === "success" && <Success/>}
            {view === "serverError" && <ServerError/>}
            <div className="mt-5">
                {(view === "success" || view === 'serverError') && <EmailWasBlocked  usernameOrEmail={variables.usernameOrEmail} />}
            </div>
            
        </div>
    )
}
export default UserForgotPassword;
