import React, { useState } from "react";
import TourReportListItem from "./TourReportListItem";

const TourReportList = ({props}) => {
    const [activeTR, setActiveTR] = useState(0);
    
    const {tours, setTours, tourReports, setTourReports} = props;
    console.log({props});



    return(
        <ul>
            {tourReports.map(tR => <TourReportListItem key={tR.id}
                props = {{...props, tourReport:tR, activeTR, setActiveTR}}/>)}
        </ul>
    )
};
export default TourReportList;