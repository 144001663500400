import React from 'react';
import {Link} from 'react-router-dom';


const OtherUserCruises = ({otherUser}) => {
    // console.log('inside OtherUserCruises', otherUser);

    const commonCruises = otherUser?.commonCruises || [];
    const list = commonCruises.map(cC => 
        <div key={cC.id} style={{backgroundColor:"rgba(255,255,255,.5)", borderRadius:"5px"}} className="m-1">
            <Link to={`/cruises/${cC.cruiseId}`} className="btn btn-outline-dark" style={{width:"100%"}}>
                <i><b>{cC.prefix} {cC.name}</b> - {cC.startAt} ({cC.startPort}) - {cC.endAt} ({cC.endPort})</i>
            </Link>
        </div>
    );
    
    return(
        <div>
            {list.length > 0 && list}
        </div>
    )

}
export default OtherUserCruises;