import React from "react";
import {Form} from "react-bootstrap";


const TextArea= ({name, props}) => {
    // console.log('TextArea', {name, props});

    const {
        variables,
        handleChange,
        validateField,
        fieldSettings, 
        fieldErrors, 
        } = props;
        const {label, required, autoFocus, toolTip, placeholder} = fieldSettings[name];
        const value = variables[name] || "";
        // console.log({name,value});
    return(
        <Form.Group size="lg" controlId={name}>
            <Form.Label>{label}{required? ' * ':' '}</Form.Label>
            { fieldErrors[name]?<small className="text-danger" > - {fieldErrors[name]}</small>:null}
            <Form.Control as="textarea" rows={5}
                name={name} 
                type="textarea" 
                key={name} 
                autoFocus={autoFocus}
                value={value}
                required = {required}
                placeholder={placeholder}
                onChange={e => handleChange(e)} 
                onBlur={e => validateField(e)}
            />
            {toolTip?<p><small className="text-black p-1" style={{backgroundColor:"rgba(255,255,255,.5"}}>{toolTip}</small></p>:null}
        </Form.Group>
    )
}
export default TextArea;
