
import React, {useState} from "react";
import { Form } from "react-bootstrap";
import { StyledDivWithBorder } from "../../styledComponents/divs";


const SecField = ({sec, setSec}) => {
    // const [sec, setSec] = useState('');
    const [variables, setVariables] = useState({sec:""});
    // const [keyList, setKeyList] = useState(['nix', 'nix']);
    // const [formNew, setFormNew] = useState(false);
    // const [privates, setPrivates] = useState([]);

    const handleChangeSec = e => {
        setVariables({...variables, [e.target.name]: e.target.value})
    };

    const submitSec = e => {
        e.preventDefault();
        setSec(variables.sec)
    }

    // console.log({sec});
    return(
        <StyledDivWithBorder>
            {sec ? <p>OK</p> : <p>X</p>}
            <Form>
                <Form.Group>
                    <Form.Label>Sec</Form.Label>
                    <Form.Control
                        name = "sec"
                        type="password"
                        value={variables.sec}
                        onChange={e => handleChangeSec(e)}
                        >
                        
                </Form.Control>
                </Form.Group>
                <button className="btn btn-outline-dark" onClick={e => submitSec(e)}>Ok</button>
        
            </Form>
        </StyledDivWithBorder>
    )
};
export default SecField;