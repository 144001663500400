import { Col, Form, Image, Row } from "react-bootstrap";
import { sortArrayByObjectField } from "../../../helpers/sort";
import CheckBox from "../../../newForms2/Fields/CheckBox";
import { useState } from "react";
import TextField from "../../../newForms2/NewFields/TextField";
import { translate } from "../../Language/Translate";
import { simpleRequest } from "../../../newForms2/simpleRequest";
// import { Console } from "console";

const ImageArea = ({images, albums, setAlbumPhotos}) => {
    const [changeArr, setChangeArr] = useState([]);
    const [albumText, setAlbumText] = useState("");
    const [selectedAlbumId, setSelectedAlbumId] = useState(0);
    // console.log({images})
    const newArr = sortArrayByObjectField(images, "origin");

    const filtered = albumText ? 
        albums.map(a => {return {text:`${a.titleDE} - ${a.titleEN}`, id:a.id}})
                    .filter(a => a.text.toLowerCase().includes(albumText.toLowerCase()))
        : [];

    const handleCheck = e => {
        // console.log(+e.target.id, e.target.checked);
        const id = +e.target.id
        setChangeArr(e.target.checked ? [...changeArr, id] : changeArr.filter(i => i!== id))
    };
    const handleChange = e => {
        console.log(e.target);
        setAlbumText(e.target.value);
    };
    const handleSelect = e => {
        console.log(e.target, +e.target.id);
        setSelectedAlbumId(+e.target.id);

    };
    const handleSubmit = async() => {
        for (let i=0; i<changeArr.length;i++){
            const request = {url: `albumPhotos/${changeArr[i]}`, method:"patch", data:{albumId: selectedAlbumId}};
            console.log({request});

            const albumPhoto = (await simpleRequest(request)).data?.albumPhoto;
            console.log({albumPhoto});
        };
        setAlbumText("");
        setChangeArr([]);
        const ap = (await simpleRequest({url: "albumphotos"})).data?.albumPhotos
        console.log({ap});
        setAlbumPhotos(ap);
    };
    
    console.log({changeArr, filtered, selectedAlbumId});
    return(
        <div>
            <h5>Images</h5>
            <Row>
                <Col className="col-6">
                <Form>
                <Form.Control 
                type = "text"
                value={albumText}
                onChange = {handleChange}
                />
            </Form>
                <ul>{filtered.map(f => <li onClick={e=>handleSelect(e)} key={f.id} id={f.id}>{f.text}</li>)}</ul>
                </Col>
                <Col className="col-6">
                <button className="btn btn-outline-dark col-12" onClick={handleSubmit}>{translate("Submit")}</button>
                </Col>
            </Row>            
            <Row>
                {newArr.map(img => {
                    // console.log({img})
                    const checked = changeArr.includes(img.id);
                    return (
                    <Col key = {img.id} className="col-4" style={{border: "1px solid grey"}} >


                        <Image src={img.imageUrl} className="img-fluid"></Image>
                        <p>{Object.entries(img).map(([k,v], idx) => <span key={idx}>{` - ${k}: ${v}`} </span>)}</p>
                       
                        <Form>
                                <Form.Group size="lg" controlId={img.id}>
                                <Form.Check type="checkbox" 
                                        label={`${img.id}`}
                                        key={img.id}
                                        id={img.id}
                                        checked={checked}
                                        value={checked}
                                        name={`${img.id}`}
                                        onChange={(e) => handleCheck(e)}
        />
                            </Form.Group>
                        </Form>
                    </Col>
                    )
                })}
            </Row>

        </div>
    )
};
export default ImageArea;