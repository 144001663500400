import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
import {translate} from '../Language/Translate';
import { BlackWhite } from "../styledComponents/colors";
// import UserList from '../UserList';
import TestArea from '../TestArea/TestArea';

const TestAreaAccordion = ({eventKey}) => {
    
    return (
        <Card style={{backgroundColor:'#e5e9df', color:"#1a1620"}}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("Test Area")}</BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
                <Card.Body>
                    <TestArea/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
export default TestAreaAccordion;