// 4.12.2023

import React, {useState, useEffect, useCallback} from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import Spinner from "../Spinner";
import { translate } from "../Language/Translate";
import AlbumDestinationList from "./AlbumDestinationList";
import { useHistory } from "react-router-dom";
import AlbumPhotos from "./AlbumPhotos";
import AlbumEdit from "../../newForms2/albums/AlbumEdit";
import { useSelector } from "react-redux";

const AlbumDetails = ({albumId, backFunc, refresh}) => {
    console.log('inside AlbumDetails')
    
    const [album, setAlbum] = useState(null);
    const [showEdit, setShowEdit] = useState(false)
    const [isLoadingLocal, setIsLoadingLocal] = useState(true);
    const history = useHistory();
    const currentUser = useSelector(st => st.currentUser);
    const mayEdit = ["developer", "admin"].includes(currentUser.userStatus) || album?.createdBy === currentUser.username;
    console.log({albumId, backFunc, refresh, album, isLoadingLocal});

    const loadAlbum = useCallback(async() => {
        const url = `albums/${albumId}`;
        const data = (await simpleRequest({url})).data;
        if(data.album)setAlbum(data.album);
    }, [albumId]);

    useEffect(() => {
        const fetch = async() => {
            await loadAlbum();
            setIsLoadingLocal(false);
        };
        if(isLoadingLocal)fetch();
    }, [isLoadingLocal, setIsLoadingLocal, loadAlbum]);

    const handleClickDestination = e => history.push(`/destination/details/${e.target.id}`);

    if(!album)return <Spinner/>;
    
    console.log({currentUser, album});
    return(
        <div>
            <h4>{`${album.titleDE} / ${album.titleEN} (${album.photos?.length || 0})`}</h4>
            <button className="btn btn-outline-dark" onClick = {backFunc}>{translate("Back to the list")}</button>
            {mayEdit && <button className="btn btn-outline-dark ml-1" onClick = {() => setShowEdit(true)}>{translate("Edit Album")}</button>}
            {showEdit && <AlbumEdit albumId = {albumId} backFunc={backFunc} refresh={refresh}/>}
            <AlbumDestinationList album = {album} handleClickDestination = {e => handleClickDestination(e)}/>
            <AlbumPhotos album={album} refresh={refresh}/>
        </div>
    )

}
export default AlbumDetails;