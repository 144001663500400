import React, { useEffect, useState } from "react";
import Spinner from '../../components/Spinner';
import { translate } from "../../components/Language/Translate";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button, Form} from "react-bootstrap";
import FormField from "../FormField";
import TourReportSchema from "./tourReportSchema";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { StyledDivWithBorder } from "../../components/styledComponents/divs";
import FormValidation from "../Fields/validation/FormValidation";
import {sortArrayByObjectField} from '../../helpers/sort';
import { getValidValue } from "../../components/CsvToJson/helpers";


const TourReportEdit = ({props}) => {
    const {tours, backFunc, tourReport, setTourReports} = props;
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const currentCruise = useSelector(st => st.currentCruise);
    
    useEffect(() => {
        const fetch = async() => {
            const fields = new TourReportSchema(tours).fields;
            setFieldSettings({...fields});
            const vars = {};
            Object.keys(fields).forEach(k =>  vars[k] = getValidValue(tourReport[k], fields[k].type));
            console.log({fields, vars, tourReport});
            setVariables({...vars});
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, currentCruise, tours, tourReport]);
    if(isLoading)return <Spinner/>;

    const handleSubmit = async(e) => {
        e.preventDefault();
        const {id} = tourReport;
        console.log(variables)
        const formValidation = new FormValidation({fieldSettings, variables, setFieldErrors});
        if(!formValidation.valid)return;
        const request = {url:`tourreports/${id}`, method:"patch", data:variables}
        console.log({request});
        const response = (await simpleRequest(request));
        const updated = response.data?.tourReport;
        console.log({updated});
        
        setTourReports(tR => sortArrayByObjectField(
                [...tR.filter(r => r.id !== id), updated], 'createdAt').reverse());
        if(response.isAxiosError){
            setServerError(response.response.data);
            setTimeout(() => setServerError(null), 5000);
            return;
        }
        backFunc();
    }

    const newProps = {    
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError
    };
    const mainOrders = Array.from(new Set(Object.values(fieldSettings).map(fs => fs.mainOrder)));
    console.log({mainOrders, fieldSettings})


    return(

        <StyledDivWithBorder className="p-2">
            <h5 >{translate("Edit Tour Report")}</h5>
            
            <Form.Row className="row row-eq-height">
                {Object.values(fieldSettings).map(lf =>
                
                <Col key = {lf.name} id={lf.id}className={`col-${lf.col}`}>
                     <FormField props = {{...newProps, name:lf.name}}/>
                </Col>
            )}
            </Form.Row>
            <Form.Row>
                <Col >
                    <Button block className="btn btn-success" onClick={handleSubmit}>{translate("Update")}</Button>
                </Col>
                <Col>
                    <Button block className="btn btn-secondary" onClick={backFunc}>{translate("Cancel")}</Button>
                </Col>
            </Form.Row>
        </StyledDivWithBorder>
    )
};
export default TourReportEdit;