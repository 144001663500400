import React, { useEffect, useState } from 'react';
import { useSelector} from "react-redux";
import { translate } from '../../components/Language/Translate';


const SelectFieldVessel = ({props}) => {
    const [showFurtherVessels, setShowFurtherVessels] = useState(false);
    const {mainVariables, setMainVariables, setStage} = props;
    const currentUser = useSelector(st => st.currentUser);
    const vessels = useSelector(st => st.vessels);  
    // console.log({vessels});
    // console.log({showFurtherVessels});


    const handleSelect = e => {
        // console.log(e.target.id);
        const value = parseInt(e.target.id);
        if(!value)return;
        // console.log({value});
        (() => setMainVariables({...mainVariables, vesselId:value}))();
        (() => setStage("selectCruise"))();
    };

    useEffect(() => {
        const vesselList = document.getElementById('vesselList');
        vesselList && vesselList.addEventListener('click', handleSelect);
        return () => {
            // console.log('CLEANUP');
            vesselList.removeEventListener('click', handleSelect);
        };
    });
    const furtherVessels = vessels
                .filter(v => {
                    return !v.hasCruises && (v.isConfirmed || v.createdBy === currentUser.username)})

    return(
        <div className = "my-5">
            
            <div id="vesselList">
                <h5>{translate("Vessels")}</h5>
                {vessels
                .filter(v => {
                    return v.hasCruises && (v.isConfirmed || v.createdBy === currentUser.username)})
                            .map(v => <div className="btn btn-outline-dark my-1 col-12" key={v.id} id={v.id} >{v.prefix} {v.name} - {v.companyName}, {v.note}</div>)}
                {/* <h5 className="mt-3">{translate("Further Vessels")}</h5> */}
                <button className="btn btn-outline-dark" onClick={() => setShowFurtherVessels(!showFurtherVessels)}>{translate("Further vessels")}</button>
                {showFurtherVessels && furtherVessels
                    .map(v => <div className="btn btn-outline-dark my-1 col-12" key={v.id} id={v.id} >{v.prefix} {v.name} - {v.companyName}, {v.note}</div>)}
            </div>
        </div>
    );
};
export default SelectFieldVessel;