import React from "react";
import {Translate} from '../../components/Language/Translate';
import { FAQDeutsch } from "./FAQDeutsch";
import { FAQEnglish } from "./FAQEnglish";

export const FAQ = () => {
    const language = Translate.language;
    return(
        <>
            {language === "deDE" && <FAQDeutsch/>}
            {language === "enUK" && <FAQEnglish/>}
        </>
        
   
    )
};
    