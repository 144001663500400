import React, { useEffect, useState} from "react";
import { useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import FormValidation from '../Validation/FormValidation';
import ArticleLinksSchema from "./ArticleLinksSchema";
import { simpleRequest } from "../simpleRequest";

  const ArticleLinkFormNew = ({article, setArticle, setShowNewForm}) => {
    // console.log('inside ArticleLinkFormNew');
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [fieldSettings, setFieldSettings] = useState();
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();

    // console.log({fieldErrors, setFieldErrors});

    useEffect(() => {
      // console.log('inside useEffect')
      const fetch = async() => {
        // console.log('inside fetch');
        const sets = new ArticleLinksSchema();
        // console.log({sets});
        const fields = sets.fields;
        // console.log({fieldSettings});
        const vars = {};
        Object.entries(fields).forEach(e => vars[e[0]] = e[1].initialValue);
        vars.subject = article.title;
        (() => setFieldSettings(sets.fields))();
        (() => setVariables({...vars}))();
        (() => setIsLoading(false))();
      }
      if(isLoading)fetch();
    },[isLoading, setIsLoading, dispatch, article.title]);

    if(isLoading) return <Spinner/>

    const handleSubmit = async() => {
      const formValidation = new FormValidation({fieldSettings, variables, setFieldErrors});
      if(!formValidation.valid)return;
      const data = {...variables, articleId: article.id};
      const resData = (await simpleRequest({url:'articlelinks', method:"post", data})).data;
      const articleLink = resData?.articleLink;
      if(!(articleLink))return;
      await ( () =>  setArticle({...article, links:[...article.links, articleLink]}))();
      (() => setShowNewForm(false))();
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors};
    // console.log({props});

    return(
        <div className = "my-5 p-3" style = {{border:"1px solid grey", borderRadius:"5px"}}>
            <h4>{translate("Add Article Link")}</h4>
            <FormFields props={props}/>
            <div className="row col-12" >
              <div className="col-6 p-1">
                <button className="btn btn-info col-12" onClick={handleSubmit}>{translate("Submit")}</button>
              </div>
              <div className="col-6 p-1">
                <button className="btn btn-secondary col-12" onClick={()=>setShowNewForm(false)}>{translate("Cancel")}</button>
              </div>
            </div>
        </div>
    )
  };
  export default ArticleLinkFormNew;