import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
import CompanyVesselLists from "./CompanyVesselLists";
import {translate} from './Language/Translate';
import { BlackWhite } from "./styledComponents/colors";
import { useSelector } from "react-redux";

const CompanyVesselListCard = ({eventKey}) => {

  const currentCompany = useSelector(st => st.currentCompany);
  const length = currentCompany.vessels && currentCompany.vessels.length;

    return(  
        <Card key={eventKey}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
            <BlackWhite>{translate('Vessels')} ({length})</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>
                <CompanyVesselLists/>
            </Card.Body>
        </Accordion.Collapse>
        </Card>
    )
};
export default CompanyVesselListCard;