import React from "react";
import { Translate,translate } from "../../../Language/Translate";
import { getDateObject } from "../../../../helpers/Date";
import { getTextGeoFromSpeciesObservations } from "../../helpers";
import { sortArrayByObjectField } from "../../../../helpers/sort";
import { useSelector } from "react-redux";
import ObservationEvent from "./ObservationEvent";


const ObservationListCruise = ({mainProps}) =>{
    const cruiseId = useSelector(st => st.currentCruise)?.id || 0;
    const speciesObservations = sortArrayByObjectField(mainProps.speciesObservations, "dateTime")
        .filter(i => i.cruiseId === cruiseId)
        .reverse();
    const getText = obs => {
        const date = getDateObject(obs.dateTime, Translate.language, "local").short;
        const geo = getTextGeoFromSpeciesObservations(obs);
        return `${date}: ${geo}`
    };
    const events = Array.from(new Set(speciesObservations.map(o => getText(o))));
    
    const handleClickEvent = (ev) => {
        const data = speciesObservations.find(so => getText(so) === ev)
        // console.log({data});
        delete data.speciesId;
    }

    if(!speciesObservations.length)return <p className="mt-3">{translate("No items")}</p>

    return(
        <div className = "mt-3">
            {events.map((ev, idx) => {
                // const obs = speciesObservations.filter(o => getText(o) === ev)
                    return(
                        <div key = {ev}>
                        <ObservationEvent key = {ev} props = {{...mainProps, ev, idx, getText, handleClickEvent, speciesObservations}}/>
                        </div>
                    )
                })}
        </div>
    );
};

export default ObservationListCruise;