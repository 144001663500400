import React, { useState } from "react";
import SpeciesGroupListItemItem from "./SpeciesGroupListItemItem";
import { Form } from "react-bootstrap";
import { BlackWhite } from "../../../styledComponents/colors";

const SpeciesGroupListItem = ({props, groupItem}) => {
    const {variables, setVariables} = props;
    const [details, setDetails] = useState(false);

    const handleChange = e => {
        const newChecked = !groupItem.checked;
        const newItem = {...groupItem, checked:newChecked};
        Object.values(newItem.species).forEach(s => s.checked = newChecked);
            setVariables({...variables, all:false, 
                speciesGroups: {...variables.speciesGroups, [groupItem.name]: {...newItem}}})
    };
    const ListView = ({setDetails}) => {
        return (
                <Form.Group >
                    <Form.Check type="checkbox" inline="true"
                        label=''
                        name = {groupItem.name}
                        checked={groupItem.checked}
                        onChange={e => handleChange(e)}
                        >
                    </Form.Check>
                    <BlackWhite onClick={() => setDetails(!details)}>{groupItem.name}</BlackWhite>
                </Form.Group>
        );
    };
    const DetailView = () => {
        return(
            <div className = "ml-4">
                { Object.values(groupItem.species).map((sp, idx) => 
                <SpeciesGroupListItemItem key={sp.id} speciesItem = {sp} groupItem = {groupItem} props={props}/>)}
            </div>
        )
    };
    return(
        <div>
            <ListView setDetails={setDetails}/>
            {details && <DetailView/>}
        </div>

    );

}
export default SpeciesGroupListItem;