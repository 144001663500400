import React from "react";
import {useSelector} from "react-redux";
import CruiseListItems from "../CruiseListItems";
import {actualDateString} from '../../helpers/Date';
import { translate } from "../Language/Translate";

const ActualCruises = () => {
    const actualDate = actualDateString()
    const currentUser = useSelector(st => st.currentUser);
    if (!currentUser?.cruises?.length) return null;
    const {cruises} = currentUser;
    const currentCruises = cruises.filter(c =>{return (c.startAt <= actualDate && c.endAt >= actualDate)});
    currentCruises.forEach(cc => cc.showVessel = true);
    const nextCruise = cruises.filter(c => c.startAt > actualDate)?.sort((a, b) => a.startAt > b.startAt ? 1 : -1)[0];
    
    if(nextCruise)nextCruise.showVessel = true;


    const Actual = () => (
        <div className="mt-5 text-center">
                {currentCruises.length === 1 && <h4>{translate("Your actual cruise")}:</h4>}
                {currentCruises.length > 1 && <h4>{translate("Your actual cruises")}:</h4>}
                <CruiseListItems cruises = {currentCruises} headLine={null} period="none" />
        </div>
    );
    const Next = () => (
        <div className="mt-5 text-center">
        <h4>{translate("Your next cruise")}:</h4>
        <CruiseListItems cruises = {[nextCruise]} headLine={null} period="none" />
    </div>
    )
    return(
        <>
            <Actual/>
            {nextCruise && <Next/>}
        </>
    )
};
export default ActualCruises;