import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../Language/Translate";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { addPhotoLikeOnApi, removePhotoLikeFromApi } from "../../actions/photoLikes";


const PhotoLikeButton = ({albumPhoto}) => {
    const [photoLike, setPhotoLike] = useState(null)
    const currentUser = useSelector(st => st.currentUser);
    const photoLikes = useSelector(st => st.photoLikes);
    const dispatch = useDispatch();
    const hasLiked = !!photoLike;
    const loggedIn = !!currentUser?.username;
    const allowed = loggedIn && currentUser.username !== albumPhoto.createdBy

    useEffect(() => {
        // console.log('inside useEffect', photoLikes)
        setPhotoLike(photoLikes.find(pl => pl.createdBy === currentUser.username && pl.albumPhotoId === albumPhoto.id) || null);
    }, [photoLikes, albumPhoto])

    
    const toggleLike = async() => {
        // console.log("clicked", albumPhoto);
        if(!loggedIn)return;
        if(hasLiked){
            dispatch(removePhotoLikeFromApi(photoLike.id));
            // setPhotoLike(null);
        }else{
            dispatch(addPhotoLikeOnApi(albumPhoto.id));
        }
    };
    // console.log({hasLiked, photoLike, photoLikes, loggedIn, albumPhoto}, photoLikes.length);
    if(!allowed)return null;
    return(
        <button className="btn btn-outline-dark" onClick={toggleLike}>
                {/* <span> */}
                        {loggedIn && !hasLiked && <span><i className="fa fa-thumbs-up ml-2"  
                            data-placement="top" title={translate("Like this photo and vote for the photo of the month")}></i></span>}
                        {loggedIn && hasLiked && <span><i className="fa fa-thumbs-up ml-2" style = {{color:"grey"}} 
                            data-placement="top" 
                            title="You like this photo already."></i></span>}
                {/* </span> */}
        </button>
    );
}; 
export default PhotoLikeButton;