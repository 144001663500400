// import Form from "react-bootstrap/Form";
// import {handleChange, handleSubmit, validateField, validateForm} from '../ReactFormFunctions';
import {Link} from "react-router-dom";

const LinkField = (props) => {
    //  // console.log('inside linkfield, ', {props});
    const {name, variables, constants} = props;
    // const {errors} = variables;
    const {fields} = constants;
    const field = fields[name]
    const value = variables.fields[name] || "";
    // console.log({name, value})
    

    return (
        <div className="mb-3">
        <Link to={value} >{field.label}</Link>
        </div>
)
}

export default LinkField;