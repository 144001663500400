import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
import Spinner from '../../components/Spinner';
import { StyledDivWithBorder } from '../../components/styledComponents/divs';
import { simpleRequest } from "../../newForms2/simpleRequest";
import TextAreaField from "../Fields/TextAreaField";
import TextField from "../Fields/TextField";
import ChatCommentSchema from "./ChatCommentSchema";

const ChatCommentNew = ({post, backFunc}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});

    useEffect(() => {
        const fetch = async() => {
            const _fields = new ChatCommentSchema().fields;
            const _vars = {};
            Object.keys(_fields).forEach(f => _vars[f] = _fields[f].initialValue);
            setVariables({..._vars});
            setFieldSettings({..._fields});
            setIsLoading(false);
        }

        if(isLoading)fetch()
    }, [setVariables, isLoading]);

    if(isLoading)return <Spinner/>

    const handleSubmit = async(e) => {
        e.preventDefault();
        const travelchatComment = (await simpleRequest({url:"travelchatcomments", method:'post', data:{...variables, postId:post.id}})).data?.travelchatComment;
        console.log({travelchatComment});
        backFunc();
    }

    console.log({variables, fieldSettings})
    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors}
    return(
        <StyledDivWithBorder className= "p-3">
            <h3>{translate("Add Comment")}</h3>
            <Form onSubmit={handleSubmit}>
                <TextAreaField props = {{...props, name:"comment"}}/>
                <Form.Row>
                    <Col>
                        <button type="submit" className="btn btn-info col-12">{translate("Submit")}</button>
                    </Col>
                    <Col>
                        <button className="btn btn-outline-secondary col-12" onClick={backFunc}>{translate("Cancel")}</button>
                    </Col>
                </Form.Row>
            </Form>

        </StyledDivWithBorder>
    )
};
export default ChatCommentNew;
