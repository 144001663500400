import {
    DELETE_CRUISE_CREW,
    FETCH_CRUISE, 
    ADD_CRUISE_CREW,
    UPDATE_CRUISE_CREW,
    DELETE_DAY,
    ADD_ANNOUNCEMENT,
    UPDATE_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT,
    ADD_PINBOARD,
    UPDATE_PINBOARD,
    DELETE_PINBOARD,
    ADD_DIARY,
    UPDATE_DIARY,
    DELETE_DIARY,
    ADD_DAY,
    UPDATE_DAY,
    // ADD_GROUP,
    // UPDATE_GROUP,
} from "../actions/types";

export default function rootReducer(state = {}, action) {
  switch (action.type) {

    case FETCH_CRUISE:
      if(!action.cruise)return state;
      return ({...action.cruise});
    case ADD_CRUISE_CREW:
      if(!action.cruiseCrew)return state;
      return ({...state, cruiseCrew:[...state.cruiseCrew, action.cruiseCrew]});
    case UPDATE_CRUISE_CREW:
      if(!action.cruiseCrew)return state;
      return ({...state, cruiseCrew:[...state.cruiseCrew.filter(v=>v.id !== action.cruiseCrew.id), action.cruiseCrew]});
    case DELETE_CRUISE_CREW:
      if(!action.deleted)return state;
      return ({...state, cruiseCrew:[...state.cruiseCrew.filter(v=>v.id !== action.deleted.id)]});
    case ADD_DAY:
      if(!action.day)return state;
      return {...state, days: [...state.days, action.day]};
    case UPDATE_DAY:
      if(!action.day)return state;
      return {...state, days:[...state.days.filter(d => d.id !== action.day.id), action.day]};
    case DELETE_DAY:
      if(!action.deleted)return state;
      return ({...state, days:[...state.days.filter(v => v.id !== action.deleted.id)]});
    case ADD_ANNOUNCEMENT:
      if(!action.announcement)return state;
      return ({...state, announcements:[...state.announcements, action.announcement]});
    case UPDATE_ANNOUNCEMENT:
      if(!action.announcement)return state;
      return ({...state, announcements:[...state.announcements.filter(a => a.id !== action.announcement.id), action.announcement]});
    case DELETE_ANNOUNCEMENT:
      if(!action.deleted)return state;
      return ({...state, announcements:[...state.announcements.filter(v => v.id !== action.deleted.id)]});
    case ADD_PINBOARD:
      if(!action.pinboard)return state;
      return ({...state, pinboard: [...state.pinboard, action.pinboard]});
    case UPDATE_PINBOARD:
      if(!action.pinboard)return state;
      return ({...state, pinboard:[...state.pinboard.filter(a => a.id !== action.pinboard.id), action.pinboard]});
    case DELETE_PINBOARD:
      if(!action.deleted)return state;
      return ({...state, pinboard:[...state.pinboard.filter(v => v.id !== action.deleted.id)]});
    case ADD_DIARY:
      if(!action.diary)return state;
      return ({...state, diaries:[...state.diaries, action.diary]});
    case UPDATE_DIARY:
      if(!action.diary)return state;
      return ({...state, diaries:[...state.diaries.filter(v => v.id !== action.diary.id), action.diary]});
    case DELETE_DIARY:
      if(!action.deleted)return state;
      return ({...state, diaries:[...state.diaries.filter(v => v.id !== action.deleted.id)]});
    // case ADD_GROUP:
    //   if(!action.group)return state;
    //   return ({...state, groups:[...state.groups, action.group]});
    // case UPDATE_GROUP:
    //   if(!action.group)return state;
    //   return ({...state, groups:[...state.groups.filter(g => g.id !== action.group.id), action.group]})
    default:
      return state;
  };
};

