import React, {useEffect, useState} from "react";
import { translate } from "../../components/Language/Translate";
import {useSelector, useDispatch} from "react-redux";
import Spinner from '../../components/Spinner';
import { formatDate } from "../../helpers/Date";
import UserCruiseSchema from './UserCruiseSchema';
import FormFields from '../FormFields';
import { simpleRequest } from "../../newForms2/simpleRequest";
import { fetchCruiseFromApi } from "../../actions/currentCruise";
import {fetchCurrentUserFromApi} from '../../actions/currentUser';

const NewUserCruise = ({props}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState(true);
    const [fieldSettings, setFieldSettings] = useState(true);
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState();
    const {stage, setStage, mainVariables, setMainVariables} = props;
    // console.log({stage, setStage, mainVariables, setMainVariables});
    const currentUser = useSelector(st => st.currentUser);
    const currentVessel = useSelector(st => st.currentVessel);
    const dispatch = useDispatch();
    
    const cruise = currentVessel.cruises.find(c => c.id === mainVariables.cruiseId);

    // console.log(setServerError);

    useEffect (() => {
        const init = () => {
            const sendData = ["cruiseStatus", "department", "position"];
            const sets = new UserCruiseSchema();
            let fields = {};
            let vars = {};
            sendData.forEach(d => {
                fields[d] = sets.fields[d];
                vars[d] = sets.fields[d].value;
            });
            (() => setFieldSettings({...fields}))();
            (() => setVariables({...vars}))();
            (() => setIsLoading(false))();
        };
        if(isLoading)init();
    },[isLoading, setIsLoading]);

    if(isLoading)return <Spinner/>

    // console.log({fieldSettings, variables});

    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log({variables});
        // fürs erste werden cruise und user neu von der API geholt
        await simpleRequest({url:`usercruises/${cruise.id}`, data:{...variables}});
        await dispatch(fetchCruiseFromApi(cruise.id));
        await dispatch(fetchCurrentUserFromApi(currentUser.username));
        (() => setStage('selectCruise'))();
    };

    const newProps ={
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError}

    return(
        <div>
            <h4>{translate("Join Cruise")}</h4>
            <h5>{currentUser.nickname}</h5>
            <h5>{currentVessel.prefix} {currentVessel.name}: {cruise.title}, 
                {` ${translate("from")} ${formatDate(cruise.startAt)} (${cruise.startPort}) 
                ${translate("to")} ${formatDate(cruise.endAt)} (${cruise.endPort})`}
            </h5>
            <FormFields props={newProps}/>
            <p>
                {translate("If you click Submit you can also find this cruise in your personal space")}.<br/>
                {translate("You can find the personal area by clicking on your first name in the navigation bar at the top")}.
            </p>
            <div className= "row col-12">
                <div className="col-md-6 p-3">
                    <button className="btn btn-outline-dark col-12" onClick={handleSubmit}>{translate("Submit")}</button>
                </div>
                <div className="col-md-6 p-3">
                    <button className="btn btn-outline-secondary col-12" onClick={() => setStage("selectCruise")}>{translate("Back")}</button>
                </div>
            </div>
        </div>
    )
};
export default NewUserCruise;

