import React, { useState } from "react";
import ArticleLinkListItem from "./ArticleLinkListItem";
import { translate } from "../Language/Translate";
import ArticleLinkFormNew from "../../newForms2/articles/ArticleLinkFormNew";
import { useSelector } from "react-redux";

const ArticleLinkList = ({article, setArticle}) => {
    const [showNewForm, setShowNewForm] = useState(false);
    // console.log({article});
    const currentUser = useSelector(st => st.currentUser);
    const isAuthor = currentUser?.username === article.createdBy;
    // console.log({isAuthor}, currentUser, article);
    
    return(
        <div className = "mt-3 p-3">
            <h5>{translate("Further Links")}</h5>
            {isAuthor && <button className = "btn btn-outline-dark mb-2" onClick = {() => setShowNewForm(true)}>
                {translate("Add Link")}
            </button>}
            <div>
                {showNewForm && <ArticleLinkFormNew article={article} setArticle= {setArticle} setShowNewForm={setShowNewForm}/>}
            </div>
            {article.links.map(l => <ArticleLinkListItem link = {l} key={l.id} setShowNewForm={setShowNewForm} article={article} setArticle={setArticle}/>)}
        </div>
    )
};
export default ArticleLinkList;