import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useSelector } from 'react-redux';
import { getDateObject } from '../../helpers/Date';
import { BlackWhite } from '../styledComponents/colors';
import { useHistory } from 'react-router-dom';
import { Translate } from '../Language/Translate';
// import L from 'leaflet';
import Icons from './Icons';

const Map2 = ({newDays, center, zoom}) => {
    // console.log({newDays, center, zoom});
    const history = useHistory();
    const language = useSelector(st => st.currentUser)?.language || Translate.language
    //  // console.log({L});
    //  // console.log(L.icon);




    return(
        

            <div id="map" style = {{height:'80vh', width:"99%", border:"1px solid black"}}>
                <MapContainer style = {{minHeight:'100%', minWidth :"100%" }} center={center} zoom={zoom} scrollWheelZoom={false}>
                  {/* {// console.log('inside MapContainer', zoom, center)} */}
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {newDays.map((d, idx) => 
                        <Marker icon = {Icons.fahne }key = {idx} position={[d.lat, d.long]} riseOnHover={true}
                            onMouseOver={e => e.target.openPopup()} onMouseOut={e => e.target.closePopup()}
                        >
                            <Popup >
                                <div>
                                    <h5><BlackWhite onClick={() => history.push(`/destination/details/${d.destinationId}`)}>{d[language]}</BlackWhite></h5>
                                    <p>{d.date && getDateObject(d.date,language, "UTC").longWeekday}</p>
                                </div>
                            </Popup>
                        </Marker>)}
                </MapContainer>
            </div>
                
    );
};
export default Map2;