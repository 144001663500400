import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import CurrencyFunctions from "./CurrencyFunctions";
import { Translate, translate } from "../../Language/Translate";
import CountryFunctions from "./CountryFunctions";
import { useSelector } from "react-redux";
import Spinner from '../../Spinner';
import { readTimeStamp } from "../../../helpers/Date";

const CurrencyConverter2 = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [settings, setSettings] = useState(CurrencyFunctions.getUserDataFromLocalStorageOrDefault());
    const [forexData, setForexData] = useState()
    const currentUser = useSelector(st => st.currentUser)
    const symbols = CountryFunctions.getSymbolObject();
    const language = useSelector(st => st.currentUser)?.language || Translate.language;
    const names = CountryFunctions.getNameObject(currentUser ? currentUser.language:"enUK");

    useEffect(() => {
        const fetch = async() => {
            const fxData = await CurrencyFunctions.getForexDataFromLocalStorageOrAPI();
            if (fxData)(() => setForexData(fxData))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    },[setForexData, isLoading, setIsLoading]);
    
    if(isLoading)return <Spinner/>
    
    const rates = forexData && forexData.values && forexData.values.rates;
    if(!rates)return <h4>No data available.</h4>

    
    
    const countryList = CountryFunctions.getCountryList(language);
    const countrySelect = countryList.sort().map(c => {return {key:c, value:c}});
    countrySelect.unshift({key:"invalid", value:` - ${translate("Select a country")} - `});

    const currencySelect = CountryFunctions.getShortList().sort().map(s => {return{key:s, value:`${s}, ${names[s]}, (${symbols[s]})`}})
    currencySelect.unshift({key:"invalid", value:` - ${translate("Or select a currency")} - `});

    const selectListCountries = countrySelect && countrySelect.map(opt => (
        <option value= {opt.key} key={`${opt.key}_${opt.value}`}>{opt.value}</option>
    ));
    const selectListCurrencies=currencySelect && currencySelect.map(opt => (
        <option value= {opt.key} key={`${opt.key}_${opt.value}`}>{opt.value}</option>
    ));
    const result = ((parseFloat(settings.amount.replace(",","."))) * rates[settings.toCurrency] / rates[settings.fromCurrency]).toFixed(2);
    const resultOne = (rates[settings.toCurrency] / rates[settings.fromCurrency]).toFixed(6);
    const resultOneBack = (rates[settings.fromCurrency] / rates[settings.toCurrency]).toFixed(6);
    const from = CountryFunctions.getLongCurrencyFromShort(settings.fromCurrency);
    const to = CountryFunctions.getLongCurrencyFromShort(settings.toCurrency);
    
    const handleChange = async(e) =>{
        // console.log(e.target.name, e.target.value)
        const sets = {...settings};
        sets[e.target.name] = e.target.value;
        switch (e.target.name){
            case "fromCountry":
                sets.fromCurrency = CountryFunctions.getShortCurrencyFromCountry(e.target.value);
                break;
            case "fromCurrency":
                sets.fromCountry = "invalid";
                break;
            case "toCountry":
                sets.toCurrency = CountryFunctions.getShortCurrencyFromCountry(e.target.value);
                break;
            case "toCurrency":
                sets.toCountry = "invalid";
                break;
            default:
                break;
        };
        (() => localStorage.setItem("forexUserSettings", JSON.stringify(sets)))();
        (() => setSettings({...sets}))();
    };
    
    const handleExChange = (e) =>{
        e.preventDefault();
        const sets = {...settings};
        let x = sets.fromCurrency;
        sets.fromCurrency = sets.toCurrency;
        sets.toCurrency = x;
        x = sets.fromCountry;
        sets.fromCountry=sets.toCountry;
        sets.toCountry = x;
        // console.log({sets});
        (() => localStorage.setItem("forexUserSettings", JSON.stringify(sets)))();
        (() => setSettings({...sets}))();
    };
    
    // console.log({settings, selectListCountries, selectListCurrencies, forexData});

    return(
        <div>
            <h3>{translate("Currency Converter")}</h3>
            <p>{translate("Last updated")}: {readTimeStamp(forexData.lastModified, true)}. {translate("All statements without guarantee")}.</p>
            <div>
                <Form>
                {/* <Form onSubmit = {e => handleSubmit(e,constants, variables)}> */}
                    <div style={{border:"1px solid black", borderRadius:"5px"}} className="p-4 mb-2">
                        <h5>{translate("from:")}</h5>
                        <Form.Group size="lg" controlId="fromCountry">
                             {/* <Form.Label>Country</Form.Label> */}
                             <Form.Control as="select"
                                 value={settings["fromCountry"]}
                                 key="countryFrom"
                                 autoFocus={true}
                                 name="fromCountry"
                                 onChange={(e) => handleChange(e)}
                             >
                                 {selectListCountries}
                             </Form.Control>
                         </Form.Group>
                        <Form.Group size="lg" controlId="fromCurrency">
                             {/* <Form.Label>Currency</Form.Label> */}
                             <Form.Control as="select"
                                 value={settings["fromCurrency"]}
                                 key="currencyFrom"
                                 name="fromCurrency"
                                 onChange={(e) => handleChange(e)}
                             >
                                 {selectListCurrencies}
                             </Form.Control>
                         </Form.Group>
                    </div>
                    <div className = "text-center mb-2">
                        <button className="btn btn-outline-dark" onClick={(e) => handleExChange(e)}><i className="fas fa-exchange-alt"></i></button>
                    </div>
                    
                    <div style={{border:"1px solid black", borderRadius:"5px"}} className="p-4 mb-2">
                        <h5>{translate("to:")}</h5>
                        <Form.Group size="lg" controlId="toCountry">
                             {/* <Form.Label>Country</Form.Label> */}
                             <Form.Control as="select"
                                 value={settings["toCountry"]}
                                 key="countryTo"
                                 name="toCountry"
                                 onChange={(e) => handleChange(e)}
                             >
                                 {selectListCountries}
                             </Form.Control>
                         </Form.Group>
                        <Form.Group size="lg" controlId="toCurrency">
                            {/* <Form.Label>Currency</Form.Label> */}
                             <Form.Control as="select"
                                 value={settings["toCurrency"]}
                                 key="currencyTo"
                                 name="toCurrency"
                                 onChange={(e) => handleChange(e)}
                             >
                                 {selectListCurrencies}
                             </Form.Control>
                         </Form.Group>
                    </div>
                    <Form.Group size="lg" controlId="toCurrency">
                            <Form.Label>Amount</Form.Label>
                             <Form.Control 
                                 type="number"
                                 value={settings.amount}
                                 key="amount"
                                 name="amount"
                                 onChange={(e) => handleChange(e)}
                             ></Form.Control>
                         </Form.Group>
                    {from.short && to.short && result &&
                    <>
                    <div className="text-center">
                        <h2>{`${from.symbol} ${settings.amount.replace(',',".")} = ${to.symbol} ${result}`}</h2>
                        <h6>{`${from.short}, ${names[from.short]}, (${symbols[from.short]}) / ${to.short}, ${names[to.short]}, (${symbols[to.short]})`}</h6>
                        <p>(Value in US$: {(settings.amount * rates["USD"] / rates[settings.fromCurrency]).toFixed(2)}, 
                                value in €: {(settings.amount * rates["EUR"] / rates[settings.fromCurrency]).toFixed(2)}) <br/>
                            1 {names[from.short]} = {resultOne} {names[to.short]}<br/>
                            1 {names[to.short]} = {resultOneBack} {names[from.short]}
                        </p>
                        {/* <p>1 {to.name} = {resultOneBack} {from.name}</p> */}
                    </div>
                    
                    <div style={{border:"1px solid black", borderRadius:"5px"}} className="p-4 mb-2">
                    <h5>{translate("Tax")} (%):</h5>
                    <Form.Group size="lg" controlId="tax">
                            {/* <Form.Label>Tax (%)</Form.Label> */}
                             <Form.Control 
                                 type="number"
                                 value={settings.tax}
                                 key="tax"
                                 name="tax"
                                 onChange={(e) => handleChange(e)}
                             ></Form.Control>
                         </Form.Group>
                         {/* <h5>Buy: {to.symbol} {(result * (1 - (((parseFloat(settings.tax.replace(',',".")))/100)))).toFixed(2)}</h5> */}
                         <h5>Buy: {to.symbol} {(result * (1 - (settings.tax/100))).toFixed(2)}</h5>
                         <h5>Sell: {to.symbol} {(result * (1 + (settings.tax/100))).toFixed(2)}</h5>
                    </div>
                    </>
                    }
                </Form>
            </div>
        </div>
    )
}

export default CurrencyConverter2;

