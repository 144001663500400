import Resizer from "react-image-file-resizer";

// import ImageResizor from 'image-resizor';

// gültig ist immer resizeFile;


export const resizeFile = (file) => new Promise((resolve, reject) => {
// export const resizeFileOld = (file) => new Promise((resolve, reject) => {
  // console.log({file});
   if(!file)reject('No file.')
  Resizer.imageFileResizer(file, 1200, 1200, 'JPEG', 100, 0,
     uri => {
      // console.log({uri})
      resolve(uri);},'file');
});

// export const resizeFileNew = (file) => new Promise((resolve, reject) => {
// export const resizeFile = async(file) => new Promise((resolve, reject) => {
//   // console.log({file}, '############################################################');
//   if(!file)reject('No file');

//   new ImageResizor(file, {
//     maxWidth: 1200,
//     maxHeight: 1200,
//     outputType: 'image/jpeg',
//     quality: .8,
//   })
//     .init()
//     .then(instance => {
//       // console.log(instance.toDataURL());
//       resolve(instance);

//   })
//     .catch(e => {
//       console.error(e);
//       reject(e)
//     });
  
  
  
// });

