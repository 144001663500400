import React from "react";
import {Accordion} from 'react-bootstrap';
import { useSelector } from "react-redux";
import ArticlesCard from "./ArticlesCard";
import MedicalInstitutes from "./MedicalInstitutes";
import ProposalsCard from "./Proposals/ProposalsCard";
// import DestinationMapCard from "./DestinationMapCard";
import DestinationMap from "./DestinationMap";
import AlbumsCard from "./AlbumsCard";

const DestinationAccordion = () => {

    const currentDestination = useSelector(st => st.currentDestination);
    // console.log(currentDestination);
    return(            
        <Accordion style={{backgroundColor:"rgba(255,255,255,.5"}}>
            <ProposalsCard eventKey="1"/>
            <ArticlesCard eventKey="3" articles={currentDestination?.articles || []}/>
            <AlbumsCard eventKey="6" articles={currentDestination?.articles || []}/>
            <div className = "my-5"></div>
            <MedicalInstitutes eventKey="2"/>
            {/* <DestinationMapCard eventKey = '4' /> */}
            <DestinationMap />
        </Accordion>
    );
};
export default DestinationAccordion;