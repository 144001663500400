import React from "react";
import NavBarFooter from "./NavbarFooter";

const Footer = () =>{
    return(
    <footer className="footer js-footer" fixed="bottom">
    <nav className="footer js-footer">
        <NavBarFooter/>
    </nav>
    </footer>
    )
}

export default Footer;