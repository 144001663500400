import React, {useState} from "react";
import HeaderNavBar from '../HeaderNavBar';
import AdvertisementArea from "../Advertisements/AdvertisementArea";
import { useSelector } from "react-redux";
import FirstStepsNotLoggedIn2 from "../FirstSteps2/FirstStepsNotLoggedIn2";

function StartPage({next}) {
  const [showFirstSteps, setShowFirstSteps] = useState(!next);
  const currentUser=useSelector(st => st.currentUser);
  const loggedIn = currentUser?.username
  return (
    <div >
    {/* <div className=""> */}
      <header className="App-header jumbotron jumbotron-fluid mt-1 py-md-1" id="jumbo" style={{backgroundColor:"rgba(255,255,255,.05)"}}>
        <h3 className="App-title display-5" >
            <i>Cruisefriends</i>.net
            </h3>
          {(!showFirstSteps || loggedIn) && <nav><HeaderNavBar/></nav>}
        <div className="mt-md-2"><AdvertisementArea /></div>
        <div className= "text-center"> 
            {!loggedIn && showFirstSteps && <FirstStepsNotLoggedIn2 setShowFirstSteps={setShowFirstSteps}/>}
        </div>
      </header>
    </div>
  );
};

export default StartPage;