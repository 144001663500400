


function _getVars(consts){
    // console.log('inside _getVars', consts)
    let fields  = {};
    Object.entries(consts.fields).forEach(e => {fields={...fields, [e[0]]:e[1].value}});
    return{fields: fields};
};

export {_getVars};