import { useSelector } from "react-redux"
import { translate } from "../../../../components/Language/Translate";


const DestinationList = ({destination="", selectDestination}) => {
    const destinations = useSelector(st => st.destinations);
    console.log({destination, selectDestination})

    const filtered = destinations.filter(d => `${d.deDE} / ${d.enUK}`?.toLowerCase().includes(destination?.toLowerCase()));
    if(!destination || destination?.length < 3)return <p>{translate("Please insert at minimum 3 Characters")}</p>
    if(destination?.length > 2 && filtered.length===0)return <p>{translate("No matching items")}</p>
    return(
        <ul>
            {filtered.map(d => <li key={d.id} id={d.id} onClick={selectDestination}>{d.deDE} / {d.enUK}</li>)}
        </ul>
    )
};
export default DestinationList;