import React, {useState, useEffect} from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import Album from "./Album";
import { translate } from "../Language/Translate";

const AlbumProvider = ({photos, setView, actualPhoto, setActualPhoto}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [album, setAlbum] = useState(true)

    useEffect(() => {
        const fetch = async () => {
            const data = (await simpleRequest({url:`albums/${actualPhoto.albumId}`})).data;
            (() => setAlbum(data?.album || {}))();
            (() => setIsLoading(false))();
        };
        if(isLoading)fetch();
    })
    
    const images = photos?.filter(ap => album.id === ap.albumId) || [];
    // console.log({images});

    return(
        <div>
            <Album albumPhotos={images} album={album} setActualPhoto={setActualPhoto} actualPhoto={actualPhoto}/>
            <button className="btn btn-outline-dark" onClick={() => setView("diaShow")}>{translate("Back to Dia Show")}</button>
        </div>
        
    )
};
export default AlbumProvider;