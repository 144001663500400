import React, {useState} from "react";
import { useSelector } from "react-redux";
import Randomfn from "../../../helpers/Randomfn";
import { translate } from "../../Language/Translate";
import ProposalsNewForm from "../../../newForms2/proposals/ProposalsNewForm";
import ProposalItems from "./ProposalItems";







const ProposalList = ({setView}) => {
    const currentDestination = useSelector(st => st.currentDestination);
    const currentUser = useSelector(st => st.currentUser);
    const loggedIn = currentUser?.username;
    const proposals = currentDestination?.proposals || [];
    const [proposalIdx, setProposalIdx] = useState(-1);
    // const [view, setView] = useState('items') // items, formNew
    const newArray = Array.from(new Set(proposals.map(p => p.type))).sort();
    const newArray2=newArray.map(p => {return{key:Randomfn.createRandomString(), type:p}});
    return(
        <div className="text-center">
            {proposals.length>0 && <h4 className="mt-4" key = 'title'>{translate("Proposals")}:</h4>}
            {loggedIn && <button onClick={() => setView('formNew')} className="btn btn-outline-dark m-1">{translate("Add Proposal")}</button>}
            {newArray2.map(t=> 
                <div key={t.key}>
                    <h5 key={t.key} className="mt-4">{translate(t.type)}</h5>
                    <ProposalItems type = {t.type} proposals={proposals} proposalIdx={proposalIdx} setProposalIdx={setProposalIdx} setView={setView}/>
                </div>
            )}

            
        </div>
    )
};
export default ProposalList;