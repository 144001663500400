// import UserFields from "./Fields/UserFields"
// import UserFormPastSubmit from './UserFormPastSubmit';
// import {FormEvaluation} from './FormEvaluation'
import UserFieldsData from '../Fields/UserFieldsData';
import FormDataClass from '../FormDataClass';
import {simpleRequest} from '../../newForms2/simpleRequest';
import {getSelectListCompanies, getSelectListStatus} from '../../helpers/auth';
import {_getVars} from '../../helpers/getVars';
import UserSchema from '../Fields/UserSchema';
import { translate } from '../../components/Language/Translate';
// import Randomfn from '../../helpers/Randomfn';
// import { seveloper } from '../../config';
import { isInDevelopment } from '../../config';
import Randomfn from '../../helpers/Randomfn';

class UserFormData{


    static async userRegister(){
        
        const consts = await new FormDataClass();
        
        consts.fields = {
            username: {...UserFieldsData.username,autofocus:true, toolTip:"The name for the login. Must be unique. No spaces or @"},
            nickname: {...UserFieldsData.nickname,toolTip:"This is the name, that will be shown in your profile. It can be your full name, or e.g. an artist name."},
            firstName: {...UserFieldsData.firstName,toolTip: "Will not be shown to other users."},
            lastName: {...UserFieldsData.lastName,toolTip: 'Will not be shown to other users.'},
            email: {...UserFieldsData.email,toolTip: 'Will not be shown to other users.'},
            confirmEmail: {...UserFieldsData.confirmEmail,toolTip: 'Will not be shown to other users.'},
            language: UserFieldsData.language,
            photoUrl: UserFieldsData.photoUrl,
            password: UserFieldsData.password,
            confirmPassword: UserFieldsData.confirmPassword
        };
        consts.formType = "userRegister";
        consts.request = {
                url:"users/register",
                method:"post"
        };
        consts.evaluation=true;
        consts.formLabels = {title: "User Register", btnLabel:"Register"};
        // consts.successButton={
        //     link :`/code/confirmations`, label:"Proceed with confirmationcode from email"
         // }
        consts.nextPage='/login';
        const randomUserData = Randomfn.randomUserData;
        // const vars = isInDevelopment ? Randomfn.randomUserData : _getVars(consts)
        const vars = _getVars(consts)
        // console.log({consts, vars, isInDevelopment, randomUserData});
        return {consts, vars};
    };
    static async userLogin(){
        // console.log('inside login')
        const consts = await new FormDataClass();
        consts.formType = "userLogin";
        consts.fields = {
                username:{...UserFieldsData.username, autofocus:true},
                password:{...UserFieldsData.password},
                forgotPassword:UserFieldsData.forgotPassword,
                // username2:{...UserFieldsData.username, autofocus:true, validation:{}},
        };
        consts.request = {url:'users/authenticate/login',
                method:'post'};
        consts.evaluation=true;
        consts.actions={onSuccess:true}
                
        consts.formLabels = {
            title:"Login",
            btnLabel:"Login"
        };
        // consts.proceedButton={
        //     link :`/confirmation`, label:"Proceed with confirmationcode from email"}
        const vars=_getVars(consts);
        delete vars.forgotPassword;
        return {consts, vars};
    };
    
    static async userEditByUser({currentUser}){
        const consts = new UserSchema();
        consts.sendData = ["nickname", "firstName", "lastName", "email", "language", "photoUrl"];
        consts.request = {url:`users/${currentUser.username}`, method:"patch"}
        const vars = {fields:{}};
        (["username"].concat(consts.sendData)).forEach(v => vars.fields[v] = currentUser[v]);
        consts.fields.email.toolTip='If you change this field, you will get a new email to confirm this email address and you must login again.'
        consts.fields.username.fieldType="info";
        consts.formLabels = {title: "Edit Profile", btnLabel:"Update"}
        consts.oldValues = {...vars.fields};
        consts.nextPage = `/users/${currentUser.username}`
        return {consts,vars};
    };

    static async userEditByAdmin(data){
       
        const {currentUser} = data;
        const otherUserName = data.params.username;
        const responseUser = await simpleRequest({url:`admin/users/${otherUserName}`, method:"get"});
         // console.log({responseUser, data}, "userEdibByAdmin")
        if (responseUser && responseUser.data && responseUser.data.error){
            return {error:true, errorMessage:responseUser.data.errorMessage}
        }
        const otherUser = responseUser.data && responseUser.data;

        // console.log({otherUser})
        if(!otherUser)return{error:true, errorMessage:"Loading Failed"}
        const responseCompany = await simpleRequest({url:`companies`, method:"get"});
        const companies = responseCompany.data.companies;
        const fieldCompanies = currentUser && companies && getSelectListCompanies(currentUser.userStatus, companies);
        const fieldStatusList = currentUser && companies && getSelectListStatus(currentUser.userStatus, companies);
        // console.log({fieldCompanies, fieldStatusList})
        const consts = await new FormDataClass();
        // console.log({consts})
        consts.formType = "userEditByAdmin";
        
        consts.fields = {
            username: {...UserFieldsData.username, value:otherUser.username, disabled:true, validation:{}}, 
            nickname: {...UserFieldsData.nickname, value: otherUser.nickname, disabled:true, validation:{}},
            firstName: {...UserFieldsData.firstName, value: otherUser.firstName, disabled:true, validation:{} },
            lastName: {...UserFieldsData.lastName, value: otherUser.lastName, disabled:true, validation:{} },
            oldStatus: {...UserFieldsData.userStatus, value: otherUser.userStatus, disabled:true, label:"Recent User Status", fieldType:"text" },
            companyHandle: {...UserFieldsData.companyHandle, value: otherUser.companyHandle},
            userStatus:{...UserFieldsData.userStatus, label:"New User Status", value:otherUser.userStatus, validation:{minLength:3}}
        };
        
        consts.request = {
            url:`admin/users/${otherUserName}`, 
            method:"patch"
        }
        consts.formLabels = {title: "Edit Profile", btnLabel:"Update"}
        consts.handleBeforeRequest=true;
        // consts.evaluation=true;
        consts.nextPage = `/users/show/${data.params.username}`
        consts.sendData = ['userStatus'];
        consts.fields.companyHandle.settings.options=fieldCompanies;
        consts.fields.companyHandle.settings.options.forEach(o=>console.log(o))
        // const oldStatus = consts.fields.oldStatus;
        // console.log({oldStatus})
        consts.fields.companyHandle.value=consts.fields.oldStatus.value.includes('|')?consts.fields.oldStatus.value.split('|')[0]:"";
        consts.fields.userStatus.settings.options=fieldStatusList;
        const vars=_getVars(consts);
        consts.oldValues = {...vars.fields}
        // console.log({consts, vars})
        return {consts, vars};
    };
    static async userForgotPassword(data){
                // console.log('inside register',data)
       
        const consts = await new FormDataClass();
        // console.log({consts});
        
        consts.fields = {
            usernameOrEmail: {...UserFieldsData.usernameOrEmail,autofocus:true},
        };
        consts.formType = "userForgotPassword";
        consts.request = {
                url:"users/password/request",
                method:"post"
        };
        // consts.successButton={link :`/password/code`, label:"Proceed with confirmationcode from email"}
        // consts.successButton={link :`/login`, label:"To login"}
        consts.evaluation=true;
        consts.formLabels = {title: "Forgot Password", btnLabel:"Send Request"};
        const vars=_getVars(consts)
        return {consts, vars};
    }

    static async userReceiveRestoreCode(data){
        // const {code}  = data.params;
        // console.log('inside userReceiveRestoreCode',{data})
        const error = {};
        // if (!code) {
        //     error.errorMessage="No data."
        //     return {consts:{}, vars :{}, error:{}}
        // }
        const consts = await new FormDataClass();
        consts.formType = "userReceiveRestoreCode";
        consts.fields ={
            code: UserFieldsData.codeField,
            password: UserFieldsData.password,
            confirmPassword: UserFieldsData.confirmPassword,
        }
        const vars = {fields: {password:"", confirmPassword:"", code:""}};
        consts.request = {url:'users/password/set', method: "patch"};
        consts.nextPage = "/login";
        consts.evaluation=true;
        consts.formLabels = {title: translate("Set new Password"), btnLabel:translate("Send")};
        // console.log({consts, vars, error})
        return {consts, vars, error};

    }
}
export default UserFormData;

