import React from "react";
// import html-l
import {html} from './html/datenschutz.js';

import {Translate, translate} from '../Language/Translate';
import UsedData from "./html/usedData.js";
import {DeleteData} from "./html/deleteData.js";

export default function Datenschutz2() {

    const language = Translate.language || "enUK";

    const Content = () => (
        <div style={{backgroundColor:"rgba(255,255,255,.7)"}} className="p-4">
            <h2>{translate("Content")}</h2>
            <p>{translate("Used Data")}</p>
            <p>{translate("Delete Account")}</p>
            <p>{translate("Privacy Policy")}</p>
        </div>
    )

    return (
        <div>
            <Content/>
            <UsedData />
            <DeleteData />
            <div dangerouslySetInnerHTML={ {__html: html[language]} } style={{backgroundColor: "rgba(255,255,255,.8)"}} className="p-3"/>
        </div>
    
        );
}

