// 4.12.23
import React, {useState} from "react";
import PhotoShow2 from "./PhotoShow2";
import PhotoDetails from "./PhotoDetails";
import AlbumProvider from "./AlbumProvider";

const PhotoShowProvider = ({photos}) => {
    
    const [view, setView] = useState('diaShow'); //"details", "album"
    const [actualPhoto, setActualPhoto] = useState({});
    return(
        <div>
            {view === "diaShow" && <PhotoShow2 photos={photos} actualPhoto = {actualPhoto} setActualPhoto={setActualPhoto} setView={setView}/>}
            {view === "details" && <PhotoDetails actualPhoto= {actualPhoto} photos={photos} setActualPhoto={setActualPhoto} setView={setView}/>}
            {view === "album" && <AlbumProvider photos={photos} actualPhoto= {actualPhoto} setActualPhoto={setActualPhoto} setView={setView}/>}
        </div>
    );
};
export default PhotoShowProvider;