import {BASE_URL} from '../config'
import axios from "axios"
import {globals} from '../helpers/globals';

function createAxiosRequest({url, method="get", data}){
    const token = globals.token || '';
    const headers = {'authorization': `Bearer ${token}`}
    return {url:`${BASE_URL}/${url}`, method, data, headers};
};



async function simpleRequestNew ({url, method="get", data}){
    const token = globals.token || '';
    const headers = {'authorization': `Bearer ${token}`}
    const axiosRequest = {url:`${BASE_URL}/${url}`,method ,data, headers };
    // console.log('inside simpleRequestNew', axiosRequest)
    return await axios(axiosRequest);

};
async function simpleRequestCopy ({url,method="get",data}){
    // const user = JSON.parse(localStorage.getItem('user'));
    // const token = user? user.token:null;
    const token = globals.token || '';
    const headers = {'authorization': `Bearer ${token}`}
    const axiosRequest = {
        url:`${BASE_URL}/${url}`,
            method,
            data,
            headers
    };
    // console.log({axiosRequest});
    try{
        const response = await axios(axiosRequest);
        // console.log(response)
        // console.log('Success', method, url, {response, axiosRequest});
        return response;
    }catch(err){
        // console.log(err)
        // console.log('Error', method, url, err && err.response);
        const errorMessage = err.response && err.response.data;
        const status = err.response && err.response.status
        return {...err, errorMessage, status};
    };
};
async function simpleRequest ({url, method="get", data}){
    // const user = JSON.parse(localStorage.getItem('user'));
    // const token = user? user.token:null;
    const token = globals.token || '';
    const headers = {'authorization': `Bearer ${token}`}
    const axiosRequest = {
        url:`${BASE_URL}/${url}`,
            method,
            data,
            headers
    };
    // console.log('axios', axiosRequest);
    try{
        const response = await axios(axiosRequest);
        // console.log({axiosRequest, response});
        // console.log('Success', method, url, {response, axiosRequest});
        return response;
    }catch(err){
        console.error("ERROR IN REQUEST", {axiosRequest, err});
        // console.log({err});
        // console.log(err);
        // console.log(err?.response);
        // console.log(err?.response?.data);

        // console.log('Error', method, url, err && err.response);
        const errorMessage = err.response && err.response.data;
        const status = err.response && err.response.status
        return {...err, errorMessage, status};
    };
};
// async function simpleRequestCopy ({url,method="get",data}){
//     // const user = JSON.parse(localStorage.getItem('user'));
//     // const token = user? user.token:null;
//     const token = globals.token || '';
//     const headers = {'authorization': `Bearer ${token}`}
//     const axiosRequest = {
//         url:`${BASE_URL}/${url}`,
//             method,
//             data,
//             headers
//     };
//     // console.log({axiosRequest});
//     try{
//         const response = await axios(axiosRequest);
//         // console.log(response)
//         // console.log('Success', method, url, {response, axiosRequest});
//         return response;
//     }catch(err){
//         // console.log(err)
//         // console.log('Error', method, url, err && err.response);
//         const errorMessage = err.response && err.response.data;
//         const status = err.response && err.response.status
//         return {...err, errorMessage, status};
//     };
// };




async function advancedRequest({url, headers={}, method="GET", data={}}){
    // console.log('inside advancedRequest', {url, headers, method, data});
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user? user.token:null;
    const axiosRequest = {
        url:`${BASE_URL}/${url}`,
            method,
            data,
            headers: {...headers, 'authorization': `Bearer ${token}`}
    };
    // console.log({axiosRequest}, axiosRequest.data);
    try{
        const response = await axios(axiosRequest);
        // console.log('Success', {response});
        // console.log('Success', response.status)
        // console.log('Success', response.statusText)
        // console.log('Success', url, response.status, response.data, response.error);
        return response
    }catch(err){
        // console.log('Errorooooooooooooooooooooooooo', {err})
        // console.log('Error', err.response && err.response.data)
        // console.log('Error', err.response && err.response.status)
        // console.log('Error', err.response && err.response.statusText)
        const res = err.response
        const message = `Error: Status ${res && res.status}, ${res && res.statusText} (${res && res.data})`;
        return {...err, message};
    };
};

async function getErrors(type){
    // console.log('inside getErrors');
    const url =`${BASE_URL}/errors/${type}`
    // console.log({url})
    const response = await axios.get(url);
    // console.log({response});
    return response;
};













export {simpleRequest, simpleRequestCopy, getErrors, advancedRequest, createAxiosRequest, simpleRequestNew};