import { Form } from "react-bootstrap"
import { translate } from "../../../../components/Language/Translate";

const TextAreaField = ({name, variables, label, handleChange}) => (
        <Form.Group size="lg" controlId={name} >
            <Form.Label >{translate(label)}</Form.Label>
            <Form.Control as="textarea" rows={5}
                name={name} 
                type="textarea" 
                fieldtype="textarea" 
                key={name} 
                autoFocus={true}
                value={variables[name]}
                onChange={e => handleChange(e)} 
            />
        </Form.Group>
    );
export default TextAreaField;