import React, { useState, useEffect } from "react";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import Spinner from "../../Spinner";
import ListOfDestinationsItem from "./ListOfDestinationsItem";

const ListOfDestinations = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [albums, setAlbums] = useState([]);
    // const [albumPhotos, setAlbumPhotos] = useState([]);
    const [albumDestinations, setAlbumDestinations] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [days, setDays] = useState([]);

    const destinationsIdsInUse = Array.from(new Set(days.filter(d => !!d.destinationId).map(d => d.destinationId)));
    const destinationsIdsWithAlbums = Array.from(new Set(albumDestinations.map(ad => ad.destinationId)));
    const both = Array.from(new Set(destinationsIdsInUse.concat(destinationsIdsWithAlbums)));
    const destinationsInUse = destinations.filter(dest => both.includes(dest.id));

    useEffect(() => {
        const fetch = async() => {
            const al = (await simpleRequest({url:"albums"})).data.albums;
            // const ab = (await simpleRequest({url:"albumphotos"})).data.albumPhotos;
            const ad = (await simpleRequest({url:"albumdestination"})).data.albumDestinations;
            const dest = (await simpleRequest({url:"destinations"})).data.destinations;
            const dys = (await simpleRequest({url:"days"})).data;
            setAlbums(al);
            // setAlbumPhotos(ab);
            setAlbumDestinations(ad);
            setDestinations(dest);
            setDays(dys);
            setIsLoading(false);
        };
        if(isLoading)fetch();
    }, [isLoading]);
    if(isLoading) return <Spinner/>

    return(
        <div className="text-left p-3">
            {destinationsInUse.map(d => 
                <ListOfDestinationsItem key={d.id}
                                        destination = {d}
                                        destinations = {destinations}
                                        days = {days}
                                        albumDestinations={albumDestinations}
                                        setAlbumDestinations={setAlbumDestinations}
                                        albums={albums}
                                        />

                )}
        </div>
    )
};
export default ListOfDestinations;