import {translate} from '../../components/Language/Translate';

class AnnouncementFormSchema{
    constructor(){
        this.formType="announcementNew";
        this.nextPage=""
        this.request = {
            url:"announcements",
            method:"post",
        };
        this.formLabels = {title: "Add Announcement", btnLabel:"Add"};
        // "cruiseId", "by", "subject", "description", "url", "visibleCrew", "visibleStaff", "visibleGuests"

        this.fields = {

            cruiseId : {
                name:"cruiseId",
                label : "Cruise ID",
                fieldType: "info_stay",
                type:"number",
                value :0,
                required:true
            },
      
            by : {
                name:"by",
                label : translate("By"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                validation:{maxLength:40}
            },
            subject : {
                name:"subject",
                label : translate("Subject"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                validation:{maxLength:40}
            },
            description: {
                name:"description",
                label : translate("Description"),
                type:"text",
                fieldType: "textarea",
                disabled:false,
                value :"",
            },
            visibleCrew: {
                name:"visibleCrew",
                label : translate("Visible for Crew"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true,
            },
            visibleStaff: {
                name:"visibleStaff",
                label : translate("Visible for Staff"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true,
            },
            visibleGuests: {
                name:"visibleGuests",
                label : translate("Visible for Guests"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true
            },

        }
    }
};
export default AnnouncementFormSchema