import React, { useState, useEffect } from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { Translate, translate } from "../Language/Translate";
import Tour from "./TourListItem";
import TourNew from "../../newForms2/tours/TourNew";
import { useSelector } from "react-redux";
import { getDateObject } from "../../helpers/Date";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TourList = ({ action, tourEscorts }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tours, setTours] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [openTour, setOpenTour] = useState(0);
    const [showFormNew, setShowFormNew] = useState(false);
    const currentCruise = useSelector(st => st.currentCruise);
    const { prefix, name } = currentCruise;
    const { language } = Translate
    const { arrival, departure } = action;
    const history = useHistory();

    const handleClickNew = () => {
        setOpenTour(0);
        setShowFormNew(true);
    };
    const handleDailyDuty = async() => {
        const dailyDuty = (await simpleRequest({url: `worddocuments/dailyduty/${currentCruise.id}/${action.date}`})).data?.dailyDuty;
        console.log({dailyDuty});

    }

    useEffect(() => {
        const fetch = async () => {
            const _tours = (await simpleRequest({ url: `tours/day/${action.id}` })).data?.tours || [];
            const _templates = (await simpleRequest({ url: `tours/destination/${action.destinationId}` })).data?.tours || [];
            // console.log({_templates, _tours});
            const sortTours = _tours
                .sort((a, b) => a.endtime <= b.endtime ? 1 : -1)
                .sort((a, b) => a.starttime >= b.starttime ? 1 : -1)
            setTours(sortTours);
            setTemplates(_templates ? _templates : []);
            setIsLoading(false);
        };
        if (isLoading) fetch()

    }, [isLoading, action]);

    // console.log({action, currentCruise})
    // if (!action.destinationId) return <h6>{translate("No Destination")}</h6>;

    const cancelled = tours.filter(t => !!t.cancelled);
    const notCancelled = tours.filter(t => !t.cancelled);

    const handleClickWishList = async () => {
        // console.log('Wish List');
        const wishlist = (await simpleRequest({ url: `worddocuments/wishlist/${currentCruise.id}` })).data?.wishlist;
        console.log({ wishlist });
    }

    const buttons = () => (
        <>
            {/* <p>Hallo Buttons</p> */}
            {!action.destinationId && <Row className="my-2">
                <Col>
                    <h6 className="text-right">{translate("No Destination")}</h6>
                </Col>
                <Col>
                    <button className="btn btn-outline-dark mx-1" onClick={() => history.push("/destinations")}>{translate("Add Destination")}</button>
                </Col>
            </Row>}
            

            <button className="btn btn-outline-dark mx-1" onClick={handleClickNew}>{translate("Add Tour")}</button>
            <button className="btn btn-outline-dark mx-1" onClick={handleClickWishList}>
                {translate("Wish List")}{` - `}{currentCruise.shorthand}
            </button>
            <button className="btn btn-outline-dark mx-1" onClick={handleDailyDuty}>
                {`${translate("Daily Duty")}, ${getDateObject(action.date, language).short}`}
            </button>
        </>
    );

    return (
        <div>
            <h5>
                {translate("Tours")}{` - `}
                {prefix ? `${prefix} ` : ""}{`${name}, `}
                {getDateObject(action.date, language).longWeekday}
                <small>{`, ${translate("Harbour Time")}: ${arrival || ''} - ${departure || ''}`}</small>
            </h5>


            <ul>
                {notCancelled.map(t => <Tour key={t.id} tour={t} openTour={openTour} setOpenTour={setOpenTour} setTours={setTours} action={action} />)}
            </ul>
            {/* {cancelled.length > 0 ? <h6>{translate("Cancelled")}</h6> : null} */}
            <ul>
                {cancelled.map(t => <Tour key={t.id} tour={t} openTour={openTour} setOpenTour={setOpenTour} setTours={setTours} action={action} />)}
            </ul>
            {!showFormNew && buttons()}
            {/* {!showFormNew && <button className="btn btn-outline-dark" onClick = {handleClickNew}>{translate("Add Tour")}</button>} */}
            {showFormNew && <TourNew action={action} backFunc={() => setShowFormNew(false)} setTours={setTours} />}
        </div>
    )
};
export default TourList;