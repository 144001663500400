import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { translate } from "../../../components/Language/Translate";

const RoutingFormNew = ({setProposals}) => {
    const [value, setValue] = useState('');
    const formatTime = (time) => {
        const arr = time.split('.');
        if(arr.length !== 2)return("");
        const newArr = arr.map(i => `0${i}`.slice(-2));
        return newArr.join(':');
    }
    
    useEffect(() => {
        if(!value)return;
        try{
            const arr = value.split('\n');
            const arr2 = arr.map((i, idx) => {
                const [wd, dateOrigin, destination, ...rest] = i.split(' ');
                console.log({i, dateOrigin});
                const [_day, _month] = dateOrigin?.split('.');
                const day= `0${_day}`.slice(-2);
                const month= `0${_month}`.slice(-2);
                const arrival = formatTime(rest.find(r => r.includes('.')) || "");
                const departure = formatTime(rest.reverse().find(r => r.includes('.')) || "");

                return{
                    idx,
                    fulltext:i,
                    day,
                    month,
                    wd,
                    dateOrigin, 
                    dateMonthDay: `-${month}-${day}`,
                    destination: i.includes('Entspannung auf See') ? "Auf See" : destination,
                    rest,
                    place: i.includes(' t ') ? "Anchor" : "Pier",
                    arrival,
                    departure
                }
            })

            setProposals([...arr2]);
        }catch(err){
            console.log({"ERROR": err})
        }
    }, [value, setProposals]);

    const handleChange = e => {setValue(e.target.value)};

    // const handleSubmit = () => console.log("submit");

    return (
        <Form>
        <Form.Group size="lg" controlId={"routing"} >
            <Form.Label  className ="pt-1 px-1" >Text</Form.Label>
            <Form.Control as="textarea" rows={5}
                name={"routing"} 
                type="textarea" 
                fieldtype="textarea" 
                key={"description"} 
                autoFocus={true}
                value={value}
                onChange={e => handleChange(e)}
                placeholder={translate("Please insert the whole routing text FROM Tours")}
            />
        </Form.Group>
        

    </Form>
    )
};
export default RoutingFormNew;