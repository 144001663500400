import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { translate } from "../Language/Translate";
import { StyledDivWithBorder } from "../styledComponents/divs";
import ChatPostNew from "../../newForms3/travelchats/ChatPostNew";
import ChatPostList from "./ChatPostList";
import ChatCommentNew from "../../newForms3/travelchats/ChatCommentNew";
import ChatCommentList from "./ChatCommentList";

const ChatPostDetails = ({post, backFunc}) => {
    const [showForm, setShowForm] = useState('none'); //addComment, //edit
    // console.log({post});
    return(
        <StyledDivWithBorder className="m-1 p-2">
            <h5>{post.title}</h5>
            <h6>{post.details}</h6>
            {showForm === "addComment" && <ChatCommentNew post={post} backFunc={() => setShowForm("none")}/>}
            {showForm === "none" && 
                <Row>
                    <Col><button className="btn btn-outline-dark col-12" onClick={() => setShowForm('addComment')}>{translate("Add Comment")}</button></Col>
                    <Col><button className="btn btn-outline-dark col-12">{translate("Edit")}</button></Col>
                    <Col><button className="btn btn-outline-secondary col-12" onClick={backFunc}>{translate("Back")}</button></Col>
                </Row>}
            {showForm === "none" && <ChatCommentList post={post}/>}
        </StyledDivWithBorder>
    )
};
export default ChatPostDetails;