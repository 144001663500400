class DestinationClass{
    static departmentArr = [
        "Hospital",
        "Hospital basic", 
        "Hospital regular", 
        "Hospital maximal", 
        "Gynecology", 
        "Pediatric", 
        "Doctor",
        "Dentist", 
        "Oculist", 
        "Pharmacy", 
    ];
    static _touristTargetArr = [
        "Restaurant",
        "Bar",
        "Beach",
        "Sight",
    ];
    static getProposalArray (destination){
        if(!destination?.proposals)return [];
        const propTypes = destination.proposals.map(p => p.type);
        return this._touristTargetArr.filter(t => propTypes.includes(t));
    };
    static _reduceToValues(arr){
        return arr.join('###')
        .replaceAll("Hospital basic", "Hospital")
        .replaceAll("Hospital regular", "Hospital")
        .replaceAll("Hospital maximal", "Hospital")
        .split('###')
    };
    static getMedInstDepartmentArray(destination){
        // console.log({destination});
        if(!destination)return [];
        const departments = destination.medicalInstitutes?.map(mi => mi.departments?.split('#') || [])
            .reduce((accum, next) => [...accum, ...next], []);
        const reduced = this._reduceToValues(departments);
        // console.log({departments});
        return this.departmentArr.filter(d => reduced.includes(d));
    };
};
export default DestinationClass;