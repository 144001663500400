import React, { useEffect, useState } from "react";
import { translate } from "../../components/Language/Translate";
import Spinner from "../../components/Spinner";
import DestinationFormSchema from "./DestinationFormSchema";
import { Button, Form } from "react-bootstrap";
import FormFields from "../FormFields";
import { useDispatch } from "react-redux";
import { addDestinationOnAPI } from "../../actions/destinations";
import { getGeolocationFromAddress } from "./helpers";
// import Geolocation from "../../helpers/geolocation";


const DestinationNew = ({setShowFormNew}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldsettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const fetch = () => {
            const sets = {...new DestinationFormSchema().fields};
            (() => setFieldsettings(sets))();
            const v = {};
            Object.keys(sets).forEach(k => v[k] = sets[k].value);
            (() => setVariables({...v}))();
            (() => setIsLoading(false))();
        };
        if(isLoading)fetch();
    }, [setFieldsettings, setVariables, setIsLoading, isLoading])
    if(isLoading)return <Spinner/>

    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError
    }



    // const handleGetGeolocationFromAddress = async(e, variables, setVariables) => {
    //     e.preventDefault();
    //     const previous = variables.geolocation;
    //     const address = variables.deDE || variables.enUK;
    //     if(!address)return;
    //     const data = (await simpleRequest({url:"/destinations/geolocation", method:"post", data:{address}})).data;
    //     setVariables({...variables, geolocation: data.geolocation || ''})
    //     // console.log({data, previous}, data.geolocation);
    // }
    // };
    const handleSubmit = e => {
        e.preventDefault();
        // console.log("submit", {fieldErrors}, Object.values(fieldErrors).join("").length);
        if(Object.values(fieldErrors).join("").length !== 0)return;
        // console.log('keine Fehler', variables);
        dispatch(addDestinationOnAPI({...variables}));
        setShowFormNew(false);
    };

    return(
        <div className = "p-3" style = {{border:"1px solid black", borderRadius:"5px"}}>
            <h4>{translate("Add Destination NEU")}</h4>
            <Form onSubmit={e => handleSubmit(e)}>
                <FormFields props = {props}/>
                <button className="btn btn-outline-dark mb-2" 
                        onClick={e => getGeolocationFromAddress(e, variables, setVariables)}>
                            {translate("Get geolocation from address")}
                </button>
                <Button block type="submit" onClick={e => handleSubmit(e)}>{translate("Submit")}</Button>
            </Form>
        </div>
    );
};
export default DestinationNew;