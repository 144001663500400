
import {
    FETCH_VESSEL, UPDATE_CURRENT_VESSEL,
    } from "../actions/types";





export default function rootReducer(state = {}, action) {
    switch (action.type) {

    case FETCH_VESSEL:
       return ({...action.currentVessel});
    case UPDATE_CURRENT_VESSEL:
      return({...state, ...action.vessel})
    default:
      return state;
  }
}