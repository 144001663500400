import React, { useState, useEffect } from "react";
import Fields from "./Fields";
import { simpleRequest } from "../../simpleRequest";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";
import Spinner from "../../../components/Spinner";

const SpeciesGroupUpdate = ({props}) => {
    const [isLoading, setIsLoading] = useState(true);
    const {setSpeciesGroups, speciesGroups, setOpenForm, speciesGroupId} = props;
    
    const [variables, setVariables] = useState({});

    useEffect(() => {
        const fetch = () => {
            const {category, enUK, deDE, latin} = speciesGroups.find(sg => sg.id === speciesGroupId);
            setVariables({category, enUK, deDE, latin});
            setIsLoading(false)
        }
        if(isLoading)fetch();
    }, [speciesGroups, speciesGroupId, setVariables, isLoading]);
    if(isLoading)return <Spinner/>

    const handleSubmit = async(e) => {
        e.preventDefault()
        const request = {url:`speciesGroups/${speciesGroupId}`, method:"patch", data:{...variables}};
        console.log({request});
        const speciesGroup = (await simpleRequest(request)).data?.speciesGroup;
        console.log({speciesGroup});
        (() => setSpeciesGroups([...speciesGroups.filter(sg => sg.id !== speciesGroupId), speciesGroup]))();
        (() => setOpenForm('speciesGroupList'))();
    };

    console.log({variables})

    return(
        <div className="mt-5">
            <h5>{translate("Update Group")}</h5>
            <Form >
                <Fields variables={variables} setVariables={setVariables}/>
                <button className="btn btn-outline-dark" onClick={e => handleSubmit(e)}>{translate("Submit")}</button>
                <button className="btn btn-outline-secondary ml-1" onClick={() => setOpenForm('showSpeciesGroup')}>{translate("Cancel")}</button>
            </Form>
        </div>

    )
};
export default SpeciesGroupUpdate;