import React, { useEffect, useState } from "react";
import {Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import Spinner from '../Spinner';
import {translate} from '../Language/Translate';
import { useDispatch, useSelector } from "react-redux";
import {BlackWhite} from '../styledComponents/colors'
import CruiseBackToCruiseButton from "../CruiseBackToCruiseButton";
import DestinationNew from "../../newForms2/destinations/DestinationNew";
import { fetchDestinationsFromAPI } from "../../actions/destinations";

const DestinationList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [showFormNew, setShowFormNew] = useState(false);
    const destinations = useSelector(st => st.destinations);
    const dispatch = useDispatch();
    
    useEffect(()=>{
        const fetchData = async() => dispatch(fetchDestinationsFromAPI());
        (() => setIsLoading(false))();
        if (!destinations?.length) fetchData();
    },[setIsLoading, dispatch, destinations]);
    
    if(isLoading)return <Spinner/>
    const handleChange=(e)=> setSearch(e.target.value);

    const destList = destinations && (destinations.filter(d => (d.deDE.toLowerCase().includes(search.toLowerCase()) 
                        || d.enUK.toLowerCase().includes(search.toLowerCase()))));


    return(
        <>
            <CruiseBackToCruiseButton />
            <h3 className="mt-5">{translate("Destinations")} </h3>
            
            <button className="btn btn-outline-dark m-3" onClick={() => setShowFormNew(!showFormNew)}>
                {showFormNew ? translate("Back") : translate("Add Destination")}
            </button>
    
            {showFormNew && <DestinationNew setShowFormNew = {setShowFormNew}/>}
            <Form onSubmit={e => e.preventDefault()}>
                <Form.Group>
                    <Form.Label><h5 className="mt-3">{translate("Get Destination")}</h5></Form.Label>
                    <Form.Control
                        type="text"
                        onChange = {e => handleChange(e)}
                        autoFocus = {true}
                    >
                    </Form.Control>
                </Form.Group>
            </Form>
            <ul>
                {destList && destList.map(d=>{
                    return(<li key={d.id}>
                                <Link to={`destination/details/${d.id}`}><BlackWhite>{d.deDE} / {d.enUK}</BlackWhite></Link>
                           </li>)
                })}
            </ul>
        </>
    );
};

export default DestinationList;