import { translate } from "../../components/Language/Translate";

// import {BASE_URL} from '../../config'

class UserFormEvaluation{

    static async userRegister(constants, variables, data){
        // console.log('inside UserFormEvaluation.userRegister', {constants, variables, data})
        // const {setSuccessText} = constants.events;
        
        if(!data || !data.user){
            constants.events.setServerError(translate('No user. Registration failed'));
            constants.events.setSuccess(false)
            return
        }
        const {user} = data;
        const text = translate(`We sent an email to your email adress.`)
        // const txt2 = `${text}\n ${translate('Please open this email and copy the code inside to the clipboard in order to confirm this email address.')}\n\n ${translate('Then login.')}`
        const txt2 = `${text}\n ${translate('Please open this email and klick the link inside to confirm this email address.')}\n\n ${translate('Then login.')}`
        constants.successText={title:`${translate('User Registration successfull')} (${translate('username')}: ${user.username}).`,
                        text:txt2
                   };
        constants.events.setServerError('')
        constants.events.setSuccess(true);
   };

    static async userLogin(constants, variables, data){
        // console.log('inside UserFormEvaluation.userLogin', {constants, variables, data});
        const user = data && data.user;
        // console.log(user)
        if (!(user && user.isConfirmed))return;
        localStorage.setItem('user', JSON.stringify(user));
        constants.successText={title: `${translate('Login successful')}: ${translate('username')}: ${user.username}.`, text:''}
        constants.events.setSuccess(true);
        // console.log(constants)
            setTimeout( ()=>{
                window.location.href = '/';
            }, 1000);
        };
    static async userEditByUser(constants){
        // alert('Vergleich Email muss noch gebaut werden.');
        // const txt = [translate("You changed your email address."),
        //             translate("You will be logged out now."),
        //             translate("You will get a new confirmation mail."),
        //             translate("Please open your mail inbox and follow the instruction to confirm your new email address."),
        //             translate("Then login again")];

            // set confirmed to no
            // send confirmation mail
            // log out


        // const username = constants.fields.username.value;

        // constants.history.push(constants.nextPage);
    }

    static async userForgotPassword(constants, variables, data){
        // console.log('inside UserFormEvaluation.userForgotPassword', {constants, variables, data})
        const t1=translate("We sent an email to your email adress if username or email exists.")
        const t2=translate("Please open this email click the link inside in order to set a new password.")
        const t3=translate(`If you have sent more than one request, be sure to use the latest link.`)
            +`\n\n`+ translate("Then proceed.");
        constants.successText={title:translate(`Set new Password.`),
                        text:`${t1}\n${t2}\n${t3}`
        };
        constants.events.setSuccess(true);
    }

    static async userReceiveRestoreCode(constants, variables, data){
        constants.successText={title: translate(`New password was set successfully.`), text:translate('You can log in now.')}
        constants.events.setSuccess(true);
        
        setTimeout( ()=>{
            constants.history.push(constants.nextPage);
        }, 1000);
    }

};

export default UserFormEvaluation;