import React from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
import FieldValidation from "./validation/FieldValidation";
    
const TextField = ({props}) => {
    // console.log({props});
    const {name, setVariables, variables, fieldSettings, fieldErrors, setFieldErrors} = props;
    const {validation, label, required, toolTip} = fieldSettings[name];
    const value = variables[name] || "";
       
    const style = fieldErrors[name] ? {border:"2px solid red", borderRadius:"5px"} : {};
    const ErrorSpan = () => <span className = "text-danger">{` - ${fieldErrors[name]}`}</span>;
    
    const handleChange = e => {
        const {name, value} = e.target;
        setFieldErrors(fieldErrors => {return{...fieldErrors, [name]:""}});
        if(validation && value.length > validation.maxLength)return;
        setVariables({...variables, [name]:value});
    };
    const validateField = e => {
        
        const validObj = new FieldValidation(name, value, fieldSettings, variables).isValid();
        if(!validObj.valid)setFieldErrors({...fieldErrors, [name]:validObj.errMsg});
    };
    // console.log({props, required})

    return(
        <Form.Group>
        <Form.Label>{translate(label) + `${required ? ` *` : ''}`}
            {fieldErrors[name] && <ErrorSpan/>}
        </Form.Label>
        <Form.Control
            style={style}
            name={name}
            value={value}
            type="text"
            onChange={e => handleChange(e)}
            required = {required}
            onBlur={e => validateField(e)}
        />
        {toolTip && <p><small>{toolTip}</small></p>}
        </Form.Group>
    );
};
export default TextField;