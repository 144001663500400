import React from "react";
import { NavLink} from "react-router-dom";
import {translate} from "./Language/Translate"
import {useSelector} from 'react-redux'
import {Nav, NavDropdown } from "react-bootstrap"
import User from '../helpers/user';
import NewsBadge from "./NewsBadge";
import Symbols from "./Symbols";


const HeaderNavLinks = () =>{
    // console.log('INSIDE NAV')
    const currentUser = useSelector(st => st.currentUser);
    const groups = useSelector(st => st.groups);
    let firstName= (() => currentUser.firstName || '')();
    let username = (() => currentUser.username || '')();
    const allNews = User.news(currentUser, groups).countAll;

    return(
        <div className = "container">
        <Nav.Link as={NavLink} exact to={`/home`}>{translate("Home")}</Nav.Link>
        <NavDropdown title={translate("Cruises")} id="collasible-nav-dropdown">
            <NavDropdown.Item as={NavLink} exact to="/companies">{translate("Companies")}</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} exact to = {`/vessels`}>{translate("Vessels")}</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} exact to = {`/cruises`}>{translate("Cruises")}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} exact to = {`/destinations`}>{translate("Destinations")}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} exact to = {`/chatgroups`}>{translate("Chat Groups")}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} exact to = {`/photoalbums`}>{translate("Photo Albums")}</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} exact to = {`/photomonth`}>{translate("Photo of the Month")}</NavDropdown.Item>
            
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} exact to = {`/observations`}>{translate("Nature Observations")}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} exact to = {`/test`}>{translate("Test-Area")}</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link as={NavLink} exact to = {`/tools`}>{translate("Tools")}</Nav.Link>
        <Nav.Link as={NavLink} exact to = {`/editorials`}>{translate("Travel Magazine")}</Nav.Link>
        {!currentUser.token?<Nav.Link as={NavLink} exact to = {`/login`}>Login</Nav.Link>:null}
        {!currentUser.token?<Nav.Link as={NavLink} exact to = {`/register`}>{translate("Register")}</Nav.Link>:null}
        {currentUser.token?<Nav.Link as={NavLink} exact to = {`/logout`}>Logout</Nav.Link>:null}
        {firstName?<Nav.Link as={NavLink} exact to = {`/users/${username}`}>
           <Symbols typeArray={["Person"]}/> {firstName} <NewsBadge count={allNews}/></Nav.Link>:null}
        <Nav.Link  className="ms-auto" as={NavLink} exact to = {'/faq'} >Help/FAQ</Nav.Link>
    </div>
    )
}

export default HeaderNavLinks;