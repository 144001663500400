import React from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
    
const CheckBox = ({props}) => {
    const {name, variables, fieldErrors, label, required, toolTip, handleCheck} = props;
    const style = fieldErrors[name] ? {border:"2px solid red", borderRadius:"5px"} : {};
    const ErrorSpan = () => <span className = "text-danger">{` - ${fieldErrors[name]}`}</span>;
    
    // const handleChange = e => {
    //     // console.log(e.target)
    //     const {name, value, checked} = e.target;
    //     // console.log(name, value, checked);
    //     setVariables({...variables, [name]:checked});
    // };

    return(
        
        <Form.Group>
        <Form.Label>{translate(label) + `${required ? ` *` : ''}`}
            {fieldErrors[name] && <ErrorSpan/>}
        </Form.Label>
        <Form.Check
            type="checkbox" 
            style={style}
            name={name}
            checked={!!variables[name]}
            value={variables[name]}
            onChange={e => handleCheck(e)}
            required = {!!required}
        />
        {toolTip && <p><small>{toolTip}</small></p>}
        </Form.Group>
    );
};
export default CheckBox;