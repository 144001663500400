import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAuthorsFromAPI } from '../../actions/authors';
import Spinner from '../Spinner';
import {translate} from '../Language/Translate';
import { BlackWhite } from "../styledComponents/colors";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { Image, Row, Col } from "react-bootstrap";

function AuthorList() {

  const authors = useSelector(st => st.authors);
  const currentUser = useSelector(st => st.currentUser);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  

  useEffect(function() {
 
      const fetchAuthors = () => {
        // console.log('inside useEffect, fetchAuthors', {isLoading})
        dispatch(fetchAuthorsFromAPI());
        setIsLoading(false);
      };
  
    if (isLoading)fetchAuthors();
  }, [isLoading, dispatch]);

  if (isLoading) return <Spinner/>;

  const loggedIn = currentUser?.username
  const admin = ["admin", "developer"].includes(currentUser?.userStatus);

  const handleApply = async () => {
    const response = await simpleRequest({method:"get", url:`authors/apply/${currentUser?.username}`});
    // console.log({response});

  };
  // console.log({authors});
  
  const authorList = authors.sort((a, b) => a.lastName > b.lastName ? 1 : -1).map(a => {
    return(
      <Row key={a.username} className="text-left px-5 my-3" style={{listStyleType:"none"}}>

        <Col sm={4} className=" text-right"><Image src={a.photoUrl || a.userPhoto} style={{maxHeight:"80px", width:"auto"}}></Image></Col>
        <Col sm={8} ><Link to={`/authors/${a.username}`}><BlackWhite>{a.firstName} {a.lastName} <small>{a.suffix && `(${a.suffix})`}</small></BlackWhite></Link></Col>
        
      </Row>
    )
    
  });
 
  return (
   <div className = "text-center">
   <h4>{translate("Authors")}:</h4>
    
    <div>
        <div>{authorList}</div>
        
    </div>
    {admin && <Link to='/authors/new' className="btn btn-outline-dark my-3">{translate("Add Author")}</Link>}
    {loggedIn && <button onClick={handleApply} className="btn btn-outline-dark mx-2 my-3">{translate("I want to publish posts here")}</button>}
  </div>
  );
}

export default AuthorList;