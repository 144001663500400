import React from "react";
import {translate} from "../Language/Translate"
import Spinner from '../Spinner';
import ArticleListTable from "./ArticleListTable";

const ArticleList = ({list}) => {
    if(!list)return <Spinner/>
    const listArticles = list.filter(e => !e.source);
    const listLinks = list.filter(e => e.source);

    const TypeList = ({keyWord, typeList}) => {
        if (typeList.length === 0) return null;
        const title = keyWord[0].toUpperCase() + keyWord.slice(1);
        return(
            <div>
                <h5>{translate(title)}</h5>
                <ArticleListTable list = {typeList}/>
            </div>
        );
    };

    const List = ({subList}) => {
        
        const types = {
            articles: subList.filter(a => a.type==="article"),
            music: subList.filter(a => a.type==="music"),
            movies: subList.filter(a => a.type==="movie"),
            books: subList.filter(a => a.type==="book"),
        }
        return(
            <>
               {Object.entries(types).map(ent => <TypeList key={ent[0]} keyWord={ent[0]} typeList = {ent[1]} />)}
            </>
        );       
    };

    return(
        <>
            {list.length === 0 && <p>{translate("No items")}</p>}

            {listArticles?.length > 0 &&
                <div>
                    <h6>{translate("Articles")}</h6>
                    <List subList = {listArticles}/>
                </div>
            }
            {listLinks?.length > 0 &&
                <div>
                    <h6>{translate("Weblinks")}</h6>
                    <List subList = {listLinks}/>
                </div>
            }
        </>
    );
};
export default ArticleList;