import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {Card} from 'react-bootstrap';
import { uniqueId } from 'lodash';
import { updateCruiseCrewOnApi } from '../actions/currentCruise';
import CruiseAreaCruiseCrewDetails from './CruiseAreaCruiseCrewDetails';
import Spinner from './Spinner';
import { sortArrayByObjectField } from '../helpers/sort';
import { globals } from '../helpers/globals';

const CruiseAreaCruiseCrewList = () => {
      const currentCruise = useSelector(st => st.currentCruise);
      const currentUser = useSelector(st => st.currentUser);
      const {userStatus} = globals;
    //   // console.log(currentCruise.id, currentUser.username);
      const dispatch = useDispatch();
      const [index, setIndex] = useState(-1)

      if(!currentCruise || !currentUser) return <Spinner />;
    const changePosition = async(idx, neighbourIdx, ccId) => {
        if(idx < 0 || neighbourIdx > crew.length-1) return;
        const place = crew[idx].place;
        const neighbourPlace = crew[neighbourIdx].place;
        const neighbourCcId = crew[neighbourIdx].id;
        dispatch(updateCruiseCrewOnApi(ccId,{place:neighbourPlace}))
        dispatch(updateCruiseCrewOnApi(neighbourCcId,{place:place}))
      }
    

      const handleClick = (e) => {
          const {cc_id, idx, type} = e.target.dataset;
          if (type === "forward")changePosition(+idx, +idx+1, +cc_id);
          if (type === "back")changePosition(+idx, +idx-1, +cc_id);
          if (type === "details")setIndex(+idx);
      };
      const crew = (currentCruise.cruiseCrew && sortArrayByObjectField(currentCruise.cruiseCrew, "place")) || [];
    //    // console.log({crew});   
    //alt 
    //   const userStatus = currentUser && currentUser.userStatus;
      
      const mayEdit = userStatus && (["developer", "admin"].includes(userStatus) || userStatus.includes(currentCruise.companyHandle));
      
      const List = () => {
          return(
            
          <div className="row row-eq-height">
            {crew.map(c => {return{...c, key:uniqueId()}}).map((cc, idx) => {
                return(
                    <div className="my-2 mx-2" key={cc.key} data-idx={idx}>
                    <Card  style = {{height: "100%", width:"250px"}} className="mb-1">
                        <Card.Header>
                            <h5 className="text-center">{cc.position}</h5>
                        </Card.Header>
                        <Card.Body className="text-center">
                                <div className="text-center"  style = {{width : "auto", height:"150px"}} >
                                   {cc.photoUrl && <img src={cc.photoUrl} 
                                            alt='' className="thumbnail" style = {{maxHeight:"100%", maxWidth:"100%"}}/>
                                    }
                                </div>
                            <h6 className="text-center mt-3">
                                {cc.firstName} {cc.lastName}
                            </h6>
                        </Card.Body>
                        <Card.Footer className="text-center">
                            <div className="mb-2">
                                <button className="btn btn-outline-dark" data-type="details" data-idx = {idx} onClick={handleClick}>Details</button>
                            </div>
                            <div>
                                {mayEdit && idx !== 0 && <button data-idx={idx} data-cc_id={cc.id} data-type="back" className = "btn btn-outline-dark mr-3" onClick={handleClick}>{`<`}</button>}
                                {mayEdit && idx !== crew.length-1 && <button data-idx={idx} data-cc_id={cc.id} data-type="forward" className = "btn btn-outline-dark ml-3" onClick={handleClick}>{`>`}</button>}
                            </div>
                        </Card.Footer>
                    </Card>
                    </div>
                )
            })}
        </div>
          )
      }
    return(
        <>
            {index < 0 && <List />}
            {index >= 0 && <CruiseAreaCruiseCrewDetails index={index} setIndex={setIndex} crew={crew}/>}
        </>
    )
}
export default CruiseAreaCruiseCrewList;