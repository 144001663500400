import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {translate} from '../Language/Translate'
import GroupList from "./GroupList";
import GroupsNewForm from '../../newForms2/groups/GroupsNewForm';
import HelpArea from "./HelpArea";
import Groups from "../../helpers/groups";
import { Accordion, Card, Button } from "react-bootstrap";
import { BlackWhite } from "../styledComponents/colors";
import { setGlobalVarToState } from "../../actions/globals";

const GroupMain = () => {
    const currentUser = useSelector(st => st.currentUser);
    const currentCruise = useSelector(st => st.currentCruise);
    const globals = useSelector(st => st.globals);
    const {groupLocation, groupShowNewForm} = globals;
    const groups = useSelector(st => st.groups).sort((a,b) => a.name > b.name ? 1 : -1);
    const dispatch = useDispatch();
    
    const gr = new Groups(groups, currentCruise, currentUser);
    const {userGroups, cruiseGroups, availableUserGroups, availableCruiseGroups} = gr;
    const loggedIn = currentUser && currentUser.username ? true:false;
    const eventKey="1";

    // const handleCreate = () => {

    // }



    return(

        <div>
            <Accordion className="mb-3">
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
                        <BlackWhite>{translate("Help")}</BlackWhite>
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={eventKey}>
                        <Card.Body>
                            <HelpArea/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
           </Accordion>
            
            {loggedIn && <button className="btn btn-outline-dark" onClick={() => dispatch(setGlobalVarToState({groupShowNewForm:groupShowNewForm ? false :true}))}>{translate("Create new group")}</button>}
            {groupShowNewForm && <GroupsNewForm/>}
            {groupLocation === "user" && <GroupList title={translate("Your Groups")} groups={userGroups}/> }
            {groupLocation === "cruise" && <GroupList title={translate("Your Groups on Cruise")} groups={cruiseGroups}/> }
            
            {groupLocation === "user" && <GroupList title={translate("Further Public Groups")} groups={availableUserGroups} /> }
            {groupLocation === "cruise" && <GroupList title={translate("Further Public Groups")} groups={availableCruiseGroups}/> }
        </div>
    );
};
export default GroupMain;