import {tourTiming} from '../../components/CsvToJson/data/tourTiming'
import { getAllPropertiesFromCsvData } from '../../components/CsvToJson/helpers'


class TourTimingSchema{
    constructor(){
        const all = getAllPropertiesFromCsvData(tourTiming);
        console.log(all);
        this.fields = all.formFields;
    }

};
export default TourTimingSchema;