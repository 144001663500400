import React from "react";
import {useSelector} from 'react-redux';
import { Col, Row} from "react-bootstrap";
import { Translate } from "../../components/Language/Translate";


const Display = ({day}) => {
    const language = useSelector(st => st.currentUser)?.language || Translate.language;
    const destinations = useSelector(st => st.destinations);
    const curDestination = destinations?.find(d => d.id === day.destinationId) || {};
    const destinationField = day.destinationId ? <b>{`${curDestination[language] || curDestination.enUK}`}</b> 
          : day.destination ? day.destination 
              : ""
    const textColor = day.destinationId ? "black" : "grey"

    return(
        <div className="p-2 text-left">
            <Row >
              <Col sm={3}>
                {day.arrival} {(day.arrival || day.departure) && `-`} {day.departure}
              </Col>
              <Col sm={9}>
                <b style={{color: textColor}}>{destinationField}</b>
              </Col>
            </Row>
            <Row >
                <Col>
                    <small>{day.description}</small>
                </Col>
            </Row>
        </div>
    )
};
export default Display;