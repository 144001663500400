import {
    SET_GLOBAL_VAR
} from "./types";


export function setGlobalVarToState(varObj){
    return function(dispatch){
        return dispatch(setGlobal(varObj))
    };
};
function setGlobal(varObj){
    return{
        type: SET_GLOBAL_VAR,
        varObj
    };
};