
import {
    FETCH_CRUISE_PROPOSALS,
    ADD_CRUISE_PROPOSAL,
    DELETE_CRUISE_PROPOSAL
    } from "../actions/types";
    
    function sortByStartAt(cruiseProposals) {
    
      return cruiseProposals.sort(startAt => startAt);
    }
    
    
    export default function rootReducer(state = [], action) {
        // console.log('inside cruiseProposals: rootReducer, action:', action)
      switch (action.type) {
    
        case FETCH_CRUISE_PROPOSALS:
          // console.log({action}) 
          return sortByStartAt([...action.cruiseProposals]);
        case ADD_CRUISE_PROPOSAL:
          // console.log(action)
          return sortByStartAt([...state, action.cruiseProposal.cruiseProposal])
        case DELETE_CRUISE_PROPOSAL:
          // console.log({action})
          return state.filter(cp => cp.id !== action.deleted.deleted.id);
        default:
          return state;
      }
    };