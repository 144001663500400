import React from "react";
import {useSelector} from "react-redux"
import { Translate, translate } from "../../Language/Translate";
// import { BlackWhite } from "../../styledComponents/colors";
import {Form} from 'react-bootstrap';
import { getDateObject } from "../../../helpers/Date";


const UserMapCheckList = ({list, setList, setShow}) => {
    const language = useSelector(st => st.currentUser)?.language || Translate.language;

    const handleSelect = (bol) => setList(list.map(uc => {return({...uc, checked:bol})}));
   
    const handleCheck = e => {
        const id = +e.target.id;
        const cruise = list.find(uc => id === uc.id);
        cruise.checked = e.target.checked;
        // console.log(list, id, cruise, checked);
        // console.log(cruise.checked, e.target.checked);
        (() => setList([...list.filter(uc => uc.id !== id), cruise].sort((a,b) => a.startAt > b.startAt ? 1 : -1)))();        
    }
    const handleSubmit = e => {
        e.preventDefault();
        // console.log("SUBMIT");
        // const newList = list.filter(uc => uc.checked === true);
        // console.log({newList})
        (() => setShow('map'))();
    }
    
    // if(!list)
    // console.log(list.map(uc => uc.checked));

    return(
        <div className="p-2" style={{backgroundColor:"rgba(255,255,255,.3)"}}>
            <h6>User Map</h6>
            <p>{translate("Please select the cruises that should be displayed on the map")}.</p>
            <button className="btn btn-outline-dark mx-1" onClick={() => handleSelect(true)}>{translate("Select all")}</button>
            <button className="btn btn-outline-dark mx-1" onClick={() => handleSelect(false)}>{translate("Deselect all")}</button>
            <div className="text-center row" >
            <div className="col-md-2"/>
            <div className="col-md-10 text-left" >
          
            <Form onSubmit={(e) => handleSubmit(e)}>
                <ul>
                    {list.map(uc => {
                        const line = `${uc.prefix && uc.prefix + " "}${uc.name}
                                    ${getDateObject(uc.startAt, language).short} (${uc.startPort}) 
                                    ${getDateObject(uc.endAt, language).short} (${uc.startPort})${uc.title && (", " + uc.title)}`;
                        return (
                            <li key={uc.id} style={{textDecoration:'none'}}>
                                <input type="checkbox" id={uc.id} checked = {uc.checked} onChange={e => handleCheck(e)}/>
                                <span className="ml-2">{line}</span>
                            </li>
                        )
                    })}
                </ul>
                <button type = "submit" className="btn btn-outline-dark ml-5">{translate("Open Map")}</button>
            </Form>
            </div>
            {/* <div className="col-md-3"/> */}
            </div>
        </div>
    )


};
export default UserMapCheckList;
