import { simpleRequest } from "../simpleRequest"

export const addEntry = async(cruiseId, date, setDays) => {
    const request = {url:'days', method:"post", data: {cruiseId, date}};
    console.log({request});
    const newDay = (await simpleRequest(request)).data?.day;
    console.log({newDay});
    if(!newDay)return;
    setDays(days => [...days, newDay]);
};
export const deleteEntry = async(id, setDays) => {
    const deleted = (await simpleRequest({url:`days/${id}`, method:"delete"})).data?.deleted;
    console.log({deleted});
    setDays(days => days.filter(d => d.id !== id));
}