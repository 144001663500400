import FieldValidation from "./FieldValidation"


class FormValidation{

    constructor(constants, variables){
        
        this.constants=constants;
        this.variables=variables;
        this.valid=true;

    }
    formValid(){
        // console.log('inside FormValidation', this.constants, this.variables)
        let errArr=[] ;
        let errObj={} ;     
        
        
        const keys=Object.keys(this.variables.fields)
        // console.log({keys})
        for (const k of keys){
             
            // console.log(k, this.variables.fields[k], this.constants, this.variables)
            const field = new FieldValidation(k, this.variables.fields[k], this.constants, this.variables)
            const error = field.isValid();
            // console.log({field, error})
            
            if (!error.valid){
                this.valid=false
                // console.log(k, 'Field not ok.', error.valid, error.errMsg)
                errArr.push([k,error.errMsg])
            } else{
                // console.log('Field ok.', error.valid, error.errMsg)
            }
            
        }
        // console.log({errArr})
        errArr.forEach(err => {
            // console.log({err})
            // const errO={}
            errObj={...errObj,[err[0]]:err[1]}
            
        })
        // console.log({errObj})
        return {valid:this.valid, errObj:errObj}
    }
};

export default FormValidation;