import React from "react";
import Auth from "../../helpers/authNew";
import { useSelector } from "react-redux";

const AuthArea = () => {

    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);
    const auth = new Auth(currentUser.userStatus, currentCruise.companyHandle)


    return(
        <div>
            <h4>Auth Area</h4>
            <ul>
                <li><b>{currentUser.userStatus}, {currentCruise.companyHandle}</b></li>
                {Object.keys(auth).slice(2).map(k => <li key={k} className={!auth[k] && "text-danger"}>{k}</li>)}
            </ul>
        </div>
    )
};
export default AuthArea;



