import React from "react";
import { Col, Form } from "react-bootstrap";
import FormField from "./FormField";

const FormRowFields = ({props}) => {
  // console.log(props);
  const {
    fieldSettings, 
    rowSet
  } = props;


  const rewriteRowSet = (rowSet, viewSpan, fieldSettings) => {
    // console.log({fieldSettings})
    let counter = -1;
     return rowSet[viewSpan].map((row,idxRow) => {
      // console.log({idxRow, counter})
      return row.map((col, idxCol) => {
        
        counter +=1;
        const setting = Object.values(fieldSettings)[counter]
        // console.log({counter, col, idxCol, setting});
        return {width: col, setting, condition: viewSpan}
      })
    });

  };
  const newRows = rewriteRowSet(rowSet, "lg", fieldSettings)
  // console.log({newRows});

  return (
    <div className="my-4">
      {newRows.map((r,idx) => {
        return(
          <Form.Row key={idx}>
            {r.map((col,idx) => {
              // console.log({col});
              const {width, condition} = col;

              return(
                <Col className = {`col-md-${width}`} key={idx}>
                  <FormField props = {{...props, field:col.setting}} />
                </Col>
              )
            })}
          </Form.Row>
        )
      })}
    </div>
  )
};

export default FormRowFields;