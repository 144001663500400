import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import { sortArrayByObjectField } from "../../../helpers/sort";

const ListOfSpeciesGroupItem = ({ props }) => {
//   console.log({ props });
const [edit, setEdit] = useState(false);
  const { theSpecies, groups, setSpecies, species } = props;
  const [val, setVal] = useState("");

  const handleChange = (e) => setVal(e.target.value);

  const filteredGroups = groups.filter((g) =>
    `${g.deDE} / ${g.enUK}`.toLowerCase().includes(val.toLowerCase())
  );
  const handleClick = async (e) => {
    const speciesId = theSpecies.id;
    const data = { speciesGroupId: +e.target.id };
    const url = `species/${theSpecies.id}`;
    const newSpecies = (await simpleRequest({ url, method: "patch", data }))
      .data?.species;
    // console.log({ newSpecies });
    const newArr = sortArrayByObjectField(
      [...species.filter((s) => s.id !== speciesId), newSpecies],
      "deDE"
    );
    setSpecies(newArr);
  };

  return (
    <Row key={theSpecies.id}>
      <Col>{`${theSpecies.deDE} / ${theSpecies.enUK}`}</Col>
      <Col>
        <button className="btn btn-outline-dark" onClick = {() => setEdit(!edit)}>Edit</button>
      </Col>
      {edit && <Col>

        <Form>
          <Form.Label>New Group</Form.Label>
          <Form.Control
            value={val}
            onChange={(e) => handleChange(e)}
          ></Form.Control>
        </Form>
        <ul>
          {filteredGroups.map((g) => (
            <li
              key={g.id}
              id={g.id}
              onClick={(e) => handleClick(e)}
            >{`${g.deDE} / ${g.enUK}`}</li>
          ))}
        </ul>
      </Col>}
    </Row>
  );
};
export default ListOfSpeciesGroupItem;
