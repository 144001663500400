import {ADD_REQUEST, RENEW_REQUESTS, DELETE_REQUEST} from "../actions/types";
// import {sortArrayByObjectField} from '../helpers/sort';


export default function rootReducer(state = [], action) {
    // console.log('inside reducer', action)
    switch (action.type) {
      
      case ADD_REQUEST:
        if(!action.request) return state;
        return [...state, action.request];
      case RENEW_REQUESTS:
        return [...action.requests];
      case DELETE_REQUEST:
        // console.log(action);
        return [...state.filter(r => JSON.stringify(r) !== JSON.stringify(action.request))]
      default:
        return state;
    }
};