import React from "react";
import {Card, Accordion, Button} from 'react-bootstrap';
import {translate} from '../Language/Translate';
import UserList from '../UserList';
import {BlackWhite} from '../styledComponents/colors'
import { useSelector } from "react-redux";
import User from "../../helpers/user";
import NewsBadge from "../NewsBadge";

const UserListAccordion = ({eventKey}) => {
    const users = useSelector(st => st.users);
    const currentUser = useSelector(st => st.currentUser);
    const {newUsers} = User.newAndOldUsers(users, currentUser);
    
    return (
        <Card style={{backgroundColor:'#e5e9df', color:"#1a1620"}}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("Users")} <NewsBadge count = {newUsers.length}/></BlackWhite></h6>
                
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
                <Card.Body>
                    <UserList/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
export default UserListAccordion;