import React, { useEffect, useState } from "react";
import { getDateObject } from "../../helpers/Date";
import { Translate } from "../Language/Translate";
import TourReportDetails from "./TourReportDetails";





const TourReportListItem = ({props}) => {
    const [tour, setTour] = useState({});
    const {tours, setTours, tourReports, setTourReports, tourReport, activeTR, setActiveTR} = props;

    useEffect(() => {
        const _tour = tours.find(t => t.id === tourReport.tourId) || {};
        setTour(_tour)
    }, [tourReport, tours,]);

    const {id, createdAt, name} = tourReport;
    const {short, title} = tour;

    let text = `${getDateObject(createdAt, Translate.language, "local").short}`
    text = tour.short ? `${text} - ${short}, ${title}`
                    : `${text} - ${name}`;

       
    const listItem = 
        <li key={id} id = {id} onClick = {() => setActiveTR(id)}>
            {text}
        </li>;
    const detailItem = <TourReportDetails props={{...props, tour}} />
    
    // console.log({activeTR, TRId:tourReport.id, tourReport, setActiveTR});

    return(
        <div>
            {activeTR !== tourReport.id && listItem} 
            {activeTR === tourReport.id && detailItem}
        </div>

    )
};
export default TourReportListItem;