import React from 'react';
import './ConfirmationArea.css'
import {translate} from "./Language/Translate"


const ConfirmationArea = ({question, handleDelete, handleCancel}) =>{
      return(
          <div className="my-5">
            <h5 className="mb-4">{question}</h5>
            <button onClick={handleDelete} className="btn btn-outline-danger mx-1">{translate("Delete")}</button>
            <button onClick={handleCancel} className="btn btn-outline-secondary mx-1">{translate("Cancel")}</button>
          </div>
      );
};

export default ConfirmationArea;