import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteOpenRequest, renewOpenRequests } from "../actions/requests";
import { simpleRequestNew } from "../newForms2/simpleRequest";
import Randomfn from "../helpers/Randomfn";
import {translate} from './Language/Translate'

const RequestItem = ({r, key}) => {
    const dispatch = useDispatch();

    return(
        <>
            <p key= {key} id={key} >
                {JSON.stringify(r)}
                <button className="btn btn-outline-dark ml-2" onClick={() => dispatch(deleteOpenRequest(r))}>X</button>
            </p>
        </>
    );
};


const Requestor = () => {
    const [success, setSuccess] = useState([]);
    const [counter, setCounter] = useState(0);
    const requests = useSelector(st => st.requests);
    const dispatch = useDispatch();
    const userStatus = useSelector(st => st.currentUser)?.userStatus || "user";

    useEffect(() => {
        // console.log('inside useEffect__________________________________', requests)
        const fetch = async() => {
            // console.log("fetch___________________________________________________________________________________________");
            const noDoubles = Array.from(new Set(requests.map(r => JSON.stringify(r)))).map(rst => JSON.parse(rst));
            if(noDoubles.length !== requests.length)dispatch(renewOpenRequests([...noDoubles]));
            const successArray = []
            const errArray = [];
            for(let i=0; i<noDoubles.length; i++){
                try{
                    const data = (await simpleRequestNew(noDoubles[i].request)).data;
                    // console.log({data}, noDoubles[i]);
                    successArray.push({...noDoubles[i], error:null});
                }catch(err){
                    // console.log({err}, noDoubles[i]);
                    errArray.push({...noDoubles[i], error:JSON.stringify(err.response.data)});
                };
            };
            setCounter(counter => counter+1);
            dispatch(renewOpenRequests([...errArray]));
            setSuccess([...successArray]);
        };
        let interval=null;
        if(requests.length){
            interval = setInterval(fetch, 1000*10);
            // console.log('intervall gestartet', interval, requests.length);
        };
        return () => {
            // console.log('unmount', {interval})
            if(interval)clearInterval(interval);
        };
    }, [requests, dispatch]);

    const UserView = () => {

        return(
            <div>
                <p>{translate("Request failed")}. </p>
                <p>{translate("Please keep this window open")}. </p>
            </div>
        )
    };
    
    const DeveloperView = () => (
        <>
            <p>Open Requests: {requests.length}, attempts: {counter}</p>
            {requests.map((r, idx) => <RequestItem r={r} />)}
            <p>Success: {success.length}</p>
            {success.map((r) => <p key= {Randomfn.createRandomString(10)} className="text-success">{JSON.stringify(r)}</p>)}
        </>
            );

    // console.log({requests, success});

    if(!requests.length)return null;
    return(
        <div>
            {userStatus === "developer" && <DeveloperView/>}
            {userStatus !== "developer" && <UserView/>}
        </div>
    )
};

export default Requestor;