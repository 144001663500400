
import Form from "react-bootstrap/Form";
import {handleChange, validateField} from '../ReactFormFunctions'


const SelectField = ({name, constants, variables}) => {

    
    // console.log('inside SelectField',{name, constants, variables})
    const {fields} = constants;
    const {errors} = variables;
    const field = fields[name]
    const value = variables.fields[name] || "0";
    const selectOptions = field.settings.options
    // console.log('SELECTFIELD', {name, constants, variables, fields, errors, field, value, selectOptions})
    // console.log({value, name})   
    // console.log(props.variables.fields[name])
    // console.log({fields, errors, field, value, selectOptions})
    return(

        
            <Form.Group size="lg" controlId={name}>
                <Form.Label>{field.label}{field.required? ' *':null}</Form.Label>
                <Form.Control as="select"
                   
                    value={value} 
                    disabled={field.disabled}
                    required={field.required}
                    key={name}
                    autoFocus={field.autoFocus}
                    name={name}
                    onChange={(e) => handleChange(e, constants, variables)}
                    onBlur={(e) => validateField(e, constants, variables)}
                >
                    {selectOptions.map(opt => (
                        <option value= {opt.key} key={`${opt.key}_${opt.value}`}>{opt.value}</option>
                    ))}
                </Form.Control>
                {errors[name]?<p><small className="text-danger" >{errors[name]}</small></p>:null}
                {field.toolTip?<p><small className="text-secondary" >{field.toolTip}</small></p>:null}
            </Form.Group>
    )
}

export default SelectField;