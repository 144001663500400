import React, {useState, useEffect} from 'react';
import { translate } from '../Language/Translate';
import Spinner from '../Spinner';
import Map2 from './Map2';

const MapMain = ({daysInMap = [], daysNotInMap = []}) => {
    // console.log({daysInMap, daysNotInMap});
    const [isLoading, setIsLoading] = useState(true);
    const [newDays, setNewDays] = useState([]);
    const [center, setCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState(1);

    useEffect(() => {
        const newD = daysInMap.map(d => {
            if(!d.geolocation){
                return({...d, lat:0, long:0});
            }else{
                const [la, lo] = d.geolocation.split(',');
                const lat = parseFloat(la);
                const long = parseFloat(lo);
                return({...d, lat, long});
            };
        });
                
        const south = newD.reduce((acc, val) => acc < val.lat ? acc : val.lat, 90);
        const north = newD.reduce((acc, val) => acc > val.lat ? acc : val.lat, -90);
        const west = newD.reduce((acc, val) => acc < val.long ? acc : val.long, 180);
        const east = newD.reduce((acc, val) => acc > val.long ? acc : val.long, -180);
        const passedDateChange = newD.some(d => d.long > 90) && newD.some(d => d.long < -90);
        
        const centerLat = (south + north) / 2;
        const firstCenterLong = (east + west) / 2;
        let centerLong = firstCenterLong;
        
        if(passedDateChange){
            centerLong = firstCenterLong < 0 ? firstCenterLong + 180 : firstCenterLong - 180
            if(centerLong < 0){
                newD.forEach(d => {if(d.long > 0)d.long = d.long - 360});
            }else{
                newD.forEach(d => {if(d.long < 0) d.long = d.long + 360});
            };
        };
        const diffEastWest = Math.abs(east - west);
        const diffNorthSouth = Math.abs(centerLat - north);
        let maxTemp = (diffEastWest > diffNorthSouth ? diffEastWest : diffNorthSouth);

        const newDiffEastWest = Math.abs(east - west);
        if(passedDateChange)maxTemp = (newDiffEastWest > diffNorthSouth ? newDiffEastWest : diffNorthSouth);

        let zoomTemp;
        if(maxTemp > 80)zoomTemp = 3;
        if(maxTemp < 80)zoomTemp = 4;
        if(maxTemp < 40)zoomTemp = 5;
        if(maxTemp < 20)zoomTemp = 6;
        if(maxTemp < 10)zoomTemp = 7;
        if(maxTemp < 6)zoomTemp = 8;
        if(maxTemp < 3)zoomTemp = 9;
        if(maxTemp <= 1)zoomTemp = 10;

        if(isLoading){
            (() => setZoom(zoomTemp))();
            (() => setCenter ([centerLat, centerLong]))();
            (() => setNewDays (newD))();
        }
        
        // console.log({daysNotInMap, diffEastWest, diffNorthSouth, maxTemp, zoom, south, 
        //     north, west, east, newEast, newWest, passedDateChange, 
        //     newDe:newD.map(d => {return {d:d.deDE, we: d.long, sn:d.lat, }})});

        (() => setIsLoading (false))(); 

    }, [daysInMap,daysNotInMap, isLoading]);

    
    if(isLoading)return <Spinner/>;
    
    
    return(
        <>
            <Map2 newDays = {newDays} center = {center} zoom = {zoom}/>
            {
                daysNotInMap.length > 0 &&
                <div className="mt-3">
                    <h6>{translate("These destinations have no geolocations yet. These will be added in the next few days.")}</h6>
                    <ul>
                        {daysNotInMap.map(d => <li key={d.id}>{d.deDE || d.deUK || d.destination}</li>)}
                    </ul>
                </div>
            }
        </>
    );
};
export default MapMain;