import FieldValidation from "./FieldValidation"


class FormValidation{

    constructor({fieldSettings, variables, setFieldErrors}){
        // console.log({fieldSettings, variables, setFieldErrors});
        this.setFieldErrors = setFieldErrors;
        this.fieldSettings=fieldSettings;
        this.variables=variables;
        this.valid=true
        this.errObj = this.formValid();

        // console.log(this);
    };
    formValid(){
        // console.log('inside FormValidation', this.fieldSettings, this.variables, this.valid)
        let errArr=[] ;
        let errObj={} ;     
        // return;
        
        const keys=Object.keys(this.variables)
        // console.log({keys})
        for (const k of keys){

            // console.log(k, this.variables[k], this.fieldSettings, this.variables);
             
            const field = new FieldValidation(k, this.variables[k], this.fieldSettings, this.variables);
            const result = field.isValid();
            // console.log({field, result})
            
            if (!result.valid){
                this.valid=false
                // console.log(k, 'Field not ok.', result.valid,result.errMsg)
                errArr.push([k,result.errMsg])
            } else{
                // console.log(k, 'Field ok.', result.valid, result.errMsg)
            }
            
        }
        // console.log({errArr})
        errArr.forEach(err => {
            // console.log({err})
            // const errO={}
            errObj={...errObj,[err[0]]:err[1]}
            
        });
        if(this.setFieldErrors) this.setFieldErrors({...errObj});
        // console.log({valid:this.valid, errObj}, errObj);
        // console.log(this.fieldErrors);
        return {valid:this.valid, errObj}
    }
};

export default FormValidation;