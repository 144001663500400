import React, { useState } from "react";
// import ListOfAlbums from "./ListOfAlbums";
// import ListOfDestinations from "./ListOfDestinations";
import ListOfSpeciesGroupItem from "./ListOfSpeciesGroupItem";

const ListOfSpeciesGroups = ({ props }) => {
  console.log({ props });
  const {
    observations,
    setObservations,
    species,
    setSpecies,
    groups,
    setGroups,
  } = props;

  return (
    <div>
      <h3>Einteilung</h3>
      {groups.map((group) => {
        const txt = `${group.deDE} / ${group.enUK}`;
        const filtered = species.filter((s) => s.speciesGroupId === group.id);
        console.log({ filtered });
        return (
          <div key={group.id}>
            <h4>{txt}</h4>
            {filtered.map((theSpecies) => <ListOfSpeciesGroupItem key={theSpecies.id} props = {{...props, theSpecies}}/>)}
          </div>
        );
      })}
    </div>
  );
};
export default ListOfSpeciesGroups;
