import React from "react";
import "./CompanyList.css";
import { Link } from 'react-router-dom';
import {Image} from 'react-bootstrap'
import { BlackWhite} from "./styledComponents/colors";

const CompanyListCard = ({company}) => {
    
    return (
      
            // <div key={company.name} className="col-md-4 my-1">
              <div className="col-lg-3 col-md-4 col-sm-6 p-2 text-center" style={{backgroundColor:"rgba(255,255,255,.4)"}}>
                <div className="card card-body p-2" style={{height:"100%"}} >
                  <div className="card-company company-body p-2">
                    <div style={{height:"100px"}} className="align-items-bottom" > 
                      {company.logoUrl && <Image src={company.logoUrl} alt={company.name} className="img-fluid" style={{maxWidth : "200px", width:"auto", maxHeight:"100%"}} />}
                    </div>
                    
                    <p className = "mt-3"><Link to={`/companies/${company.handle}`}><BlackWhite>{company.name}</BlackWhite></Link></p>
                    {/* <p className = "mt-3"><Link to={`/companies/${company.handle}`}><EeeLink>{company.name}</EeeLink></Link></p> */}
                  </div>
                  <div className="card-text">
                    <i>{company.description}</i>
                  </div>
                </div>
              </div>
            // </div>
          
    )
}

export default CompanyListCard;



