import React, {useState, useEffect} from "react";
import {Row, Col, Spinner} from 'react-bootstrap';
import { translate } from "../../components/Language/Translate";
import {StyledDivWithBorder } from "../../components/styledComponents/divs";
import { simpleRequest } from "../simpleRequest";
import FormRowFields from "../FormRows/FormRowFields";
import { useSelector } from "react-redux";
import TourEscortSchema from "./TourEscortSchema";

const TourEscortEdit = ({account, setTourEscorts, backFunc}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);

    useEffect(() => {
        const initialData = () => {
            const sendData=["displayedName", "position", "responseEmail", "fields", "fieldsDd"]
            const _fields = new TourEscortSchema(currentUser).fields;
            const _vars = {};
            sendData.forEach(k => _vars[k] = account[k]);
            console.log({account, _vars})
            setVariables({..._vars});
            setFieldSettings({..._fields});
        }
        const fetch = async() => {
            initialData();
            setIsLoading(false);
        }
        if(isLoading)fetch()
    }, [isLoading, currentUser, account]);

    if(isLoading)return <Spinner/>

    const handleSubmit = async () => {
        const {companyHandle} = currentCruise;
        const data = {...variables, companyHandle};
        const tourEscort = (await simpleRequest({url:`tourescorts/${account.id}`, method:"patch", data})).data?.tourEscort;
        await setTourEscorts(te => [...te.filter(t => t.id !== account.id), tourEscort]);
        backFunc();
    };

    const rowSet = {
        lg: [[3,3], [6,6], [12], [12]],  
        sm: null,
    }

    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError,
        rowSet
      };

      console.log({props});

    return(
        <StyledDivWithBorder>
            <h4>{translate("Edit Account")} </h4>
            {/* <TemplateList templates={templates} handleClick={handleClick} setTemplates={setTemplates} /> */}
            <FormRowFields props= {props}/>
            <Row>
                <Col>
                    <button className="btn btn-info" onClick={handleSubmit}>{translate("Submit")}</button>
                </Col>
                <Col>
                    <button className="btn btn-info" onClick={backFunc}>{translate("Cancel")}</button>
                </Col>
            </Row>
        </StyledDivWithBorder>
    )

};
export default TourEscortEdit;