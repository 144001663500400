import {translate} from "../../components/Language/Translate"

class FieldValidation {

    constructor(name, value, constants, variables) {
        // console.log({name, value, constants, variables})

        this.constants = constants;
        this.fields = variables.fields;
        this.fieldSettings = constants.fields;
        this.name = name;
        this.value = value;
        // console.log(name, this.fieldSettings)
        this.label = this.fieldSettings[name]?this.fieldSettings[name].label || "Kein Label":"Kein Label";
        this.validation = this.fieldSettings[name]?this.fieldSettings[name].validation || "":"";
        this.errMsg = [];
        this.valid = true;

    }

    isValid() {
        if (!this.validation) return {
            valid: true
        };
        // console.log(this.errMsg, this.value, this.name, this.validation)
        this.minNumber()
        this.minLength();
        this.maxLength();
        this.noNumbers();
        this.isEmail();
        this.mustBeEqualTo();
        this.mustNotBeEqualTo();
        // this.mustBeGreaterThan();
        this.noSpace();
        this.mustNotContain();
        this.lowerCased();
        this.ifStartsWithCharFieldMustNotBeEqualTo();
        this.validDateString();
        if (this.errMsg.length > 0) {
            // console.log('==============================isValid, errMsg', this.errMsg, this.errMsg.length)
            this.valid = false
        }
        const result = {
            valid: this.valid,
            errMsg: this.errMsg.join(' ') + ''
        }
        // console.log({result});
        return result;

    }

    minLength() {
        // console.log('inside minLength')
        if (this.validation.minLength && this.value && this.validation.minLength > this.value.length) {
            this.errMsg.push(translate('Minimum number of characters') + ":" + this.validation.minLength + ".");
        }
    }
    maxLength() {
        if (this.validation.maxLength && this.value && this.validation.maxLength < this.value.length) {
            this.errMsg.push(translate('Maximum number of characters') + ":" + this.validation.maxLength + ".");
        }
    }
    noNumbers() {
        if (this.validation.noNumbers && (/\d/.test(this.value))) {
            this.errMsg.push(`${translate('This field must not contain numbers')}.`);
        }
    }
    isEmail() {
        if (this.validation.isEmail && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.value))) {
            this.errMsg.push(`${translate("Invalid email adress")}.`)
        }
    }
    mustBeEqualTo() {

        if (this.validation.mustBeEqualTo && (this.value !== this.fields[this.validation.mustBeEqualTo])) {
            this.errMsg.push(`${translate("The fields do not match")}: ${this.fieldSettings[this.validation.mustBeEqualTo].label}`)
            // this.errMsg.push(`The fields do not match.`)
        };
    }


    noSpace() {
        if (this.validation.noSpace && (this.value.includes(' '))) {
            this.errMsg.push(`${translate("This field must not have any empty spaces")}.`)
        }
    }
    mustNotContain(){
        if(this.validation.mustNotContain && this.value.includes(this.validation.mustNotContain)){
            this.errMsg.push(`${translate("Must not contain")}: ${this.validation.mustNotContain}.`)
            // console.log(this)
        }
    }
    lowerCased() {
        if (this.validation.lowerCased && this.value !== this.value.toLowerCase()) {
            this.errMsg.push(translate('For this field use only lowercased letters.'))
        }

    }
    ifStartsWithCharFieldMustNotBeEqualTo() {

        if (this.validation.ifStartsWithCharFieldMustNotBeEqualTo) {
            const valObj = this.validation.ifStartsWithCharFieldMustNotBeEqualTo
            const {fieldName} = valObj;
            const value = this.value;
            const fieldValue = this.fields[fieldName];
            // console.log({value,fieldValue});
            if (value[0] === "|" && !fieldValue) {
                this.errMsg.push(translate('There must be a selected Company.'))
            }
        }

    }
    validDateString(){
        if (this.validation.validDateString) {
            const dateString = this.value;
            const first = dateString.slice(4, 5)
            const second = dateString.slice(7, 8)
            const y = parseInt((dateString.slice(0, 4)));
            const m = parseInt((dateString.slice(5, 7)));
            const d = parseInt((dateString.slice(8, 10)));
            const newDate = new Date(dateString)
            // console.log({y,m,d,newDate,first,second})
            // console.log({newDate})
            if (dateString.length !== 10 || y < 1900 || y > 3000 || m < 1 || m > 12 || d < 1 || d > 31 ||
                !newDate || first !== "-" || second !== "-") {
                this.errMsg.push(`${translate("Invalid date string")}. Format: 2021-06-12`)
            }
        }

    }
    minNumber = () => {
        if(this.validation.minNumber){
            
            if (typeof parseInt(this.value) !=="number" || this.value < this.validation.minNumber){
                // console.log('inside min', typeof parseInt(this.value), typeof this.value !=="number", this.value < this.validation.minNumber, this.value, this.validation.minNumber)
                this.errMsg.push(`${translate("Not valid. The number must be at least")} ${this.validation.minNumber}.`)
            }
        }
    }
    mustNotBeEqualTo = () => {
            // console.log('inside FieldValidation.mustNotBeEqualTo', this.value, this.validation);
            const mustNotBeEqualTo = this.fields[this.validation.mustNotBeEqualTo] && this.fields[this.validation.mustNotBeEqualTo].value;
            if (!mustNotBeEqualTo) return;
            if (this.validation.mustNotBeEqualTo && (this.value === this.fields[this.validation.mustNotBeEqualTo].value)) {
                this.errMsg.push(`${translate("The fields must be different")}.`);
            }
    }





}
    




    







// mustBeGreaterThan() {
//     if (!(this.validation && this.validation.mustBeGreaterThan)) return;
//     if (!this.constants.fields[this.name].validation)return
//     const otherFieldName = this.constants.fields[this.name].validation.mustBeGreaterThan;
//     const otherFieldLabel = this.constants.fields[otherFieldName] && this.constants.fields[otherFieldName].label;
//     const otherValue = this.fields[this.constants.fields[this.name].validation.mustBeGreaterThan];
//     // console.log(this.label, this.value, 'must be greater than', otherFieldName, otherFieldLabel, otherValue)
//     if (this.validation.mustBeGreaterThan && (this.value <= otherValue)) {
//         this.errMsg.push(`This value must be greater than "${otherFieldLabel}"`
//     }
//     }



export default FieldValidation;