
import React, {useState} from "react";
import { translate } from "../../components/Language/Translate";
import { simpleRequest } from "../simpleRequest";
import { useHistory } from "react-router-dom"
import Spinner from "../../components/Spinner";


const EmailWasBlocked = ({usernameOrEmail}) => {

    const [view, setView] = useState('init'); //init, waiting, done
    const history = useHistory();

    const btnEmailBlocked = async () => {
        (() => setView("waiting"))();
       

        const response = await simpleRequest({method:"get", url:`confirmation/no_email/${usernameOrEmail}`});
        // console.log({response});
        (() => setView("done"))();
        setTimeout(() => history.push(`/login`), 3000);
    };

    const Spinning = () => (
        <div className="text-center">
            <h6>{translate("Please wait a moment...")}</h6>
            <Spinner/>
        </div>
    )

    const NotPressed = () => (
        <div className="text-center">
            <h6>{translate("You did not receive the email?")}</h6>
            <p>{`${translate("Email was sent to")}: ${usernameOrEmail}`}</p>
            <p>{translate('Some providers block emails from unknown servers')}.</p>
            <p>{translate('Please click the button')}.</p>
            <p>{translate('You will get the email manually')}.</p>
            <p>{translate('This may take some hours')}.</p>
            <button className="btn btn-outline-dark" onClick = {btnEmailBlocked}>{translate("No Email ? - Please click here")}</button>
        </div>
    );
    const Pressed = () => (
        <div className="text-center">
        <h6>{translate("You will get the email manually")}.</h6>
        <p>{translate('This may take some hours')}.</p>
    </div>
    )

    


    return (
        <div className="mt-5">
            {view === "init" && <NotPressed/>}
            {view === "waiting" && <Spinning/>}
            {view === "done" && <Pressed/>}
        </div>

    );
};
export default EmailWasBlocked;