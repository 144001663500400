
// Die default-einstellung "base64" wurde durch "blob" ersetzt

import Resizer from "react-image-file-resizer";





export const resizeFile = (file) =>
  
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      80,
      0,
      (uri) => {
          // console.log({uri})
        resolve(uri);
      },
      "blob",
      // "base64"
    );
  });


// Original
// export const resizeFile = (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       300,
//       400,
//       "JPEG",
//       80,
//       0,
//       (uri) => {
//           // console.log({uri})
//         resolve(uri);
//       },
//       
//     );
//   });