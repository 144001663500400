import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import CompanyCrewFormSchema from './CompanyCrewFormSchema';
import { addCompanyCrewOnAPI } from "../../actions/currentCompany";
import FormValidation from '../Validation/FormValidation';

  const CompanyCrewNew = ({setShowNewForm}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [fieldSettings, setFieldSettings] = useState();
    const [fieldErrors, setFieldErrors] = useState({});
    const currentCompany = useSelector( st => st.currentCompany);
    const dispatch = useDispatch();

    useEffect(() => {
      const sets = new CompanyCrewFormSchema();
      const sendData = ["firstName", "lastName", "bio"];
      const fieldSets = [];
      const vars = [];
      sendData.forEach(d => {
        vars[d] = sets.fields[d].value;
        fieldSets[d] = sets.fields[d];
      });
      (() => setVariables({...vars}))();
      (() => setFieldSettings({...fieldSets}))();
      (() => setIsLoading(false))();

    },[isLoading, setIsLoading])

    if(isLoading) return <Spinner/>

    // console.log({variables, fieldSettings});

    const handleSubmit = async() => {
      const formValidation = new FormValidation({fieldSettings, variables});
      if(!formValidation.valid)return;
      // console.log({variables});
      await dispatch(addCompanyCrewOnAPI({handle:currentCompany.handle, data:variables}));
      (() => setShowNewForm(false))();
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors};
    // const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div className = "my-5 p-3" style = {{border:"1px solid grey", borderRadius:"5px"}}>
            <h4>{translate("Add Crew")}</h4>
            {/* <DestinationField props={props}/> */}
            <FormFields props={props}/>
            <div className="row col-12" >
              <div className="col-6 p-1">
                <button className="btn btn-outline-info col-12" onClick={handleSubmit}>{translate("Submit")}</button>
              </div>
              <div className="col-6 p-1">
                <button className="btn btn-outline-secondary col-12" onClick={()=>setShowNewForm(false)}>{translate("Cancel")}</button>
           
              </div>
            </div>
        </div>
    )
  };
  export default CompanyCrewNew;