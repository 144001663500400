import React, { useEffect, useState, useCallback} from "react";
import Spinner from '../../components/Spinner';
import UserSchema from "./UserSchema";
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import FormValidation from "../Validation/FormValidation";
import { useHistory } from "react-router";
import { createAxiosRequest } from "../simpleRequest";
import { LargeButton } from "../../components/styledComponents/colors";
import { trimVariables } from "../../helpers/string";
import { isInDevelopment } from "../../config";
import Randomfn from "../../helpers/Randomfn";
import EmailWasBlocked from "./EmailWasBlocked";
import axios from "axios";
import PasswordToggle from '../Fields/PasswordToggle'

const UserRegister = () => {
    const [isLoading, setIsLoading] = useState(true);
    // const [constants, setConstants] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [view, setView] = useState('input'); // input, waiting, serverError, success
    const history = useHistory();
    
    const fetch = useCallback(async () => {
        const schema = new UserSchema();
        const vars = {};
        const fields = {}
        const fieldArr = ["username", "nickname", "firstName", "lastName", "language", "email", "confirmEmail", "password", "confirmPassword"]
        
        fieldArr.forEach(i => {
            vars[i] = schema.fields[i].initialValue;
            fields[i] = schema.fields[i]
        });
        (() => setVariables(vars))();
        (() => setFieldSettings({...fields}))();
        (() => setIsLoading(false))();
    }, []);
    useEffect(() => {if(isLoading)fetch()},[isLoading, fetch]);


    if (isLoading) return <Spinner/>

    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log("handleSubmit");
        (() => setVariables(trimVariables(variables)))();
        const formValidation = new FormValidation({fieldSettings, variables, setFieldErrors});
        if(!formValidation.valid){
            // console.log(formValidation.errObj.errObj);
            (() => setFieldErrors({...formValidation.errObj.errObj}))();
            return;
        };        
        (() => setView('waiting'))();
        try{
            const request = createAxiosRequest({url:"users/register", method:"post", data:variables});
            // console.log({request});
            const response = await axios(request);
            // console.log(response.status);
            (() => setView('success'))();
            (() => setServerError(""))();
        }catch(err){
            // console.log("ERROR", err.status, {err});
            // console.log(err.response?.data);
            // console.log(err.err?.response?.data);            
            (() => setView("serverError"))();
            (() => setServerError(err.response?.data))();
        }
    };

    const Spinning = () => (
        <div className = "text-center">
            <h6>{translate("Please wait a moment...")}</h6>
            <Spinner/>
        </div>
    );    
    const SuccessArea = () => {
        return(
            <div className = "text-center">
                <p>{translate('Register was successfull.')}</p>
                <p>{translate('We sent you a new email to your email address.')}</p>
                <p>{translate('Please open your emails and klick the link inside the mail from Cruisefriends.de.')}</p>
                <p>{translate('Then login')}.</p>
                <button className = "btn btn-outline-dark" onClick={() => history.push('/login')}>{translate('To Login')}</button>
            </div>
        );
    };
    const ServerError = () => {
        const handleBack = () => {
            // (() => setServerError(""))();
            (() => setView('input'))();
        }
        return(
            <div className="text-danger text-center">
            <h6 className="text-center">{serverError}</h6>
            <button className="btn btn-outline-dark" onClick={handleBack}>{translate("Back")}</button>
            </div>
        )
        
    };

    const props = {fieldSettings, setFieldSettings, variables, setVariables, 
            fieldErrors, setFieldErrors, serverError, setServerError};

    return(
        <div style={{backgroundColor:"rgba(255,255,255,.5)",borderRadius:"5px" }} className="p-3">
            <h4 className="text-center">{translate("Register")}</h4>
            {view === "success" && <SuccessArea/>}
            {view === "success" && <EmailWasBlocked usernameOrEmail = {variables.email || variables.username}/>}
            {view === "input" && isInDevelopment && <h5 onClick = {() => setVariables(Randomfn.randomUserData)}>&gt;</h5>}
            {view === "input" && <FormFields props={props} />}
            {view === "input" && <PasswordToggle props={props} />}
            {view === "input" && <LargeButton onClick={handleSubmit}>{translate("Submit")}</LargeButton>}
            {view === "waiting" && <Spinning/>}
            {view === "serverError" && <ServerError/>}
            
        </div>
    );

};
export default UserRegister;