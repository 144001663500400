import React, { useState } from "react";
import ObservationEventItem from "./ObservationEventItem";
import { translate } from "../../../Language/Translate";
import { BlackWhite } from "../../../styledComponents/colors";
import ObservationNew from "../../../../newForms2/observations/ObservationNew";
import { getNewDateObject } from "../../../../helpers/Date";
import { StyledDivWithBorder } from "../../../styledComponents/divs";

const ObservationEvent = ({props}) => {
    const [showButton, setShowButton] = useState(false);
    const [openFormLocal, setOpenFormLocal] = useState('list');
    const {speciesObservations, getText, ev, idx, handleClickEvent} = props;
    const obs = speciesObservations.filter(o => getText(o) === ev);
    const now = getNewDateObject(new Date(obs[0].dateTime));
    const {geolocation, destinationId, albumId} = obs[0];
    const initialValues = {geolocation, destinationId, albumId, speciesId:0, dateTime:now, description:""};
      
        return(
            <StyledDivWithBorder className="mt-3" key={idx} onClick={() => handleClickEvent(ev)}>
                <h5 key={idx} onClick={() => setShowButton(!showButton)}><BlackWhite>{ev}</BlackWhite></h5>
                {showButton && <button className = "btn btn-outline-dark" onClick={() => setOpenFormLocal("new")}>{translate("Add Observations here")}</button>}
                {openFormLocal=== "new" && <ObservationNew mainProps = {{...props, setOpenFormLocal:setOpenFormLocal}} initialValues={initialValues}/>}
                {obs.map(o => {
                    // console.log({o});
                    return(
                        <ObservationEventItem o={o} key={o.id} props={props}/>
                    )
                })}
            </StyledDivWithBorder>
        )
};
export default ObservationEvent;