import React, { useState } from "react";
import { Form} from 'react-bootstrap';
import { simpleRequest } from "../../../newForms2/simpleRequest";
import ImageArea from "./ImageArea";

const AddDestination = ({props}) => {
    const {destinations, album, setAlbumDestinations, setAddNew} = props;
    const [inputTxt, setInputTxt] = useState('');
    const [filteredDests, setFilteredDests] = useState([...destinations]);

    const newDests = [{id:0, deDE:"Please Select"}, ...destinations];

    const options = newDests.map( d => {return{key: d.id, value:d.deDE}});
    const handleChange = async(e) => {
        if(!+e.target.value)return;
        await simpleRequest({url:"albumdestination", method:'post', data:{albumId:album.id, destinationId:+e.target.value}});
        setAlbumDestinations((await simpleRequest({url:"albumdestination"})).data.albumDestinations);
        setAddNew(false);
    };    
    const handleClick = async(e) => {
        const destinationId = +e.target.id;
        if(!destinationId)return;
        await simpleRequest({url:"albumdestination", method:'post', data:{albumId:album.id, destinationId}});
        setAlbumDestinations((await simpleRequest({url:"albumdestination"})).data.albumDestinations);
        setAddNew(false);
        // console.log(e.target.id);

    }
    const handleChange2 = async(e) => {
        // console.log(e.target.name, e.target.value);
        const {value} = e.target;
        setInputTxt(value);
        const filt = destinations.filter(d => `${d.deDE}, ${d.enUK}`.toLowerCase().includes(value.toLowerCase()));
        setFilteredDests(filt);
    };
    return(
        <div>
            <Form>
            <Form.Group size="lg">
                <Form.Control as="select"
                    name={album.titleDE} 
                    type="select" 
                    key={album.id} 
                    value={0}
                    onChange={(e) => handleChange(e)} 
                    // onBlur={(e) => validateField(e)}
                >
                {options.map(opt => (
                            <option value= {opt.key} key={`${opt.key}_${opt.value}`}>{opt.value}</option>
                        ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Control
                    type="text"
                    onChange={e => handleChange2(e)}
                    name="inputTxt"
                    value={inputTxt}
                    
                    
                    >

                </Form.Control>
            </Form.Group>
        </Form>
        <ul>
            {
                inputTxt.length > 2 
                ? filteredDests.map(f => <li key={f.id} id={f.id} onClick={e => handleClick(e)}>{`${f.deDE}, ${f.enUK}`}</li>)
                : null
            }
        </ul>
        </div>
        )
};

const ListOfAlbumsItem = ({album, albums, albumDestinations, albumPhotos, setAlbumPhotos,
                            destinations, setAlbumDestinations, setAlbums}) => {
    const [addNew, setAddNew] = useState(false);


    const [showImages, setShowImages] = useState(false);
    const albDest = albumDestinations.filter(ad => ad.albumId === album.id);
    const albPh = albumPhotos.filter(ap => ap.albumId === album.id);

    const isEmpty = !(albDest.length || albPh.length);
 


    const handleDelete = async(e) => {
        const id = +e.target.parentElement.parentElement.id;
        await simpleRequest({url:`albumdestination/${id}`, method:'delete'});
        setAlbumDestinations([...albumDestinations.filter(ad => ad.id !== id)]);
    };
     
    const handleDeleteAlbum = async() => {
        await simpleRequest({url:`albums/${album.id}`, method:'delete'});
        setAlbums([...albums.filter(a => a.id !== album.id)]);
    }
    const props = {destinations, album, setAlbumDestinations, setAddNew}

    return(

        <div className = "my-5" style={{border:"1px solig grey", borderRadius:"5px"}}>
            <h4>{album.titleDE || "Unbekannt"} ({albPh.length})
            {/* <p>{`${isEmpty} ${albumDestinations.length} ${albumPhotos.length}`}</p> */}
            {isEmpty && <button className = "btn btn-outline-danger ml-2" onClick={handleDeleteAlbum}>Delete Album</button>}
            <button className = "btn btn-outline-dark ml-2" onClick={() => setAddNew(!addNew)}>Add Destination</button>
            <button className = "btn btn-outline-dark ml-2" onClick={() => setShowImages(!showImages)}>Images</button>
            
            </h4>
            {albDest.map(ad => (
                <div className = "px-4" id={ad.id} key={ad.id}>
                    <h6 key={ad.id}>
                        {ad.deDE}
                        <span className="text-danger ml-3 px-2" style={{border:"1px solid black"}} onClick = {e => handleDelete(e)}>X</span>
                    </h6>

                </div>))}
            {addNew && <AddDestination props={props}/>}
                {showImages && <ImageArea images = {albPh} albums={albums} setAlbumPhotos={setAlbumPhotos}/>}
        </div>

    )
};
export default ListOfAlbumsItem;