import React from "react";
import {Accordion} from 'react-bootstrap';
import CardForexConverter from "./CardForexConverter";
import CardMemory from "./CardMemory"
// import CardLocations from "./CardLocations"
import CruiseBackToCruiseButton from './../CruiseBackToCruiseButton'



const Tools = () => {

    return(
        <div className="text-center">
        <CruiseBackToCruiseButton/>
        <Accordion className="text-center" >
            <CardForexConverter eventKey = "0"/>
            {/* <CardLocations eventKey="2"/> */}
            <CardMemory eventKey="1"/>
        </Accordion>
        </div>
    )
}
export default Tools;



