import React, {useEffect, useState} from "react";
import {Form } from "react-bootstrap"
import {translate} from '../../components/Language/Translate'
// import UploadFiles from "./UploadFiles";
import UploadFile from "./UploadFile";
import UploadLink from "./UploadLink";
import InsertLink from './InsertLink';
import HintArea from "./HintArea";
import {advancedRequest} from '../simpleRequest';
import {useSelector} from 'react-redux';
import {resizeFile} from './Resize';

const UploadAreaSingle = ({title, setUploadArea, methods=["file", "url", "link"], setUploadData,uploadData, subFolder})  => {
        
    // console.log('UploadAreaSingle', {title, setUploadArea, methods, setUploadData, subFolder});
    const defaultMethod = methods.includes("file") ? "file" :"link";
    const folder = process.env.NODE_ENV === "development" 
           ? `development/${subFolder}`
           : process.env.NODE_ENV === "test" 
           ? `test/${subFolder}`
           : `cruisefriends/${subFolder}`;
    const [data, setData] = useState({method:defaultMethod, title, folder});
    const [fieldError, setFieldError] = useState(null);
    const [hintArea, setHintArea] = useState(false);
    const [rightsConfirmed, setRightsConfirmed] = useState(false);
    const [fileInputState, setFileInputState] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [previewSource, setPreviewSource] = useState('');
    const [urlForTable, setUrlForTable] = useState(null);
    const currentUser = useSelector(st => st.currentUser);
        
    useEffect(() => {
        // when urlForTable is changed, dispatch the url by changing setUploadData;
        const dispatchUrl = async () => {
            (() => setUploadData({changed:true, data:{[title]:urlForTable}, method:data.method}))();
        }
        if (urlForTable)dispatchUrl();
    }, [urlForTable, data, setUploadData, title]);


    const developer = currentUser.userStatus === "developer";
    
    const handleChangeCheck = e => {
        // console.log('inside handleChangeCheck', e.target, e.target.value);
        (() => setData({...data, method: e.target.value}))();
    };
    const handleRemove = (e) => {
        e.preventDefault();
        setUploadData({changed:true, data:{[title]:""}, method:"remove"});
    }
        
    const uploadImage = async (base64EncodedImage, selectedFile) => {
            // console.log('inside uploadImage_______________________________________________________', base64EncodedImage)
            // try {



                const formData = new FormData();
                formData.append('image', selectedFile);
                
                // alte cloudinary route STEHENLASSEN
                const requestObj={
                    url:'upload/cloud', 
                    method: 'POST',
                    data: {...data, source: base64EncodedImage.toString() },
                    headers: { 'Content-Type': 'application/json' }
                };
            
            
            
                //    const url = 'freeimages/upload'
               
                // STEHEN LASSEN
                // const requestObj={
                //     url:'freeimages/upload', 
                //     method: 'POST',
                //     data:{image: base64EncodedImage.toString()},
                //     headers: { 'Content-Type': 'application/json' }
                // };

                const response = await advancedRequest(requestObj);
                // console.log(response);
                if(response.data && response.data.cloudUrl){
                    (() => setUrlForTable(response.data.cloudUrl))();
                }else{
                    const msg = (response.response && response.response.data && response.response.data.error && response.response.data.error.message);
                    (developer && msg)? flashError(msg) : flashError(translate("Something went wrong by creating the url. Perhaps the connection to the internet does not work."));
                }
    };

        const uploadFileAndGetCloudUrl = async() => {
                    // console.log('inside uploadImageAndGetCloudUrl', data.selectedFile);
                    if(!data.selectedFile)return;
                    const  {selectedFile} = data;
                    const resizedFile = selectedFile.type.includes("image") ? await(resizeFile(selectedFile)) : selectedFile;
                    const reader = new FileReader();
                    // console.log({resizedFile});
                    reader.readAsDataURL(resizedFile);
                    
                    // reader.readAsDataURL(data.selectedFile);
                    reader.onloadend = async() => await uploadImage(reader.result, selectedFile);
        };

        const uploadUrlAndGetCloudUrl = async () => {
            // console.log('inside uploadUrlAndGetCloudUrl', {data});
            await uploadImage(data.value);
        }; 

        const isFormError = () => {
            let error = ""
            if(["file", "url"].includes(data.method) && !rightsConfirmed) error = (translate("Please confirm"));
            if(!data.value) error = (translate("No data."));
            if(error){
                flashError(error)
                return true;
            };
            return false;
        };
        
        const flashError = (errMsg) => {
            (() => setFieldError(errMsg))();
            (() => setTimeout(() => setFieldError(null), 3000))();
        };
        


        

        const handleSubmit = async(e) => {
            e.preventDefault();
            
            // console.log("BUTTON GEDRÜCKT", {data}, data.method, data.value);
            if(isFormError() && data.method !== "remove")return;
            if(data.method === "file"){
                await uploadFileAndGetCloudUrl();
            };
            if(data.method === "url"){
                await uploadUrlAndGetCloudUrl();
            };
            if(data.method === "link"){
                (() => setUploadData({changed:true, data:{[title]:data.value}, method:data.method}))();
            }
            if(!urlForTable){
                // flashError("Something went wrong by creating the link.");
                return;
            };
            (() => setPreviewSource(""))();
            (() => setData({method:"file", title}))();
            (() => setUploadArea(false))();
            return;
        
        };
            
        useEffect(() => (() => setHintArea(["file", "url"].includes(data.method)))(), [data]);
        
        const displayedTitles = {url:"Document", documentUrl:"Document", photoUrl:"Photo", photo:"Photo", logo:"Logo"}
        const displayedTitle = translate(displayedTitles[title]) || translate("Photo");
        
        const handleCancel = e =>{
            e.preventDefault();
            (() => setUploadArea(false))();
        }
        // console.log({selectedFile});
        // console.log({uploadData});

        // console.log(data.method, {data})
        return(
        <div className="m-1 p-lg-5 p-md-3 p-sm-2 text-left" 
              style={{border:"1px solid lightgrey", width:"100%", borderRadius:"5px", backgroundColor:"rgba(255,255,255,.6)"}}>
            <h5>{displayedTitle}</h5>
            
            <h6 className="mt-3">{translate("Please select")}:</h6><br/>

            <Form>
                {methods.map(method => {
                    // console.log({method}, data.method, method===data.method);
                    
                    return(
                    <Form.Check
                      key={`inline-radio-${method}`}
                      defaultChecked = {data.method === method}
                      onChange = {e => handleChangeCheck(e)}
                      label={method === "file" ? translate("Upload local file")
                                :method==="url"? translate("Upload data from URL (data will be stored)")
                                :translate("Insert a link (only the link will be stored)")}
                      name="selectMethod"
                      type="radio"
                      id={method}
                      value={method}
                    />
                );
                })}

                {data.method === "file" && <UploadFile data={data} setData={setData} setPreviewSource={setPreviewSource
                    } previewSource={previewSource} fileInputState={fileInputState} selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile} setFileInputState={setFileInputState}/>}
                {data.method === "url" && <UploadLink data={data} setData={setData}/>}
                {data.method === "link" && <InsertLink data={data} setData={setData}/>}
                {hintArea && <HintArea rightsConfirmed={rightsConfirmed} setRightsConfirmed={setRightsConfirmed} />}
                
                {fieldError && <h6 className="text-danger">{fieldError}</h6>}
                <div className="row p-1">
                <div className = "col-md-4 my-1"><button type="submit" className="btn btn-outline-dark col-12" onClick={e => handleSubmit(e)}>{translate(`Add/Replace`)}</button></div>
                <div className = "col-md-4 my-1"><button className="btn btn-outline-danger col-12" onClick={e => handleRemove(e)}>{translate(`Remove`)}</button></div>
                <div className = "col-md-4 my-1" ><button className="btn btn-outline-secondary col-12" onClick = {e => handleCancel(e)}>{translate("Cancel")}</button></div>
                </div>
                
            </Form>
        </div>
        )
    };
    export default UploadAreaSingle;