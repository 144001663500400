import React, {useEffect, useState} from "react";
import {Card, Accordion, Button} from 'react-bootstrap'
// import {useDispatch} from 'react-redux'
import {translate} from '../Language/Translate';
import { BlackWhite } from "../styledComponents/colors";
import { simpleRequest } from "../../newForms2/simpleRequest";
import Spinner from "../Spinner";
import NewDestinationList from "./NewDestinations/NewDestinationList";
import NewsBadge from "../NewsBadge";

const CardNewDestinationDays = ({eventKey}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [newDays, setNewDays] = useState([]);
    
    useEffect(() => {
        const fetch = async() =>  {
            const allDays = (await simpleRequest({url:"days", method:"get"})).data || [];
            // console.log({allDays});
            const destDays = allDays.filter (d => d.destination !== null && d.destinationId === 0);
            // console.log({destDays});
            (() => setNewDays(destDays))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    });
    if (isLoading)return <Spinner/>
    // console.log({newDays});
        
    return (
        <Card style={{backgroundColor:'#e5e9df', color:"#1a1620"}}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("New Destinations in Diaries")} <NewsBadge count = {newDays.length}/></BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
                <Card.Body>
                    <NewDestinationList newDays={newDays} setNewDays={setNewDays}/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};
export default CardNewDestinationDays;