// import FormCruiseNotFoundForm from "./FormCruiseNotFound";
import {translate} from '../../components/Language/Translate'

class CruiseProposalSchema {
    constructor(vessels){
        this.fields = {
            vesselId : {
                fieldType: "select", 
                label:translate("Select Vessel"), 
                autoFocus:true,
                initialValue:'-1', 
                options: this.writeVesselSelectList(vessels)
            },
            vessel : {
                fieldType: "text",
                label:translate("Vessel"), 
                initialValue:"", 
                placeholder:`... ${translate("or write the ship's name in this field.")}`,
            },
            // shorthand : {
            //     fieldType: "text",
            //     label:translate("Shorthand"), 
            //     initialValue:"",
            //     toolTip:"Optional"
            // },
            startAt : {
                fieldType: "text",
                label:translate("Start at"), 
                placeholder: translate('e.g.')+": 2021-09-09",
                required:true, 
                initialValue:"",
                toolTip:translate("Please insert the date with the format yyyy-mm-dd."),
                validation:{validDateString:true}
            },
            startPort: {
                fieldType: "text",
                label:translate("Start Port"), 
                initialValue:"",
                required :true,
                toolTip:translate('Please insert a port or "unknown".')
            },
            endAt : {
                fieldType: "text",
                label:translate("End at"), 
                placeholder: translate('e.g.')+": 2021-10-09",
                required:true,
                toolTip:translate("Please insert the date with the format yyyy-mm-dd."),
                initialValue:"",
                validation:{validDateString:true}
            },
            endPort: {
                fieldType: "text",
                label:translate("End Port"),
                required:true, 
                initialValue:"",
                toolTip:translate('Please insert a port or "unknown".')
            },
            title: {
                fieldType: "text",
                label:translate("Title"), 
                initialValue:"",
                toolTip:translate("Optional, eg: Cruise through the Baltic See")
            },
            description: {
                fieldType: "textarea",
                label:translate("Description"), 
                initialValue:"",
                toolTip:"Optional"
            }
        };
        

    };
    static sendData = ["vesselId", "vessel", "shorthand", "startAt", 
            "startPort", "endAt", "endPort", "title", "description"];
    
    writeVesselSelectList(vessels){
        const vesselSelect = vessels && vessels.map(v => {
            const value = `${v.prefix} ${v.name} - ${v.companyName}${v.note?` (${v.note})`:""}, ${translate(v.type)}`;
            return {key: v.id.toString(), value}
        });
        vesselSelect.unshift({key:"-1", value:translate('Please select a vessel')})
        return vesselSelect;
    };
};

export default  CruiseProposalSchema;