import DateTests from "./DateTests";




class Test{
    static async testFunctions(){
        // return DateTests.actualDateString();           
        // return DateTests.actualTimeString();           
        // return DateTests.actualDateTimeString();     
        return DateTests.getDateObject();                
        // return DateTests.formatDate();
        // return DateTests.actualDateString();
        // return DateTests.actualDateTimeString();
        // return DateTests.dateObject();
        // return DateTests.dateTimeStringToDate();
        // return DateTests.Duration();
        // return DateTests.ensureDateTimeFormat();
        // return DateTests.formatDate();
        // return DateTests.monthsDE(); 
        // return DateTests.monthsEN();
        // return DateTests.readTimeStamp();
        // return DateTests.smartTimeDifference();
        // return DateTests.smartTimeDiffText();
        // return DateTests.timeStampToString();
        // return DateTests.weekdaysDE();
        // return DateTests.weekdaysEN();
        // return DateTests.writeTimeString();
        
    }
};
export default Test;