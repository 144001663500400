import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux';
import { Translate } from "../../components/Language/Translate";


const DestinationList = ({variables, setVariables, setChanged}) => {
    // const [isLoading, setIsLoading] = useState(true);
    const [filteredList, setFilteredList] = useState([]);
    // const dispatch = useDispatch();
    const destinations = useSelector(st => st.destinations);
    const language = useSelector(st => st.currentUser).language || Translate.language;
    
    useEffect(() => {
        const chars = variables.destination;
        const filt = destinations?.filter(d => {
        return (
            (chars?.length > 2 &&
                (
                    d.deDE.toLowerCase().includes(chars.toLowerCase())
                    || d.enUK.toLowerCase().includes(chars.toLowerCase())
                )
                ) 
                )
            });
        setFilteredList(filt || []);
    }, [destinations, variables, setFilteredList]);

    const handleClick = e => {
        // console.log(e.target, e.target.dataSet, e.target.value, e.target.innerText);
        (() => setVariables({...variables, destinationId:+e.target.id, destination:e.target.innerText}))();
        (() => setChanged(true))();
    };


    

    return(
        <ul className = "my-2">
            {filteredList.map(dt => <li 
                                        key={dt.id} id={dt.id}
                                        onClick={e => handleClick(e)}
                                    >
                                        {`${dt[language]}`}
                                    </li>)}
                                        
        </ul>
    );
};
export default DestinationList;