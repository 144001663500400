import React from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../../../components/Language/Translate";

const TextField = ({name, variables, label, handleChange}) => (
        <Form.Group>
        <Form.Label>{translate(label)}</Form.Label>
        <Form.Control
            name={name}
            value={variables[name]}
            id="arrival"
            type="text"
            onChange = {e => handleChange(e)}
            />
        </Form.Group>
    );
export default TextField;