import { countries, countriesDE } from "./countriesCurrencies";
// import regeneratorRuntime from "regenerator-runtime";

class CountryFunctions{


    static getCountryList(language = "enUK"){
        if (language === "deDE") {
            return countriesDE.map(c => c.country)
        }else{
            return countries.map(c => c.country)
        }
    }
    static getShortCurrencyFromCountry(country){
        // => finds country in all country lists, returns e.g. "EUR" or "invalid"
        const enIdx = countries.findIndex(c => c.country === country);
        const deIdx = countriesDE.findIndex(c => c.country === country);
        // console.log({country, enIdx, deIdx})
        if(enIdx !== -1)return countries[enIdx].short;
        if(deIdx !== -1)return countriesDE[deIdx].short;
        return "invalid";
    }
    
    static getLongCurrencyFromCountry(country){
        // "Algerien" => {currency: {short: 'DZD', symbol: 'Ï»Ï¼',name: { enUK: 'Algerian Dinar', deDE: 'Algerischer Dinar' }}}
        const short = this.getShortCurrencyFromCountry(country);
        // console.log({short});
        if(short === "invalid") return {currency:{short, symbol:"invalid", name:{enUK:"invalid", deDE:"invalid"}}};
        const idxEn = countries.findIndex(c => c.short === short);
        const idxDe = countriesDE.findIndex(c => c.short === short);
        // console.log({idxDe, idxEn, short});
        const symbol = (countries[idxEn] && countries[idxEn].symbol) || "invalid";
        const enUK = (countries[idxEn] && countries[idxEn].name) || "invalid";
        const deDE = (countriesDE[idxDe] && countriesDE[idxDe].name) || "invalid";
        // console.log({currency:{short, symbol, name:{enUK, deDE}}});
        return {currency:{short, symbol, name:{enUK, deDE}}};
    }
    static getLongCurrencyFromShort(short){
        // "EUR" => {short: 'EUR',symbol: 'Ôé¼',name: { enUK: 'European Euro', deDE: 'Euro' }}
        const idxEn = countries.findIndex(c => c.short === short);
        const idxDe = countriesDE.findIndex(c => c.short === short);
        // console.log({idxDe, idxEn, short});
        // if(idxDe < 0 || idxEn < 0)return{name:null};
        const symbol = (countries[idxEn] &&  countries[idxEn].symbol) || short;
        const en= (countries[idxEn] && countries[idxEn].name) || "invalid";
        const de= (countries[idxDe] && countriesDE[idxDe].name) || "invalid";
        return {short, symbol,  name:{enUK: en, deDE: de}};
    }
    static getShortList(){
        // ["EUR", "USD"....]
        const newSet = new Set();
        countries.forEach(c => newSet.add(c.short));
        countriesDE.forEach(c => newSet.add(c.short));
        return Array.from(newSet)
    }
    static getNameObject(language){
        // {AED: 'VAE-Dirham', AFN: 'Afghani'}

        const newObj = {};
        if (language === "deDE") {
            countriesDE.forEach(c => newObj[c.short] = c.name);
        }else{
            countries.forEach(c => newObj[c.short]=c.name);
        }
        return newObj;
        
    }
    static getSymbolObject(){
        const newObj = {};
        // countries.forEach(c => // console.log({c}, c.short))
        countries.forEach(c => newObj[c.short] = c.symbol || c.short)
        return newObj;
    }
  }


export default CountryFunctions;