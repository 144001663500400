import React from "react";
import { Route, Switch } from "react-router-dom";
import './App.css';
import Logout from './Logout'
import Home from "./Home";
import CompanyList from "./CompanyList";
import CompanyDetails from "./CompanyDetails"
import VesselList from "./VesselList";
import CruiseList from "./CruiseList";
import CruiseDetails from "./CruiseDetails/CruiseDetails";
import UserList from "./UserList";
import UserArea from "./UserArea"
import UserForm from '../forms/users/UserForm';
import UserForm2 from '../forms/users/UserForm2';
// import OtherUser from './OtherUser'
import NotFound from './NotFound';
import VesselDetails from './VesselDetails';
import AdminArea from "./Admin/AdminArea";
import DestinationList from "./Destinations/DestinationList";
import DestinationDetails from "./Destinations/DestinationDetails";
import AuthorDetails from "./Admin/AuthorDetails";
import ArticleDetails from "./Admin/ArticleDetails";
import Editorials from "./Admin/Editorials";
import Tools from "./Tools/Tools";
import DebugArea from "./DebugArea";
import ProposalsNewForm from '../forms/proposals/ProposalsNewForm'
import ProposalsEditForm from '../forms/proposals/ProposalsEditForm'
import UserEditByUser from '../newForms2/users/UserEditByUser'
import CruiseProposalNew from "../newForms2/FirstSteps/CruiseProposalNew";
import CompanyNew from '../newForms2/companies/CompanyNew';
import UserPasswordInsertCodeAndPw from "../newForms2/users/UserPasswordInsertCodeAndPw";
// import UserConfirmationCode from "../newForms2/users/UserConfirmationCode";
import AGBs from "./AGB/AGB";
import Bildnachweis from "./AGB/Bildnachweis";
import Impressum from "./AGB/Impressum";
import Datenschutz2 from './AGB/Datenschutz2'
import UserRegister from "../newForms2/users/UserRegister";
import Main from '../newForms2/FirstSteps2/Main';
import UserLogin from "../newForms2/users/UserLogin";
import { FAQ} from "./FirstSteps3/FAQ";
import TestFrontendUrl from "./TestArea/urls/TestFrontendUrl";
import UrlConfirmation from "./UrlConfirmation";
import OtherUser2 from "./OtherUser2";
import NewPassword from "../newForms2/users/NewPassword";
import UserForgotPassword from "../newForms2/users/UserForgotPassword";
import UserAreaCardProfile from "./UserAreaCardProfile";
import DeleteAccount from './DeleteAccount';
import EmailConfirmation from "../newForms2/users/EmailConfirmation";
import UnSubscribe from "./UnSubscribe";
import DeleteAccountTarget from "./DeleteAccountTarget";
// import PhotosMain from "./Photos/PhotosMain";
import AlbumList from "./Albums/AlbumList";
import ButtonAuth from "./Auth/ButtonAuth";
// import Observations from "./Observations";
import ObservationsAllMain from "./species/species/all/ObservationsAllMain";
import PhotoMonthMain from "./PhotoMonth/PhotoMonthMain";
import ProposalsEdit from "../newForms2/proposals/ProposalsEdit";
import DaysMain from "../newForms2/daysNew/DaysMain";

import ChatMain from "./TravelChat/ChatMain";
import HeicInput from "../newForms2/uploadNewHeic/HeicInput";
// import ChatGroupsMain from "./ChatGroups/ChatGroupMain";


const Routes = () =>{
    // console.log("Routes");
    // console.count('Routes')
    return (
        <>
        <Switch>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/login">
              {/* <UserForm2 formType="userLogin" /> */}
              <ButtonAuth service = "Google"/>
              <ButtonAuth service = "Facebook"/>
              <UserLogin/>
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/register">
              <ButtonAuth service = "Google"/>
              <ButtonAuth service = "Facebook"/>
              <UserRegister/>       
            </Route>
            <Route exact path="/deleteform">
              <DeleteAccount />       
            </Route>
            <Route exact path="/delete/:username/:email/:code">
              <DeleteAccountTarget />       
            </Route>
            <Route exact path="/admin">       
              <AdminArea/>
            </Route>
            <Route exact path="/admin/advertisement/:cruise_id/new">       
              <UserForm formType="advertisementNew"/>
            </Route>
            <Route exact path="/admin/advertisement/:id/edibt">       
              <UserForm formType="advertisementEdit"/>
            </Route>
            <Route exact path="/editorials">       
              <Editorials/>
            </Route>
            <Route exact path="/Start">       
              <Main/>
            </Route>
            <Route exact path="/authors/new">       
              <UserForm formType="authorNew"/>
            </Route>
            <Route exact path="/authors/:username">
              <AuthorDetails />
            </Route>
            <Route exact path="/authors/edit/:username">
              <UserForm formType="authorEdit" />
            </Route>
            <Route exact path="/articles/new">
              <UserForm formType="articleNew" />
            </Route>
            <Route exact path="/articles/edit/:id">
              <UserForm formType="articleEdit" />
            </Route>
            <Route exact path="/articles/:id">
              <ArticleDetails />
            </Route>
            <Route exact path="/cruises">
              <CruiseList />
            </Route>
            <Route exact path="/chatgroups">
              <ChatMain/>
            </Route>
            <Route exact path="/cruises/new">
                <UserForm formType="cruiseNew" />
            </Route>
            <Route exact path="/cruises/:cruise_id">
              {/* <p>Dietmar</p> */}
                <CruiseDetails />
            </Route>
            <Route exact path="/routing">
              {/* <p>Dietmar</p> */}
                <DaysMain/>
            </Route>

            <Route exact path="/cruises/:cruise_id/edit">
                <UserForm formType="cruiseEdit" />
            </Route>
            <Route exact path="/days/new/:cruise_id/:date">
                <UserForm formType="dayNew" />
            </Route>
            <Route exact path="/announcements/new/:cruise_id">
                <UserForm formType="announcementNew" />
            </Route>
            <Route exact path="/announcements/edit/:id">
                <UserForm formType="announcementEdit" />
            </Route>
            <Route exact path="/pinboard/new/:cruise_id">
                <UserForm formType="pinboardNew" />
            </Route>
            <Route exact path="/pinboard/edit/:id">
                <UserForm formType="pinboardEdit" />
            </Route>
            <Route exact path="/admin/users/:username">
              <UserForm formType="userEditByAdmin"/>
            </Route>
            <Route exact path="/users">
              <UserList />
            </Route>
            <Route exact path="/users/:username">
              <UserArea />
            </Route>
            <Route exact path="/users/:username/profile">
              <UserAreaCardProfile/>
            </Route>
            <Route exact path="/users/show/:username">
              {/* <OtherUser /> */}
              {/* <p>________________________________</p> */}
              <OtherUser2 />
            </Route>
            <Route exact path="/users/currentUser/edit">
              <UserEditByUser />
            </Route>
            <Route exact path="/users/cruises/join">
              <UserForm formType="connectUserCruiseNew"/>
            </Route>
            <Route exact path="/password/request">
              {/* <UserForm formType="userForgotPassword"/> */}
              <UserForgotPassword/>
            </Route>
            <Route exact path="/password/code">
              <UserPasswordInsertCodeAndPw/>
            </Route>
            <Route exact path="/code/:type">
              <UserForm formType="codeInput" />
            </Route>
            <Route exact path="/confirmation/:username/:code">
              <EmailConfirmation />
            </Route>
            <Route exact path="/messages/:sentTo">
                <UserForm formType="messageNew" />
            </Route>
            <Route exact path="/friends/apply/:friendName">
                <UserForm formType="friendNew" />
            </Route>
            <Route exact path="/companies">
              <CompanyList />
            </Route>        
            <Route exact path="/companies/new">
                <CompanyNew />
            </Route>
            <Route exact path="/companies/:handle">
              <CompanyDetails />
            </Route>
            <Route exact path="/cruisecrew/new/:id">
              <UserForm formType="cruiCrewNew" />
            </Route>
            <Route exact path="/cruisecrew/edit/:id">
              <UserForm formType="cruiCrewEdit" />
            </Route>
            <Route exact path="/destinations">
              <DestinationList />
            </Route>
            <Route exact path="/destination/details/:id">
              <DestinationDetails />
            </Route>
            {/* <Route exact path="/destination/new">
              <UserForm formType="destinationNew" />
            </Route> */}
            <Route exact path="/destination/edit/:id">
              <UserForm2 formType="destinationEdit" />
            </Route>
            <Route exact path="/destination/proposals/:destinationId">
              <ProposalsNewForm />
            </Route>
            <Route exact path="/proposals/:id">
              <>
              <ProposalsEditForm />
              <ProposalsEdit/>
              </>
            </Route>
            <Route exact path= "/debug">
              <DebugArea />
            </Route>
            <Route exact path="/vessels">
              <VesselList />
            </Route>
            <Route exact path="/vessels/:vessel_id">
              <VesselDetails />
            </Route>
            <Route exact path="/vessels/edit/:vessel_id">
                <UserForm formType="vesselEdit" />
            </Route>
            <Route exact path="/vessels/:vessel_id/new_cruise">
              <UserForm formType="cruiseNew"/>
            </Route>
            <Route exact path="/tools">
              <Tools/>
            </Route>
            <Route exact path="/firststeps/form">
              <CruiseProposalNew />
            </Route>
            <Route exact path ="/agbs" >
              <AGBs/>
            </Route>
            <Route exact path ="/bildnachweis" >
              <Bildnachweis/>
            </Route>
            <Route exact path ="/impressum" >
              <Impressum/>
            </Route>
            <Route exact path ="/datenschutz" >
              <Datenschutz2/>
            </Route>
            <Route exact path ="/faq" >
              <FAQ/>
            </Route>
            <Route exact path ="/photoalbums" >
              <AlbumList/>
            </Route>
            <Route exact path ="/photomonth" >
              <PhotoMonthMain/>
            </Route>
            <Route exact path ="/test" >
              <HeicInput/>
            </Route>
            <Route exact path = "/testUrl/:code">
              <TestFrontendUrl/>
            </Route>
            <Route exact path = "/url/emailconfirmation/:username/:code">
              <UrlConfirmation/>
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/newpassword/:username/:code">
              <NewPassword />
            </Route>
            <Route exact path="/unsubscribe/:email/:code">
              <UnSubscribe/>
            </Route>
            <Route exact path="/observations">
              <ObservationsAllMain/>
            </Route>
            <Route path="/">
              <NotFound />
            </Route>
          </Switch>
        </>
    )
}

export default Routes;