import React from "react";
import { Translate, translate } from "../../../components/Language/Translate";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";
import DaysToursActions from "./DaysToursActions";
import { getDateObject } from "../../../helpers/Date";

const DaysToursDates = ({props}) => {
    const {days, date, idx} = props;
    // console.log({props});
    const localDays = days.filter(d => date === d.date);
    return(
        <StyledDivWithBorder>
            <h5 className="p-1">
                {getDateObject(date, Translate.language, "UTC").longWeekday}, {translate("Day")} {idx+1}
            </h5>
            {localDays.map(ld => <DaysToursActions props = {{...props, localDays, action:ld}}/>)}
        </StyledDivWithBorder>
    )
};
export default DaysToursDates;