import React, { useEffect, useState, useMemo } from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import Spinner from '../Spinner'
import Rekursion from "./Rekursion";

const Variables = () => {
    const [variables, setVariables] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const processEnvArr = useMemo(() => [
        'NODE_ENV',
        'FRONTEND_URL',
        'BACKEND_URL',
        "PORT",
        "DATABASE_URL",
        'PG_PASSWORD',
        'MAIL_PASSWORD_GMX',
        'SENDGRID_API_KEY',
        'FOREX_ACCESS_KEY',
        'NODE_API_KEY',
        'TOKEN_SECRET_KEY',
        'CLOUDINARY_CLOUD_NAME', 
        'CLOUDINARY_API_KEY',
        'CLOUDINARY_API_SECRET',
        'CLOUDINARY_URL'
    ],[]);
  
    useEffect(()=>{
        const fetch = async () => {
            const response = await simpleRequest({method:"get", url:`admin/variables`})
            const v = response.data || {};
            const pe = processEnvArr.map(p => {
                return (v && v.process_env && {[p]:v.process_env[p] || ""});
            });
            // console.log({v, pe})
            v.process_env= pe;
            // console.log({v})
            setVariables(v);
            setIsLoading(false)
        }
        if(isLoading)fetch();
        
    }, [isLoading, processEnvArr])

    if(isLoading)return <Spinner />
    // console.log({variables})
    if(!variables)return<p>no values</p>


    // console.log({Rekursion})
    return(
        <div>
            <h4>Variables</h4>
            {/* <p>{JSON.stringify(variables)}</p> */}
            <div><Rekursion props={variables} name="Variables"/></div>
        </div>
    )



}
export default Variables;