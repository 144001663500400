import React from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import {translate} from "./Language/Translate"
import {BlackWhite} from './styledComponents/colors';
import CruiseAlbumsList from "./CruiseAlbumsList";


const CruiseAlbumsCard = ({eventKey, defaultKey, albums}) =>{
    return(
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} onClick={() => defaultKey.current=eventKey}>
            <BlackWhite>{translate("Photo Albums")}</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey} id={eventKey} >
          <Card.Body>
              <div className="p-3">
                <CruiseAlbumsList albums={albums}/>
              </div>
          </Card.Body>
        </Accordion.Collapse>
    </Card>
    )
}

export default CruiseAlbumsCard;