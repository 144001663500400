import React,{useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux"
import {Accordion} from 'react-bootstrap';
import UserAreaCard from './UserAreaCard'
import {fetchCurrentUserFromApi} from '../actions/currentUser'
import { translate } from "./Language/Translate"
import Spinner from './Spinner';
import CruiseBackToCruiseButton from "./CruiseBackToCruiseButton";
import User from "../helpers/user";
import { fetchGroupsFromApi } from "../actions/groups";
// import UserMap from "./User/UserMaps/UserMap";
// import UserMap from "./User/UserMaps/UserMap";
// import Groups from '../helpers/groups';

const UserArea = () =>{
    // const { username } = useParams();
    // const [serverErrors, setServerErrors] = useState (null)
    const currentUser = useSelector(st => st.currentUser);
    const  username  = useParams().username || currentUser.username;
    // const showNext = useParams();
    const currentCruise = useSelector(st => st.currentCruise);
    const groups = useSelector(st => st.groups);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    // console.log('RERENDER USER AREA', isLoading);
    // console.log({currentUser});
    
    
    useEffect(() =>{
        // console.log('inside useEffect, _____________________________________________________________', username);
        const fetch = async (username) => {
            
            const token=currentUser && currentUser.token;
            (() => dispatch(fetchCurrentUserFromApi(username, token)))();
            (() => dispatch(fetchGroupsFromApi(currentCruise && currentCruise.id)))();
        };
        if (isLoading) {
            fetch(username);
            (() => setIsLoading(false))();
        }
    },[isLoading, setIsLoading, currentCruise, currentUser, dispatch, username]);

    if(isLoading) return <Spinner/>

    const news = User.news(currentUser, groups);
    const {countMessages, countFriendRequests, countGroupChats, countGroupsInvited} = news;
    
    // console.log({currentUser});
    return(
        <div className = "text-center">
            <CruiseBackToCruiseButton/>
            {/* <h6 className="ml-3 mt-5">{`${currentUser.nickname}`}</h6> */}
            {/* <UserMap/> */}
            <Accordion>
                <UserAreaCard eventKey='3' type = "showCruises" title={`${translate("Your Cruises")} (${currentUser.cruises && currentUser.cruises.length})`} setIsLoading={setIsLoading}/>
                <UserAreaCard eventKey='6' type = "showFavourites" title={`${translate("Your Favourites")} (${currentUser.cruises && currentUser.favourites.length})`} setIsLoading={setIsLoading}/>
                <UserAreaCard eventKey='5' type = "showMap" title={translate("Your Map")} setIsLoading={setIsLoading}/>
                <UserAreaCard eventKey='2' type = "showMessages" title={`${translate("Your Messages")}`} count = {countMessages} setIsLoading={setIsLoading}/>
                <UserAreaCard eventKey='4' type = "showGroups" title={translate("Your Groups")} count = {countGroupChats + countGroupsInvited} setIsLoading={setIsLoading}/>
                <UserAreaCard eventKey='1' type = "showFriends" title={translate("Your Friends")} count = {countFriendRequests} setIsLoading={setIsLoading}/>
                <UserAreaCard eventKey='0' type = "showProfile" title={translate("Your Profile")} setIsLoading={setIsLoading}/>
            </Accordion>
        </div>
    );
};

export default UserArea;



