import { useSelector } from "react-redux";
import { Translate } from "../../Language/Translate";
import Album from "../../../helpers/albums/albumObj";
import PhotoShowProvider from "../PhotoShowProvider";

const PhotoShowDestination = () => {
    // console.log({Album});
    const currentDestination = useSelector(st => st.currentDestination);
    const language = Translate.language || "enUK";
    // console.log({currentDestination});
    let allPhotos = [];
    currentDestination.albums?.forEach(a => {
        allPhotos = [...allPhotos, ...a.photos.map(p => {
            const newTitle = Album.analyze(a, language);
            return {...p, title:newTitle}
        })]
    });
    // console.log({allPhotos});

    return(
        <div className = "p-2 text-center" >
        {/* <div className = "p-2 text-center" style={{border:"1px solid grey"}} > */}
            {/* <h5>Alben</h5> */}
            <PhotoShowProvider photos = {allPhotos}/>
        </div>
    );
};
export default PhotoShowDestination;