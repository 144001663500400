import React from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import { BlackWhite } from "../../styledComponents/colors";
import { translate } from "../../Language/Translate";
import { useSelector } from "react-redux";
import ProposalsMain from "./ProposalsMain";
import NewsBadge from '../../NewsBadge';
import DestinationClass from "../../../helpers/destinationClass";
import Symbols from "../../Symbols";


const ProposalsCard = ({eventKey}) => {
  const currentDestination = useSelector(st => st.currentDestination);
  const len = currentDestination?.proposals?.length || 0;
  // console.log({currentDestination});

    return(
        <Card>
            <Card.Header className="text-center">
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
                <BlackWhite>{translate("Touristic Proposals")} <NewsBadge count={len}/></BlackWhite>
                <Symbols typeArray={DestinationClass.getProposalArray(currentDestination)}/>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey}>
              <Card.Body>
                <ProposalsMain/>
              </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};
export default ProposalsCard;