// 4.12.23
import React, {useState, useEffect} from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { translate } from "../Language/Translate";
import AlbumDetails from "./AlbumDetails";
import AlbumInitialList from "./AlbumInitialList";
import Spinner from "../Spinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchPhotoLikesFromApi } from "../../actions/photoLikes";

const AlbumList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [albums, setAlbums] = useState([]);
    const [albumId, setAlbumId] = useState(0)
    const [view, setView] = useState('list') // details
    const dispatch = useDispatch();
    const photoLikes = useSelector(st => st.photoLikes);

    useEffect(() => {
        const fetch = async () => {
            const data = (await simpleRequest({url:"albums"})).data;
            (() => setAlbums(data.albums))();
            if(photoLikes.length === 0)dispatch(fetchPhotoLikesFromApi());
            (() => setIsLoading(false))();

        }
        console.log('inside useEffect___________________________________');
        if(isLoading)fetch();
    }, [isLoading, photoLikes, dispatch]);


    if(isLoading)return <Spinner/>

    const handleClick = async(e) => {
        setAlbumId(+e.target.id);
        setView("details");
    };
    console.log({photoLikes, albums, albumId, view, isLoading});
    
    return(
        <div className = "p-2" style={{backgroundColor:"rgba(255,255,255,.5)"}}>
            <h6>{translate("Photo Albums")}:</h6>
            {view === "list" && <AlbumInitialList albums={albums} handleClick={handleClick}/>}
            {view === "details" && <AlbumDetails albumId={albumId} backFunc={() => setView('list')} refresh={() => setIsLoading(true)}/>}
        </div>
    )
};
export default AlbumList;