import React from "react";
import "./MainBoard.css" 
import {useSelector} from "react-redux";
import {readTimeStamp} from '../../../helpers/Date';
import {getUserScores} from './functions';
// import { ScoreResults } from "../../styledComponents/colors";

const UserScores = ({duration, allScores}) => {
    const username = useSelector(st => st.currentUser).username;

    const userScores = getUserScores({username, duration, allScores});
    
    const {best, fastest} = userScores;
    const durationText = duration ? ` (last ${duration} days)` : "";
    return(


        <div className="my-3 p-3" style = {{border:"1px solid grey", borderRadius:"5px",backgroundColor: "rgba(255,255,255,.3)" }}>
            {/* {scores.map(s => <p key={s.id}>{s.createdAt}</p>)} */}
            {(best || fastest) && <h5>Your best results{durationText}:</h5>}
            <p>{best && <i>Best: <b>Counter: {best.counter},</b> Seconds: {best.seconds} ({readTimeStamp(best.createdAt)})<br/></i>}
            {fastest && <i>Fastest: Counter: {fastest.counter}, <b>Seconds: {fastest.seconds}</b> ({readTimeStamp(fastest.createdAt)})</i>}</p>
        </div>

    );
};
export default UserScores;