
// 11.11.23
import {FETCH_AUTHORS} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchAuthorsFromAPI() {
  
  return async function (dispatch) {
    const response = await simpleRequest({url:`authors`, method:"get"});
    const authors = response.data && response.data.authors;
    return dispatch(getAuthors(authors));
  };
};
function getAuthors(authors) {
  return {
    type: FETCH_AUTHORS,
    authors
  };
};

