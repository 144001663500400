import { 
    actualDateString,
    actualTimeString,
    actualDateTimeString,
    dateLanguage,
    dateObjectLocal,
    dateObjectUTC,
    formatDate,
    getDateObject,
} from "./Date";
    
const dateString = "2022-01-15"

class DateTests{

    static actualDateString(){                              
        return {
            local: actualDateString('local'),
            uTC: actualDateString('UTC')
        };
    };
    static actualTimeString(){                              
        return {
            local: actualTimeString('local'),
            uTC: actualTimeString('UTC')
        };
    };
    static actualDateTimeString(){                          
        return {
            local: actualDateTimeString('local'),
            uTC: actualDateTimeString('UTC'),
            unknown: actualDateTimeString()
        };
    };
    static dateLanguage(){
        const localDE = dateLanguage(dateObjectLocal(new Date()), 'deDE');
        const uTCDE = dateLanguage(dateObjectUTC(new Date()), 'deDE');
        const localEN = dateLanguage(dateObjectLocal(new Date()), 'enUK');
        const uTCEN = dateLanguage(dateObjectUTC(new Date()), 'enUK');
        const localDefault = dateLanguage(dateObjectLocal(new Date()));
        const uTCDefault = dateLanguage(dateObjectUTC(new Date()));

        return {localDE, uTCDE, localEN, uTCEN, localDefault, uTCDefault};
    };
    static getDateObject(){
        return {
            UTC: getDateObject("2022-12-01 08:44", 'deDE', "UTC"),
            local: getDateObject("2022-12-01 08:44", 'deDE', "local"),
            db: getDateObject("2022-12-01 08:44", 'deDE', "db")
        };
    };
       
    static formatDate(){
        const de = formatDate(dateString, "deDE");
        const en = formatDate(dateString, "enUK");
        const deft = formatDate(dateString, "default");
        // console.log({dateString, de, en, deft});
        return {dateString, de, en, deft};
    };
};
export default DateTests;