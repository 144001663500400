import {
    FETCH_PHOTO_LIKES,
    ADD_PHOTO_LIKE,
    REMOVE_PHOTO_LIKE,
  } from "./types";
import { simpleRequest } from "../newForms2/simpleRequest";

export function fetchPhotoLikesFromApi() {
    // console.log('inside fetchPhotoLikesFromApi')
    return async function (dispatch) {
      const response = await simpleRequest({url:`photolikes`, method:"get"});
      const photoLikes=response.data && response.data.photoLikes;
      // console.log({photoLikes})
      if(!photoLikes)return null;
      return dispatch(getPhotoLikes(photoLikes));
    };
  };
  
  function getPhotoLikes(photoLikes) {
    return {
      type: FETCH_PHOTO_LIKES,
      photoLikes,
    };
  };

  export function addPhotoLikeOnApi(albumPhotoId){
    // console.log('inside addPhotoLikeOnApi', {albumPhotoId});
    return async function(dispatch){
        const response = await simpleRequest({url:"photolikes", method:"post", data:{albumPhotoId}});
        // console.log({response});
        const photoLike = response.data?.photoLike || null;
        if(!photoLike)return;
        return dispatch(addPhotoLike(photoLike))
    }
  };
  function addPhotoLike(photoLike){
    return{
        type: ADD_PHOTO_LIKE,
        photoLike
    };
  };
  export function removePhotoLikeFromApi(id){
    // console.log('removePhotoLikeFromApi', {id});
    return async function(dispatch){
        const response = await simpleRequest({url:`photolikes/${id}`, method:"delete"});
        // console.log(response, response.data)
        const deleted = response.data?.deleted || null;
        if(!deleted)return;
        return dispatch(removePhotoLike(deleted))
    }
  };
  function removePhotoLike(deleted){
    return{
        type: REMOVE_PHOTO_LIKE,
        deleted
    };
  };