import Form from "react-bootstrap/Form";
import {validateField} from '../ReactFormFunctions';

const CheckBox = (props) => {
    // console.log('inside CheckBox' ,{props})
    // console.log('inside CheckBox', {props})
    const {name, variables, constants} = props;
    const {errors} = variables;
    const {fields} = constants;
    const field = fields[name]
    // const value = variables.fields[name]?isSelected || false;
    const c = variables.fields[name]?true:false;
    const checked = c;
    // console.log({checked})

    const handleCheck=(e,constants, variables)=>{
        // console.log(e.target.checked);
        const {name,checked} = e.target;
        const fields = {...variables.fields,[name]: checked}
        const vars = {...variables,fields: fields}
        constants.events.setVariables(vars);
        // console.log({variables})

    }




    return (

        <Form.Group size="lg" controlId={name}>
        {/* <Form.Label>{field.label}{field.required? ' * ':' '}</Form.Label> */}
        { errors[name]?<small className="text-danger" > - {errors[name]}</small>:null}
        <Form.Check type="checkbox" 
                    label={field.label}
                    key={name}
                    checked={checked}
                    // value={value}
                    name={name}
                    onChange={(e) => handleCheck(e, constants, variables)}
                    onBlur={(e) => validateField(e, constants, variables)}
                    // onTouchEnd={(e) => // console.log('onTouchEnd')}
        />
            {field.toolTip?<p><small className="text-secondary" >{field.toolTip}</small></p>:null}
        </Form.Group>
    
 
    )
}

export default CheckBox;

