import React from "react"

const UsedData = () => (

    <div style={{backgroundColor:"rgba(255,255,255,.7)"}} className="p-4">
                <h4>Folgende Daten werden bei der Registrierung erhoben</h4>
                <h5>1. Benutzername</h5>
                <p>Dies kann ein selbst gewählter Name sein, oder auch eine vom System erstellte zufällige Zeichenkette.
                    Diese ist notwendig, um Benutzereingaben innerhalb des Systems zuordnen zu können. 
                    Aus dem Benutzernamen kann man keine Rückschlüsse auf Nutzerdaten ableiten.
                </p>
                <h5>2. Angezeigter Name</h5>
                <p>Das ist der Name, der auch für andere Benutzern sichtbar ist.<br/>
                    Dies kann der normale Name sein, aber auch ein 
                    Künstlername oder ein Spitzname.
                    Er ist frei wählbar, sollte sich aber von den Namen anderer Benutzer unterscheiden.
                </p>
                <h5>3. Vor- und Zuname</h5>
                <p>Sie werden anderen Benutzern nicht angezeigt.<br/> Im eingeloggten Zustand findet man den eigenen Vornamen in der Startleiste,
                    um zum persönlichen Bereich zu gelangen. In vom System versendeten Emails, wird man mit Vor- und Zuname angesprochen.
                </p>
                <h5>4. Email</h5>
                <p> Emailadressen werden niemals angezeigt, sondern dienen nur der Kommunikation zwischen Cruisefriends.net und dem Benutzer.<br/>
                    Die Emailadresse wird zur Feststellung der Identität benutzt. Bei der Registrierung muss die Emailadresse bestätigt werden.
                    Wenn man sich über ein soziales Netzwerk registriert, geschieht dies meist automatisch.
                    
                </p>
                <h5>5. Profilbild</h5>
                <p>Ein Profilbild ist frei wählbar. Wenn man sich über ein soziales Netzwerk anmeldet, wird es meist übernommen. Es kann im 
                    persönlichen Bereich jederzeit ausgetauscht oder gelöscht werden.
                    Ein persönliches Profilbild wird erwünscht.
                </p>
                <p><b>Es werden keine persönlichen Daten an Dritte weitergegeben.</b></p>
    </div>
);
export default UsedData;







