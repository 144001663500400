import React from "react";
import { useParams } from "react-router-dom";

const UrlConfirmation = () => {
    const {username, code} = useParams();
    

    return(
        <>
        <h3>Email Confirmation</h3>
        <p>{username}</p>
        <p>{code}</p>
        </>
    );
};
export default UrlConfirmation;